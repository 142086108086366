import { Company } from '@/shared/model/company.model';
import { baseAuthAPI } from './base-auth.api'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { User } from '@/shared/model/user.model';
import { UserInfo } from '@/shared/model/user-info.model';
import { FileInfoResponse } from '@/shared/model/file-info-response.model';
import { UserCompany } from '@/shared/model/user-company.model';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const userInfoApiUrl = '/rest/portal/portal/userInfo';
const fileUploadUrl = '/rest/portal/fptfiles'

export const userInfoApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getUserInfo: build.query<UserInfo, void>({
            query: () => ({
                url: userInfoApiUrl,
                method: 'GET',
                cache: 'no-cache'
            })
        }),
        getCurrentUserCompany: build.query<UserCompany, void>({
            query() {
              return {
                url: `/rest/portal/company-user/get-current-user?fetchPlan=userCompany-fetch-plan`,
                method: 'GET',
                cache: 'no-cache'
              }
            },
          }),
        downloadAvatar: build.query<string, string>({
            query(fileId) {
                return {
                    url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
                    method: 'GET',
                    responseHandler: async (response) => URL.createObjectURL(await response.blob()),
                    cache: "no-cache",
                }
            },
        }),

        uploadAvatar: build.mutation<FileInfoResponse, FormData>({
            query(file) {
                return {
                  url: `${fileUploadUrl}`,
                  method: 'POST',
                  formData: true,
                  body: file,
                  cache: 'no-cache'
                }
              },
        }),

        updateUserInfo: build.mutation<UserInfo, Partial<UserCompany>>({
            query(data) {
                const { id, ...body } = data
                console.log('updatedValues::',body)

                return {
                    url: `/rest/portal/company-user/${id}`,
                    method: 'PUT',
                    body:body,
                    // cache: 'no-cache'
                }
            }
        })
    }),
})

export const {
    useGetUserInfoQuery,
    useGetCurrentUserCompanyQuery,
    useDownloadAvatarQuery,
    useUpdateUserInfoMutation,
    useUploadAvatarMutation    
} = userInfoApi

export const {
    endpoints: { getUserInfo,getCurrentUserCompany, downloadAvatar, updateUserInfo, uploadAvatar },
} = userInfoApi