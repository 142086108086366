import React, { useEffect, useState } from 'react'
import HeaderApp from './header';
import { Breadcrumb, Layout, theme, Image, Menu, Avatar } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import BreadcrumbApp from './breadcrumb';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Link } from 'react-router-dom';
import { UploadOutlined, VideoCameraOutlined, FundOutlined } from '@ant-design/icons';
import { AUTHORITIES } from '@/config/constants';
import { hasAnyAuthority } from '@/shared/utils/jmix-rest/security';
import { translations } from '@/i18n/i18n';
import { clearAuthentication } from '@/shared/reducers/authentication.reducer';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import SiderMenu from './menu';
import logoOnly from './images/logoOnly.png';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { AppstoreOutlined, FileTextOutlined, UserOutlined, BankOutlined, ProfileOutlined, TeamOutlined, IdcardOutlined } from "@ant-design/icons";
import { setSiderCollapsed } from '../layout.reducer';
import AppFooter from './footer';
import './index.css'
import { Company } from '@/shared/model/company.model';
import { NEW_CODE } from '@/config/config';

const { Header, Content, Sider } = Layout;
export default function AppLayout(props: any) {
    const { children } = props;
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const siderCollapsed = useAppSelector(store => store.layoutapp.siderCollapsed)
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const permissions = useAppSelector(state => state.authentication.permissions);
    const userName = useAppSelector(state => state.authentication.userName);
    let isMobile = useIsMobile();
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);

    useEffect(() => {
        console.log("NEW_CODE", NEW_CODE);
        if (isMobile) dispatch(setSiderCollapsed(true))
    }, [NEW_CODE])

    useEffect(() => {
        if (isMobile) dispatch(setSiderCollapsed(true))
        console.log(siderCollapsed)
    }, [isMobile])

    return (
        <Layout className='app-layout'>
            <Layout>
                <Sider
                    width={264}
                    style={{ background: colorBgContainer }}
                    trigger={null}
                    collapsible
                    collapsed={siderCollapsed}
                >
                    <Link key={'logo'} to={'/home/common'}>
                        <div style={!siderCollapsed ? { background: 'rgb(47, 87, 250)' } : { background: 'blue', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >

                            <span>
                                <Image
                                    style={!siderCollapsed ? { width: "auto", height: '64px', marginLeft: 40 } : {}}
                                    preview={false}
                                    src={!siderCollapsed ? "https://w.ladicdn.com/s500x400/60b444ceeba2a30012e68735/logo-white-w-20230928005958-f031a.png" : logoOnly}
                                />
                            </span>

                        </div>
                    </Link>
                    {/* <SiderMenu /> */}
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                    >
                          {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key={'Dashboard'} className='MenuItem' icon={<FundOutlined />}>
                                <Link
                                    key={'admin'}
                                    to={'/admin'} >
                                    <span >{t(translations.header.settingMenu.dashboard)}</span>
                                </Link>
                            </Menu.Item>
                        )}

                        {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key={'Onboarding'} className='MenuItem' icon={<FileTextOutlined />}>
                                <Link
                                    key={'admin/onboarding'}
                                    to={'/admin/onboarding'} >
                                    <span >{!companyEntity?.isOnboardingSuccess ? t(translations.header.settingMenu.onboarding) : t(translations.header.settingMenu.afterOnboarding)}</span>
                                </Link>
                            </Menu.Item>
                        )}
                        {!!isAuthenticated && (
                            <Menu.Item key="userinfo" icon={<UserOutlined />}>
                                <Link
                                    key={'/admin/userinfo'}
                                    to={'/admin/userinfo'} >
                                    <span >{t(translations.header.settingMenu.userInfo)}</span>
                                </Link>
                            </Menu.Item>
                        )}
                        {!isAuthenticated && (<Menu.Item key="signin" icon={<AppstoreOutlined />} onClick={() => auth.signinRedirect()}>{t(translations.header.settingMenu.login)}</Menu.Item>)}
                        

                        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key="entities/bank" icon={<BankOutlined />}>
                                <Link
                                    key={'admin/bank'}
                                    to={'/admin/bank'} >
                                    <span style={{ fontSize: '16px' }}>Ngân hàng</span>
                                </Link>
                            </Menu.Item>
                        )}
                        {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key="admin/usercompany" icon={<IdcardOutlined />}>
                                <Link
                                    key={'admin/usercompany'}
                                    to={'/admin/usercompany'} >
                                    <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.userCompanyManagement)}</span>
                                </Link>
                            </Menu.Item>
                        )}
                        {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key="admin/usermanage" icon={<TeamOutlined />}>
                                <Link
                                    key={'admin/usermanage'}
                                    to={'/admin/usermanage'} >
                                    <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.userManagement)}</span>
                                </Link>
                            </Menu.Item>
                        )} */}

                        {/* {NEW_CODE && !!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                            <Menu.Item key="admin/bankaccount" icon={<ProfileOutlined />}>
                                <Link
                                    key={'admin/bankaccount'}
                                    to={'/admin/bankaccount'} >
                                    <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.bankAccount)}</span>
                                </Link>
                            </Menu.Item>
                        )} */}
                    </Menu>
                </Sider>

                <Layout>
                    <HeaderApp />
                    <Layout style={{ padding: '0px 24px' }}>
                        <BreadcrumbApp />
                        <Content
                            style={{
                                // padding: 24,
                                margin: 0,
                                minHeight: !isMobile ? window.innerHeight - 20 * window.innerHeight / 100 : 0,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            {children}
                        </Content>
                    </Layout>
                </Layout>

                {/* <AppFooter /> */}
            </Layout>
            <AppFooter />               
        </Layout>
    )
}