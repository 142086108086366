import React from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import './index.css';
import { useTransition, animated, config } from '@react-spring/web';

const { Text, Link, Title } = Typography;

function TransactionProcess({ display, isMobile }: any) {
    const sectionTransition = useTransition(display, {
        from: { y: -50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        config: config.slow,
        delay: 500,
        style: { display: 'flex', justifyContent: 'center' },
    });

    return (
        <div
            className="SECTION5"
            style={{
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                margin: !isMobile ? '3% 10% 0 10%' : '3% 5% 0 5%',
            }}
        >
            <Row justify={'center'}>
                {sectionTransition((style, item) => (
                    <animated.div style={style}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Col>
                                <Title level={3} style={{
                                    textAlign: 'center',
                                }}>
                                    End to end transaction on a single platform
                                </Title>
                            </Col>
                        </div>

                        <Col
                            lg={{ span: 24, offset: 0 }}
                            md={{ span: 24, offset: 0 }}
                            sm={{ span: 24, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        // style={{ display: 'block', margin: 'auto' }}
                        >
                            <img
                                style={{ width: '100%'}}
                                src="/images/home-en/common/tong-quan_dn-20231207070914-cbaxm.png"
                            />
                        </Col>
                    </animated.div>
                ))}
            </Row>
        </div>
    );
}

export default TransactionProcess;
