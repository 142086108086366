import ErrorBoundaryRoutes from "@/shared/error/error-boundary-routes";
import React from "react";
import { Route } from "react-router-dom";
import  UserCompanyEdit from "./company-user-edit";
import  UserCompanyDetail from "./company-user-detail";
import  UserCompanyDeleteDialog from "./company-user-delete-dialog";
import  UserCompanytList from "./company-user";

export default function UserCompanyRouter(){
    return(
        <ErrorBoundaryRoutes>
        <Route index element={<UserCompanytList />} />
        <Route path="new" element={<UserCompanyEdit />} />
        <Route path=":id">
          <Route index element={<UserCompanyDetail />} />
          <Route path="edit" element={<UserCompanyEdit />} />
          <Route path="delete" element={<UserCompanyDeleteDialog />} />
        </Route>
      </ErrorBoundaryRoutes>
    )
}