import { Button, Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CompanyUserDeleteDialog(){
    const navigate = useNavigate();
    
    const handleClose = () => {
        navigate('/company-user');
      };

    return(
        <>
     
        <Modal open={true}>
                <div>   BankAccountDeleteDialog</div>

                <Button onClick={handleClose}>Đóng</Button>
        </Modal>
        </>
    )
}