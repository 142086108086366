import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { translations } from "@/i18n/i18n";
import { Space, Table, Tag } from 'antd';
import type { GetProp, TableProps } from 'antd';
import { TaskData } from "@/shared/model/task/task-data.model";
import { useGetTasksQuery } from "@/services/api/task-data.api";

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;
interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

export default function MyTaskComponent() {
    const { t } = useTranslation();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    // const { data, isLoading, isFetching, isError } = useGetUserCompaniesQuery(tableParams);
    const { data, isLoading, isFetching, isError } = useGetTasksQuery(tableParams);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns: TableProps<TaskData>['columns'] = [
        {
            title: t(translations.taskData.processDefinitionName),
            dataIndex: 'processDefinitionName',
            key: 'processDefinitionName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: t(translations.taskData.name),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t(translations.taskData.boCode),
            dataIndex: 'boCode',
            key: 'boCode',
        },
        {
            title: t(translations.taskData.createTime),
            key: 'createTime',
            dataIndex: 'createTime',
        },
        {
            title: t(translations.taskData.dueDate),
            key: 'dueDate',
            dataIndex: 'dueDate',
        },
        {
            title: t(translations.taskData.remainingTime),
            key: 'remainingTime',
            dataIndex: 'remainingTime',
        },
        {
            title: t(translations.taskData.boStatus),
            key: 'boStatus',
            dataIndex: 'boStatus',
        },
        {
            title: t(translations.taskData.taskStatus),
            key: 'taskStatus',
            dataIndex: 'taskStatus',
        },
        {
            title: t(translations.taskData.createdBy),
            key: 'createdBy',
            dataIndex: 'createdBy',
        },
        {
            title: t(translations.taskData.comment),
            key: 'comment',
            dataIndex: 'comment',
        },
    ];


    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };
    return (<><Table
        columns={columns}
        dataSource={!!data?.tasks ? data.tasks : []}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        scroll={{ x: 'calc(50px + 50%)' }}
    />
    </>)
}