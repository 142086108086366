import { Company } from '@/shared/model/company.model';
import { baseAuthAPI } from './base-auth.api'
import { CompanyBankAccount } from '@/shared/model/company-bank-account.model';
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { User } from '@/shared/model/user.model';

const companyApiUrl = '/rest/portal/company';

export const companyApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getCompanies: build.query<Company[], void>({
            query: (params) => ({
                url: `${companyApiUrl}/search`,
                method: 'POST',
                body: { filter: {} },
            }),
        }),
        getCompany: build.query<Company, string>({
            query: (id) => `${companyApiUrl}/${id}`,
        }),

        getCurrentCompany: build.query<Company, void>({
            query: () =>({
                url: `${companyApiUrl}?fetchPlan=company-fetch-plan`,
                method: 'GET'
            }) 
        }),

        getCompanyBankAccounts: build.query<CompanyBankAccount[], any>({
            query: (params:SearchEntitiesRequestModel) => {
                return({
                    url: '/rest/portal/company-bank-account/search',
                    method: 'POST',
                    body: params
                })
            }
        }),

        getCurrentUser: build.query<any, void>({
            query(){
                return{
                    url: "rest/portal/portal/userInfo",
                    method: "GET"
                }
            }
        }),

        checkCompanyCodeOnboarding: build.mutation<boolean, string>({
            query: (companyCode) => {
                return({
                    url: `${companyApiUrl}/check-company-code`,
                    method: 'POST',
                    body: companyCode
                })
            }
        }),
    }),
})

export const {
    useGetCompaniesQuery,
    useGetCompanyQuery,
    useGetCurrentCompanyQuery,
    useGetCompanyBankAccountsQuery,
    useGetCurrentUserQuery,
    useCheckCompanyCodeOnboardingMutation
} = companyApi

export const {
    endpoints: { getCompanies, getCompany, getCurrentCompany, getCompanyBankAccounts, getCurrentUser, checkCompanyCodeOnboarding},
} = companyApi