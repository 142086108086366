import React, { useState } from "react";
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from "antd";
import { animated, config, useTransition } from "@react-spring/web";

import "./index.css";
import useIsMobile from "@/shared/utils/use-is-mobile.hook";
const { Text, Link, Title, Paragraph } = Typography;

const Connection = ({ display }:any) => {
  const [ellipsis, setEllipsis] = useState(true);
  const isMobile = useIsMobile()

  const textTransition = useTransition(display, {
    from: { y: -50, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  const imageTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  return (
    <div
      className="Transection"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: !isMobile ? "1% 8% 0 8%" : '1% 5% 0 5%',
      }}
    >
      <div>
        {textTransition((style, item) => (
          <animated.div style={style}>
            <Title
              level={3}
              style={{
                width: "100%",
                whiteSpace: "normal",
                textAlign: "center",
                marginBottom:"7%"
              }}
            >
              Transparent transactions, Increase reliability
            </Title>
          </animated.div>
        ))}
      </div>
      {imageTransition((style, item) => (
        <animated.div style={style}>
          <Row justify="space-evenly" align="middle" gutter={[0, 16]}>
            <Col lg={{ span: 7 }} md={{ span: 24 }}>
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgb(239, 248, 255)",
                  paddingBottom:'18%'
                }}
                bodyStyle={{ padding: 0 }}
              >
                  <Image preview={false} className="ImageStyle" src='/images/home/Guarantee/image-27-20231222033552-p4tzb.png' />
                  <Title level={5} style={{ padding: "0 20px 0 20px" }}>
                  Convenience, reliabilty
                  </Title>
                  <ul>
                    <li>
                      <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }} className="TextStyle">
                      Electronic guarantee is a form of guarantee performed through the TradeFlat platform, instead of using a traditional process.
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: "more" }} className="TextStyle">
                      By using technology and superior digital transformation solutions, the TradeFlat electronic guarantee trading platform helps increase convenience, speed and reliability in banking guarantee operations.
                      </Paragraph>
                    </li>
                  </ul>
              </Card>
            </Col>

            <Col lg={{ span: 7 }} md={{ span: 24 }}>
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgb(255, 250, 236)",
                  paddingBottom:'26%'

                }}
                bodyStyle={{ padding: 0, overflow: "hidden" }}
              >
                <Image
                  preview={false}
                  className="ImageStyle"
                  src="/images/home/Guarantee/image-26-20231222033017-gvtky.png"
                />
                <Title level={5} style={{ padding: "0 20px 0 20px" }}>
                Fraud risk reduction
                </Title>
                <ul>
                  <li>
                    <Paragraph ellipsis={ellipsis ? { rows: 8, expandable: true, symbol: 'more' } : false} className="TextStyle">
                    Electronic guarantees also bring higher reliability in the process of performing guarantee operations. The electronic system allows encrypted and secure information transactions and communications, ensuring data integrity and inedibility. Creating a safe and trustworthy environment for underwriting transactions.
                    </Paragraph>
                  </li>
                </ul>
              </Card>
            </Col>

            <Col lg={{ span: 7 }} md={{ span: 24 }}>
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgb(235, 253, 247)",
                  paddingBottom:'23%'

                }}
                bodyStyle={{ padding: 0, overflow: "hidden" }}
              >
                <Image
                  preview={false}
                  className="ImageStyle"
                  src="/images/home/Guarantee/icons8-bank-96-20231221152546-kivdi.png"
                />

                <Title level={5} style={{ padding: "0 20px 0 20px" }}>
                Cost saving
                </Title>
                <ul>
                  <li>
                    <Paragraph ellipsis={ellipsis ? { rows: 5, expandable: true, symbol: 'more' } : false} className="TextStyle">
                    Electronic guarantee also helps reduce paperwork and associated costs. Eliminating reliance on traditional paperwork minimizes the risk of loss, damage or fraud.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph ellipsis={ellipsis ? { rows: 3, expandable: true, symbol: 'more' } : false} className="TextStyle">
                    At the same time, reducing the need to print and transport documents also saves costs and has a positive impact on the environment.
                    </Paragraph>
                  </li>
                </ul>

              </Card>
            </Col>
          </Row>
        </animated.div>
      ))}
    </div>
  );
};

export default Connection;
