import React, { useState, useEffect } from "react";
import { Steps, Button, Layout, Col, Row, Table, Form, Input, message, Typography, Upload, Image, Modal } from "antd";
import {
    UploadOutlined,
    BellOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    LoadingOutlined,
    CloseOutlined
} from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { translations } from "@/i18n/i18n";
import { useAppDispatch, useAppSelector } from "@/config/store";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { UserInfo } from "@/shared/model/user-info.model";
import { UpdateListUserInfo, getAllUserInfo } from "../user-manage.reducer";
import { v4 as uuidv4 } from "uuid";
import * as yup from 'yup';

const { Title, Text } = Typography;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
};

const schema = yup.object().shape({
    phone: yup
        .number()
        .required()
        .typeError('Number only.')
        .test('isPhoneNumber', 'Phone number must have 10 digits', (value: any) => {
            return /^\d{9}$/.test(value);
        })
    ,
    email: yup
        .string()
        .required()
        .test("", "Email is not valid", (values: any) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(values)
        })
    ,
    age: yup
        .number()
        .required()
        .typeError('Number only.')
        .positive()
        .integer()
        .round()
        .min(6)
        .max(12)
    ,
});

const yupSync = {
    async validator({ field }: { field: string }, value: any) {
        await schema.validateSyncAt(field, { [field]: value });
    },
    type: 'custom',
};

function ModalAddUser({ listUserInfo, visible, isModalOpen }: { listUserInfo: any, visible: boolean, isModalOpen: (result: boolean) => void }) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [visibleModal, setVisibleModal] = useState<boolean>(visible);

    useEffect(() => {
        setVisibleModal(visible)
    }, [visible])

    const userInfo = {};

    const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            // Get this url from the response in the real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        isModalOpen(false);
        setVisibleModal(false);
    };

    const onFinish = (values: any) => {

        const data: UserInfo = {
            ...values,
            id: uuidv4(),
            srcImage: imageUrl,
        }
        console.log("Form values:", data);
        listUserInfo = [
            ...listUserInfo,
            data

        ]

        dispatch(UpdateListUserInfo(listUserInfo));
        isModalOpen(false);
        setVisibleModal(false)
    };

    return (
        <Modal
            title="Add User"
            open={visibleModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    Back
                </Button>,
            ]}
        >
            <Form form={form} initialValues={userInfo} onFinish={onFinish} layout="vertical">
                <Col lg={{ span: 24 }}>
                    <Row gutter={[24, 0]} style={{ marginBottom: "5%" }}>
                        <Col lg={{ span: 12 }} style={{ padding: 0 }}>
                            <Col lg={{ span: 24 }}>
                                {/* <Upload
                                    name="avatar"
                                    listType="picture-circle"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "red",
                                    }}
                                >
                                    {imageUrl ? (
                                        <Image
                                            src={imageUrl}
                                            preview={false}
                                            style={{
                                                width: "210px",
                                                height: "260px",
                                                objectFit: "cover",
                                                border: "1px solid #e0e0e0",
                                                borderRadius: 8,
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload> */}
                            </Col>
                        </Col>
                        <Col lg={{ span: 12 }}>
                            <Form.Item label={t(translations.user.status)} name={"status"} hasFeedback rules={[{ required: true }]}>
                                <Input defaultValue={"true"}></Input>
                            </Form.Item>
                            <Form.Item label={t(translations.user.firstName)} name={"firstName"} hasFeedback rules={[{ required: true }]}>
                                <Input></Input>
                            </Form.Item>
                            <Form.Item label={t(translations.user.lastName)} name={"lastName"} hasFeedback rules={[{ required: true }]}>
                                <Input></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 24 }}>
                            <Row gutter={[24, 0]}>
                                <Col lg={{ span: 12 }}>
                                    <Form.Item label={t(translations.user.position)} name={"position"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 12 }}>
                                    <Form.Item label={t(translations.user.assignedTo)} name={"assignedTo"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={{ span: 24 }}>
                                    <Form.Item label={t(translations.user.company)} name={"company"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "2%" }}>
                        <Col
                            lg={{ span: 24 }}
                            style={{
                                paddingBottom: "2%",
                                borderBottom: "1px solid #e0e0e0",
                            }}
                        >
                            <Title level={5}>{t(translations.user.contacts)}</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: 24 }}>
                            <Row>
                                <Col lg={{ span: 24 }}>
                                    <Form.Item label={t(translations.user.address)} name={"address"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col lg={{ span: 12 }}>
                                    <Form.Item label={t(translations.user.city)} name={"city"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 6 }}>
                                    <Form.Item label={t(translations.user.state)} name={"state"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 6 }}>
                                    <Form.Item label={t(translations.user.zipCode)} name={"zipCode"} hasFeedback rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col lg={{ span: 12 }}>
                                    <Form.Item label={t(translations.user.phone)} name={"phone"} rules={[{ required: true }, yupSync as any]} hasFeedback>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                                <Col lg={{ span: 12 }}>
                                    <Form.Item label={t(translations.user.email)} name={"email"} rules={[{ required: true }, yupSync as any]} hasFeedback>
                                        <Input></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Form>
        </Modal>
    );
}

export default ModalAddUser;