import { Button, Col, Row, Typography, Modal, Checkbox, ConfigProvider } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useState, useEffect } from 'react';
import { SettingTwoTone, FileTextOutlined, FileTextTwoTone, HomeOutlined, HomeTwoTone } from '@ant-design/icons'
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { SCF_LINK, LC_LINK, GUARANTEE_LINK } from '@/config/config'
import { Link } from 'react-router-dom';
import { useGetCurrentUserCompanyQuery, useGetUserInfoQuery, userInfoApi } from "@/services/api/user-info.api";
import { useAppDispatch, useAppSelector } from "@/config/store";
import { Company } from '@/shared/model/company.model';
import { useLazyGetCompanyQuery } from '@/services/api/onboarding.api';
import { saveDraftCompanyEntity } from '../onboarding/onboarding.reducer';
import type { ThemeConfig } from 'antd';


const { Text } = Typography;

export default function Dashboard() {
    const isMobile = useIsMobile()
    const dispatch = useAppDispatch();

    const [currentTime, setCurrentTime] = useState<string>('');
    const [currentDate, setCurrentDate] = useState<string>('');
    const [timeOfDay, setTimeOfDay] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const plainOptions = ['LC', 'SCF', 'Guarrantee'];
    const [checkLC, setCheckLC] = useState(false)
    const [checkSCF, setCheckSCF] = useState(false)
    const [checkBL, setCheckBL] = useState(false)
    const { data: useInfo, isLoading: isLoadGet, isError: isErrorGet, isSuccess: isSuccessGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()
    const [getCompany, { data: companyEntityLoad, isLoading: isLoadingCompany, isSuccess: isSuccessCompany, isError: isErrorCompany }] = useLazyGetCompanyQuery()
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const [trueCount, setTrueCount] = useState(0);

    const themeDashBoard: ThemeConfig = {
        token: {
            fontFamily: "Lexend",
            fontSize: 16, //1em
            colorPrimary: 'rgb(28, 0, 194)',
            colorPrimaryHover: 'rgb(253, 199, 87)',
            // fontSize: 16
        },
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const hour = now.getHours();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}`;
            setCurrentTime(formattedTime);

            let time = "";

            setTimeOfDay(time);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const now = new Date();
        const formattedDate = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        if (companyEntityLoad)
            dispatch(saveDraftCompanyEntity(companyEntityLoad))
    }, [companyEntityLoad])

    useEffect(() => {
        const count = [checkLC, checkSCF, checkBL].filter(val => val).length;
        setTrueCount(count);
    }, [checkLC, checkSCF, checkBL]);


    return (
        <ConfigProvider theme={themeDashBoard}>
            <Row justify={'center'} style={{height:"100%"}}>
                <Col xxl={{ span: companyEntity?.isOnboardingSuccess ? 18 : 24, order: 1 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}>

                    {
                        !companyEntity?.isOnboardingSuccess && (
                            <Row style={{height:"100%"}}>
                                <Col
                                    lg={{ span: 12, order: 1 }}
                                    md={{ span: 24, order: 2 }}
                                    xs={{ span: 24, order: 2 }}
                                    sm={{ span: 24, order: 2 }}
                                >
                                    <Row justify={'center'}>
                                        <img src='images/TradeFlat_logo_01.png' style={{ margin: '10%' }} />
                                    </Row>
                                    <Row justify={'center'} >
                                        <Col span={20} style={{ textAlign: 'center' }}>
                                            Công ty TNHH FPT IS
                                        </Col>
                                    </Row>
                                    <Row justify={'center'}>
                                        <Col xxl={{span:10}}  xl={{span:15}} lg={{span: 18}} sm={{span:16}} xs={{span:20}} style={{ textAlign: 'center' }}>
                                            <Title level={3} style={{ marginTop: 10 }}>Xin chào, {!!!useInfo?.fullName ? useInfo?.email : useInfo?.fullName}</Title>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 20 }} justify={'center'} >
                                        <Col xxl={{span:11}}  xl={{span:15}} lg={{span: 20}}  md={{span:11}} sm={{span:16}} xs={{span:20}} style={{ textAlign: 'center' }}>
                                            <Text >Doanh nghiệp của bạn cần đăng ký thông tin để tiếp tục sử dụng dịch vụ!</Text>
                                        </Col>
                                    </Row>
                                    <Row justify={'center'} gutter={[16, 16]} style={{ marginBottom: 20 }}>
                                        <Col  xxl={{span:11}}  xl={{span:15}} lg={{span: 20}} style={{ textAlign: 'center' }}>
                                            <Link
                                                key={'admin/onboarding'}
                                                to={'/admin/onboarding'} >
                                                <Button type='primary' style={{ width: '100%', height: 40 }} onClick={() => setOpenModal(true)}>
                                                    Đăng ký thông tin doanh nghiệp
                                                </Button>
                                            </Link>
                                        </Col>

                                    </Row>
                                    <Row justify={'center'} style={{ width: '100%' }} >
                                        <Link
                                            to={'/home/common'} >

                                            Quay lại trang chủ
                                        </Link>
                                    </Row>
                                </Col>

                                <Col
                                    lg={{ span: 12, order: 2 }}
                                    md={{ span: 24, order: 1 }}
                                    xs={{ span: 24, order: 1 }}
                                    sm={{ span: 24, order: 1 }}
                                >
                                    <img src={'images/440973907_1509936859960278_2734526752135319129_n.png'} alt="Registration Image" style={{ width: '100%', height:"100%", objectFit:'cover', objectPosition:'center'}} />
                                </Col>
                            </Row>
                        )

                    }

                    <Row justify={(!isMobile && companyEntity?.isOnboardingSuccess) ? "space-between" : 'center'} style={{ marginBottom: 40 }}>
                        {
                            companyEntity?.isOnboardingSuccess &&
                            (
                                <>
                                    <Row style={{ marginTop: '3vw', width: '100%' }} justify={'space-between'}>
                                        <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                            <Row style={{ width: '100%' }}>
                                                <Title level={5}>Xin chào</Title>
                                            </Row>
                                            <Row style={{ width: '100%' }}>
                                                <Title level={3} style={{ margin: '0 0 20px 0' }}>{!!!useInfo?.fullName ? useInfo?.email : useInfo?.fullName}</Title>
                                            </Row>
                                        </Col>
                                        <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                            <Row justify={'end'}>
                                                <Title level={5}>Hà Nội, {currentDate}</Title>
                                            </Row>
                                            <Row justify={'end'}>
                                                <Title level={3} style={{ margin: '0 0 20px 0' }}>{currentTime}</Title>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row justify={'space-between'} style={{ width: '100%' }}>
                                        <Col lg={{ span: 7 }} md={{ span: 14 }}>
                                            <div style={{ position: 'relative' }}>
                                                <img src="/images/main-page/lc.png" width={'100%'} style={{ minWidth: 150 }} />
                                                <Title
                                                    level={4}
                                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: '2%', left: 30 }}
                                                >
                                                    Letter of Credit
                                                </Title>
                                                <Button
                                                    style={{
                                                        position: 'absolute',
                                                        color: 'rgba(255, 255, 255, 1)',
                                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                                        borderColor: 'rgba(54, 106, 226, 1)',
                                                        top: '75%',
                                                        left: 30,
                                                    }}
                                                // onClick={() => setCheckLC(!checkLC)}
                                                >
                                                    Dịch vụ sắp ra mắt
                                                </Button>
                                                {/* <Title
                                    level={1}
                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                                >
                                    31
                                </Title>
                                <Text
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        top: 140,
                                        left: 30,
                                    }}
                                >
                                    Tác vụ cần xử lý
                                </Text> */}

                                                {/* {
                                    checkLC && <Link to={`${LC_LINK}`}>
                                    
                                </Link>
                                }

                                {
                                    !checkLC && <Button
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                        borderColor: 'rgba(54, 106, 226, 1)',
                                        top: '75%',
                                        left: 30,
                                    }}
                                    onClick={() => setCheckLC(!checkLC)}
                                >
                                    Đăng ký dịch vụ
                                </Button>
                                } */}

                                            </div>
                                        </Col>

                                        <Col lg={{ span: 7 }} md={{ span: 14 }}>
                                            <div style={{ position: 'relative' }}>
                                                <img src="/images/main-page/scf.png" width={'100%'} style={{ minWidth: 150 }} />
                                                <Title
                                                    level={4}
                                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 5, left: 30 }}
                                                >
                                                    Supply Chain Finance
                                                </Title>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => setOpenModal(true)}
                                                    style={{
                                                        position: 'absolute',
                                                        color: 'rgba(255, 255, 255, 1)',
                                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                                        borderColor: 'rgba(54, 106, 226, 1)',
                                                        top: '75%',
                                                        left: 30,
                                                    }}
                                                // onClick={() => setCheckLC(!checkLC)}
                                                >
                                                    <Link to={`${SCF_LINK}`} target='_blank'>
                                                        Dịch vụ SCF
                                                    </Link>
                                                </Button>
                                                {/* <Title
                                    level={1}
                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                                >
                                    06
                                </Title>
                                <Text
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        top: 140,
                                        left: 30,
                                    }}
                                >
                                    Tác vụ cần xử lý
                                </Text>

                                {
                                    checkSCF && <Link to={`${LC_LINK}`}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            color: 'rgba(255, 255, 255, 1)',
                                            backgroundColor: 'rgba(54, 106, 226, 1)',
                                            borderColor: 'rgba(54, 106, 226, 1)',
                                            top: '75%',
                                            left: 30,
                                        }}
                                    >
                                        Thực hiện ngay
                                    </Button>
                                </Link>
                                }

                                {
                                    !checkSCF &&  <Button
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                        borderColor: 'rgba(54, 106, 226, 1)',
                                        top: '75%',
                                        left: 30,
                                    }}
                                    onClick={() => setCheckSCF(!checkSCF)}
                                >
                                    Đăng ký dịch vụ
                                </Button>
                                } */}
                                            </div>
                                        </Col>

                                        <Col lg={{ span: 7 }} md={{ span: 14 }}>
                                            <div style={{ position: 'relative' }}>
                                                <img src="/images/main-page/bl.png" width={'100%'} style={{ minWidth: 150 }} />
                                                <Title
                                                    level={4}
                                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 5, left: 30 }}
                                                >
                                                    Bảo lãnh
                                                </Title>
                                                <Button
                                                    style={{
                                                        position: 'absolute',
                                                        color: 'rgba(255, 255, 255, 1)',
                                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                                        borderColor: 'rgba(54, 106, 226, 1)',
                                                        top: '75%',
                                                        left: 30,
                                                    }}
                                                // onClick={() => setCheckLC(!checkLC)}
                                                >
                                                    Dịch vụ sắp ra mắt
                                                </Button>
                                                {/* <Title
                                    level={1}
                                    style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                                >
                                    09
                                </Title>
                                <Text
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        top: 140,
                                        left: 30,
                                    }}
                                >
                                    Tác vụ cần xử lý
                                </Text>


                                {
                                    checkBL &&  <Link to={`${GUARANTEE_LINK}`}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            color: 'rgba(255, 255, 255, 1)',
                                            backgroundColor: 'rgba(54, 106, 226, 1)',
                                            borderColor: 'rgba(54, 106, 226, 1)',
                                            top: '75%',
                                            left: 30,
                                        }}
                                    >
                                        Thực hiện ngay
                                    </Button>
                                </Link>
                                }

                                {
                                    !checkBL && <Button
                                    style={{
                                        position: 'absolute',
                                        color: 'rgba(255, 255, 255, 1)',
                                        backgroundColor: 'rgba(54, 106, 226, 1)',
                                        borderColor: 'rgba(54, 106, 226, 1)',
                                        top: '75%',
                                        left: 30,
                                    }}
                                    onClick={() => setCheckBL(!checkBL)}
                                >
                                    Đăng ký dịch vụ
                                </Button>
                                }

                                 */}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )

                        }
                    </Row>

                    <Modal
                        title="Quản lý dịch vụ đăng ký"
                        centered
                        // open={openModal}
                        onOk={() => setOpenModal(false)}
                        onCancel={() => setOpenModal(false)}
                        width={520}
                    >
                        <p>Lựa chọn ít nhất 1 dịch vụ</p>

                        <Col xxl={{ span: 24 }} style={{ marginBottom: 20 }}>
                            <Button type='text' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: ' 1px solid rgba(24, 119, 242, 1)' }} onClick={() => {
                                // setCheckLC(!checkLC)
                                setCheckLC(true)

                            }}>
                                <Row style={{ padding: 20, width: "100%" }} justify={'space-between'}>
                                    <Col lg={{ span: 22 }} style={{ textAlign: 'start' }}><Text strong>Thư tín dụng (LC)</Text></Col>
                                    <Col lg={{ span: 2 }}><Checkbox checked={checkLC}></Checkbox></Col>
                                </Row>
                            </Button>
                        </Col>

                        <Col xxl={{ span: 24 }} style={{ marginBottom: 20 }}>
                            <Button type='text' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: ' 1px solid rgba(24, 119, 242, 1)' }} onClick={() => {
                                // setCheckSCF(!checkSCF)
                                setCheckSCF(true)
                            }}>
                                <Row style={{ padding: 20, width: "100%" }} justify={'space-between'}>
                                    <Col lg={{ span: 22 }} style={{ textAlign: 'start' }}><Text strong>Tài trợ chuỗi cung ứng (SCF)</Text></Col>
                                    <Col lg={{ span: 2 }}><Checkbox checked={checkSCF}></Checkbox></Col>
                                </Row>
                            </Button>
                        </Col>

                        <Col xxl={{ span: 24 }}>
                            <Button type='text' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: ' 1px solid rgba(24, 119, 242, 1)' }} onClick={() => {
                                // setCheckBL(!checkBL)
                                setCheckBL(true)
                            }}>
                                <Row style={{ padding: 20, width: "100%" }} justify={'space-between'}>
                                    <Col lg={{ span: 22 }} style={{ textAlign: 'start' }}><Text strong>Bảo lãnh điện tử (e-Guarrantee)</Text></Col>
                                    <Col lg={{ span: 2 }}><Checkbox checked={checkBL}></Checkbox></Col>
                                </Row>
                            </Button>
                        </Col>

                        <p ><span style={{ color: 'red' }}>*</span> Để ngưng/hủy dịch vụ vui lòng liên hệ với hotline 0966.181.681</p>

                    </Modal>
                </Col>
            </Row>
        </ConfigProvider>

    );
}


{/* <Row justify={(!isMobile && trueCount===3) ? 'space-between' : ((!isMobile && (trueCount===2 || trueCount ===1)) ? 'start' : 'center')} style={{ marginBottom: 40 }}> */ }




{/* <Row justify={!isMobile ? 'space-between' : 'center'} style={{ marginBottom: 40 }} gutter={isMobile ? [0, 24] : [0, 0]}>
                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 24 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 4 }}>
                                <img src='/images/main-page/income, money.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        1000+
                                    </Title>

                                </Row>
                                <Row >Tỷ giải ngân</Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 24 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 4 }}>
                                <img src='/images/main-page/speed.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        250+
                                    </Title>

                                </Row>
                                <Row >Doanh nghiệp sử dụng</Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 24 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 4 }}>
                                <img src='/images/main-page/Target.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        4600+
                                    </Title>

                                </Row>
                                <Row >Đơn hàng được hỗ trợ</Row>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}