import React, { useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Layout, Space, Menu, Image, Button, Drawer, Avatar, Typography } from 'antd';
import { MenuOutlined, SettingOutlined, UserOutlined, CloseOutlined } from '@ant-design/icons';
const { Header, Footer, Sider, Content } = Layout;
import { Storage } from '@/shared/utils/storage-util';
import { Link } from 'react-router-dom';
import { translations } from 'i18n/i18n';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useAppDispatch, useAppSelector } from '@/config/store';
import LocaleMenu from '../../menu/locale';
import { setLocale } from '@/shared/reducers/locale';
import SettingMenu from '../../menu/setting';
import DrawerInMinWith from './drawer';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { setSiderCollapsed } from '../../layout.reducer';
import { useGetCurrentUserCompanyQuery } from '@/services/api/user-info.api';


export interface IHeaderProps {
    isAuthenticated: boolean;
    currentLocale: string;
}

const { Text, Paragraph } = Typography

export default function HeaderHome() {
    const auth = useAuth();
    const scrollDirection = useScrollDirection();
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const userName = useAppSelector(state => state.authentication.userName);
    let isMobile = useIsMobile();;
    const { data: useInfo, isLoading: isLoadGet, isError: isErrorGet, isSuccess: isSuccessGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()

    const headerItem = [
        {
            path: '/home/common',
            name: t(translations.header.mainMenu.overview),
        },
        {
            path: '/home/scf',
            name: t(translations.header.mainMenu.scf),
        },
        {
            path: '/home/guarantee',
            name: t(translations.header.mainMenu.guarantee),
        },
        {
            path: '/home/lc',
            name: t(translations.header.mainMenu.lc),
        },
        {
            path: '/home/business-health',
            name: t(translations.header.mainMenu.healthMonitor),
        },
    ];

    const [openMenu, setOpenMenu] = useState(false);
    const handleLocaleChange = (langKey: string) => {
        Storage.session.set('locale', langKey);
        dispatch(setLocale(langKey));
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         const windowWidth = window.innerWidth;
    //         if (windowWidth >= 1450) {
    //             setOpenMenu(false);
    //         }
    //     };
    //     window.addEventListener('resize', handleResize);
    //     handleResize();
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleChangeOpen = () => {
        setOpenMenu(false);
    };

    useEffect(() => {
        if (isMobile) dispatch(setSiderCollapsed(true))
    }, [])

    useEffect(() => {
        if (isMobile) dispatch(setSiderCollapsed(true))
    }, [isMobile])

    return (
        <div style={{ width: '100%' }}>
            {!isMobile && <Header
                // className={`headerMaxWidth header ${scrollDirection === 'down' ? 'hide' : 'show'}`}
                // className={`header `}
                id='headerMaxWidth'
                style={{ padding: 0, position: 'fixed', zIndex: 99, width: '100%', backgroundColor: 'rgb(47, 87, 250)', display: 'flex', justifyContent: 'center' }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '75vw' }}>
                    <div className='imageHeader'>
                        <Link key={'logo'} to={'/home/common'}>
                            <span>
                                <Image
                                    width={150}
                                    preview={false}
                                    src="/images/logoHeader.png"
                                />
                            </span>
                        </Link>
                    </div>
                    {!isMobile &&
                        <div style={{ width: '65vw' }}>
                            <Menu
                                mode="horizontal"
                                defaultSelectedKeys={['logo']}
                                className='headerMenuItemMaxWidth'
                            >
                                {headerItem.map((item, index) => {
                                    return (
                                        <Menu.Item key={item.path}
                                            className='gridMenuItem'
                                        >
                                            <Link key={item.path} to={item.path}>
                                                <div
                                                    className="headerMenuItem"
                                                    style={{width : item.name ==="Bảo lãnh điện tử" ? "100px" : "110px"}}
                                                >
                                                    {item.name}
                                                </div>
                                            </Link>
                                        </Menu.Item>
                                    );
                                })}
                            </Menu>
                        </div>
                    }
                    <div className='customMenu' style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        {!isAuthenticated && (
                            <Button key="signin"
                                onClick={() => auth.signinRedirect()}
                                style={{
                                    backgroundColor: '#FDC757',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    whiteSpace: 'normal',
                                    // display: 'flex',
                                    // alignItems:'center',
                                    fontSize: '0.9em',
                                    width: '140px',
                                    height: '40px',
                                    padding: '0 20px 0 20px',
                                    textAlign: 'center'

                                }}>
                                <span
                                >
                                    {t(translations.header.settingMenu.login)}
                                </span>
                            </Button>
                        )}

                        {
                            isAuthenticated && (
                                <div className='settingMenu' style={{ display: 'flex', width: '10vw', lineHeight: '0.8', alignItems: 'center', justifyContent: 'end' }}>
                                    <div style={{ margin: 'auto' }} className='iconMenu'>
                                        <SettingMenu />
                                    </div>
                                    <div>
                                        <Text style={{ margin: 0, color: 'rgb(253, 199, 87)', position: 'absolute', marginTop: '1px', fontSize: 12 }}>Xin chào,</Text>
                                        <Paragraph style={{ margin: 0, color: '#FDC757', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '140px', marginTop: 14 }}>{useInfo?.fullName ? useInfo?.fullName : useInfo?.email}</Paragraph>
                                    </div>
                                </div>
                            )
                        }


                        {/* <div className='localeMenu' style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <LocaleMenu />
                        </div> */}
                    </div>

                </div>
            </Header>}

            {isMobile &&
                <Header className='headerMixWidth' style={{ padding: 0, position: 'fixed', zIndex: 99999 }}
                >
                    <div style={{ display: 'flex', padding: 0, position: 'fixed', width: '100%' }}>
                        <div
                            className='imageHeader'
                        >
                            <Link key={'logo'} to={'/home/common'}>
                                <span>
                                    {' '}
                                    <Image
                                        width={150}
                                        preview={false}
                                        src="https://w.ladicdn.com/s500x400/60b444ceeba2a30012e68735/logo-white-w-20230928005958-f031a.png"
                                    />
                                </span>
                            </Link>
                        </div>
                        <Menu
                            mode="horizontal"
                            defaultSelectedKeys={['logo']}
                            className='headerMenuItemMixWidth'
                        >
                            <Menu.Item
                                onClick={() => {
                                    setOpenMenu(openMenu => !openMenu);
                                }}
                            >
                                {openMenu && <CloseOutlined style={{ fontSize: 30, paddingTop: 15 }} />}
                                {!openMenu && <MenuOutlined style={{ fontSize: 30, paddingTop: 15 }} />}

                            </Menu.Item>
                        </Menu>
                    </div>
                </Header>
            }


            <DrawerInMinWith
                onChange={handleChangeOpen}
                open={openMenu}
                navItems={headerItem}
                isAuthenticated={isAuthenticated}
                authentication={auth}
                userName={userName}
            />
        </div>
    );
}

// scroll direction hook

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState<any>(null);

    React.useEffect(() => {
        let lastScrollY = window.scrollY;
        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? 'down' : 'up';
            if (
                direction !== scrollDirection &&
                (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
            ) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener('scroll', updateScrollDirection); // clean up
        };
    }, [scrollDirection]);

    return scrollDirection;
}
