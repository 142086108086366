import React, { useEffect, useState } from 'react';
import { Table, Card, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import './index.css'
import { UserCompany } from '@/shared/model/user-company.model';
import { useGetCurrentUserCompanyQuery } from '@/services/api/user-info.api';
import type { TableColumnsType } from 'antd';


export default function UserCompanyInfo(props: any) {
    const { data: useInfo, isLoading: isLoadGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()
    const [dataSource, setDataSource] = useState<UserCompany[]>([]);

    const columns: TableColumnsType<UserCompany> = [
        {
            title: 'Tên hiển thị',
            dataIndex: 'fullName',
            key: 'fullName',
            fixed: 'left',
        },
        {
            title: 'Chức vụ', dataIndex: 'position', key: 'position',
            render: (position) => {
                if (position === "MANAGER") {
                    return "Trưởng phòng";
                }
                if (position === "SUB_MANAGER") {
                    return "Phó phòng";
                }
                if (position === "STAFF") {
                    return "Nhân viên";
                }
                if (position === "LEGAL_REPRESENTATIVE") {
                    return "Người đại diện pháp luật";
                }
                if (position === "CHIEF_ACCOUNTANT") {
                    return "Kế toán trưởng";
                }
                if (position === "AUTHORIZATION_OF_LEGAL_REPRESENTATIVE") {
                    return "Ủy quyền người đại diện pháp luật";
                }
                if (position === "AUTHORIZATION_OF_CHIEF_ACCOUNTANT") {
                    return "Ủy quyền kế toán trưởng";
                }
                return position;
            }
        },
        {
            title: 'Nhóm người dùng', dataIndex: 'roleAssignmentText', key: 'roleAssignmentText',
            render(value, record, index) {
                return "Admin"
            },
        },
        {
            title: 'Phân quyền ứng dụng',
            render(value, record, index) {
                return "Admin"
            }
        },
        {
            title: 'Giấy tờ định danh', dataIndex: 'docType', key: 'docType',
            render(docType) {
                if (docType === "CMND") {
                    return "CMND/CCCD/CCCD gắn chíp"
                }
                else if (docType === "HC") {
                    return "Hộ chiếu"
                }
                else {
                    return "Khác"
                }
            }
        },
        { title: 'Ngày cấp', dataIndex: 'dateRange', key: 'dateRange' },
        { title: 'Nơi cấp', dataIndex: 'issuedBy', key: 'issuedBy' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Số điện thoại', dataIndex: 'phoneNumber', key: 'phoneNumber' },
    ];

    useEffect(() => {
        if (useInfo) {
            setDataSource(prevDataSource => {
                const existingIndex = prevDataSource.findIndex(item => item.id === useInfo.id);

                if (existingIndex !== -1) {
                    return prevDataSource.map(item =>
                        item.id === useInfo.id ? useInfo : item
                    );
                } else {
                    return [...prevDataSource, useInfo];
                }
            });
        }
    }, [useInfo]);

    useEffect(() => {
        refetch()
    }, [])

    return (
        <div className='Onboarding-user-company' style={{ height: '100%' }}>
            <Spin spinning={isLoadGet}>
                <Content style={{ height: '100%', margin: 20 }} >
                    <Card type="inner" title="Thông tin người dùng quản trị" style={{ height: "100%", width: '100%' }} >
                        <Table columns={columns} scroll={{ x: 2500 }} dataSource={dataSource}></Table>
                    </Card>
                </Content>
            </Spin>
        </div>


    )
}