import React, { useEffect, useState } from 'react';
import { Steps, Button, Layout, Col, Row, Table, Form, Input, Space, Pagination } from 'antd';
import type { GetProp, TableProps } from 'antd';
import { UploadOutlined, BellOutlined, PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Content, Footer, Header } from 'antd/es/layout/layout';

import './index.css'
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/config/store';

import { useNavigate } from 'react-router-dom';
import { entries } from 'lodash';
import { SearchEntitiesRequestModel, defaultValue } from '@/shared/model/search-api-model/search-entities-request.model';
import { UserCompany } from '@/shared/model/user-company.model';
import { getUserCompanies, useGetUserCompaniesQuery } from '@/services/api/user-company.api'
import { useGetCompanyQuery } from '@/services/api/company.api';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { updateBreadcrumbItems } from '@/shared/layout/layout.reducer';

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;
interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}
const breadcrumbItems: ItemType[] = [
    {
        title: 'Trang chủ',
    },
    {
        title: 'Quản lý người dùng doanh nghiệp',
        href: '',
    },
    {
        title: 'Danh sách người dùng',
    }
]
export default function BankAccountList(props: any) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const { data, isLoading, isFetching, isError } = useGetUserCompaniesQuery(tableParams);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const columns: ColumnsType<UserCompany> = [
        {
            title: t(translations.user.username),
            dataIndex: 'username',
            key: 'username',
            sorter: true,
        },
        {
            title: t(translations.user.fullName),
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: t(translations.user.email),
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: t(translations.user.active),
            dataIndex: 'active',
            key: 'active',
        },
    ];
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onCreateNew = () => {
        navigate('/admin/usercompany/new');
    }
    const onEdit = () => {
        const selectedRow = selectedRowKeys[0];
        if (!!selectedRow) {
            console.log(selectedRow)
            navigate(`/admin/usercompany/${selectedRow}/edit`)
        }
    }
    const onView = () => {
        const selectedRow = selectedRowKeys[0];
        if (!!selectedRow) {
            console.log(selectedRow)
            navigate(`/admin/usercompany/${selectedRow}`)
        }
    }
    const onDelete = () => {
        //navigate('');
    }

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        console.log("HANT handleTableChange pagination:", pagination)
        console.log("HANT handleTableChange filters:", filters)
        console.log("HANT handleTableChange sorter:", sorter)
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    useEffect(() => {
        dispatch(updateBreadcrumbItems(breadcrumbItems));
        return () => {
            dispatch(updateBreadcrumbItems([]));
        };
    }, [])

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: data?.totalCount,
            },
        });
    }, [data])
    return (
        <>
            <Content className='ContentCompanyUser'>
                <div style={{ display: 'flex', margin: '10px 10px 10px 0' }}>
                    <Row justify={'start'} gutter={[16, 16]}>
                        <Col>
                            <Button type="primary"
                                onClick={onCreateNew}
                            >
                                <PlusOutlined />Thêm tài khoản
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={onEdit} disabled={selectedRowKeys.length != 1}><EditOutlined />Sửa</Button>
                        </Col>
                        <Col>
                            <Button onClick={onView} disabled={selectedRowKeys.length != 1}><EyeOutlined />Xem</Button>
                        </Col>
                        <Col>
                            <Button onClick={onDelete} disabled={!selectedRowKeys.length || selectedRowKeys.length < 0}><DeleteOutlined />Xóa</Button>
                        </Col>
                    </Row>
                </div>
                <div>hant: {tableParams?.pagination?.total}</div>
                <Table
                    rowSelection={rowSelection}
                    dataSource={!!data?.users ? data.users : []}
                    rowKey={(record) => record.id || ""}
                    columns={columns}
                    loading={isLoading}
                    onChange={handleTableChange}
                    pagination={tableParams.pagination}
                    scroll={{ x: 'calc(50px + 50%)' }}
                // pagination={<Pagination defaultCurrent={6} total={500} />} 
                />

            </Content>
        </>
    )
}