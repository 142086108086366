import React, { useEffect, useRef, useState } from 'react'
import { Button, Table, Input, Space, Typography } from 'antd'
import {
    SearchOutlined,
} from '@ant-design/icons'
import { Content } from 'antd/es/layout/layout'
import './index.css'
import { translations } from '@/i18n/i18n'
import { useTranslation } from 'react-i18next'
import type { GetProp, InputRef, TableColumnsType, TableColumnType, TableProps } from 'antd';
import { CompanyBankAccount } from '@/shared/model/company-bank-account.model'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model'
import { useGetCompanyBankAccountsQuery } from '@/services/api/company-bank-account.api'
import { CompanyBankAccountTypeEnum } from '@/shared/model/enums/enums'
import { FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words';
import * as yup from 'yup';
import { useGetCompanyBankCifMutation } from '@/services/api/onboarding.api'
import { CompanyBankCif } from '@/shared/model/company-bank-cif.model'

type TableRowSelection<T> = TableProps<T>['rowSelection'];
const { Text } = Typography
type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;
interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}
type TablePagination<T extends object> = NonNullable<Exclude<TableProps<T>['pagination'], boolean>>;
type TablePaginationPosition = NonNullable<TablePagination<any>['position']>[number];


type DataIndex = keyof CompanyBankAccount;

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

export default function BankAccount(props: any) {
    let schema = yup.object().shape({
        owner: yup.string().required(),
        bank: yup.string().required(),
        accountOwner: yup.string().required(),
        accountNumber: yup.number().required(),
        bankAccountType: yup.string().required(),
    });
    const yupSync: any = {
        async validator({ field }: any, value: any) {
            await schema.validateSyncAt(field, { [field]: value });
        },
    };

    const { t } = useTranslation()
    const [isModalOpen] = useState(false)
    const [getCompanyBankCif, { data: dataCompanyBankCif, isSuccess: isSuccessGetCompanyBankCif }] = useGetCompanyBankCifMutation()
    const [companyBankCif, setCompanyBankCif] = useState<CompanyBankCif[]>();

    const loadOptions: SearchEntitiesRequestModel = {
        filter: {
            conditions: []
        },
        fetchPlan: 'companyBankAccount-fetch-plan'
    }
    const { data, refetch } = useGetCompanyBankAccountsQuery(loadOptions)
    const [top] = useState<TablePaginationPosition>('topRight');
    const [allDataCompanyBankAccount, setAllDataCompanyBankAccount] = useState<CompanyBankAccount[] | undefined>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const generateKey = (record: any) => {
        return record.id
    }

    const pagination = {
        pageSize: 10,
        position: [top],
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<CompanyBankAccount> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        // icon={<SearchOutlined />}
                        size="small"
                    // style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const recordValue = record[dataIndex];
            if (!recordValue) {
                return false;
            }
            return recordValue.toString().toLowerCase().includes((value as string).toLowerCase());
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: TableColumnsType<CompanyBankAccount> = [
        {
            title: "Số thứ tự",
            render: (text, record, index) => index + 1,
        },
        {
            title: t(translations.companyBankAccount.bank),
            dataIndex: 'bank',
            key: 'bank',
            render: (text: any, record: any) => {
                // setBankId(text.id)
                return text !== undefined ? text.name : ""
            },
            sorter: (a, b) => ((a.bank?.name || '')).localeCompare(b.bank?.name || ''),
        },
        {
            title: "Mã số CIF",
            key: 'companyBankCif',
            dataIndex: 'companyBankCif',
        },
        {
            title: t(translations.companyBankAccount.accountNumber),
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            ...getColumnSearchProps('accountNumber'),

        },
        {
            title: t(translations.companyBankAccount.bankAccountType),
            dataIndex: 'bankAccountType',
            key: 'bankAccountType',

        },
        {
            title: t(translations.companyBankAccount.accountOwner),
            dataIndex: 'accountOwner',
            key: 'accountOwner',
            ...getColumnSearchProps('accountOwner'),
        },
    ]

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    useEffect(() => {
        getCompanyBankCif()
    }, []);

    useEffect(() => {
        if (isSuccessGetCompanyBankCif) {
            setCompanyBankCif(dataCompanyBankCif || [])
        }
    }, [dataCompanyBankCif, getCompanyBankCif]);

    useEffect(() => {
        const transformedData = data?.map(companyBankAccount => {
            const bankCif = companyBankCif?.find(company => company?.bank?.id === companyBankAccount?.bank?.id)
            console.log("PAV companyBankCif", bankCif)
            if (companyBankAccount?.bankAccountType?.toString() === "TAI_KHOAN_THANH_TOAN") {
                return { ...companyBankAccount, companyBankCif: bankCif?.cif, bankAccountType: CompanyBankAccountTypeEnum.paymentAccount };
            }
            else if (companyBankAccount?.bankAccountType?.toString() === "TAI_KHOAN_CHUYEN_DUNG") {
                return { ...companyBankAccount, companyBankCif: bankCif?.cif, bankAccountType: CompanyBankAccountTypeEnum.specialAccount };
            }
            return { ...companyBankAccount, companyBankCif: bankCif?.cif };
        });
        setAllDataCompanyBankAccount(transformedData);
        console.log("allDataCompanyBankAccount: ", data)
    }, [data, companyBankCif])

    useEffect(() => {
        if (!isModalOpen) refetch()
    }, [isModalOpen])

    return (
        <div className='companyBankAccount' style={{ height: '95%' }}>
            <Content className="ContentBankAccount" style={{ margin: 20, height: "90%" }}>

                <Table
                    rowSelection={rowSelection}
                    dataSource={allDataCompanyBankAccount}
                    columns={columns}
                    pagination={pagination}
                    rowKey={generateKey}
                    scroll={{ x: 'calc(300px + 50%)' }}
                />
            </Content>
        </div>
    )
}