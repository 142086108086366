import { useAppSelector } from "@/config/store";
import { Company } from "@/shared/model/company.model";
import { Col, Divider, Form, Input, Radio, Row, Space, Typography, Image, Card } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { translations } from '@/i18n/i18n';
import { fileApi } from "@/services/api/file.api";
import { CorporatePositionEnum } from "@/shared/model/enums/enums";

const { Title, Text, Link } = Typography;
const getDefaultImage = () => {
  return '/images/default_image.png';
};

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  console.log(year, month, day);
  return `${day}/${month}/${year}`;
};

export default function CompanyRepresentative() {
  const { t } = useTranslation();
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
  const { legalRepresentative = null } = companyEntity || {}
  const [form] = Form.useForm();
  const [downloadRepresentativeFrontImage, { data: frontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
  const [downloadRepresentativeBackImage, { data: backImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
  const [downloadRepresentativeFaceImage, { data: faceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();


  let schema = yup.object().shape({
    legalRepresentative: yup.object().shape({
      name: yup.string().required(t(translations.onboarding.representative.error.name)),
      cardIssueDate: yup.date().required(t(translations.onboarding.representative.error.registrationDate)),
      cardFrontImage: yup.string().required('Thông tin không được để trống'),
      cardBackImage: yup.string().required("Thông tin không được để trống"),
      faceImage: yup.string().required("Thông tin không được để trống"),
    }),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
  }

  useEffect(() => {
    if (legalRepresentative?.cardFrontImage)
      downloadRepresentativeFrontImage({ fileId: legalRepresentative?.cardFrontImage, objectType: "employee" })
  }, [legalRepresentative?.cardFrontImage])
  useEffect(() => {
    if (legalRepresentative?.cardBackImage)
      downloadRepresentativeBackImage({ fileId: legalRepresentative?.cardBackImage, objectType: "employee" })
  }, [legalRepresentative?.cardBackImage])
  useEffect(() => {
    if (legalRepresentative?.faceImage)
      downloadRepresentativeFaceImage({ fileId: legalRepresentative?.faceImage, objectType: "employee" })
  }, [legalRepresentative?.faceImage])

  useEffect(() => {
    let entity;
    entity = {
      ...companyEntity,
      legalRepresentative: {
        ...legalRepresentative,
        cardIssueDate: formatDate(legalRepresentative?.cardIssueDate || ""),
        birthday: formatDate(legalRepresentative?.birthday || "")
      }
    }
    form.setFieldsValue(entity)
    form.setFieldValue(['legalRepresentative', 'position'], companyEntity.legalRepresentative?.position === CorporatePositionEnum.REPRESENTATIVE ? "Người đại diện pháp luật" : "")

  }, [])

  return (
    <Content>
      <Card type='inner' title='Thông tin người đại diện' style={{ margin: 20 }} >
        <Row>
          <Space >
            <Text>Loại giấy tờ</Text>
            <Divider orientation="right" />
            <Radio.Group value={legalRepresentative?.docType} >
              <Radio value={"CMND"}>CMND/CCCD/CCCD gắn chíp</Radio>
              <Radio value={"HC"}>Hộ chiếu</Radio>
            </Radio.Group>
          </Space>
        </Row>
        <Form colon={false}
          form={form}
        >
          <Row style={{ margin: '24px 0px' }} gutter={[16, 16]}>
            <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <div style={{
                padding: '12px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px 8px 8px 8px',
                minHeight: 200
              }}>
                <Form.Item
                  name={['legalRepresentative', 'cardFrontImage']}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}

                >
                  <div style={{ width: '23vw', minWidth: 200, minHeight: 90, height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Image
                      style={{ width: '100%', maxHeight: '18vw' }}
                      preview={false}
                      src={
                        frontImageFile || getDefaultImage()
                      }
                      fallback={getDefaultImage()}
                    />
                  </div>
                </Form.Item>
              </div>

            </Col>
            {(legalRepresentative?.docType?.toString() !== "HC") && (
              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div style={{
                  padding: '12px',
                  border: '1px solid #f0f0f0',
                  borderRadius: '8px 8px 8px 8px',
                  minHeight: 200
                }}>
                  <Form.Item
                    name={['legalRepresentative', 'cardBackImage']}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}

                  >
                    <div style={{ width: '23vw', minWidth: 200, minHeight: 90, height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          backImageFile || getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>
                </div>
              </Col>
            )}

            <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <div style={{
                padding: '12px',
                border: '1px solid #f0f0f0',
                borderRadius: '8px 8px 8px 8px',
                minHeight: 200
              }}>
                <Form.Item
                  name={['legalRepresentative', 'faceImage']}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}

                >
                  <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                    <Image
                      style={{ width: '100%', maxHeight: '18vw' }}
                      preview={false}
                      src={
                        faceImageFile || getDefaultImage()
                      }
                      fallback={getDefaultImage()}
                    />
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      < Card type='inner' title='Xác thực thông tin người đại diện' style={{ margin: 20 }
      } >
        <Row>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form colon={false}
              form={form}
              labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
              wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
              layout="horizontal"
              disabled={true}

            >
              <Form.Item
                name={['legalRepresentative', 'name']}
                // name={'name'}
                label="Họ và tên"
                labelAlign="left"
                rules={[yupSync]}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>
              <Form.Item
                name={['legalRepresentative', 'cardID']}
                // name={'name'}
                label="Số giấy tờ định danh"
                labelAlign="left"
                rules={[yupSync]}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>

              <Form.Item
                name={['legalRepresentative', 'cardIssueDate']}
                label="Ngày cấp"
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>

              <Form.Item
                name={['legalRepresentative', 'cardIssueLocation']}
                // name={'name'}
                label="Nơi cấp"
                labelAlign="left"
                rules={[yupSync]}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>
            </Form>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form colon={false}
              form={form}
              labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
              wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
              layout="horizontal"
              disabled={true}
            >
              <Form.Item
                name={['legalRepresentative', 'birthday']}
                label="Ngày sinh"
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>

              <Form.Item
                label="Chức vụ"
                labelAlign="left"
                style={{ width: '100%' }}
                name={['legalRepresentative', 'position']}
              >
                <Input disabled style={{ height: 40 }} />
              </Form.Item>

              <Form.Item
                name={['legalRepresentative', 'phoneNumber']}
                label="Số điện thoại"
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <Input style={{ height: 40 }} maxLength={10} />
              </Form.Item>

              <Form.Item
                name={['legalRepresentative', 'email']}
                label="Email"
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <Input style={{ height: 40 }} />
              </Form.Item>
            </Form>
          </Col>
        </Row>

      </ Card>
    </Content>
  )
}