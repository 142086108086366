import React from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import './index.css';
import { useTransition, animated, config } from '@react-spring/web';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';

const { Text, Link, Title } = Typography;

function MillionsConnections({ display, isMobile }: any) {
  const textTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  const imageTransition = useTransition(display, {
    from: { x: 50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  return (
    <div
      className="MillionsConnectionsHome"
      style={{ margin: !isMobile ? '5% 13% 0 13%' : '5% 5% 0 5%' }}
    >
      <Row justify={'space-between'} align={'middle'}>
        <Col
          lg={{ span: 9, order: 1 }}
          md={{ span: 20, order: 2 }}
          sm={{ span: 24, order: 2 }}
          xs={{ span: 24, order: 2 }}
        >
          {textTransition((style, item) => (
            <animated.div style={style}>
              <div>
                <Title level={3}>One platform, millions of connections</Title>
                <p
                  style={{
                    lineHeight: 1.6,
                    textAlign: 'justify',
                    fontWeight: 400,
                    width: '100%',
                    whiteSpace: 'normal',
                  }}
                >
                  Researched and developed by FPT and partners that are leading commercial banks in Vietnam, TradeFlat applies Blockchain technology to help connect banks and businesses, analyze data and provide connection recommendations, thereby solving the overall problem of capital financing in commercial transactions for businesses
                </p>
              </div>
            </animated.div>
          ))}
        </Col>

        <Col
          lg={{ span: 13, order: 2 }}
          md={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          xs={{ span: 24, order: 1 }}
        >
          {imageTransition((style, item) => (
            <animated.div style={style}>
              <Image
                preview={false}
                style={{ width: '100%', height: 'auto' }}
                src="/images/home/Overview/lc-20221018170424-dngaj-20230921064850-taxud.png"
              />
            </animated.div>
          ))}
        </Col>
      </Row>
    </div>
  );
}

export default MillionsConnections;
