import { translations } from '@/i18n/i18n';
import { Form, Input, Select, Typography, Button, message, notification, Row, Card } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { EntitiesLoadOptions } from '@/shared/reducers/reducer.utils';
import { useGetCompanyBankAccountsQuery, useGetCurrentCompanyQuery } from '@/services/api/company.api';
import { useAddCompanyBankAccountsMutation, useGetCompanyBankAccountQuery, useUpdateCompanyBankAccountMutation } from '@/services/api/company-bank-account.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAllBankQuery } from '@/services/api/bank.api'

const { Text, Title } = Typography;

const loadOptions: SearchEntitiesRequestModel = {
  filter: {
      conditions: []
  },
  fetchPlan: 'companyBankAccount-fetch-plan'
}

export default function BankAccountEdit(props: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const {
    data: userCompany,
  } = useGetCurrentCompanyQuery();
  const navigate = useNavigate()
  const [bankAccountType] = useState(['TAI_KHOAN_THANH_TOAN']);

  const { data: companyBankAccount} = id ? useGetCompanyBankAccountQuery(id) : { data: undefined };

  const [addCompanyBankAccount, {isSuccess: isSuccessAdd }] = useAddCompanyBankAccountsMutation()
  const [updateCompanyBankAccount, { isSuccess: isSuccessUpdate }] = useUpdateCompanyBankAccountMutation()
  const { data: bankList = [] } = useGetAllBankQuery()
  const { data: allComapnyBankAccount } = useGetCompanyBankAccountsQuery(loadOptions)

  const loadOptionsBankList: EntitiesLoadOptions = {};
  let schema = yup.object().shape({
    owner: yup.string().required(),
    bank: yup.string().required("Hãy chọn ngân hàng thanh toán!"),
    accountOwner: yup.string().required("Hãy thêm tên chủ tài khoản!"),
    accountNumber: yup.number().required("Hãy thêm số tài khoản!"),
    bankAccountType: yup.string().required("Hãy chọn loại tài khoản!"),
  });
  const yupSync: any = {
    async validator({ field }: any, value: any) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };

  const onFinish = async () => {
    const values = await form.getFieldsValue()
    values.owner = userCompany
    values.bank = bankList.filter(bank => bank.id === values.bank)[0]
    console.log(values)
    if (id === undefined && values.owner !== undefined && allComapnyBankAccount?.some(data => data.accountNumber === values.accountNumber)) notification.error({ message: "Số tài khoản đã tồn tại!" })
    else if (id === undefined && values.owner !== undefined && allComapnyBankAccount?.some(data => data.accountNumber !== values.accountNumber)) await addCompanyBankAccount(values)
    else if (id !== undefined && values.owner !== undefined) await updateCompanyBankAccount({ id, ...values })
    else message.error("Cannot submit")
  }

  const onCancel = () => {
    navigate('/admin/bankaccount');
  }

  useEffect(() => {
    console.log(allComapnyBankAccount)
  }, [allComapnyBankAccount])

  useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        owner: companyBankAccount?.owner?.name,
        accountOwner: companyBankAccount?.accountOwner,
        accountNumber: companyBankAccount?.accountNumber,
        bank: companyBankAccount?.bank?.name,
        bankAccountType: companyBankAccount?.bankAccountType
      })
    }
  }, [companyBankAccount])

  useEffect(() => {
    if (isSuccessAdd || isSuccessUpdate) navigate('/admin/bankaccount');
  }, [isSuccessAdd, isSuccessUpdate])

  return (
      <div style={{ height: "100%", width:'100%', padding: 20, display:'flex', alignItems:'center' , justifyContent:'center'}}>
        <Card type="inner" title="Tài khoản ngân hàng của công ty" style={{ height: "100%", width:'100%' }} 
        >
          <Row align={'middle'}>
            <Form colon={false} 
              form={form}
              labelCol={{ lg: { span: 3 } }}
              wrapperCol={{ lg: { span: 8 } }}
              style={{ height:'100%', width:'100%' }}
              onFinish={onFinish}
            >
              <Form.Item label={t(translations.companyBankAccount.owner)} name="owner">
                {/* <Select style={{ width: 315 }}>
                            {companyList.map(company => (
                                <Select.Option key={company.id} value={company.id}>
                                    {company.name}
                                </Select.Option>
                            ))}
                        </Select> */}
                <Text>{userCompany?.name}</Text>
              </Form.Item>
              <Form.Item label={t(translations.companyBankAccount.bank)} name="bank" rules={[yupSync]} hasFeedback>
                <Select>
                  {bankList.map(bank => (
                    <Select.Option key={bank.id} value={bank.id} >
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t(translations.companyBankAccount.bankAccountType)}
                name="bankAccountType"
                rules={[yupSync]}
                hasFeedback
              >
                <Select>
                  {bankAccountType.map(type => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item >
              <Form.Item label={t(translations.companyBankAccount.accountOwner)} name="accountOwner" rules={[yupSync]} hasFeedback>
                <Input />
              </Form.Item>
              <Form.Item label={t(translations.companyBankAccount.accountNumber)} name="accountNumber" rules={[yupSync]} hasFeedback>
                <Input />
              </Form.Item>

              <Footer style={{ display: 'flex', backgroundColor: 'white', justifyContent: 'end'  }}>
                <Form.Item wrapperCol={{ lg: { span: 24 } }}>
                  <Row justify={'space-between'} >
                    <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
                      Lưu
                    </Button>
                    <Button onClick={onCancel}>Đóng</Button>
                  </Row>

                </Form.Item>
              </Footer>

            </Form>
          </Row>

        </Card>
      </div>

  );
}
