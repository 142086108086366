import { Button, Col, Row, Typography, Modal, Checkbox } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useState, useEffect } from 'react';
import { SettingTwoTone } from '@ant-design/icons'
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
const { Text } = Typography;

function MainPage() {
    const isMobile = useIsMobile()
    const [currentTime, setCurrentTime] = useState<string>('');
    const [currentDate, setCurrentDate] = useState<string>('');
    const [openModal, setOpenModal] = useState(false);
    const plainOptions = ['LC', 'SCF', 'Guarrantee'];

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}`;
            setCurrentTime(formattedTime);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const now = new Date();
        const formattedDate = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
        setCurrentDate(formattedDate);
    }, []);
    return (
        <Row style={{ paddingTop: 95 }} justify={'center'}>
            <Col lg={{ span: 14 }}>
                <Row style={{ marginBottom: 40 }} justify={!isMobile ? 'start' : 'center'}>
                    <Col lg={{ span: 12 }}>
                        <Row>
                            <Title level={2}>Good morning, Mai Anh</Title>
                        </Row>
                        <Row>
                            <Text>Lần đăng nhập gần nhất: 10:32, 20/12/2022</Text>
                        </Row>
                    </Col>
                    <Col lg={{ span: 12 }}>
                        <Row justify={'end'}>
                            <Title level={2}>{currentTime}</Title>
                        </Row>
                        <Row justify={'end'}>
                            <Text>Hà Nội, {currentDate}</Text>
                        </Row>
                    </Col>
                </Row>

                <Row justify={!isMobile ? 'space-between' : 'center'} style={{ marginBottom: 40 }}>
                    <Col lg={{ span: 7 }}>
                        <div style={{ position: 'relative' }}>
                            <img src="/images/main-page/lc.png" style={{ width: '100%' }} />
                            <Title
                                level={4}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 5, left: 30 }}
                            >
                                Letter of Credit
                            </Title>
                            <Title
                                level={1}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                            >
                                31
                            </Title>
                            <Text
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    top: 140,
                                    left: 30,
                                }}
                            >
                                Tác vụ cần xử lý
                            </Text>
                            <Button
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    backgroundColor: 'rgba(54, 106, 226, 1)',
                                    borderColor: 'rgba(54, 106, 226, 1)',
                                    top: 250,
                                    left: 30,
                                }}
                            >
                                Thực hiện ngay
                            </Button>
                        </div>
                    </Col>
                    <Col lg={{ span: 7 }}>
                        <div style={{ position: 'relative' }}>
                            <img src="/images/main-page/scf.png" style={{ width: '100%' }} />
                            <Title
                                level={4}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 5, left: 30 }}
                            >
                                Supply Chain Finance
                            </Title>
                            <Title
                                level={1}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                            >
                                06
                            </Title>
                            <Text
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    top: 140,
                                    left: 30,
                                }}
                            >
                                Tác vụ cần xử lý
                            </Text>
                            <Button
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    backgroundColor: 'rgba(54, 106, 226, 1)',
                                    borderColor: 'rgba(54, 106, 226, 1)',
                                    top: 250,
                                    left: 30,
                                }}
                            >
                                Thực hiện ngay
                            </Button>
                        </div>
                    </Col>
                    <Col lg={{ span: 7 }}>
                        <div style={{ position: 'relative' }}>
                            <img src="/images/main-page/bl.png" style={{ width: '100%' }} />
                            <Title
                                level={4}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 5, left: 30 }}
                            >
                                Bảo lãnh
                            </Title>
                            <Title
                                level={1}
                                style={{ position: 'absolute', color: 'rgba(255, 255, 255, 1)', top: 20, left: 30 }}
                            >
                                09
                            </Title>
                            <Text
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    top: 140,
                                    left: 30,
                                }}
                            >
                                Tác vụ cần xử lý
                            </Text>
                            <Button
                                style={{
                                    position: 'absolute',
                                    color: 'rgba(255, 255, 255, 1)',
                                    backgroundColor: 'rgba(54, 106, 226, 1)',
                                    borderColor: 'rgba(54, 106, 226, 1)',
                                    top: 250,
                                    left: 30,
                                }}
                            >
                                Thực hiện ngay
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row justify={!isMobile ? 'space-between' : 'center'} style={{ marginBottom: 100 }} gutter={isMobile ? [0, 24] : [0, 0]}>
                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 14 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <img src='/images/main-page/income, money.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        1000+
                                    </Title>

                                </Row>
                                <Row >Tỷ giải ngân</Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 14 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <img src='/images/main-page/speed.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        250+
                                    </Title>

                                </Row>
                                <Row >Doanh nghiệp sử dụng</Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={{ span: 7 }} md={{ span: 14 }} sm={{ span: 14 }} xs={{ span: 14 }}>
                        <Row justify={'space-evenly'}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <img src='/images/main-page/Target.png' />
                            </Col>
                            <Col lg={{ span: 18 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ borderRight: '1px solid rgba(0, 53, 128, 0.08)' }}>
                                <Row >
                                    <Title level={4} style={{ margin: 0 }}>
                                        4600+
                                    </Title>

                                </Row>
                                <Row >Đơn hàng được hỗ trợ</Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row justify={'center'}>
                    <Button style={{ border: '1px solid rgba(24, 119, 242, 1)' }} onClick={() => setOpenModal(true)}>
                        <Col>
                            <SettingTwoTone style={{ marginRight: 10 }} />
                            <Text style={{ color: 'rgba(24, 119, 242, 1)' }}>
                                Thay đổi dịch vụ
                            </Text>
                        </Col>
                    </Button>
                </Row>

                <Modal
                    title="Quản lý dịch vụ đăng ký"
                    centered
                    open={openModal}
                    onOk={() => setOpenModal(false)}
                    onCancel={() => setOpenModal(false)}
                    width={520}
                >
                    <p>Lựa chọn ít nhất 1 dịch vụ</p>
                    <Row style={{ padding: 20, border: ' 1px solid rgba(24, 119, 242, 1)', borderRadius: 5, marginBottom: 20 }}>
                        <Col lg={{ span: 22 }}><Text strong>Thư tín dụng (LC)</Text></Col>
                        <Col lg={{ span: 2 }}><Checkbox defaultChecked={false}></Checkbox></Col>
                    </Row>
                    <Row style={{ padding: 20, border: ' 1px solid rgba(24, 119, 242, 1)', borderRadius: 5, marginBottom: 20 }}>
                        <Col lg={{ span: 22 }}><Text strong>Tài trợ chuỗi cung ứng (SCF)</Text></Col>
                        <Col lg={{ span: 2 }}><Checkbox defaultChecked={false}></Checkbox></Col>
                    </Row><Row style={{ padding: 20, border: ' 1px solid rgba(24, 119, 242, 1)', borderRadius: 5, marginBottom: 20 }}>
                        <Col lg={{ span: 22 }}><Text strong>Bảo lãnh điện tử (e-Guarrantee)</Text></Col>
                        <Col lg={{ span: 2 }}><Checkbox defaultChecked={false}></Checkbox></Col>
                    </Row>
                </Modal>
            </Col>
        </Row>
    );
}

export default MainPage;
