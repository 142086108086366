import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '@/shared/error/error-boundary';
import AppRoutes from 'routes';
import HeaderHome from '@/shared/layout/home-layout/header';
import Layout, { Content } from 'antd/es/layout/layout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from './config/store';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { clearAuth, getCurrentUser, getUserPermissions, loginSuccess } from './shared/reducers/authentication.reducer';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Modal } from 'antd';

const baseHref: any = document?.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');

function App() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const [confirm, setConfirm] = useState(false)
  const [tabLength, setTabLength] = useState()
  const auth = useAuth();

  useEffect(() => {
    if (!!i18n && !!i18n.changeLanguage)
      i18n.changeLanguage(currentLocale);
  }, [currentLocale])

  // useEffect(() => {
  //   const handleTabClose = (event: any) => {
  //     event.preventDefault();
  //     return (event.returnValue =
  //       'Are you sure you want to exit?');
  //   };
  //   window.addEventListener('beforeunload', handleTabClose);
  //   return () => {
  //     dispatch(clearAuth());
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  // useEffect(() => {
  //   const tabId = Date.now().toString();
  //   const tabsKey = 'activeTabs';

  //   const handleTabOpen = () => {
  //     const tabs = JSON.parse(localStorage.getItem(tabsKey) || "[]") || [];
  //     tabs.push(tabId);
  //     localStorage.setItem(tabsKey, JSON.stringify(tabs));
  //   };

  //   const handleTabClose = () => {
  //     let tabs = JSON.parse(localStorage.getItem(tabsKey) || "[]") || [];
  //     tabs = tabs.filter((id: string) => id !== tabId);
  //     localStorage.setItem(tabsKey, JSON.stringify(tabs));
  //     if (tabs.length === 0) {
  //       setConfirm(true);
  //       setTabLength(tabs.length);
  //       dispatch(clearAuth());
  //     } else {
  //       setTabLength(tabs.length);
  //     }
  //   };

  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'hidden') {
  //       handleTabClose();
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   window.addEventListener('beforeunload', handleTabClose);
  //   handleTabOpen();

  //   return () => {
  //     handleTabClose();
  //     window.removeEventListener('beforeunload', handleTabClose);
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      const user: any = auth.user;
      dispatch(loginSuccess(user));
      dispatch(getUserPermissions());
      dispatch(getCurrentUser());
    }
  }, [auth.isAuthenticated]);

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent()
    })
  }, [auth.events, auth.signinSilent]);
  React.useEffect(() => {
    // the `return` is important - addSilentRenewError() returns a cleanup function
    return auth.events.addSilentRenewError(() => {
      auth.revokeTokens();
      dispatch(clearAuth())

    })
  }, [auth.events, auth.signinSilent]);
  // React.useEffect(()=>{
  //   window.onbeforeunload =cleanup
  //   console.log("HANT : function cleanup() ")
  //   //Callback when close app
  //    function cleanup() {
  //    return dispatch(clearAuth());
  //   };
  // })
  return (
    <>
      <BrowserRouter basename={baseHref}>
        <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
        <AppRoutes />
      </BrowserRouter>

      <Modal
        title={tabLength}
        onOk={() => {
          dispatch(clearAuth())
          setConfirm(false)
        }}
        onCancel={() => setConfirm(false)}
      >

      </Modal>
    </>


  );
}

export default App;
