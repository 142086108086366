import { useAppDispatch, useAppSelector } from '@/config/store';
import { translations } from '@/i18n/i18n';
import { defaultValue as defaultRegConfirmRequest } from '@/shared/model/registration-confirmation-request.model';
import { Button, Col, ConfigProvider, Form, Input, Row, Space, Spin, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmation, reset, setUpdateSuccess } from '../registration.reducer';
import './../index.css';
import { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useAuth } from 'react-oidc-context';

export default function RegistrationConfirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { regcode } = routeParams;
  const { Text } = Typography;
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
  const loading = useAppSelector(state => state.registration.loading);
  const updateSuccess = useAppSelector(state => state.registration.updateSuccess);
  const auth = useAuth();

  let formInitData = defaultRegConfirmRequest;

  useEffect(() => {
    console.log(updateSuccess)
    if (updateSuccess) {
      // auth.signinRedirect();
      // navigate('/registration/confirmation_success');
      // Call login 
      //username / password
      //Login truc tiep
      
      
    }
  }, [updateSuccess]);

  const onVerify = useCallback(
    (token: string | null) => {
      setIsButtonEnabled(!!token);
    },
    [token],
  );

  const reloadReCapcha = () => {
    setIsButtonEnabled(false);
    setRecaptchaKey(new Date().getTime().toString());
  };

  useEffect(() => {
    reloadReCapcha();
  }, [i18next.language]);

  const onFinishConfirmation = (values: any) => {
    //Validate data in values here

    //Call API to confirm
    const data = {
      ...formInitData,
      ...values,
      ...{ regCode: regcode },
    };
    dispatch(confirmation(data));
    reloadReCapcha();
  };

  if (!regcode)
    return (
      <>
        <Content style={{ padding: '65px 65px' }}>
          <Text>Registration code is not available</Text>
        </Content>
      </>
    );

  return (
    <>
      <Spin spinning={loading}>
        {/* <ConfigProvider
          componentSize={'large'}
          theme={{
            token: {
              colorPrimary: '#1877F2',
            },
          }}
        > */}
        <Row>
          <Col lg={{ span: 12, order:1 }} md={{span:24, order:2}} xs={{span:24, order:2}} style={{ paddingTop: 63, display:'flex', alignItems:'center' }}>
            {/* <Text>RegCode : {regcode}</Text> */}
            <div style={{ width: '100%', textAlign:'center'}}>
              <h1 style={{ color: 'black', fontSize: 30 }}>
                {t(translations.register.confirmEmail)}
              </h1>
              <Row justify={'center'}>
                <Form colon={false} 
                  className="FormSignUp"
                  name="vertical_login"
                  onFinish={onFinishConfirmation}
                  initialValues={formInitData}
                  layout="vertical"
                  style={{ width:"100%", margin: '0 13vw 0 13vw' }}
                >
                  <Form.Item
                    label={t(translations.register.emaiLabel)}
                    name={'userEmail'}
                    rules={[{ required: true, message:"Hãy nhập email!" }]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input placeholder={t(translations.register.emailHolder)} />
                  </Form.Item>
                  <Form.Item
                    label={t(translations.register.passwordLabel)}
                    name={'password'}
                    rules={[{ required: true, message: "Hãy nhập mật khẩu!" }]}
                    style={{ marginBottom: 16 }}
                  >
                    <Input.Password placeholder={t(translations.register.passwordHolder)} />
                  </Form.Item>
                  <Form.Item>
                    <ReCAPTCHA
                      key={recaptchaKey}
                      sitekey="6LcEexkpAAAAADuucB4mCq4iQKjKI1wbpfn8fkWv"
                      onChange={onVerify}
                      type="image"
                      hl={i18next.language}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: 10 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      disabled={!isButtonEnabled}
                    >
                      {t(translations.register.confirmButton)}
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
            </div>

          </Col>
          <Col lg={{ span: 12, order:2 }} md={{span:24, order:1}} xs={{span:24, order:1}}>
            <img
              style={{ width: '100%' }}
              src={'../../images/registration.jpg'}
              alt="Registration Image"
            />
          </Col>
        </Row>
        {/* </ConfigProvider> */}
      </Spin>
    </>
  );
}
