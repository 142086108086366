export enum EKYCStatusEnum {
    // New = "New",
    // Accepted = "Accepted",
    // Shipping = "Shipping",
    // Complete = "Complete",
    // Cancel = "Cancel"
  }
  export enum CompanyOnboardingRegisterEnum {
    // New = "New",
    // Accepted = "Accepted",
    // Shipping = "Shipping",
    // Complete = "Complete",
    // Cancel = "Cancel"
  }

  export enum FeeServicePackageEnum {
    // New = "New",
    // Accepted = "Accepted",
    // Shipping = "Shipping",
    // Complete = "Complete",
    // Cancel = "Cancel"
  }

  export enum StatusEnum {
    active = "ACTIVE",
  }

  export enum ESiteEnum {
    
  }
  export enum CompanyOnboardingRegisterEnum{
    onboarding = "ONBOARDING",

  }
  export enum DigitalSignStatusEnum {
    success = "SUCCESS",
    cancel = "CANCEL",
    pending = "PENDING"
  }
  export enum CorporatePositionEnum{
    REPRESENTATIVE = "REPRESENTATIVE",
    ACCOUNTANT = "ACCOUNTANT",
    AUTHORIZATION_CHIEF_ACCOUNTANT = "AUTHORIZATION_CHIEF_ACCOUNTANT",
    AUTHORIZATION_CHIEF_REPRESENTATIVE = "AUTHORIZATION_CHIEF_REPRESENTATIVE"
  }
  export enum GenderEnum{

  }
  export enum EDoctype{
    CCCD  = "CMND",
    HC = "HC",
  }
  export enum BankTypeEnum{

  }

  export enum CompanyBankAccountTypeEnum{
    paymentAccount = "Tài khoản thanh toán",
    specialAccount = "Tài khoản chuyên dùng"
  }

  export enum ApplicationEnum{

  }

  export enum FeeTypeEnum{
    
  }