export interface UserInfo {
  username? : string | null; 
  locale? : string | null; 
  attributes?: {
    id?: string | null;
    email?: string | null;
    password?: string | null;
    age?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    srcImage?: string | null;
    phoneNumber?: string | null;
    active?: boolean | null;
    roleAssignmentText?: string | null;
    assignedTo?: string | null;
    company?: string | null;
    address?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    avatar?: {
      storageName?: string | null,
      path?: string | null,
      fileName?: string | null
    }
    employeeCode?: string | null;
    fullName?: string | null;
  };
}

export const defaultValue: Readonly<UserInfo> = {};
