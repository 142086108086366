import { useAppSelector } from "@/config/store";
import { Company } from "@/shared/model/company.model";
import { Col, Divider, Form, Input, Radio, Row, Space, Typography, Image, Button, Card, Modal, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { extractName } from "@/shared/utils/jmix-rest/file";
import { EyeTwoTone } from "@ant-design/icons";
import { fileApi } from "@/services/api/file.api";
import { CorporatePositionEnum } from "@/shared/model/enums/enums";

const { Title, Text, Link } = Typography;
const getDefaultImage = () => {
    return '/images/default_image.png';
};

const formatDate = (dateString: string): string => {
    if (!dateString) {
        return dateString;
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        return dateString;
    }

    const [year, month, day] = dateString.split('-');
    console.log(year, month, day);
    return `${day}/${month}/${year}`;
};

export default function CompanyEmpowerChiefAccountant() {
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const { empowerChiefAccountant = null } = companyEntity || {}
    const [form] = Form.useForm();
    const [downloadChiefAccountantFrontImage, { data: frontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [downloadChiefAccountantBackImage, { data: backImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [downloadChiefAccountantFaceImage, { data: faceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [downloadfile, { data: pdfFlie }] = fileApi.endpoints.downloadFile.useLazyQuery();

    const [openModalReviewFile, setOpenModalReviewFile] = useState(false)


    let schema = yup.object().shape({
        empowerChiefAccountant: yup.object().shape({
            name: yup.string(),
            cardIssueDate: yup.date(),
            cardFrontImage: yup.string(),
            cardBackImage: yup.string(),
            faceImage: yup.string(),
        }),
    });
    const yupSync = yupValidator(schema, form.getFieldsValue);

    interface FieldData {
        name: string | number | (string | number)[];
        value?: any;
        touched?: boolean;
        validating?: boolean;
        errors?: string[];
    }

    const onPreviewFile = async () => {
        if (companyEntity?.empowerChiefAccountant?.empowerDoc) {
            setOpenModalReviewFile(true)
            downloadfile({ fileId: companyEntity.empowerChiefAccountant?.empowerDoc, objectType: 'employee' })
        }

        else {
            notification.error({ message: "Tải File không thành công!" })
        }
    }

    useEffect(() => {
        console.log("HANT  useEffect downloadChiefAccountantFrontImage: ", empowerChiefAccountant)
        if (empowerChiefAccountant?.cardFrontImage)
            downloadChiefAccountantFrontImage({ fileId: empowerChiefAccountant?.cardFrontImage, objectType: 'employee' })
    }, [empowerChiefAccountant?.cardFrontImage])
    useEffect(() => {
        if (empowerChiefAccountant?.cardBackImage)
            downloadChiefAccountantBackImage({ fileId: empowerChiefAccountant?.cardBackImage, objectType: 'employee' })
    }, [empowerChiefAccountant?.cardBackImage])
    useEffect(() => {
        if (empowerChiefAccountant?.faceImage)
            downloadChiefAccountantFaceImage({ fileId: empowerChiefAccountant?.faceImage, objectType: 'employee' })
    }, [empowerChiefAccountant?.faceImage])

    useEffect(() => {
        let entity;
        entity = {
            ...companyEntity,
            empowerChiefAccountant: {
                ...empowerChiefAccountant,
                cardIssueDate: formatDate(empowerChiefAccountant?.cardIssueDate || ""),
                birthday: formatDate(empowerChiefAccountant?.birthday || ""),
                empowerDateTo: formatDate(empowerChiefAccountant?.empowerDateTo || ""),
                empowerDateFrom: formatDate(empowerChiefAccountant?.empowerDateFrom || ""),
            }
        }

        console.log("Entity", entity)

        form.setFieldsValue(entity)
        form.setFieldValue(['empowerChiefAccountant', 'position'], companyEntity.empowerChiefAccountant?.position === CorporatePositionEnum.AUTHORIZATION_CHIEF_ACCOUNTANT ? "Ủy quyền kế toán trường" : "")

    }, [])

    return (
        <Content>
            <Card type='inner' title='Thông tin ủy quyền kế toán trưởng' style={{ margin: 20 }}>
                <Row>
                    <Space >
                        <Text>Loại giấy tờ</Text>
                        <Divider orientation="right" />
                        <Radio.Group value={empowerChiefAccountant?.docType}>
                            <Radio value={"CMND"}>CMND/CCCD/CCCD gắn chíp</Radio>
                            <Radio value={"HC"}>Hộ chiếu</Radio>
                        </Radio.Group>
                    </Space>
                </Row>
                <Form colon={false}
                    form={form}
                >
                    <Row style={{ margin: '24px 0px' }} gutter={[16, 16]}>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <div
                                style={{
                                    padding: '12px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '8px 8px 8px 8px',
                                }}
                            >
                                <Form.Item
                                    name={['empowerChiefAccountant', 'cardFrontImage']}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}

                                >
                                    <div style={{ width: '23vw', minWidth: 200, minHeight: 90, height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Image
                                            style={{ width: '100%', maxHeight: '18vw' }}
                                            preview={false}
                                            src={
                                                frontImageFile || getDefaultImage()
                                            }
                                            fallback={getDefaultImage()}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                        </Col>

                        {
                            (empowerChiefAccountant?.docType?.toString() !== "HC") && (
                                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                    <div style={{
                                        padding: '12px',
                                        border: '1px solid #f0f0f0',
                                        borderRadius: '8px 8px 8px 8px',
                                    }}>
                                        <Form.Item
                                            name={['empowerChiefAccountant', 'cardBackImage']}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}

                                        >
                                            <div style={{ width: '23vw', height: '18vw', minWidth: 200, minHeight: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Image
                                                    style={{ width: '100%', maxHeight: '18vw' }}
                                                    preview={false}
                                                    src={
                                                        backImageFile || getDefaultImage()
                                                    }
                                                    fallback={getDefaultImage()}
                                                />
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Col>
                            )
                        }

                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <div style={{
                                padding: '12px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '8px 8px 8px 8px',
                            }}>
                                <Form.Item
                                    name={['empowerChiefAccountant', 'faceImage']}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}

                                >
                                    <div style={{ width: '23vw', height: '18vw', minWidth: 200, minHeight: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Image
                                            style={{ width: '100%', maxHeight: '18vw' }}
                                            preview={false}
                                            src={
                                                faceImageFile || getDefaultImage()
                                            }
                                            fallback={getDefaultImage()}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>

            < Card type='inner' title='Xác thực thông tin ủy quyền kế toán trưởng' style={{ margin: 20 }
            } >

                <Row>
                    <Col
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        <Form colon={false}
                            form={form}
                            labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                            wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                            layout="horizontal"
                            disabled={true}

                        >
                            <Form.Item
                                name={['empowerChiefAccountant', 'name']}
                                // name={'name'}
                                label="Họ và tên"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input placeholder="Nhập họ và tên" style={{ height: 40 }} />
                            </Form.Item>
                            <Form.Item
                                name={['empowerChiefAccountant', 'cardID']}
                                // name={'name'}
                                label="Số giấy tờ định danh"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input placeholder="Nhập số giấy tờ kinh doanh" style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                name={['empowerChiefAccountant', 'cardIssueDate']}
                                label="Ngày cấp"
                                labelAlign="left"

                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                name={['empowerChiefAccountant', 'cardIssueLocation']}
                                // name={'name'}
                                label="Nơi cấp"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        <Form colon={false}
                            form={form}
                            labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                            wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                            layout="horizontal"
                            disabled={true}
                        >
                            <Form.Item
                                name={['empowerChiefAccountant', 'birthday']}
                                label="Ngày sinh"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                label="Chức vụ"
                                labelAlign="left"
                                style={{ width: '100%' }}
                                name={['empowerChiefAccountant', 'position']}
                            >
                                <Input disabled style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                name={['empowerChiefAccountant', 'phoneNumber']}
                                label="Số điện thoại"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} maxLength={10} />
                            </Form.Item>

                            <Form.Item
                                name={['empowerChiefAccountant', 'email']}
                                label="Email"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </ Card>

            < Card type='inner' title='Xác thực thông tin ủy quyền' style={{ margin: 20 }
            } >
                <Col
                    xl={{ span: 12 }}
                    lg={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                >
                    <Form colon={false}
                        form={form}
                        labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                        wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                        layout="horizontal"
                        disabled={true}

                    >
                        <Form.Item
                            // name={['empowerChiefAccountant', 'cardIssueLocation']}
                            // name={'name'}
                            label="Giấy tờ ủy quyền có kí số"
                            labelAlign="left"
                            rules={[yupSync]}
                        >
                            {
                                companyEntity.empowerChiefAccountant?.empowerDoc && <div style={{ display: "flex" }}>
                                    <Button disabled={false} onClick={onPreviewFile} type='default' style={{ marginRight: 5, backgroundColor: 'rgb(232, 241, 254)', color: "rgb(24, 119, 242)", border: 'none' }}>{extractName(companyEntity.empowerChiefAccountant?.empowerDoc)}<EyeTwoTone /></Button>
                                </div>

                            }
                        </Form.Item>
                        <Form.Item
                            name={['empowerChiefAccountant', 'empowerDateFrom']}
                            label="Ngày bắt đầu ủy quyền"
                            labelAlign="left"
                            rules={[yupSync]}
                            style={{ width: '100%' }}
                        >
                            <Input style={{ height: 40 }} />
                        </Form.Item>

                        <Form.Item
                            name={['empowerChiefAccountant', 'empowerDateTo']}
                            label="Ngày kết thúc ủy quyền"
                            labelAlign="left"
                            rules={[yupSync]}
                            style={{ width: '100%' }}
                        >
                            <Input style={{ height: 40 }} />
                        </Form.Item>
                    </Form>
                </Col>
            </ Card>

            <Modal
                open={openModalReviewFile}
                onOk={() => setOpenModalReviewFile(false)}
                onCancel={() => setOpenModalReviewFile(false)}
                title={"Xem chi tiết"}
                footer={(_, { OkBtn }) => (
                        <Button onClick={() => setOpenModalReviewFile(false)}>Đóng</Button>
                )}
                width={'50vw'}
                centered={true}
            >
                {pdfFlie && (
                    <iframe
                        src={pdfFlie}
                        width="100%"
                        height="700px"
                        title="PDF Viewer"
                    />
                )}
            </Modal>
        </Content>
    )
}