import application from './en/application.json';
import header from './en/header.json';
import onboarding from './en/onboarding.json'
import action from './en/action.json'
import bankBranch from './en/model/bank-branch.json'
import bank from './en/model/bank.json'
import companyBankAccount from './en/model/company-bank-account.json'
import companyBusinessRegistration from './en/model/company-business-registration.json'
import companyCheckEkyc from './en/model/company-check-ekyc.json'
import companydigitalSign from './en/model/company-digital-sign.json'
import companyemployee from './en/model/company-employee.json'
import companyFeeservice from './en/model/company-fee-service.json'
import company from './en/model/company.json'
import corporateAddress from './en/model/corporate-address.json'
import district from './en/model/district.json'
import fileInfoResponse from './en/model/file-info-response.json'
import province from './en/model/province.json'
import registrationModel from './en/model/registration.json'
import roleassignment from './en/model/role-assignment.json'
import usercompany from './en/model/user-company.json'
import user from './en/model/user.json'
import ward from './en/model/ward.json'
import register from './en/register.json'
import companyDetail from './en/company-detail.json'
import bpm from './en/bpm.json'
import taskData from './en/model/task-data.json'
export default {
    application,
    header,
    onboarding,
    action,
    bankBranch,
    bank,
    companyBankAccount,
    companyBusinessRegistration,
    companyCheckEkyc,
    companydigitalSign,
    companyemployee,
    companyFeeservice,
    company,
    corporateAddress,
    district,
    fileInfoResponse,
    province,
    registrationModel,
    roleassignment,
    usercompany,
    user,
    ward,
    register,
    companyDetail,
    bpm,
    taskData

}