
import BankDetail from '@/modules/entities/bank/bank-detail'
import { baseAuthAPI } from './base-auth.api'
import { Bank } from '../../shared/model/bank.model'
import { News } from '@/shared/model/news.model'
import { DigitalSignature } from '@/shared/model/digital-signature.model'

const digitalURL = 'http://localhost:14211'

export const digitalApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getToken: build.mutation<string, void>({
            query() {
                return {
                    url: `${digitalURL}/GetToken`,
                    method: "POST",
                    responseHandler: (response) => response.text()
                }
            }
        }),

        digitalSignature: build.mutation<DigitalSignature, string>({
            query (token){
                return{
                    url: `${digitalURL}/GetCert`,
                    method: 'POST',
                    body: {
                        "token": token,
                        "signatureProperty": {
                            "lang": "en"
                        }
                    }
                }

            },
        }),
    })
})

export const {
    useGetTokenMutation,
    useDigitalSignatureMutation
} = digitalApi

export const {
    endpoints: { getToken, digitalSignature },
} = digitalApi