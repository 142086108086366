import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Table, Form, Input, Space, Modal, Select, Typography, notification, Spin, Radio } from 'antd'
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { Content, Footer } from 'antd/es/layout/layout'
import './index.css'
import { translations } from '@/i18n/i18n'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/config/store'
import type { GetProp, InputRef, TableColumnsType, TableColumnType, TableProps, RadioChangeEvent } from 'antd';
import { CompanyBankAccount } from '@/shared/model/company-bank-account.model'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model'
import { useGetCurrentCompanyQuery } from "@/services/api/company.api";
import { useGetCompanyBankAccountsQuery, useAddCompanyBankAccountsMutation, useDeleteCompanyBankAccountMutation, useUpdateCompanyBankAccountMutation } from '@/services/api/company-bank-account.api'
import { useGetAllBankQuery } from '@/services/api/bank.api'
import { CompanyBankAccountTypeEnum } from '@/shared/model/enums/enums'
import { FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words';
import * as yup from 'yup';
import { Company } from '@/shared/model/company.model'
import { goPrev, saveDraftCompanyEntity } from '../../onboarding.reducer'
import { useCheckNumberCifMutation, useCompleteOnboardingMutation, useGetCompanyBankCifMutation, usePostCompanyBankCifMutation, useUpdateCompanyBankCifMutation } from '@/services/api/onboarding.api';
import { CompanyBankCif } from '@/shared/model/company-bank-cif.model'
import { Bank } from '@/shared/model/bank.model'

type TableRowSelection<T> = TableProps<T>['rowSelection'];
const { Text } = Typography
type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;
interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}
type TablePagination<T extends object> = NonNullable<Exclude<TableProps<T>['pagination'], boolean>>;
type TablePaginationPosition = NonNullable<TablePagination<any>['position']>[number];


type DataIndex = keyof CompanyBankAccount;

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export default function OnboardingBankAccount(props: any) {
    let schema = yup.object().shape({
        owner: yup.string().required(),
        bank: yup.string().required("Hãy chọn ngân hàng thanh toán!"),
        accountOwner: yup.string().required("Hãy thêm tên chủ tài khoản!")
            .test('not-only-spaces', 'Tên chủ tài khoản không được chỉ chứa khoảng trắng.', value => value.trim().length > 0),
        accountNumber: yup.number().typeError('Số tài khoản không hợp lệ!').required("Hãy thêm số tài khoản!"),
        bankAccountType: yup.string().required("Hãy chọn loại tài khoản!"),
        cif: yup.number().required("Hãy thêm số CIF!").typeError('Số CIF không hợp lệ!')

    });
    const yupSync: any = {
        async validator({ field }: any, value: any) {
            await schema.validateSyncAt(field, { [field]: value });
        },
    };

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [handleOkCase, setOkCase] = useState(true)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [form] = Form.useForm()
    const [formCif] = Form.useForm()
    const [bankAccountType, setBankAccountType] = useState("TAI_KHOAN_THANH_TOAN")
    const { data: bankList = [], isLoading: isLoadingGetBankList, refetch: refetchBankList } = useGetAllBankQuery()
    const loadOptions: SearchEntitiesRequestModel = {
        filter: {
            conditions: []
        },
        fetchPlan: 'companyBankAccount-fetch-plan'
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const { data, isLoading: isLoadingGet, refetch } = useGetCompanyBankAccountsQuery(loadOptions)

    const [completeOnboarding, { data: dataCompleted, isLoading: isLoadingCompleted, isSuccess: isSuccessCompleted, isError: isErrorCompleted }] = useCompleteOnboardingMutation()
    const [addCompanyBankAccount, { isError: isErrorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAdd }] = useAddCompanyBankAccountsMutation()
    const [deleteCompanyBankAccount, { isSuccess: isSuccessDelete, isLoading: isLoadingDelete }] = useDeleteCompanyBankAccountMutation()
    const [getCompanyBankCif, { data: dataCompanyBankCif, isSuccess: isSuccessGetCompanyBankCif, isLoading: isLoadingGetCompanyBankCif }] = useGetCompanyBankCifMutation()
    const [postCompanyBankCif, { isLoading: isLoadingPostCompanyBank, isSuccess: isSuccessPostCompanyBank, isError: isErrorPostCompanyBank }] = usePostCompanyBankCifMutation()
    const [updateCompanyBankCif, { isLoading: isLoadingUpdateBankCif, isSuccess: isSuccessUpdateBankCif, isError: isErrorUpdateBankCif }] = useUpdateCompanyBankCifMutation()
    const [funcCheckCifNumber, { data: checkCifNumber, isLoading: isLoadingCheckCifNumber, isSuccess: isSuccessCheckCifNumber, isError: isErrorCheckCifNumber }] = useCheckNumberCifMutation()
    const { data: userCompany } = useGetCurrentCompanyQuery();
    const { data: company, isLoading: isLoadingCurrentCompany } = useGetCurrentCompanyQuery();
    const [companyBankCif, setCompanyBankCif] = useState<CompanyBankCif[]>();
    const [updateCompanyBankAccount, { isSuccess: isSuccessUpdate }] = useUpdateCompanyBankAccountMutation()
    const [top] = useState<TablePaginationPosition>('topRight');
    const [allDataCompanyBankAccount, setAllDataCompanyBankAccount] = useState<CompanyBankAccount[] | undefined>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [bank, setBank] = useState<Bank>();
    const [confirmSubmit, setConfirmSubmit] = useState(false)
    const [selectCompanyBankCif, setSelectCompanyBankCif] = useState<CompanyBankCif>();
    const [dataBody, setDataBody] = useState<CompanyBankCif>()
    const [dataCompanyBankAccount, setDataCompanyBankAccount] = useState<CompanyBankAccount>()
    const [openModalEnterCIF, setOpenModalEnterCIF] = useState(false)

    const generateKey = (record: any) => {
        return record.id
    }

    const pagination = {
        pageSize: 10,
        position: [top],
    }

    const onSelectChangeBank = (value: any) => {
        const bankSelect = bankList.find(bank => bank.name === value)
        setBank(bankSelect)
        const data = companyBankCif?.find(companyBank => companyBank?.bank?.id === bankSelect?.id)
        console.log(data)
        setSelectCompanyBankCif(data)
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const onCompleteOnboarding = () => {
        if (data?.length && data?.length > 0) {
            setConfirmSubmit(true)
        }

        else {
            notification.error({
                message: (
                    <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                        Vui lòng cung cấp thông tin tài khoản doanh nghiệp tại ngân hàng
                    </div>
                ),
                style: {
                    width: 400,
                },
            });
        }
    }

    const onGoPrev = () => {
        dispatch(goPrev(companyEntity));
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<CompanyBankAccount> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        // icon={<SearchOutlined />}
                        size="small"
                    // style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const recordValue = record[dataIndex];
            if ( !recordValue ) {
                return false;
            }
            return recordValue.toString().toLowerCase().includes((value as string).toLowerCase());
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: TableColumnsType<CompanyBankAccount> = [
        {
            title: "Số thứ tự",
            render: (text, record, index) => index + 1,
        },
        {
            title: t(translations.companyBankAccount.bank),
            dataIndex: 'bank',
            key: 'bank',
            render: (text: any, record: any) => {
                // setBankId(text.id)
                return text !== undefined ? text.name : ""
            },
            // sorter: (a, b) => ((a.bank?.name || '')).localeCompare(b.bank?.name || ''),
        },
        {
            title: "Mã số CIF",
            key: 'companyBankCif',
            dataIndex: 'companyBankCif',
        },
        {
            title: t(translations.companyBankAccount.accountNumber),
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            ...getColumnSearchProps('accountNumber'),

        },
        {
            title: t(translations.companyBankAccount.bankAccountType),
            dataIndex: 'bankAccountType',
            key: 'bankAccountType',

        },
        {
            title: t(translations.companyBankAccount.accountOwner),
            dataIndex: 'accountOwner',
            key: 'accountOwner',
            ...getColumnSearchProps('accountOwner'),
        },
        // {
        //     title: "Ngày tạo",
        //     dataIndex: 'createdDate',
        //     key: 'createdDate',
        //     render: (date: string) => formatDate(date),
        //     sorter: (a, b) => new Date(a.createdDate || "").getTime() - new Date(b.createdDate || "").getTime(),

        // },
        // {
        //     title: "Người sửa",
        //     dataIndex: 'createdBy',
        //     key: 'createdBy',
        //     sorter: (a, b) => (a.createdBy || '').localeCompare(b.createdBy || ''),
        // },
        // {
        //     title: "Ngày sửa",
        //     dataIndex: 'lastModifiedDate',
        //     key: 'lastModifiedDate',
        //     render: (date: string) => formatDate(date),
        //     sorter: (a, b) => new Date(a.createdDate || "").getTime() - new Date(b.createdDate || "").getTime(),
        // },
    ]

    const onChangeBankAccoutType = (e: RadioChangeEvent) => {
        setBankAccountType(e.target.value)
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const onCreateNew = () => {
        form.resetFields()
        form.setFieldValue("bankAccountType", "TAI_KHOAN_THANH_TOAN")
        setIsModalOpen(true)
        setOkCase(true)
        // navigate('/admin/bankaccount/new')
    }

    const onEdit = () => {

        let selectCompanyBankAccount = data?.find(account => account.id === selectedRowKeys[0]);
        console.log(selectCompanyBankAccount);

        const dataCompanyBankCif = companyBankCif?.find(companyBank => companyBank?.bank?.id === selectCompanyBankAccount?.bank?.id)
        setSelectCompanyBankCif(dataCompanyBankCif)
        if (selectCompanyBankAccount) {
            const { owner, bank, ...rest } = selectCompanyBankAccount;
            form.setFieldsValue({
                ...rest,
                owner: owner?.name,
                bank: bank?.name,
                cif: dataCompanyBankCif?.cif
            });
        }

        setIsModalOpen(true)
        setOkCase(false)

        // navigate(`/admin/bankaccount/${selectedRowKeys}/edit`)
    }
    const onDelete = () => {
        setConfirmDelete(true)
    }

    const handelDeleted = () => {
        for (const key of selectedRowKeys as string[]) {
            deleteCompanyBankAccount(key)
            // dispatch(saveDraftCompanyEntity({
            //     ...companyEntity,
            //     bankAccount: (companyEntity.bankAccount || []).filter(bankAccount => bankAccount.id !== key)
            // }))
        }
        setConfirmDelete(false)
        notification.info({
            message: (
                <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                    {t(translations.companyBankAccount.delete.messageSucces)}
                </div>
            )
        })
    }

    const handleCreate = async () => {
        form.validateFields().then((values) => {
            const hasAccountNumber = data?.some(companyBank => companyBank?.bank?.id === bank?.id && companyBank?.accountNumber === values?.accountNumber)
            if (hasAccountNumber) {
                form.setFields([{
                    name: "accountNumber",
                    errors: ["Số tài khoản đã tồn tại!"]
                }])
                return
            }
            // const values =  form.getFieldsValue()
            values.bank = bankList.filter(bank => bank.name === values.bank)[0]
            values.owner = userCompany
            setDataCompanyBankAccount(values)
            console.log('Submit successful:', values)

            const cif = form.getFieldValue("cif")
            const dataPost = {
                cif,
                company,
                bank
            }

            setDataBody(dataPost)

            funcCheckCifNumber(dataPost)
        })
            .catch((e) => {console.log(e)})
    }

    const handleUpdate = async () => {
        form.validateFields().then((values) => {

            let bankToUpdate = bankList.filter(bank => bank.name === values.bank || bank.id === values.bank)[0]

            const hasAccountNumber = data?.some(companyBank => companyBank?.bank?.id === bankToUpdate?.id && companyBank?.accountNumber === values?.accountNumber && companyBank?.id !== selectedRowKeys[0])
            if (hasAccountNumber) {
                form.setFields([{
                    name: "accountNumber",
                    errors: ["Số tài khoản đã tồn tại!"]
                }])
                return
            }

            try {
                const values = form.getFieldsValue()
                values.bank = bankList.filter(bank => bank.name === values.bank || bank.id === values.bank)[0]
                values.owner = userCompany
                values.id = selectedRowKeys[0]

                setDataCompanyBankAccount(values)

                const cif = form.getFieldValue("cif")
                const dataPost = {
                    cif,
                    company,
                    bank: bankToUpdate
                }

                setDataBody(dataPost)

                funcCheckCifNumber(dataPost)


            }
            catch {
                console.log('Submit fails:')
            }
            setIsModalOpen(false)
            form.resetFields()
        })
        .catch((e) => {console.log(e)})
    }

    useEffect(() => {
        if (isSuccessCheckCifNumber) {
            if (checkCifNumber) {
                notification.info({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Đã tồn tại số CIF!
                            </div>
                        )
                })
            }
            else {
                if (handleOkCase) {
                    if (dataBody && dataCompanyBankAccount) {
                        if (selectCompanyBankCif?.cif) {
                            updateCompanyBankCif({ id: selectCompanyBankCif?.id || "", body: dataBody })
                        }
                        else {
                            postCompanyBankCif(dataBody)
                        }
                        addCompanyBankAccount(dataCompanyBankAccount)
                        setIsModalOpen(false)
                        form.resetFields()
                    }
                    else {
                        notification.error({
                            message:
                                (
                                    <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                        Cập nhật thông tin không thành công!
                                    </div>
                                )
                        })
                    }
                }
                else {
                    if (dataBody && dataCompanyBankAccount) {
                        if (selectCompanyBankCif?.cif) {
                            updateCompanyBankCif({ id: selectCompanyBankCif?.id || "", body: dataBody })
                        }
                        else {
                            postCompanyBankCif(dataBody)
                        }
                        updateCompanyBankAccount(dataCompanyBankAccount)
                        setIsModalOpen(false)
                        form.resetFields()
                    }
                    else {
                        notification.error({
                            message:
                                (
                                    <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                        Cập nhật thông tin không thành công!
                                    </div>
                                )
                        })
                    }
                }
            }
        }

        else if (isErrorCheckCifNumber) {
            notification.error({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Kiểm tra số cif không thành công!
                        </div>
                    )
            })
        }
    }, [funcCheckCifNumber, isSuccessCheckCifNumber, isLoadingCheckCifNumber, isErrorCheckCifNumber])

    const handleCancel = () => {
        setIsModalOpen(false)
        setConfirmDelete(false)
        form.resetFields()
    }

    useEffect(() => {
        getCompanyBankCif()
    }, []);

    useEffect(() => {
        if (isSuccessGetCompanyBankCif) {
            setCompanyBankCif(dataCompanyBankCif || [])
        }
    }, [dataCompanyBankCif, getCompanyBankCif]);

    useEffect(() => {
        if (isSuccessDelete) {
            refetch();
            refetchBankList();
        }
    }, [isSuccessDelete])

    useEffect(() => {
        const transformedData = data?.map(companyBankAccount => {
            const bankCif = companyBankCif?.find(company => company?.bank?.id === companyBankAccount?.bank?.id)
            console.log("PAV companyBankCif", bankCif)
            if (companyBankAccount?.bankAccountType?.toString() === "TAI_KHOAN_THANH_TOAN") {
                return { ...companyBankAccount, companyBankCif: bankCif?.cif, bankAccountType: CompanyBankAccountTypeEnum.paymentAccount };
            }
            else if (companyBankAccount?.bankAccountType?.toString() === "TAI_KHOAN_CHUYEN_DUNG") {
                return { ...companyBankAccount, companyBankCif: bankCif?.cif, bankAccountType: CompanyBankAccountTypeEnum.specialAccount };
            }
            return { ...companyBankAccount, companyBankCif: bankCif?.cif };
        });
        if (transformedData) {
            setAllDataCompanyBankAccount([...transformedData].reverse());
        } else {
            setAllDataCompanyBankAccount(transformedData);
        } console.log("allDataCompanyBankAccount: ", data)
    }, [data, companyBankCif])

    // useEffect(() => {
    //     if (!isModalOpen) refetch()
    // }, [isModalOpen])

    useEffect(() => {
        if (isModalOpen) {
            form.setFieldValue("cif", selectCompanyBankCif?.cif)
        }
    }, [selectCompanyBankCif])

    useEffect(() => {
        if ((isSuccessPostCompanyBank && isSuccessAdd) || (isSuccessUpdateBankCif && isSuccessAdd) || (isSuccessPostCompanyBank && isSuccessUpdate) || (isSuccessUpdateBankCif && isSuccessUpdate)) {
            setIsModalOpen(false)
            getCompanyBankCif()
            refetch()
            form.resetFields()

            notification.success({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Cập nhật thông tin thành công!
                        </div>
                    )
            })
        }
        else if (isErrorAdd || isErrorPostCompanyBank || isErrorUpdateBankCif) {
            notification.error({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Cập nhật thông tin không thành công!
                        </div>
                    )
            })
        }
    }, [addCompanyBankAccount, isSuccessPostCompanyBank, isSuccessAdd, isSuccessUpdateBankCif, updateCompanyBankAccount, isSuccessPostCompanyBank, isSuccessUpdate, isSuccessUpdateBankCif])

    useEffect(() => {
        const submit = async () => {
            if (isErrorCompleted) {
                notification.error({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Đăng ký thông tin doanh nghiệp không thành công!
                            </div>
                        )
                })
            }

            if (isSuccessCompleted) {
                await dispatch(saveDraftCompanyEntity(dataCompleted));
                notification.success({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Đăng ký thông tin doanh nghiệp thành công!
                            </div>
                        )
                })
            }
        }

        submit()

    }, [isSuccessCompleted, isErrorCompleted, isLoadingCompleted])

    return (
        <Spin spinning={isLoadingPostCompanyBank || isLoadingGet || isLoadingGetBankList || isLoadingCurrentCompany || isLoadingAdd || isLoadingDelete || isLoadingGetCompanyBankCif || isLoadingUpdateBankCif || isLoadingCheckCifNumber}>
            <div className='companyBankAccount' style={{ height: '95%' }}>
                <Content className="ContentBankAccount" style={{ margin: 20, height: "90%" }}>
                    <Row gutter={[12, 12]}>
                        <Col>
                            <Button type="primary" onClick={onCreateNew} style={{ backgroundColor: '#2F57FA' }}>
                                <PlusOutlined />
                                {t(translations.companyBankAccount.create.button)}
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={onEdit} disabled={selectedRowKeys.length != 1} style={{}}>
                                <EditOutlined />
                                {t(translations.companyBankAccount.edit.button)}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                onClick={onDelete}
                                disabled={!selectedRowKeys.length || selectedRowKeys.length < 0}
                            >
                                <DeleteOutlined />
                                {t(translations.companyBankAccount.delete.button)}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        rowSelection={rowSelection}
                        dataSource={allDataCompanyBankAccount}
                        columns={columns}
                        pagination={pagination}
                        rowKey={generateKey}
                        scroll={{ x: 'calc(300px + 50%)' }}
                    />
                </Content>
                <Footer
                    style={{
                        background: '#fff',
                        // position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ display: 'inline-block' }}>
                        <Button style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
                            {t(translations.action.previous)}
                        </Button>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <Button type='primary' style={{ margin: '0 8px', height: 40 }} onClick={onCompleteOnboarding}>
                            {t(translations.action.complete)}
                        </Button>


                    </div>
                </Footer>
                <Modal
                    title={t(translations.companyBankAccount.title)}
                    open={isModalOpen}
                    onOk={handleOkCase ? handleCreate : handleUpdate}
                    onCancel={handleCancel}
                    width={"50%"}
                    okText="Lưu"
                    cancelText="Hủy"

                    footer={() => (
                        <Footer style={{ backgroundColor: "white", padding: 0 }}>
                            <Row justify={'space-between'}>
                                <Col span={3}>
                                    <Button style={{ width: '100%', height: 40 }} onClick={handleCancel}>Quay lại</Button>
                                </Col>
                                <Col span={3}>
                                    <Button style={{ width: '100%', height: 40 }} type='primary' onClick={handleOkCase ? handleCreate : handleUpdate}>Lưu</Button>
                                </Col>
                            </Row>
                        </Footer>
                    )}

                >
                    <Form colon={false}
                        form={form}
                        labelCol={{ lg: { span: 6 } }}
                        wrapperCol={{ lg: { span: 12 } }}
                        style={{ height: '100%', width: '100%' }}
                        labelAlign='left'
                    // onFinish={onFinish}
                    >
                        {/* <Form.Item
                            label={
                                <span>
                                    {t(translations.companyBankAccount.owner)}
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            }
                            name="owner">
                            <Text>{companyEntity?.name}</Text>
                        </Form.Item> */}
                        <Form.Item
                            label={
                                <span>
                                    {t(translations.companyBankAccount.bankAccountType)}
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            }
                            name="bankAccountType"
                            rules={[yupSync]}
                            hasFeedback
                        >
                            {/* <Select>
                                {bankAccountType.map(type => (
                                    <Select.Option key={type} value={type}>
                                        {type === "TAI_KHOAN_THANH_TOAN" ? "Tài khoản thanh toán" : "Khác"}
                                    </Select.Option>
                                ))}
                            </Select> */}

                            <Radio.Group onChange={onChangeBankAccoutType} value={bankAccountType}>
                                <Radio value={"TAI_KHOAN_THANH_TOAN"}>Tài khoản thanh toán</Radio>
                                <Radio value={"TAI_KHOAN_CHUYEN_DUNG"}>Tài khoản chuyên dùng</Radio>
                            </Radio.Group>

                        </Form.Item >
                        <Form.Item label={
                            <span>
                                {t(translations.companyBankAccount.bank)}
                                <span style={{ color: 'red' }}> *</span>
                            </span>
                        } name="bank" rules={[yupSync]} hasFeedback>
                            <Select onChange={onSelectChangeBank} showSearch style={{ height: 40 }}>
                                {bankList.map(bank => (
                                    <Select.Option key={bank.id} value={bank.name} >
                                        {bank.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={
                                <span>
                                    Mã khách hàng (CIF)
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            } rules={[yupSync]} name={"cif"} hasFeedback>
                            <Input placeholder={"Hãy nhập số cif"} style={{ height: 40 }} maxLength={20} />
                        </Form.Item>


                        <Form.Item
                            label={
                                <span>
                                    {t(translations.companyBankAccount.accountOwner)}
                                    <span style={{ color: 'red' }}> *</span>
                                </span>

                            }
                            name="accountOwner" rules={[yupSync]} hasFeedback>
                            <Input style={{ height: 40 }} maxLength={50} />
                        </Form.Item>
                        <Form.Item
                            label={
                                <span>
                                    {t(translations.companyBankAccount.accountNumber)}
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            } name="accountNumber" rules={[yupSync]} hasFeedback>
                            <Input style={{ height: 40 }} maxLength={20} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={t(translations.companyBankAccount.delete.confirm)}
                    open={confirmDelete}
                    onOk={handelDeleted}
                    onCancel={handleCancel}
                >
                    <Text>{t(translations.companyBankAccount.delete.textInfoConfirm)}</Text>
                </Modal>

                <Modal
                    className='chief-accountant'
                    style={{ top: 200, textAlign: 'center' }}
                    open={confirmSubmit}
                    // open={true}
                    title="Xác nhận"
                    width={450}
                    onCancel={() => {
                        setConfirmSubmit(false)
                    }}
                    footer={() => (
                        <>
                            <Row justify={'space-around'}>
                                <Col span={11}>
                                    <Button style={{ width: '100%', height: 40 }} onClick={() => {
                                        setConfirmSubmit(false)
                                    }}>Quay lại</Button>
                                </Col>
                                <Col span={11}>
                                    <Button style={{ width: '100%', height: 40 }} type='primary' onClick={async () => {
                                        await completeOnboarding(companyEntity);
                                        setConfirmSubmit(false)
                                    }}>Xác nhận</Button>
                                </Col>
                            </Row>
                            {isLoadingCompleted && (
                                <Row justify={'center'} style={{ marginTop: 20 }}>
                                    <Spin>Đang đăng ký</Spin>
                                </Row>
                            )}


                            {/* <CancelBtn />
            <OkBtn /> */}
                        </>
                    )}
                >
                    <p style={{ margin: '24px 0' }}>Quý khách có chắc chắn muốn đăng ký thông tin doanh nghiệp không?</p>

                </Modal>


                <Modal
                    open={openModalEnterCIF}
                    onCancel={() => setOpenModalEnterCIF(false)}
                    onOk={() => {
                        const cif = formCif.getFieldValue("cif")
                        const dataPost = {
                            cif,
                            company,
                            bank
                        }

                        console.log(dataPost)
                        postCompanyBankCif(dataPost)
                        getCompanyBankCif()
                        formCif.resetFields()
                        // setCifNumber(cif)
                        setOpenModalEnterCIF(false)
                    }}
                    title={"Tạo số CIF"}
                >
                    <Form colon={false}
                        form={formCif}
                        labelAlign='left'
                        labelCol={{ lg: { span: 6 } }}
                    >
                        <Form.Item label={
                            <span>
                                {"Ngân hàng"}
                                <span style={{ color: 'red' }}> *</span>
                            </span>
                        }>
                            {bank?.name}
                        </Form.Item>
                        <Form.Item label={
                            <span>
                                {"Số CIF"}
                                <span style={{ color: 'red' }}> *</span>
                            </span>
                        } name={"cif"}>
                            <Input placeholder="Nhập số CIF" maxLength={20} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Spin >
    )
}