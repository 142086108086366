import { Bank } from "./bank.model";
import { Company } from "./company.model";
import { CompanyBankAccountTypeEnum } from "./enums/enums";

export interface CompanyBankAccount  {
    id?: string;
    instanceName?: string | null;
    bank?: Bank | null;
    owner?: Company | null;
    version?: string | null;
    accountNumber?: string | null;
    accountOwner?: string | null;
    bankAccountType?: CompanyBankAccountTypeEnum | null;
    createdBy?: string | null;
    createdDate?: string | null;
    lastModifiedBy?: string | null;
    lastModifiedDate?: string | null;
    deletedBy?: string | null;
    deletedDate?: string | null;
    companyBankCif?: string | null;
  }

  export const defaultValue: Readonly<CompanyBankAccount> = {};