import './index.css';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Form,
  Input,
  Upload,
  Button,
  DatePicker,
  Radio,
  Col,
  Row,
  Typography,
  RadioChangeEvent,
  Space,
  Card,
  Divider,
  Avatar,
  UploadProps,
  Flex,
  message,
  Spin,
  Checkbox,
  notification,
  Modal,
  Result
} from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import {
  deleteLegalRepresentativeBackImage,
  deleteLegalRepresentativeFaceImage,
  deleteLegalRepresentativeFrontImage,
  goNext,
  goPrev,
  goToStep,
  saveDraftCompanyEntity,
  updateLegalRepresentativeBackImage,
  updateLegalRepresentativeFaceImage,
  updateLegalRepresentativeFrontImage

} from '../../onboarding.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { deepMerge } from '@/shared/utils/object-utils';
import { useNavigate } from 'react-router-dom';
import { displayPartsToString } from 'typescript';
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { executeOcrPersonEKycRepresentative, onboardingApi, useExecuteOcrPersonEKycRepresentativeMutation, useGetCompanyQuery, useUpdateCompanyMutation, useUploadRepresentativeBackImageMutation, useUploadRepresentativeFaceImageMutation, useUploadRepresentativeFrontImageMutation, useVertifyIdentifyMutation } from '@/services/api/onboarding.api';
import { CompanyEmployee } from '@/shared/model/company-employee.model';
import { convertDateTimeToServer } from '@/shared/utils/date-utils';
import dayjs from "dayjs";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { fileApi } from '@/services/api/file.api';
import { NEW_CODE } from '@/config/config';
import { CorporatePositionEnum } from '@/shared/model/enums/enums';
const { Title, Text, Link } = Typography;

const getDefaultImage = () => {
  return '/images/default_image.png';
};

function isValidDate(dateString: any): boolean {
  const now = new Date();
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateRegex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  if (date > now) {
    return false;
  }

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};
const reFormatDate = (dateString: string): string => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

export default function OnboardingRepresentative(props: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { onNext, onPrev, onSaveDraft } = props;

  const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const [canNext, setCanNext] = useState(false)
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
  const { legalRepresentative } = companyEntity;
  const [form] = Form.useForm();
  const [downloadFileFrontImage, { data: frontImageFile, isLoading: isLoadingDownload, isError: isErrorDownload, isSuccess: succesDownloadFrontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()

  // const [downloadRepresentativeFrontImage, { data: frontImageFile, isSuccess: succesDownloadFrontImageFile }] = onboardingApi.endpoints.downloadRepresentativeFrontImage.useLazyQuery();
  // const [downloadRepresentativeBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = onboardingApi.endpoints.downloadRepresentativeBackImage.useLazyQuery();
  // const [downloadRepresentativeFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = onboardingApi.endpoints.downloadRepresentativeFaceImage.useLazyQuery();

  const [uploadRepresentativeFrontImage, { reset: resetFrontImage, data: frontImage, isLoading: isLoadingFrontImage, isSuccess: isSuccessFrontImage, isError: isErrorFrontImage }] = useUploadRepresentativeFrontImageMutation()
  const [uploadRepresentativeBackImage, { reset: resetBackImage, data: backImage, isLoading: isLoadingBackImage, isSuccess: isSuccessBackImage, isError: isErrorBackImage }] = useUploadRepresentativeBackImageMutation()
  const [uploadRepresentativeFaceImage, { reset: resetFaceImage, data: faceImage, isLoading: isLoadingFaceImage, isSuccess: isSuccessFaceImage, isError: isErrorFaceImage }] = useUploadRepresentativeFaceImageMutation()
  const [executeOcrPersonEKycRepresentative, { data: dataOCR, isLoading: isLoadingOCR, isSuccess: isSuccessOCR, isError: isErrorOCR }] = useExecuteOcrPersonEKycRepresentativeMutation();
  const [vertifyIdentify, { data: vertifyIdentifyUser, isLoading: isLoadingVertify, isSuccess: isSuccessVertify, isError: isErrorVertify }] = useVertifyIdentifyMutation();
  const [extract, setExtract] = useState(false)
  const [count, setCount] = useState(true);
  const [docType, setDocType] = useState(companyEntity?.legalRepresentative?.docType || "CMND");
  const [changeDocType, setChangeDocType] = useState(false)
  const [localFrontImage, setLocalFrontImage] = useState<any>(null)
  const [localBackImage, setLocalBackImage] = useState<any>(null)
  const [localFaceImage, setLocalFaceImage] = useState<any>(null)
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [typeFileDelete, setTypeFileDelete] = useState('');

  const [entityContinue, setEntityContinue] = useState<CompanyEmployee>()
  const [clickNext, setClickNext] = useState(false)
  const [buttonToTest, setButtonToTest] = useState(true)

  const emailRegex = /^(?=.{6,50}$)(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9._%+\-!#$%^&*()=]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/

  let schema = yup.object().shape({
    legalRepresentative: yup.object().shape({
      name: yup.string().required(t(translations.onboarding.representative.error.name))
        .test('not-only-spaces', 'Họ và tên không được chỉ chứa khoảng trắng.', value => value.trim().length > 0),
      cardIssueDate: yup.string().required('Ngày cấp không được để trống')
        .test('valid-date', 'Ngày cấp không hợp lệ.', value => {
          if (!value) return false;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false
          return true
        }),
      birthday: yup.string().required("Ngày sinh không hợp lệ!")
        .test('valid-date', 'Ngày sinh không hợp lệ.', value => {
          if (!value) return false;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false
          return true
        }),
      phoneNumber: yup.string().required("Số điện thoại không được để trống").test('valid-date', 'Số điện thoại không được chỉ chứa khoảng trắng.', value => {
        if (value.trim().length === 0) return false;
        return true
      }),
      cardID: yup.string().required("Số giấy tờ định danh không được để trống")
        .test('not-only-spaces', 'Số giấy tờ định danh không được chỉ chứa khoảng trắng.', value => value.trim().length > 0),
      cardIssueLocation: yup.string().required("Nơi cấp không được để trống")
        .test('not-only-spaces', 'Nơi cấp không được chỉ chứa khoảng trắng.', value => value.trim().length > 0),
      email: yup.string().email(t(translations.register.errors.emailInvalid)).required("Email không được để trống")
        .test('valid email', t(translations.register.errors.emailInvalid), (value: any) => {
          return emailRegex.test(value)
        }).max(50)
        ,
      cardFrontImage: yup.string().nullable(),
      cardBackImage: yup.string().nullable(),
      faceImage: yup.string().nullable(),
    }),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
  }

  const uploadFrontImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();

      formData.append('file', file);
      // dispatch(uploadRepresentativeFrontImage(formData));
      uploadRepresentativeFrontImage(formData)
      return false;
    },
  };
  const uploadBackImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeBackImage(formData)
      return false;
    },
  };
  const uploadFaceImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeFaceImage(formData)
      return false;
    },
  };

  const onGoNext = async () => {
    form
      .validateFields()
      .then(values => {

        const submit = async () => {
          if (extract === false) {
            notification.info({
              message:
                (
                  <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                    Hãy trích xuất hình ảnh để tiếp tục!
                  </div>
                )
            })
          }
          else {

            let date = form.getFieldValue(['legalRepresentative', 'cardIssueDate'])
            let birthDay = form.getFieldValue(['legalRepresentative', 'birthday'])
            let entityForm = form.getFieldsValue();

            entityForm = {
              ...entityForm,
              legalRepresentative: {
                ...entityForm.legalRepresentative,
                position: CorporatePositionEnum.REPRESENTATIVE,
                cardIssueDate: date ? date.format("YYYY-MM-DD") : null,
                birthday: birthDay ? birthDay.format("YYYY-MM-DD") : null
              }
            }

            let legalRepresentativeOriginal = deepMerge(companyEntity.legalRepresentative, entityForm.legalRepresentative)

            setEntityContinue(legalRepresentativeOriginal)

            setClickNext(true)

            // if((checkRespre?.name || checkRespre?.cardIssueDate) && (checkRespre.name === entityForm.legalRepresentative.name && checkRespre.cardIssueDate === entityForm.legalRepresentative.cardIssueDate)){

            // } 

            // else{
            //   notification.error({message:"Xác thực thông tin người đại diện không thành công!", description:'Thông tin người đại diện không trùng khớp với thông tin người đại diện trong giấy đăng ký kinh doanh.'})
            // }
          }
        }
        // const entity = deepMerge(companyEntity, { legalRepresentative: values });
        submit()
      })
      .catch(errorInfo => {console.log(errorInfo)});
  };
  const onGoPrev = async () => {
    let date = form.getFieldValue(['legalRepresentative', 'cardIssueDate'])
    let birthDay = form.getFieldValue(['legalRepresentative', 'birthday'])
    let entityForm = form.getFieldsValue();

    entityForm = {
      ...entityForm,
      legalRepresentative: {
        ...entityForm.legalRepresentative,
        cardIssueDate: date ? date.format("YYYY-MM-DD") : null,
        birthday: birthDay ? birthDay.format("YYYY-MM-DD") : null
      }
    }

    let legalRepresentativeOriginal = deepMerge(companyEntity.legalRepresentative, entityForm.legalRepresentative)
    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      legalRepresentative: {
        ...companyEntity.legalRepresentative,
        ...legalRepresentativeOriginal
      }
    }));
    dispatch(goPrev({
      ...companyEntity,
      legalRepresentative: {
        ...companyEntity.legalRepresentative,
        ...legalRepresentativeOriginal
      }
    }));
  };

  const onClose = () => {
    //Check if editingMode = true
    dispatch(goToStep(1));

  };
  const onChangeDocType = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setDocType(newValue);
    form.setFieldsValue({
      legalRepresentative: {
        ...form.getFieldValue('legalRepresentative'),
        docType: newValue,
      },
    });
    setChangeDocType(true)
  };

  const onFormFieldsChange = (allFields: FieldData[]) => {
    // let entityForm = form.getFieldsValue();
    // let legalRepresentativeOriginal = deepMerge(companyEntity.legalRepresentative, entityForm.legalRepresentative)
    // dispatch(saveDraftCompanyEntity({ ...companyEntity, ...{ legalRepresentative: legalRepresentativeOriginal } }));
    // setExtract(false)
  }
  const onGetOcrPersonEKyc = async () => {
    await vertifyIdentify({
      frontImage: companyEntity?.legalRepresentative?.cardFrontImage,
      faceImage: companyEntity?.legalRepresentative?.faceImage,
    });
  };

  const deleteFile = (values: any) => {
    let dataDeleteFile
    if (values === "front") {
      setLocalFrontImage(null)
      resetFrontImage()

      let deleteFile = {
        ...companyEntity.legalRepresentative,
        cardFrontImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.legalRepresentative, deleteFile)
    }

    else if (values === "back") {
      setLocalBackImage(null)
      resetBackImage()

      let deleteFile = {
        ...companyEntity.legalRepresentative,
        cardBackImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.legalRepresentative, deleteFile)
    }

    else if (values === "face") {
      setLocalFaceImage(null)
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.legalRepresentative,
        faceImage: null
      }
      dataDeleteFile = deepMerge(companyEntity?.legalRepresentative, deleteFile)
    }

    setExtract(false)

    const fieldsToReset = [
      ['legalRepresentative', 'email'], ['legalRepresentative', 'phoneNumber'], ['legalRepresentative', 'birthday'], ['legalRepresentative', 'cardIssueLocation'], ['legalRepresentative', 'cardIssueDate'], ['legalRepresentative', 'cardID'], ['legalRepresentative', 'name']
    ];

    fieldsToReset.forEach(field => form.setFieldValue(field, null));

    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      legalRepresentative: {
        ...legalRepresentative,
        ...dataDeleteFile,
      }
    }));
  }

  useEffect(() => {
    form.setFieldValue(['legalRepresentative', 'cardFrontImage'], legalRepresentative?.cardFrontImage)
    form.setFieldValue(['legalRepresentative', 'cardBackImage'], legalRepresentative?.cardBackImage)
    form.setFieldValue(['legalRepresentative', 'faceImage'], legalRepresentative?.faceImage)
  }, [legalRepresentative])


  useEffect(() => {
    const submit = async () => {
      if (clickNext) {
        let legalCheck = JSON.parse(companyEntity.businessRegistration?.jsonData || "")
        if (buttonToTest) {
          if (entityContinue?.name?.toLowerCase() === legalCheck.nguoiDaiDienPhapLuat[0].hoVaTen?.toLowerCase()) {
            await dispatch(saveDraftCompanyEntity({ ...companyEntity, legalRepresentative: entityContinue }));
            await dispatch(goNext({ ...companyEntity, legalRepresentative: entityContinue }));
          }
          else {
            form.setFields([{
              name: ['legalRepresentative', 'name'],
              errors: ["Không khớp với thông tin giấy chứng nhận doanh nghiệp!"]
            }])
            // notification.error({ message: "Không khớp với thông tin giấy chứng nhận doanh nghiệp" })
          }
        }
        else {
          await dispatch(saveDraftCompanyEntity({ ...companyEntity, legalRepresentative: entityContinue }));
          await dispatch(goNext({ ...companyEntity, legalRepresentative: entityContinue }));
        }
        setClickNext(false)
      }

    }

    submit()
  }, [clickNext])

  useEffect(() => {
    if (changeDocType) {
      setLocalFrontImage(null)
      setLocalBackImage(null)
      setLocalFaceImage(null)
      resetFrontImage()
      resetBackImage()
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.legalRepresentative,
        cardFrontImage: null,
        cardBackImage: null,
        faceImage: null
      }
      let dataDeleteFile = deepMerge(companyEntity?.legalRepresentative, deleteFile)

      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        legalRepresentative: {
          ...legalRepresentative,
          ...dataDeleteFile,
          docType: docType
        }
      }));

      const fieldsToReset = [
        ['legalRepresentative', 'email'], ['legalRepresentative', 'phoneNumber'], ['legalRepresentative', 'birthday'], ['legalRepresentative', 'cardIssueLocation'], ['legalRepresentative', 'cardIssueDate'], ['legalRepresentative', 'cardID'], ['legalRepresentative', 'name']
      ];

      setExtract(false)

      fieldsToReset.forEach(field => form.setFieldValue(field, null));

      form.setFieldsValue({
        legalRepresentative: {
          docType: docType,
          position: "Người đại diện pháp luật"
        },
      });

      // dispatch(saveDraftCompanyEntity({
      //   ...companyEntity,
      //   legalRepresentative: {
      //     ...companyEntity.legalRepresentative,
      //     docType: docType
      //   }
      // }));

      setChangeDocType(false)
    }
  }, [docType])

  useEffect(() => {
    if (frontImageFile && succesDownloadFrontImageFile && localFrontImage !== frontImageFile) {
      setLocalFrontImage(frontImageFile);
    }
  }, [frontImageFile, succesDownloadFrontImageFile])

  useEffect(() => {
    if (backImageFile && succesDownloadBackImageFile && localBackImage !== backImageFile) setLocalBackImage(backImageFile);

  }, [backImageFile, succesDownloadBackImageFile])

  useEffect(() => {
    if (faceImageFile && succesDownloadFaceImageFile && localFaceImage !== frontImageFile) setLocalFaceImage(faceImageFile);

  }, [faceImageFile, succesDownloadFaceImageFile]);

  useEffect(() => {
    setCount(false)


    if (isSuccessVertify && vertifyIdentifyUser && !count && companyEntity.legalRepresentative) {
      executeOcrPersonEKycRepresentative(companyEntity.legalRepresentative);
    }

    if (isSuccessVertify && !vertifyIdentifyUser && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              eKYC cá nhân không khớp với ảnh trên giấy tờ
            </div>
          )
      })
      setExtract(false)
    }

    if (isErrorVertify && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Không thể thực hiện nhận dạng!
            </div>
          )
      })
      setExtract(false)
    }

  }, [vertifyIdentifyUser, isLoadingVertify, isSuccessVertify, isErrorVertify])

  useEffect(() => {
    if (legalRepresentative?.cardFrontImage) {
      downloadFileFrontImage({ fileId: legalRepresentative.cardFrontImage, objectType: null })
      // downloadRepresentativeFrontImage(legalRepresentative.cardFrontImage)
    }
  }, [legalRepresentative?.cardFrontImage]);
  useEffect(() => {
    if (legalRepresentative?.cardBackImage)
      downloadFileBackImage({ fileId: legalRepresentative.cardBackImage, objectType: null })
  }, [legalRepresentative?.cardBackImage]);
  useEffect(() => {
    if (legalRepresentative?.faceImage)
      downloadFileFaceImage({ fileId: legalRepresentative.faceImage, objectType: null })
  }, [legalRepresentative?.faceImage]);

  useEffect(() => {
    if (isSuccessFrontImage && frontImage?.fileRef) {
      const fieldsToReset = [
        ['legalRepresentative', 'email'], ['legalRepresentative', 'phoneNumber'], ['legalRepresentative', 'birthday'], ['legalRepresentative', 'cardIssueLocation'], ['legalRepresentative', 'cardIssueDate'], ['legalRepresentative', 'cardID'], ['legalRepresentative', 'name']
      ];

      setExtract(false)

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FrontImage
      dispatch(updateLegalRepresentativeFrontImage(frontImage))
    }
  }, [isSuccessFrontImage])
  useEffect(() => {
    if (isSuccessBackImage && backImage?.fileRef) {
      const fieldsToReset = [
        ['legalRepresentative', 'email'], ['legalRepresentative', 'phoneNumber'], ['legalRepresentative', 'birthday'], ['legalRepresentative', 'cardIssueLocation'], ['legalRepresentative', 'cardIssueDate'], ['legalRepresentative', 'cardID'], ['legalRepresentative', 'name']
      ];

      setExtract(false)

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update BackImage
      dispatch(updateLegalRepresentativeBackImage(backImage))
    }
  }, [isSuccessBackImage])
  useEffect(() => {
    if (isSuccessFaceImage && faceImage?.fileRef) {
      const fieldsToReset = [
        ['legalRepresentative', 'email'], ['legalRepresentative', 'phoneNumber'], ['legalRepresentative', 'birthday'], ['legalRepresentative', 'cardIssueLocation'], ['legalRepresentative', 'cardIssueDate'], ['legalRepresentative', 'cardID'], ['legalRepresentative', 'name']
      ];

      setExtract(false)

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FaceImage
      dispatch(updateLegalRepresentativeFaceImage(faceImage))
    }
  }, [isSuccessFaceImage])

  // useEffect(() => {

  // }, [companyEntity])

  useEffect(() => {
    if (dataOCR && dataOCR.cardIssueDate) {
      let dataOcrNew

      if (dataOCR.docType === null) {
        let dataOcrNew = {
          ...dataOCR,
          docType: docType
        }
      }
      else {
        dataOcrNew = dataOCR
      }
      dispatch(saveDraftCompanyEntity({ ...companyEntity, legalRepresentative: dataOcrNew }))
      form.setFieldsValue({
        legalRepresentative: {
          ...dataOcrNew,
          position: "Người đại diện pháp luật",
          birthday: dataOcrNew?.birthday ? dayjs(dataOcrNew?.birthday, "YYYY-MM-DD") : "",
          cardIssueDate: dataOcrNew?.cardIssueDate ? dayjs(dataOcrNew?.cardIssueDate, "YYYY-MM-DD") : ""
        }
      });

      notification.success({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin thành công!
            </div>
          )
      });
      setExtract(true);
    }

    else if (dataOCR === null || (dataOCR?.name === null && dataOCR?.cardIssueDate === null)) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin không thành công!
            </div>
          )
      });
      setExtract(false)
    }
  }, [dataOCR])


  //cập nhật form lần đầu
  useEffect(() => {
    if (!companyEntity.legalRepresentative?.docType) {
      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        legalRepresentative: {
          ...legalRepresentative,
          docType: "CMND"
        }
      }));
      form.setFieldsValue({
        legalRepresentative: {
          docType: "CMND",
        },
      });
    }
    form.setFieldsValue({
      legalRepresentative: {
        position: "Người đại diện pháp luật",
      },
    });

    if (companyEntity.legalRepresentative?.jsonData) {
      let entity = deepMerge(companyEntity)
      entity = {
        ...entity,
        legalRepresentative: {
          ...entity.legalRepresentative,
          birthday: entity.legalRepresentative.birthday ? dayjs(entity.legalRepresentative.birthday, "YYYY-MM-DD") : "",
          cardIssueDate: entity.legalRepresentative.cardIssueDate ? dayjs(entity.legalRepresentative.cardIssueDate) : ""
        }
      }
      form.setFieldsValue(entity)

      form.setFieldsValue({
        legalRepresentative: {
          position: "Người đại diện pháp luật",
        },
      });


      if (legalRepresentative?.cardFrontImage) downloadFileFrontImage({ fileId: legalRepresentative.cardFrontImage, objectType: null })
      if (legalRepresentative?.cardBackImage) downloadFileBackImage({ fileId: legalRepresentative.cardBackImage, objectType: null });
      if (legalRepresentative?.faceImage) downloadFileFaceImage({ fileId: legalRepresentative.faceImage, objectType: null });

      if (
        (legalRepresentative?.docType?.toString() === "CMND" && legalRepresentative?.cardBackImage !== null && legalRepresentative?.cardFrontImage != null && legalRepresentative?.faceImage !== null && legalRepresentative?.name !== null && legalRepresentative?.cardIssueDate !== null) ||
        (legalRepresentative?.docType?.toString() === "HC" && legalRepresentative?.cardFrontImage != null && legalRepresentative?.faceImage !== null && legalRepresentative?.name !== null && legalRepresentative?.cardIssueDate !== null)
      ) {
        setExtract(true)
      }
    }
  }, [])

  return (
    <Spin spinning={isLoadingOCR || isLoadingVertify} >
      <Content className='representative'>
        {/* {NEW_CODE && <Button onClick={() => { setButtonToTest(!buttonToTest) }}>{buttonToTest.toString()}</Button>} */}
        <Card type='inner' title='Thông tin người đại diện' style={{ margin: 20 }} >
          <Row>
            <Form form={form} initialValues={{ legalRepresentative: { docType } }}>
              <Form.Item label={"Loại giấy tờ"} name={['legalRepresentative', 'docType']}>
                <Radio.Group onChange={onChangeDocType} value={companyEntity?.legalRepresentative?.docType?.toString()} defaultValue={'CMND'}>
                  <Radio value={"CMND"} >CMND/CCCD/CCCD gắn chíp</Radio>
                  <Radio value={"HC"}>Hộ chiếu</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider orientation="right" />
            </Form>
          </Row>
          <Form id='orcForm'
            form={form}
          >
            {/* <Form.Item name={['legalRepresentative', 'hasEKYC']} >

            <Input />
          </Form.Item> */}

          </Form>
          <Form id='imagesForm'
            form={form}
          >
            <Row style={{ margin: '24px 0px' }}>
              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200,
                    marginRight: 10
                  }}
                >
                  <Form.Item
                    name={['legalRepresentative', 'cardFrontImage']}
                    rules={[yupSync]}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          localFrontImage
                            ? localFrontImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>

                  <p style={{ fontWeight: '700' }}>Ảnh mặt trước giấy tờ</p>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFrontImageProps} maxCount={1} showUploadList={false} style={{ width: '100%' }}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!legalRepresentative?.cardFrontImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("front")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>

              {!(docType === "HC") && (
                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                  <div
                    style={{
                      padding: '12px',
                      border: '1px solid #f0f0f0',
                      borderRadius: '8px 8px 8px 8px',
                      minHeight: 200,
                      marginRight: 10
                    }}
                  >
                    <Form.Item
                      name={['legalRepresentative', 'cardBackImage']}
                      rules={[yupSync]}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}

                    >
                      <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                        <Image
                          style={{ width: '100%', maxHeight: '18vw' }}
                          preview={false}
                          src={
                            localBackImage
                              ? localBackImage
                              : getDefaultImage()
                          }
                          fallback={getDefaultImage()}
                        />
                      </div>
                    </Form.Item>

                    <p style={{ fontWeight: '700' }}>Ảnh mặt sau giấy tờ</p>
                    <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                    <Row justify={'space-between'}>
                      <Col lg={{ span: 11 }}>
                        <Upload {...uploadBackImageProps} maxCount={1} showUploadList={false}>
                          <Button disabled={isLoadingOCR || isLoadingVertify}
                            style={{
                              width: '100%',
                              border: 'none',
                              backgroundColor: 'rgba(232, 241, 254, 1)',
                              color: 'rgba(24, 119, 242, 1)',
                              height: 40
                            }}
                          >
                            Tải ảnh lên
                          </Button>
                        </Upload>
                      </Col>
                      <Col lg={{ span: 11 }}>
                        <Button disabled={!legalRepresentative?.cardBackImage} onClick={() => {
                          setConfirmDeleteFile(true)
                          setTypeFileDelete("back")
                        }} style={{ width: '100%', height: 40 }}>
                          Xóa
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200
                  }}
                >
                  <Form.Item
                    name={['legalRepresentative', 'faceImage']}
                    rules={[yupSync]}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}

                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          localFaceImage
                            ? localFaceImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>
                  <p style={{ fontWeight: '700' }}>eKYC cá nhân</p>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFaceImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!legalRepresentative?.faceImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("face")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>

          {((docType === "CMND" && companyEntity.legalRepresentative?.cardFrontImage &&
            companyEntity.legalRepresentative?.cardBackImage &&
            companyEntity.legalRepresentative?.faceImage) ||
            (companyEntity.legalRepresentative?.docType === "HC" && companyEntity.legalRepresentative?.cardFrontImage &&
              companyEntity.legalRepresentative?.faceImage)) && (
              <div>
                <Row justify={'center'}>
                  <Col lg={{ span: 12 }}>
                    {/* <Row justify={'end'}>
                      {(isLoadingOCR || isLoadingVertify) && <Spin>Đang tải thông tin</Spin>}
                    </Row> */}
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Row style={{ width: '100%' }} justify={'end'}>
                      <Button disabled={isLoadingOCR || isLoadingVertify} onClick={onGetOcrPersonEKyc} style={{ backgroundColor: '#1c00c2', color: 'white', height: 40, border: "none", float: 'right' }}><CloudDownloadOutlined /> Trích xuất</Button>
                    </Row>
                  </Col>
                </Row>
              </div>

            )}
        </Card >


        {/* <Divider /> */}
        < Card type='inner' title='Xác thực thông tin người đại diện' style={{ margin: 20 }
        } >
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                colon={false}
              >
                <Form.Item
                  name={['legalRepresentative', 'name']}
                  // name={'name'}
                  label={
                    <span>
                      Họ và tên
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync]}
                >
                  <Input placeholder="Nhập họ và tên" style={{ height: 40 }} maxLength={50} />
                </Form.Item>
                <Form.Item
                  name={['legalRepresentative', 'cardID']}
                  // name={'name'}
                  label={
                    <span>
                      Số giấy tờ định danh
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync]}
                >
                  <Input placeholder="Nhập số giấy tờ kinh doanh" style={{ height: 40 }} maxLength={12} />
                </Form.Item>
                <Form.Item
                  name={['legalRepresentative', 'cardIssueDate']}
                  label={
                    <span>
                      Ngày cấp
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync]}
                  style={{ width: '100%' }}
                >
                  {/* <Input placeholder="Nhập ngày cấp" style={{ height: 40 }} /> */}
                  <DatePicker placeholder='Nhập ngày cấp' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                  name={['legalRepresentative', 'cardIssueLocation']}
                  // name={'name'}
                  label={
                    <span>
                      Nơi cấp
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync]}
                >
                  <Input placeholder="Nhập nơi cấp" style={{ height: 40 }} maxLength={300} />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form colon={false}
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}

                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 }, xs: { span: 24 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
              // disabled={true}
              >
                <Form.Item
                  name={['legalRepresentative', 'birthday']}
                  label={
                    <span>
                      Ngày sinh
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync]}
                  style={{ width: '100%' }}
                >
                  {/* <Input placeholder='Nhập ngày sinh' style={{ height: 40 }} /> */}
                  <DatePicker placeholder='Nhập ngày sinh' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
                </Form.Item>

                <Form.Item
                  label="Chức vụ"
                  labelAlign="left"
                  style={{ width: '100%' }}
                  name={['legalRepresentative', 'position']}
                >
                  <Input disabled style={{ height: 40 }} />
                </Form.Item>

                <Form.Item
                  name={['legalRepresentative', 'phoneNumber']}
                  label={
                    <span>
                      Số điện thoại
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập số điện thoại' style={{ height: 40 }} maxLength={10} />
                </Form.Item>

                <Form.Item
                  name={['legalRepresentative', 'email']}
                  label={
                    <span>
                      Email
                      <span style={{ color: 'red' }}> *</span>
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập email' style={{ height: 40 }} maxLength={50} />
                </Form.Item>
              </Form>
            </Col>
          </Row>

        </ Card>

      </Content >
      <Footer
        style={{
          background: '#fff',
          // position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'inline-block' }}>
          {/* <Button disabled={isLoadingOCR} style={{ margin: '0 8px', height: 40 }} onClick={onSaveEntity}>
            {t(translations.action.save_draft)}
          </Button> */}
          <Button disabled={isLoadingOCR || isLoadingVertify} style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
            {t(translations.action.previous)}
          </Button>
        </div>
        <div style={{ display: 'inline-block' }}>

          <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext} disabled={isLoadingOCR || isLoadingVertify}>
            {t(translations.action.next)}
          </Button>
        </div>
      </Footer>

      <Modal
        open={confirmDeleteFile}
        onCancel={() => setConfirmDeleteFile(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40 }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40, backgroundColor: '#ED5E68' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xóa
                </Button>
              </Col>

            </Row>
          </>
        )}
      >
        {/* <Result
          title="Xác nhận xóa file"
          extra={
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xác nhận
                </Button>
              </Col>

            </Row>
          }
        /> */}
        <p style={{ margin: '24px 0', textAlign: 'center' }}>Bạn có chắc chắn muốn xóa ảnh</p>
      </Modal>
    </Spin>
  );
}
