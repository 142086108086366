import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '@/shared/error/error-boundary-routes';

import UserInfoPage from '.'; 
import ChangePassword from './changePassword';
import ChangeInfo from './changeInfo';
export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="/" element={<UserInfoPage />} />
        <Route path="/test" element={<ChangeInfo />} />
        <Route path="/changeInfo/:idUser" element={<ChangeInfo />} />
        <Route path="/changePassword/:idUser" element={<ChangePassword />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};
