import type { ThemeConfig } from 'antd';

const themeApp: ThemeConfig = {
  token: {
    fontFamily: "Lexend",
    fontSize: 16, //1em
    colorPrimary: 'rgb(28, 0, 194)',
    colorPrimaryHover: 'rgb(253, 199, 87)',
  },
};

export default themeApp;