import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { EntitiesLoadOptions, createEntitySlice, serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { axiosSecurity } from '@/config/axios-interceptor';
import { encodeGetParams } from '@/shared/utils/jmix-rest/util';
import { UserCompany, defaultValue as defaultUserCompany } from '@/shared/model/user-company.model';
import { SearchEntitiesRequestModel, defaultValue as defaultSearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';

const initialState: any = {
  loading: false,
  errorMessage: null,
  entities: [] as UserCompany[],
  entity: defaultUserCompany,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = '/rest/portal/company-user';

// Actions

export const getEntities = createAsyncThunk('company_bank_account/fetch_entity_list', async (params: SearchEntitiesRequestModel) => {
  const requestUrl = apiUrl + "/search?" ;
  return axiosSecurity.post<UserCompany[]>(requestUrl, params);
});
export const getEntity = createAsyncThunk(
  'company_bank_account/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axiosSecurity.get<UserCompany>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'company_bank_account/create_entity',
  async (entity: UserCompany, thunkAPI) => {
    const result = await axiosSecurity.post<UserCompany>(apiUrl,entity);
    thunkAPI.dispatch(getEntities(defaultSearchEntitiesRequestModel));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'company_bank_account/update_entity',
  async (entity: UserCompany, thunkAPI) => {
    const result = await axiosSecurity.put<UserCompany>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(defaultSearchEntitiesRequestModel));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'company_bank_account/partial_update_entity',
  async (entity: UserCompany, thunkAPI) => {
    const result = await axiosSecurity.patch<UserCompany>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(defaultSearchEntitiesRequestModel));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'company_bank_account/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axiosSecurity.delete<UserCompany>(requestUrl);
    thunkAPI.dispatch(getEntities(defaultSearchEntitiesRequestModel));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice
export const UserCompanySlice = createSlice({
  name: 'companyBankAccount',
  initialState,
  reducers: {
    /**
     * Reset the registration state to initial state
     */
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        const totalItems = parseInt(headers['X-Total-Count'], 10);
        console.log("HANT parseInt(headers['X-Total-Count'] : ",parseInt(headers['X-Total-Count']));
        console.log("HANT action.payload: ",action.payload);
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: totalItems,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = UserCompanySlice.actions;

// Reducer
export default UserCompanySlice.reducer;  