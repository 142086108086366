// 'use client';
import { TranslatorContext } from '@/shared/utils/translate/index';
import { Storage } from '@/shared/utils/storage-util';
import { setLocale } from '@/shared/reducers/locale';


TranslatorContext.setDefaultLocale('en');
TranslatorContext.setRenderInnerTextForMissingKeys(false);


export const languages: any = {
  vi: { name: 'VI', icon: '/images/vi_flag.png' },
  en: { name: 'EN', icon: '/images/en_flag.png' },
};
// images/en_flag.png
export const locales = Object.keys(languages);

export const registerLocale = (store:any) => {
  store.dispatch(setLocale(Storage.session.get('locale', 'vi')));
};
