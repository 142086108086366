import { UserCompany } from '@/shared/model/user-company.model';
import { baseAuthAPI } from './base-auth.api'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';

export const userCompanyApi = baseAuthAPI.injectEndpoints({
  endpoints: (build) => ({
    // <{ apiResponse: UserCompany[], totalCount: number}, Object>
    getUserCompanies: build.query<{ users: UserCompany[], totalCount: number }, Object>({
      query: (params: any) => {
        //Convert Ant Table Params to Jmix Params
        const loadOptions: SearchEntitiesRequestModel = {
          filter: {
            conditions: []
          },
          offset: params?.pagination?.current > 0 ? (params?.pagination?.current - 1) * params?.pagination?.pageSize : 0,
          limit: params?.pagination?.pageSize,
          returnCount: true,
          sort: 'username'
        }
        return ({
          url: '/rest/portal/company-user/search',
          method: 'POST',
          body: loadOptions,
        })
      },
      transformResponse: async (response: any, meta: any) => {
        const totalCount = Number(meta?.response?.headers.get('X-Total-Count'));
        const users = await response;
        return { users: users, totalCount: totalCount }
      }
    }),
    addUserCompany: build.mutation<UserCompany, Partial<UserCompany>>({
      query: (body) => ({
        url: `/rest/portal/company-user`,
        method: 'POST',
        body,
      }),
    }),
    getCurrentUserCompany: build.query<UserCompany, undefined>({
      query() {
        return {
          url: `/rest/portal/company-user/get-current-user?fetchPlan=userCompany-fetch-plan`,
          method: 'GET',
        }
      },
    }),
    getUserCompany: build.query<UserCompany, string>({
      query(id) {
        return {
          url: `/rest/portal/company-user/${id}?fetchPlan=userCompany-fetch-plan`,
          method: 'GET',
        }
      },
    }),
    updateUserCompany: build.mutation<UserCompany, Partial<UserCompany>>({
      query(data) {
        const { id, ...body } = data
        return {
          url: `/rest/portal/company-user/${id}`,
          method: 'PUT',
          body,
        }
      },
    }),
    deleteUserCompany: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/rest/portal/company-user/${id}`,
          method: 'DELETE',
        }
      },
    }),
  }),
})

export const {
  useGetUserCompaniesQuery,
  useGetUserCompanyQuery,
  useAddUserCompanyMutation,
  useUpdateUserCompanyMutation,
  useDeleteUserCompanyMutation,
} = userCompanyApi

export const {
  endpoints: { getUserCompanies, getUserCompany, addUserCompany, updateUserCompany, deleteUserCompany },
} = userCompanyApi