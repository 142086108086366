import React, { useState } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import { useTransition, animated, config } from '@react-spring/web';
import { useAppDispatch, useAppSelector } from '@/config/store';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { Link } from 'react-router-dom';

// const { Text, Title } = Typography;

// const Introduction = ({ display }: any) => {
//   const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
//   const isMobile = useIsMobile();

//   const imageTransition = useTransition(display, {
//     from: { x: -50, opacity: 0 },
//     enter: { x: 0, opacity: 1 },
//     config: config.slow,
//     delay: 500,
//   });

//   const textTransition = useTransition(display, {
//     from: { x: 50, opacity: 0 },
//     enter: { x: 0, opacity: 1 },
//     config: config.slow,
//     delay: 500,
//   });

//   return (
//     <div
//       className="SECTION-IMG"
//       style={{
//         color: 'white',
//         backgroundImage:
//           'url(' +
//           '/images/home/header_background.png' +
//           ')',
//         backgroundSize: 'cover',
//         backgroundOrigin: 'content-box',
//         backgroundPosition: '50% 50%',
//         backgroundRepeat: 'repeat',
//         backgroundAttachment: 'scroll',
//         paddingBottom: '0%',
//         paddingTop: '3%',
//       }}
//     >
//       <Row justify={'space-between'} align={'middle'}>
//         <Col lg={{ span: 12, order:1}} md={{ span: 24, order:2}} xs={{ span: 24, order:2}} style={{display:'flex', justifyContent:'end'}}>
//           {imageTransition((style, item) => (
//             <animated.div style={style}>
//               <Image
//                 preview={false}
//                 style={{
//                   marginTop: '10vh',
//                   marginBottom: '13vh',
//                   // padding:"10vh",
//                   width: '70%',
//                   borderRadius: '0 70px 0 0',
//                 }}
//                 src={require('./image/eg-20230924101824-juytd.png')}
//                 alt="image"
//               />
//             </animated.div>
//           ))}
//         </Col>

//         <Col lg={{ span: 10, order:2}} md={{ span: 24, order:1}} xs={{ span: 24, order:1}}>
//           <Row justify={!isMobile ? 'start' : 'center'} >
//             <Col lg={{ span: 7 }} md={{ span: 20}} xs={{ span: 24}}>
//               {textTransition((style, item) => (
//                 <animated.div style={style}>
//                   <Title
//                     level={3}
//                     style={{
//                       // marginTop: '20vh',
//                       color: 'white',
//                       textAlign: isMobile ? 'center' : 'start',
//                     }}
//                   >
//                     Bảo lãnh điện tử (e-Guarantee)
//                   </Title>
//                 </animated.div>
//               ))}
//             </Col>
//           </Row>
//           <Row justify={!isMobile ? 'start' : 'center'}>
//             <Col
//               lg={{ span: 10, order: 2 }}
//               md={{ span: 20, order: 1 }}
//               xs={{ span: 24, order: 1 }}
//             >
//               {textTransition((style, item) => (
//                 <animated.div style={style}>
//                   <Text
//                     style={{
//                       margin: '2vh 0 3vh 0',
//                       display: 'block',
//                       color: 'rgb(255, 255, 255)',
//                       fontWeight: 400,
//                       textAlign: isMobile ? 'center' : 'start',
//                     }}
//                   >
//                     Chuyển đổi số ưu việt hỗ trợ bảo lãnh điện tử, thúc đẩy tài chính số
//                   </Text>
//                 </animated.div>
//               ))}
//             </Col>
//           </Row>
//           <Row justify={!isMobile ? 'start' : 'center'}>
//             <Col
//               lg={{ span: 17, order: 2 }}
//               md={{ span: 24, order: 1 }}
//               xs={{ span: 24, order: 1 }}
//             >
//               {textTransition((style, item) => (
//                 <animated.div style={style}>
//                   {!isAuthenticated && (
//                     <Button
//                       style={{
//                         backgroundColor: 'rgb(253, 199, 87)',
//                         width: '25%',
//                         height: 'auto',
//                         color: 'black',
//                         padding: '10px 15px 10px 15px',
//                         fontWeight: 'bold',
//                         whiteSpace: 'normal',
//                         minWidth: '200px',
//                         display: 'block',
//                         margin: isMobile ? 'auto' : '0',
//                       }}
//                       type="primary"
//                     >
//                       {' '}
//                       <Link to={'/registration'}>Đăng ký tham gia</Link>
//                     </Button>
//                   )}
//                 </animated.div>
//               ))}
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Introduction;


// import React, { useState } from 'react';
// import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
// import { useTransition, animated, config } from '@react-spring/web';
// import { useAppDispatch, useAppSelector } from '@/config/store';
// import useIsMobile from '@/shared/utils/use-is-mobile.hook';
// import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

const Introduction = ({ display }: any) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isMobile = useIsMobile();

  const imageTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  const textTransition = useTransition(display, {
    from: { x: 50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  return (
    <div
      className="SECTION-IMG"
      style={{
        color: 'white',
        backgroundImage:
          'url(' +
          '/images/home/header_background.png' +
          ')',
        backgroundSize: 'cover',
        backgroundOrigin: 'content-box',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'repeat',
        backgroundAttachment: 'scroll',
        paddingTop: '3%',
      }}
    >
      <Row justify={isMobile ? 'center' : 'end'}>
        <Col
          lg={{ span: 8, order: 1 }}
          md={{ span: 16, order: 2 }}
          xs={{ span: 24, order: 2 }}
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 32px 0 32px' }}
        >
          {imageTransition((style, item) => (
            <animated.div style={style}>
              <Image
                preview={false}
                style={{
                  margin: '13vh 0 12vh 0',
                  width: '100%',
                  height: 'auto',
                  borderRadius: '0 70px 0 0',
                }}
                src={require('./image/eg-20230924101824-juytd.png')}
                alt="image"
              />
            </animated.div>
          ))}
        </Col>

        <Col lg={{ span: 11, order: 2 }} md={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }} style={{ padding: '0 32px 0 32px' }}>
          <Row justify={!isMobile ? 'start' : 'center'}>
            <Col lg={{ span: 12, order: 2 }} md={{ span: 20, order: 1 }} xs={{ span: 20, order: 1 }}>
              {textTransition((style, item) => (
                <animated.div style={style}>
                  <Title
                    level={3}
                    style={{
                      marginTop: '17vh',
                      color: 'white',
                      textAlign: isMobile ? 'center' : 'start',
                    }}
                  >
                    E-Guarantee
                  </Title>
                </animated.div>
              ))}
            </Col>
          </Row>
          <Row justify={!isMobile ? 'start' : 'center'}>
            <Col
              lg={{ span: 11, order: 2 }}
              md={{ span: 20, order: 1 }}
              xs={{ span: 20, order: 1 }}
            >
              {textTransition((style, item) => (
                <animated.div style={style}>
                  <Text
                    style={{
                      margin: '2vh 0 3vh 0',
                      display: 'block',
                      color: 'rgb(255, 255, 255)',
                      fontWeight: 400,
                      textAlign: isMobile ? 'center' : 'start',
                    }}
                  >
                    Superior digital transformation supports
                    E-Guarantees and promotes digital finance
                  </Text>
                </animated.div>
              ))}
            </Col>
          </Row>
          <Row justify={!isMobile ? 'start' : 'center'}>
            <Col
              lg={{ span: 17, order: 2 }}
              md={{ span: 24, order: 1 }}
              xs={{ span: 24, order: 1 }}
            >
              {textTransition((style, item) => (
                <animated.div style={style}>
                  {!isAuthenticated && (
                    <Button
                      style={{
                        backgroundColor: 'rgb(253, 199, 87)',
                        width: '25%',
                        height: 'auto',
                        color: 'black',
                        padding: '10px 15px 10px 15px',
                        fontWeight: 'bold',
                        whiteSpace: 'normal',
                        minWidth: '270px',
                        display: 'block',
                        margin: isMobile ? 'auto' : '0',
                      }}
                      type="primary"
                    >
                      {' '}
                      <Link to={'/registration'}>Contact us</Link>
                    </Button>
                  )}
                </animated.div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Introduction;
