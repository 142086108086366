import ErrorBoundaryRoutes from "@/shared/error/error-boundary-routes";
import React from "react";
import { Route } from "react-router-dom";
import BankAccountEdit from "./bank-account-edit";
import BankAccountDetail from "./bank-account-detail";
import BankAccountDeleteDialog from "./bank-account-delete-dialog";
import BankAccountList from "./bank-account";

export default function BankAccountRouter(){
    return(
        <ErrorBoundaryRoutes>
        <Route index element={<BankAccountList />} />
        <Route path="new" element={<BankAccountEdit />} />
        <Route path=":id">
          <Route index element={<BankAccountDetail />} />
          <Route path="edit" element={<BankAccountEdit />} />
          <Route path="delete" element={<BankAccountDeleteDialog />} />
        </Route>
      </ErrorBoundaryRoutes>
    )
}