import React, { useState, ChangeEvent, useEffect } from "react";
import { Col, Row, Typography, Input, Button, Image, Form } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Link, useParams } from "react-router-dom";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { UserInfo, defaultValue } from "@/shared/model/user-info.model";
import { UpdateUserInfo } from "../user-info.reducer";
import { useAppDispatch, useAppSelector } from "@/config/store";

const { Title, Text } = Typography;

const isPasswordValid = (password: string) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    return (
        uppercaseRegex.test(password) &&
        lowercaseRegex.test(password) &&
        digitRegex.test(password) &&
        specialCharacterRegex.test(password)
    );
};

function ChangePassword() {
    const dispatch = useAppDispatch();
    const { idUser } = useParams();

    const [password, setPassword] = useState<Boolean>(false)
    const [repassword, setrepassword] = useState<Boolean>(false)
    const [canSubmit, setCanSubmit] = useState<Boolean>(false)
    const userinfo = useAppSelector(state => state.userInfo.entity)
    const [passwordUser, setPasswordUser] = useState<string>("");
    const [form] = Form.useForm();
    const handleChangePasswordlUser = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordUser(e.target.value)
    }

    useEffect(() => {
        if (password && repassword) {
            setCanSubmit(true)
        }

        else {
            setCanSubmit(false)
        }
    }, [password, repassword])

    const handleSubmit = () => {
        // const data: UserInfo = {
        //     id: idUser,
        //     age: userinfo.age,
        //     email: userinfo.email,
        //     password: passwordUser,
        //     srcImage: userinfo.srcImage,
        // };
        // console.log(data);
        // dispatch(UpdateUserInfo(data));
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Col
                lg={{ span: 6 }}
                style={{
                    backgroundColor: "#f5f5f5",
                    margin: "5% 13% 0 13%",
                    padding: "1% 3% 3% 3%",
                }}
            >
                <Row style={{ marginBottom: "5%" }}>
                    <Title level={1}>
                        Change Password
                    </Title>
                    <Text>
                        Mật khẩu của bạn phải có ít nhất 12 ký tự, bao gồm cả chữ số, chữ cái và ký tự đặc biệt (!$@%).
                    </Text>
                </Row>
                <Row
                    gutter={[128, 0]}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "5%",
                    }}
                >
                    <Col lg={{ span: 24 }}>
                        <Form labelCol={{ span: 4 }} layout="horizontal" labelAlign="left" form={form}>
                            <Form.Item label="ID">
                                <Text>{idUser}</Text>
                            </Form.Item>

                            <Form.Item
                                name={'password'}
                                style={{ marginBottom: 16 }}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                    },
                                    ({
                                        validator(_, value) {
                                            if (!value || value.length < 12 || isPasswordValid(value) === false) {
                                                setPassword(false)
                                                return Promise.reject();
                                            }
                                            setPassword(true)
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password value={passwordUser} onChange={handleChangePasswordlUser} placeholder={"Nhập mật khẩu"} />
                            </Form.Item>

                            <Form.Item
                                name={'repassword'}
                                style={{ marginBottom: 16 }}
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && getFieldValue('password') === value && value.length !== 0) {
                                                setrepassword(true)
                                                return Promise.resolve();
                                            }
                                            setrepassword(false)
                                            return Promise.reject(new Error('Mật khẩu không trùng khớp!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder={"Nhập lại mật khẩu mới"} />
                            </Form.Item>
                        </Form>
                    </Col>

                </Row>
                <Row gutter={[24, 0]}>
                    <Col lg={{ span: 8 }}>
                        <Link to="/user/userInfo/">
                         
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={handleSubmit}
                                style={{ width: "100%", height: "100%" }}
                                disabled=  {!form.getFieldsError}
                            >
                                Save
                            </Button>
                        </Link>


                    </Col>
                    <Col lg={{ span: 8 }} >
                        <Link to="/user/userInfo/">
                            <Button
                                style={{ width: "100%", height: "100%" }}
                            >
                                Back
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </div>
    );
}


export default ChangePassword;
