import React, { useState } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import { animated, config, useTransition } from '@react-spring/web';

import './index.css'
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
const { Text, Link, Title, Paragraph } = Typography;

const BlockchainFunding = ({ display }: any) => {
    const [ellipsis, setEllipsis] = useState(true);
    const isMobile = useIsMobile()

    const textTransition = useTransition(display, {
        from: { y: -50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        config: config.slow,
        delay: 500
    })

    const imageTransition = useTransition(display, {
        from: { x: -50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500
    })

    return (
        <div className='BlockchainFundingHome' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: !isMobile ? '3% 11% 0% 11%' : '3% 5% 0% 5%' }}>
            <Col>
                <Row justify={'center'}>
                    <Col lg={{ span: 14 }}>
                        {
                            textTransition((style, item) => (
                                <animated.div style={style}>
                                    <Title level={3} style={{ textAlign: 'center' }}>Transparent transaction, quick Approval</Title>
                                    <Title level={5} style={{ textAlign: 'center', marginBottom:'7%' }}>Banks' benefit when joining in platform</Title>
                                </animated.div>
                            ))
                        }
                    </Col>
                </Row>
                {
                    imageTransition((style, item) => (
                        <animated.div style={style}>
                            <Row justify={'space-evenly'} gutter={[0, 16]}>
                                <Col lg={{ span: 5 }}>
                                    <Card style={{ width: '100%', height: '100%', backgroundColor: 'rgb(239, 248, 255)' }} bodyStyle={{ padding: 0, overflow: 'hidden', paddingBottom: 80 }}>
                                        <Image preview={false} className="ImageStyle" src='/images/home/LC/lc-13-20221018164134-afxl_.png' />
                                        <Title level={4} style={{ padding: '0 20px 0 20px' }}>
                                        Customers and Partners
                                        </Title>
                                        <ul>
                                            <li>
                                                <Paragraph  className='TextStyle'>
                                                Issuing bank & Financing bank easily negotiate with each other on the platform
                                                </Paragraph>
                                            </li>
                                            <li>
                                                <Paragraph  className='TextStyle'>
                                                Accelerate productivity and customer service capabilities.
                                                </Paragraph>
                                            </li>
                                        </ul>
                                    </Card>
                                </Col>
                                <Col lg={{ span: 5 }}>
                                    <Card style={{ width: '100%', height: '100%', backgroundColor: 'rgb(255, 250, 236)' }} bodyStyle={{ padding: 0, overflow: 'hidden', paddingBottom: 110 }}>
                                        <Image preview={false} className="ImageStyle" src='/images/home/LC/lc-14-20221018175515-me_bv.png' />
                                        <Title level={4} style={{ padding: '0 20px 0 20px' }}>
                                        Smart transparency
                                        </Title>
                                        <ul>
                                            <li>
                                                <Paragraph className='TextStyle'>
                                                Up-to-date and instant transactions among stakeholders
                                                </Paragraph>
                                            </li>
                                            <li>
                                                <Paragraph className='TextStyle'>
                                                Immutable data and documents on Blockchain
                                                </Paragraph>
                                            </li>
                                        </ul>

                                    </Card>
                                </Col>
                                <Col lg={{ span: 5 }}>
                                    <Card style={{ width: '100%', height: '100%', backgroundColor: 'rgb(235, 253, 247)' }} bodyStyle={{ padding: 0, overflow: 'hidden', paddingBottom: 50 }}>
                                        <Image preview={false} className="ImageStyle" src='/images/home/LC/lc-15-20221018175515-sz-5d.png' />
                                        <Title level={4} style={{ padding: '0 20px 0 20px' }}>
                                        Cost saving
                                        </Title>
                                        <ul>
                                            <li>
                                                <Paragraph className='TextStyle'>
                                                Cost saving of bank personnel: L/C application acceptance, checking documents, SWIFT telegram process
                                                </Paragraph>
                                            </li>
                                            <li>
                                                <Paragraph className='TextStyle'>
                                                Cost of document inventory in Corporates and Banks, SWIFT charges, .....
                                                </Paragraph>
                                            </li>
                                        </ul>
                                    </Card>
                                </Col>
                                <Col lg={{ span: 5 }}>
                                    <Card style={{ width: '100%', height: '100%', backgroundColor: 'rgb(249, 245, 255)' }} bodyStyle={{ padding: 0, overflow: 'hidden', paddingBottom: 100 }}>
                                        <Image preview={false} className="ImageStyle" src='/images/home/LC/lc-16-20221018175515-daxpe.png' />
                                        <Title level={4} style={{ padding: '0 20px 0 20px' }}>
                                        Reduce fraud risk
                                        </Title>
                                        <ul>
                                            <li>
                                                <Paragraph className='TextStyle'>
                                                KYC customers and documents in accordance with AML/CTF regulations
                                                </Paragraph>
                                            </li>
                                        </ul>
                                    </Card>
                                </Col>
                            </Row>
                        </animated.div>
                    ))
                }

            </Col>
        </div>
    )
}

export default BlockchainFunding;