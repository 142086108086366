import React from "react";
import { useTranslation } from "react-i18next";
import { translations } from "@/i18n/i18n";
import { Space, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { TaskData } from "@/shared/model/task/task-data.model";

export default function ExpiredTaskComponent() {

    const { t } = useTranslation();
    const columns: TableProps<TaskData>['columns'] = [
        {
          title:t(translations.taskData.processDefinitionName), 
          dataIndex: 'processDefinitionName',
          key: 'processDefinitionName',
          render: (text) => <a>{text}</a>,
        },
        {
          title:t(translations.taskData.name),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title:t(translations.taskData.boCode) ,
          dataIndex: 'boCode',
          key: 'boCode',
        },
        {
          title:t(translations.taskData.createTime)  ,
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title:t(translations.taskData.dueDate)  ,
          key: 'dueDate',
          dataIndex: 'dueDate',
        },
        {
            title: t(translations.taskData.remainingTime)  ,
            key: 'remainingTime',
            dataIndex: 'remainingTime',
          },
          {
            title:t(translations.taskData.boStatus)  ,
            key: 'boStatus',
            dataIndex: 'boStatus',
          },
          {
            title: t(translations.taskData.taskStatus)  ,
            key: 'taskStatus',
            dataIndex: 'taskStatus',
          },
          {
            title: t(translations.taskData.createdBy)  ,
            key: 'createdBy',
            dataIndex: 'createdBy',
          },
          {
            title: t(translations.taskData.comment)  ,
            key: 'comment',
            dataIndex: 'comment',
          },
      ];
    const data: TaskData[] = []
    return(<><Table columns={columns} dataSource={data} />
    </>)
}