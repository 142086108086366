import React from 'react';
import { Layout, Space, Menu, Image, Button, Drawer, Avatar } from 'antd';
import { MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import LocaleMenu from '../../menu/locale';
import './drawer.css'
import LocaleMenuDrawer from '../../menu/locale-drawer';
import { useTranslation } from 'react-i18next';
import { translations } from '@/i18n/i18n';
import { useAuth } from 'react-oidc-context';

function DrawerInMinWith(props: any) {
    const { t } = useTranslation();
    const auth = useAuth();
    return (
        <Drawer
            className='Drawer'
            open={props.open}
            closable={false}
            onClose={() => props.onChange()}
            style={{ backgroundColor: '#2F54EB' }}
        >
            <Menu
                className='Menu'
                mode="inline"
                theme='dark'
                style={{ border: 'none', backgroundColor: '#2f57fa', color: 'white', marginTop: 50 }}
            >
                {(props.navItems).map((item: any, index: any) => {
                    return (
                        <Menu.Item key={item.path}>
                            <Link
                                key={item.path}
                                to={item.path}
                                onClick={() => props.onChange()}
                            >
                                <span style={{ fontSize: '1em' }}>{item.name}</span>
                            </Link>
                        </Menu.Item>

                    );
                })}

                <Menu.Item>
                    <div style={{ borderBottom: "1px solid #FAFBFF" }}></div>
                </Menu.Item>
                {/* <div style={{ borderBottom: "1px solid #FAFBFF" }}></div> */}

                {!!props.isAuthenticated && (
                    <Menu.Item key={'admin'} className='MenuItem' style={{ background: 'blue' }}>
                        <Link
                            key={'admin'}
                            to={'/admin'} >
                            <span >{t(translations.header.settingMenu.administration)}</span>
                        </Link>
                    </Menu.Item>
                )}

                {!props.isAuthenticated && (<Menu.Item key="signin" onClick={() => () => auth.signinRedirect()}>{t(translations.header.settingMenu.login)}</Menu.Item>)}

                {
                    !props.isAuthenticated && (
                        <Menu.Item key="signup">
                            <Link
                                key={'registration'}
                                to={'registration'} >
                                <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.registration)}</span>
                            </Link>
                        </Menu.Item>
                    )
                }

                {props.isAuthenticated && (<Menu.Item key="signin" onClick={() => auth.signoutSilent()}>
                {t(translations.header.settingMenu.logout)}</Menu.Item>)}

                <Menu.Item>
                    <div style={{ borderBottom: "1px solid #FAFBFF" }}></div>
                </Menu.Item>

                {/* <Menu.Item style={{ backgroundColor: 'rgb(47, 87, 250)' }}>
                    <LocaleMenuDrawer />
                </Menu.Item> */}
                {/* {!!props.isAuthenticated && (
                            <Menu.Item key="userinfo">
                                <Link
                                    key={'userinfo'}
                                    to={'/user-info'} >
                                    <span style={{ fontSize: '16px' }}>Hello {props.userName}</span>
                                </Link>
                            </Menu.Item>
                        )} */}
                {/* <Menu.Item key="entities/bank">
                            <Link
                                key={'entities/bank'}
                                to={'/entities/bank'} >
                                <span style={{ fontSize: '16px' }}>Ngân hàng</span>
                            </Link>
                        </Menu.Item> */}
                {/* <Menu.SubMenu
                    title={
                        <div className='Setting' >
                            <SettingOutlined style={{ padding: 10 }} />
                            Cài đặt
                        </div>}
                    theme='light'
                >
                </Menu.SubMenu> */}
            </Menu>
        </Drawer>
    );
}

export default DrawerInMinWith;