import {  createSlice} from '@reduxjs/toolkit';
const initialState: any = {
  breadcrumbItems:[],
  siderCollapsed:false
};

// slice
export const LayoutAppSlice = createSlice({
  name: 'layoutapp',
  initialState,
  reducers: {
    updateBreadcrumbItems(state, action){
        state.breadcrumbItems = action.payload;
    },
    setSiderCollapsed(state,action){
      state.siderCollapsed = action.payload;
    },
    /**
     * Reset the layoutapp state to initial state
     */
    reset() {
      return initialState;
    },
  },

});

export const { reset,updateBreadcrumbItems,setSiderCollapsed } = LayoutAppSlice.actions;

// Reducer
export default LayoutAppSlice.reducer;
