
import { baseAuthAPI } from './base-auth.api'

const registrationUrl = '/rest/portal/registration'

export const registrationApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        checkEmail: build.query<Boolean, any>({
            query: (body) => ({
                url: `${registrationUrl}/check-user-email`,
                method: 'POST',
                body: body
            })
        }),

        checkCompanyCode: build.query<Boolean, any>({
            query: (body) => ({
                url: `${registrationUrl}/check-company-code`,
                method: 'POST',
                body: body
            })
        }),

        checkValidEmail: build.query<void, any>({
            query: (body) => ({
                url: `${registrationUrl}/send-otp-email`,
                method: 'POST',
                body: body
            })
        }),

        sendRequestCheckValidEmail: build.query<Boolean, any>({
            query: (body) => ({
                url: `${registrationUrl}/input-user-check-code`,
                method: 'POST',
                body: body
            })
        }),
    })
})

export const {
    useCheckEmailQuery,
    useCheckCompanyCodeQuery,
    useCheckValidEmailQuery,
    useSendRequestCheckValidEmailQuery
  } = registrationApi

export const {
endpoints: { checkEmail, checkCompanyCode, checkValidEmail, sendRequestCheckValidEmail },
} = registrationApi