import { useAppSelector } from '@/config/store';
import { Col, Row, Space } from 'antd';
import './../index.css';
import { InfoCircleFilled, InfoCircleTwoTone } from '@ant-design/icons';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export default function ConfirmationSuccess() {
  const registrationEntity = useAppSelector(state => state.registration.entity);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  console.log('registrationEntity:: --', registrationEntity);

  const goToLogin = () => {
    auth.signinRedirect();
    // navigate('/registration/confirmation_success');
  };

  return (
    <>
      {/* <div className="success">
        <div className="SECTION-LEFT">
          <Space direction="vertical" align="center" style={{ width: '24vw', fontSize: '18px' }}>
            <img src="../images/TradeFlat_logo_01.png" style={{ marginBottom: '36px' }} />
            <InfoCircleFilled className="infor-icon" />
            <p>{t(translations.register.confirmation.confirmationSuccess)}</p>
            <p>
              {t(translations.register.confirmation.returnToLogin)}
              <a onClick={() => goToLogin()}>{t(translations.register.confirmation.LoginBtn)}</a>
            </p>
          </Space>
        </div>
        <img
          className="SECTION-RIGHT"
          src={'../images/registration.jpg'}
          alt="Registration Image"
        />
      </div> */}
      <Row>
        <Col lg={{ span: 12, order: 1 }} md={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} style={{ paddingTop: 63, display: 'flex', alignItems: 'center' }}>
          {/* <Text>RegCode : {regcode}</Text> */}
          <div style={{ width: '100%', textAlign: 'center', display: "flex", justifyContent: 'center' }}>
            <div>
              <img src="../images/TradeFlat_logo_01.png" style={{ marginBottom: '12px', display: 'block', margin:"auto" }} />
              {/* <InfoCircleFilled className="infor-icon" /> */}
              <InfoCircleTwoTone style={{ fontSize: '36px' }} />
              <p>{t(translations.register.confirmation.confirmationSuccess)}</p>
              <p>
                {/* {t(translations.register.confirmation.returnToLogin)} */}
                <div><a onClick={() => goToLogin()}>{t(translations.register.confirmation.LoginBtn)}</a> / <Link to={'/home/common'}>Quay lại trang chủ</Link></div>
              </p>

              <p>
                
              </p>
            </div>

          </div>

        </Col>
        <Col lg={{ span: 12, order: 2 }} md={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
          <img
            style={{ width: '100%' }}
            src={'../../images/registration.jpg'}
            alt="Registration Image"
          />
        </Col>
      </Row>
    </>
  );
}
