import './index.css'
import { useAppSelector } from "@/config/store";
import { translations } from "@/i18n/i18n";

import { Company } from "@/shared/model/company.model";
import { Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CompanyCommon from "./component/common";
import CompanySignRegistration from "./component/digital-sign-registration";
import CompanyRepresentative from "./component/representative";
import CompanyChiefAccountant from "./component/chief-accountant";
import BankAccount from "./component/bank-account";
import UserCompanyInfo from "./component/user-company";
import CompanyEmpowerChiefAccountant from "./component/empower-chief-accountant";
import CompanyEmpowerPresentative from "./component/empower-presentative";

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
      
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return width;
  };

export default function CompanyDetail() {
    // const { data: companyEntity } = useGetCurrentCompanyQuery()
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const { t } = useTranslation();
    const width = useWindowWidth();
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t(translations.companyDetail.commonInfo),
            children: <CompanyCommon />,
        },
        {
            key: '2',
            label: t(translations.companyDetail.eSign),
            children: <CompanySignRegistration />,
        },
        {
            key: '3',
            label: t(translations.companyDetail.representative),
            children: <CompanyRepresentative />,
        },
        ...(
            (
                // companyEntity.empowerRepresentative?.birthday
                // companyEntity.empowerRepresentative?.cardIssueDate
                // || companyEntity.empowerRepresentative?.empowerDateFrom
                // || companyEntity.empowerRepresentative?.empowerDateTo
                // || companyEntity.empowerRepresentative?.empowerDoc
                // || companyEntity.empowerRepresentative?.cardID
                companyEntity.empowerRepresentative?.cardFrontImage
                // || companyEntity.empowerRepresentative?.cardIssueLocation
                // || companyEntity.empowerRepresentative?.cardNumber
                || companyEntity.empowerRepresentative?.faceImage
                || companyEntity.empowerRepresentative?.cardBackImage
            )
                ? [{
                    key: '4',
                    label: t(translations.companyDetail.empowerRepresentative),
                    children: <CompanyEmpowerPresentative />,
                }]
                : []
        ),
        ...(
            (
                // companyEntity.chiefAccountant?.birthday
                // || companyEntity.chiefAccountant?.cardIssueDate
                // || companyEntity.chiefAccountant?.cardID
                companyEntity.chiefAccountant?.cardFrontImage
                // || companyEntity.chiefAccountant?.cardIssueLocation
                || companyEntity.chiefAccountant?.cardBackImage
                || companyEntity.chiefAccountant?.faceImage
                // || companyEntity.chiefAccountant?.name
            )
                ? [{
                    key: '5',
                    label: t(translations.companyDetail.chiefAccountant),
                    children: <CompanyChiefAccountant />,
                },]
                : []
        ),
        ...(
            (
                // companyEntity.empowerChiefAccountant?.birthday
                // || companyEntity.empowerChiefAccountant?.cardIssueDate
                // || companyEntity.empowerChiefAccountant?.empowerDateFrom
                // || companyEntity.empowerChiefAccountant?.empowerDateTo
                // || companyEntity.empowerChiefAccountant?.empowerDoc
                // || companyEntity.empowerChiefAccountant?.cardID
                companyEntity.empowerChiefAccountant?.cardFrontImage
                || companyEntity.empowerChiefAccountant?.cardBackImage
                // || companyEntity.empowerChiefAccountant?.cardNumber
                || companyEntity.empowerChiefAccountant?.faceImage
                // || companyEntity.empowerChiefAccountant?.name
            )
                ? [{
                    key: '6',
                    label: t(translations.companyDetail.emChiefAccountant),
                    children: <CompanyEmpowerChiefAccountant />,
                },]
                : []
        ),
        {
            key: '7',
            label: "Thông tin người dùng",
            children: <UserCompanyInfo />,
        },

        {
            key: '8',
            label: "Tài khoản ngân hàng",
            children: <BankAccount />,
        },


        // {
        //     key: '5',
        //     label: t(translations.companyDetail.bankAccount),
        //     children: <BankAccount/>,
        // },
        // {
        //     key: '6',
        //     label: t(translations.companyDetail.users),
        //     children: <User />,
        // },
    ];

    return (
        <Tabs className="companyOnboardInfo" defaultActiveKey="1" centered items={items} tabPosition={width < 1250 ? 'left' : 'top'} style={{height:'100%', width:"100%", marginTop:10}}/>
    )
} 