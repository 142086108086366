// import  axios from 'axios';
import { axiosNotAuth } from '@/config/axios-interceptor';
import { Registration, defaultValue } from '@/shared/model/registration.model';
import { serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
const initialState: any = {
  loading: false,
  errorMessage: null,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};
import { createAction } from '@reduxjs/toolkit';


const registrationApiUrl = 'rest/portal/registration';

// Actions

export const createdRegistration = createAsyncThunk(
  'registration/create_registration',
  async (entity: Registration, thunkAPI) => {
    console.log('data--:: ', JSON.stringify(entity));
    const result = await axiosNotAuth.post<Registration>(registrationApiUrl + '/create', cleanEntity(entity));

    console.log('result--:: ', JSON.stringify(result));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const confirmation = createAsyncThunk(
  'registration/registration_confirmation',
  async (entity: Registration, thunkAPI) => {
    const result = await axiosNotAuth.post<Registration>(registrationApiUrl + '/confirmation', cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const updateSuccessSlice = createSlice({
  name: 'updateSuccess',
  initialState: false,
  reducers: {
    setUpdateSuccess: (state, action) => {
      return action.payload;
    },
  },
});

export const RegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    /**
     * Reset the registration state to initial state
     */
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(createdRegistration), (state, action) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
      })
      .addMatcher(isFulfilled(createdRegistration), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isRejected(createdRegistration), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = false;
        state.entity = {};
      })

      .addMatcher(isPending(confirmation), (state, action) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
      })
      .addMatcher(isFulfilled(confirmation), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isRejected(confirmation), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = false;
        state.entity = {};
      });
  },
});

export const { reset } = RegistrationSlice.actions;

export const { setUpdateSuccess } = updateSuccessSlice.actions;

// Reducer
export default RegistrationSlice.reducer;
