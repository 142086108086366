export type OperatorType = '=' | '>' | '>=' | '<' | '<=' | '<>' | 'startsWith' | 'endsWith' | 'contains'
  | 'doesNotContain' | 'in' | 'notIn' | 'notEmpty';

export type GroupType = 'AND' | 'OR';

export interface EntityFilterModel {
  conditions?: Array<ConditionModel | ConditionsGroupModel>;
}

export interface ConditionsGroupModel {
  group: GroupType;
  conditions: ConditionModel[];
}

export type FilterValue = string | number | string[] | number[] | null;

export interface ConditionModel {
  property: string;
  operator: OperatorType;
  value: FilterValue;
}

export const defaultEntityFilter: Readonly<EntityFilterModel> = {
  conditions: []
};
export const defaultConditionsGroupModel: Readonly<ConditionsGroupModel> = {
  group: "AND",
  conditions: []
};
export const defaultConditionModel: Readonly<ConditionModel> = {
  property: "",
  operator: "=",
  value: null
};