import { store } from '@/config/store'
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { User, UserManager } from 'oidc-client-ts';
import { JMIX_REST_URL, KEYCLOAK_CLIENT_ID, KEYCLOAK_URL } from '@/config/config';
import { getOidcConfig } from '@/config/keycloak.config';
import { clearAuth } from '@/shared/reducers/authentication.reducer';

function getUser() {
  const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`);

  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);

}

function getUserManager() {
  const oidcConfig = getOidcConfig(null);

  const userManager: UserManager = new UserManager(oidcConfig);
  return userManager;

}
const baseQuery = fetchBaseQuery({ baseUrl: JMIX_REST_URL })

// Create our baseQuery instance
const baseAuthQuery = fetchBaseQuery({
  baseUrl: JMIX_REST_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    // const token = (getState() as IRootState).authentication.access_token

    const user = getUser();

    const token = user?.access_token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers
  },
})


const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseAuthQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    store.dispatch(clearAuth());
  }
  return result
}

const baseAuthQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 1 })

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded 
 */
export const baseAuthAPI = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'splitApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseAuthQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ['UserCompany', 'Bank', 'Counter'],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})

export const enhancedApi = baseAuthAPI.enhanceEndpoints({
  endpoints: () => ({
    getPost: () => 'test',
  }),
})
