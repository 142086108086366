import React, { useState, ChangeEvent, useEffect } from "react";
import { Steps, Button, Layout, Col, Row, Table, Form, Input, message, Typography, Upload, Image, Modal } from "antd";
import {
    UploadOutlined,
    BellOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    LoadingOutlined,
    CloseOutlined
} from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import "./index.css";
import { useTranslation } from "react-i18next";
import { translations } from "@/i18n/i18n";
import { useAppDispatch, useAppSelector } from "@/config/store";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { getUserInfo, UpdateUserInfo } from "../user-info/user-info.reducer";
import { UserInfo } from "@/shared/model/user-info.model";
import { UpdateListUserInfo, getAllUserInfo } from "./user-manage.reducer";
import AddUser from "./card-user";
import { useTransition, animated, config } from '@react-spring/web';
import { v4 as uuidv4 } from "uuid";
import CardUserInfo from "./card-user";
import ModalAddUser from "./add-user";


export default function UserManage(props: any) {
    const { t } = useTranslation();
    const [cardDisplay, setCardDisplay] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [idClick, setIDClick] = useState()
    const [dataSource, setDataSource] = useState([])
    const dispatch = useAppDispatch();
    let listUserInfo = useAppSelector((state) => state.userManger.entities);

    const pagination = {
        pageSize: 10,
    };

    const columns = [
        {
            title: t(translations.user.fullName),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t(translations.user.company),
            dataIndex: "company",
            key: "company",
        },
        {
            title: t(translations.user.assignedTo),
            dataIndex: "assignedTo",
            key: "assignedTo",
        },
        {
            title: t(translations.user.phone),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t(translations.user.email),
            dataIndex: "email",
            key: "email",
        },
    ];

    const cardInfoTranstion = useTransition(cardDisplay, {
        from: { x: 950, y: 10, opacity: 0, zIndex: 2 },
        enter: { x: 850, y: 10, opacity: 1, zIndex: 2 },
        leave: { x: 950, y: 10, opacity: 0, zIndex: 2 },
    })

    const handleRowClick = (record: any) => {
        setIDClick(record.key)
        // const data = listUserInfo.find((user: UserInfo) => user.id === record.key)
        // setUserInfo(data)
        setCardDisplay(true)
    };

    const handleCloseClick = () => {
        setCardDisplay(false)
    }

    const showModal = (result: boolean) => {
        setIsModalOpen(result);
    };

    useEffect(() => {
        console.log(idClick)
    }, [idClick])


    useEffect(() => {
        dispatch(getAllUserInfo());
    }, [])

    useEffect(() => {
        let dataSourceNew = listUserInfo.map((info: UserInfo) => ({
            key: info.attributes?.id,
            name: `${info.attributes?.firstName} ${info.attributes?.lastName}`,
            company: info.attributes?.company,
            assignedTo: info.attributes?.assignedTo,
            phone: info.attributes?.phoneNumber,
            email: info.attributes?.email
        }));
        setDataSource(dataSourceNew);
    }, [listUserInfo])

    return (
        <div style={{ width: '100%', height: "100%", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)", }}>
            <Content className="ContentUserManage" style={{ height: '100%', width: '100%' }}>
                <div
                    className="TableInfo"
                    style={{ lineHeight: 3, position: 'relative', padding: '2%' }}
                >
                    {
                        cardInfoTranstion((style, item) => (
                            item && (
                                <animated.div style={{ ...style, position: 'absolute', top: '-5vh', width: '50%', height: '100%' }}>
                                    <CardUserInfo listUserInfo={listUserInfo} IdUserInfo={idClick} openCardUser={handleCloseClick} />
                                </animated.div>
                            )
                        ))
                    }
                    <div style={{ display: "flex" }}>
                        <Button type="primary" onClick={() => showModal(true)}>
                            <PlusOutlined />
                            {t(translations.user.addUser)}
                        </Button>
                        <ModalAddUser listUserInfo={listUserInfo} visible={isModalOpen} isModalOpen={showModal} />
                    </div>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={pagination}
                        onRow={(record, rowIndex) => ({
                            onClick: () => handleRowClick(record),
                        })}
                        scroll={{ x: 'calc(300px + 50%)'}}
                    />
                </div>
            </Content >
        </div>
    );
}
