import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { useTransition, animated, config } from '@react-spring/web';
import { Button } from 'antd';
import { Carousel } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

interface Slide {
  id: number;
  content: string;
}

interface SliderProps {
  slides: Slide[];
}

interface SliderArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SampleNextArrow: React.FC<SliderArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "20px",
        backgroundColor: 'rgb(165, 204, 231)',
        borderRadius: '20px',
      }}
      onClick={onClick}
    >
    </div>
  );
};

const SamplePrevArrow: React.FC<SliderArrowProps> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "20px",
        backgroundColor: 'rgb(165, 204, 231)',
        borderRadius: '20px',
      }}
      onClick={onClick}
    >
    </div>
  );

};

// const contentStyle: React.CSSProperties = {
//   height: '160px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const SimpleSlider = ({ display }: any) => {

  const titleTransition = useTransition(display, {
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    config: config.slow,
    delay: 500
  })

  const sectionTransition = useTransition(display, {
    from: { x: -100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500
  })

  const slides = [
    { id: 1, link: "https://cafebiz.vn/hop-tac-viet-nhat-tao-diem-sang-cho-thuong-mai-chau-a-thai-binh-duong-176231218145645501.chn", content: 'Vietnamese-Japanese cooperation creating a bright spot for Asia-Pacific trade.', image: "https://w.ladicdn.com/s600x500/60b444ceeba2a30012e68735/photo-1-17028732869522091994057-1-20231221063307-vsb_i.png" },
    { id: 2, link: "https://vnexpress.net/fpt-hop-tac-ong-lon-thuong-mai-so-nhat-ban-4668443.html", content: 'FPT cooperates with Japanese digital commerce giant', image: "https://w.ladicdn.com/s600x500/60b444ceeba2a30012e68735/so-1-nhat-ban-20231211154755-6efzs.jpg" },
    { id: 3, link: "https://vtv.vn/cong-nghe/trinh-dien-cong-nghe-ai-blockchain-tu-dong-hoa-rpa-tai-industry-40-20230615095607887.htm", content: 'Presentation of AI, Blockchain, and RPA Automation Technologies in Industry 4.0', image: "https://w.ladicdn.com/s600x500/60b444ceeba2a30012e68735/gian-han-cong-nghe-20231211154755-p4uly.jpg" },
    { id: 4, link: "https://www.techsignin.com/fpt-vietnam-blockchain-summit-2022/", content: 'FPT showcases its blockchain technology application product ecosystem.', image: "https://w.ladicdn.com/s600x500/60b444ceeba2a30012e68735/blockchain-20231211154755-hxtvd.jpg" },
    { id: 5, link: "https://baotintuc.vn/khoa-hoc-cong-nghe/gioi-cong-nghe-viet-nam-va-quoc-te-hoi-tu-tai-fpt-techday-2023-20231024144121675.htm", content: 'Vietnamese and international technology circles converge at FPT Techday 2023', image: "https://w.ladicdn.com/s600x500/60b444ceeba2a30012e68735/techday241023-2-20231211155137-nejf0.jpg" },
  ];

  return (
    <div style={{ backgroundColor: 'rgb(239, 248, 255)', padding: '20px 0 60px 0', marginTop: '3%', textAlign: 'center' }}>
      {
        titleTransition((style, item) => (
          <animated.div style={style}>
            <Title level={3} style={{ textAlign: 'center' }}>News & Events</Title>
          </animated.div>
        ))
      }

      <Carousel
        autoplay={true}
        swipeToSlide={true}
        infinite={true}
        speed={500}
        slidesToShow={4}
        slidesToScroll={1}
        arrows={true}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        initialSlide={0}
        responsive={[
          {
            breakpoint: 1760,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 875,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
        ]}
        style={{ width: '70%', margin: 'auto' }}
      >
        {slides.map((item) => (
          <div key={item.id}>
            <Link to={item.link} style={{ textDecoration: 'none', margin: 20 }} target="_blank" rel="noopener noreferrer">
              <img src={item.image} style={{ display: 'block', margin: 'auto', width: "250px", height: "170px" }} alt='' />
              <p style={{ display: 'block', margin: '15px 35px 0 40px', color: 'black', textAlign: 'start' }}>{item.content}</p>
            </Link>
          </div>
        ))}
      </Carousel>


    </div>

  );
};

export default SimpleSlider;
