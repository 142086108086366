
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError, EntitiesLoadOptions } from '@/shared/reducers/reducer.utils';
import { Bank,defaultValue } from '@/shared/model/bank.model';
import { encodeGetParams } from '@/shared/utils/jmix-rest/util';
import { axiosSecurity } from '@/config/axios-interceptor';
const initialState: EntityState<Bank> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const bankApiUrl = 'rest/portal/bank';

// Actions

export const getEntities = createAsyncThunk('bank/fetch_entity_list', async (params: EntitiesLoadOptions) => {
  const requestUrl = bankApiUrl+"/list?" + encodeGetParams(params);
  // const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axiosSecurity.get<Bank[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'bank/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${bankApiUrl}/${id}`;
    return axiosSecurity.get<Bank>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);


// slice

export const BankSlice = createEntitySlice({
  name: 'bank',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      }) 
  },
});

export const { reset } = BankSlice.actions;

// Reducer
export default BankSlice.reducer;
