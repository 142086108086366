import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '@/shared/error/error-boundary-routes';

import BankPage from '@/modules/entities/bank';


export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        <Route path="bank/*" element={<BankPage />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};
