
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { baseAuthAPI } from './base-auth.api';
import { EmailSupport } from '@/shared/model/email-support.model';

const SupportRegApiUrl = '/rest/portal/registration/create-support-message';

export const supportApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        addEmailSupport: build.mutation<EmailSupport, Partial<EmailSupport>>({
            query: (body) => ({
                url: SupportRegApiUrl,
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const {
   useAddEmailSupportMutation
} = supportApi

export const {
    endpoints: { addEmailSupport },
} = supportApi