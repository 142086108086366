
import BankDetail from '@/modules/entities/bank/bank-detail'
import { baseAuthAPI } from './base-auth.api'
import {Bank} from '../../shared/model/bank.model'

const bankUrl = '/rest/portal/bank'

export const bankApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllBank: build.query<Bank[], void>({
            query: () => ({
                url: `${bankUrl}/all`,
                method: 'GET'
            })
        })
    })
})

export const {
   useGetAllBankQuery
  } = bankApi

export const {
endpoints: { getAllBank },
} = bankApi