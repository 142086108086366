import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Image, Row, Col, Card, Flex, Typography, Switch, Checkbox, Form, type FormProps, message, Mentions, Modal, notification } from 'antd';
import { useTransition, animated, config } from '@react-spring/web';
import './index.css'
import type { CheckboxProps } from 'antd';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import FormItem from 'antd/es/form/FormItem';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { translations } from '@/i18n/i18n';
import { useAddEmailSupportMutation } from '@/services/api/email-support.api';

const { Text, Title, Paragraph } = Typography;

function Register({ display, isMobile }: any) {
    const { t } = useTranslation();
    const [openModalReviewFile, setOpenModalReviewFile] = useState(false)

    const [addEmailSupport, { isError: isErrorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAdd }] = useAddEmailSupportMutation()
    let schema = yup.object().shape({
        name: yup
            .string()
            .required("Hãy nhập tên!"),
        email: yup
            .string()
            .required(t(translations.register.errors.emailError))
            .email(t(translations.register.errors.emailInvalid)),
        phone: yup
            .number()
            .required("Hãy nhập số điện thoại!")
            .typeError('Chỉ chấp nhận chữ số!')
            .test('isPhoneNumber', 'Số điện thoại phải chứa 10 chữ số!', (value: any) => {
                return /^\d{9}$/.test(value);
            }),
        company: yup
            .string()
            .required("Hãy nhập tên công ty!"),
        message: yup
            .string()
            .required("Hãy nhập tin nhắn!"),
    });
    const yupSync: any = {
        async validator({ field }: any, value: any) {
            await schema.validateSyncAt(field, { [field]: value });
        },
    };

    const [check, setCheck] = useState(false)

    const titleTransition = useTransition(display, {
        from: { x: -50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 1000,
    })

    const contentTransition = useTransition(display, {
        from: { x: 50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 1000,
    })

    const onChange: CheckboxProps['onChange'] = (e) => {
        console.log('checked = ', e.target.checked);
        setCheck(e.target.checked);
    };

    const onFinish: FormProps["onFinish"] = (values) => {
        addEmailSupport(values);
        console.log('Success:', values);
    };

    const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleModalCancel = () => {
        setOpenModalReviewFile(false)
    }
    
    useEffect(()=> {
        if(isSuccessAdd)
            {
                notification.success({
                    message: "Đăng ký tư vấn thành công!",
                    description: "Chúng tôi sẽ liên hệ lại với bạn."
                  });
                setCheck(true)
            }
            if(isErrorAdd)  
            {
                notification.error({message: "Đăng ký tư vấn không thành công!"})
                setCheck(true)
            }
        if(isLoadingAdd){
            // message.success("Đang gửi tin nhắn")
            setCheck(false)
        }

    }, [isSuccessAdd, isErrorAdd, isLoadingAdd])

    return (
        <div className='Register' style={{ margin: !isMobile ? '3% 13% 3% 13%' : '3% 5% 3% 5%' }}>
            <Form colon={false} 
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row justify={!isMobile ? 'space-between' : 'center'}>
                    <Col lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} >
                        {
                            titleTransition((style, item) => (
                                <animated.div style={style}>
                                    <Image preview={false} style={{ width: '100%', height: 'auto', maxWidth: 247 }} src='/images/home/logo-tran-w-20230921063156-bzd33.png' />
                                    <Title level={3} style={{ width: '100%', textAlign: 'justify', marginTop: 0 }}>Đồng hành kiến tạo nền tảng tài chính số đầu tiên tại Việt Nam</Title>
                                </animated.div>
                            ))
                        }
                    </Col>
                    <Col lg={{ span: 14 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <Row justify={'space-between'}>
                            <Col lg={{ span: 11 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ marginTop: '1%' }}>
                                {
                                    contentTransition((style, item) => (
                                        <animated.div style={style}>
                                            <Row>
                                                <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                                                    <Form.Item name={"name"} rules={[yupSync]} hasFeedback>
                                                        <Input style={{ width: '130%', height: 47 }} placeholder="Họ và tên" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                                                    <Form.Item name={"email"} rules={[yupSync]} hasFeedback>
                                                        <Input style={{ width: '130%', height: 47 }} placeholder="Email" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                                                    <Form.Item name={"phone"} rules={[yupSync]} hasFeedback>
                                                        <Input style={{ width: '130%', height: 47 }} placeholder="Số điện thoại" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                                                    <Form.Item name={"company"} rules={[yupSync]} hasFeedback>
                                                        <Input style={{ width: '130%', height: 47 }} placeholder="Công ty" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </animated.div>
                                    ))
                                }
                            </Col>
                            <Col lg={{ span: 11 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ marginTop: '1%' }}>
                                {
                                    contentTransition((style, item) => (
                                        <animated.div style={style}>
                                            <Row>
                                                <Col lg={{ span: 24 }} md={{ span: 23 }} sm={{ span: 23 }} xs={{ span: 23 }} >
                                                    <Form.Item name={"message"} rules={[yupSync]} hasFeedback>
                                                        {/* <Input style={{ width: '100%', height: 250 }} placeholder="Để lại lời nhắn cho chúng tôi" /> */}
                                                        <Mentions
                                                            rows={10}
                                                            placeholder="Để lại lời nhắn cho chúng tôi"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                    <Col lg={{ span: 24 }} md={{ span: 23 }} sm={{ span: 23}} xs={{ span: 23}}>
                                        <Button style={{ width: '100%', height: 47 }} type="primary">Đăng ký tư vấn</Button>
                                    </Col>
                                </Row> */}
                                        </animated.div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify={'start'} align={'middle'} style={{ marginBottom: 20 }}>
                    <Col lg={{ offset: 10, span: 14 }} md={{ span: 23 }} sm={{ span: 23 }} xs={{ span: 23 }}>
                        <Checkbox onChange={onChange} checked={check} style={{border:'none'}}>
                            Tôi đồng ý với chính sách Bảo mật dữ liệu cá nhân
                        </Checkbox>
                    </Col>

                </Row>
                <Row justify={'start'} align={'middle'} gutter={[0, 24]}>
                    <Col lg={{ offset: 10, span: 5, order: 1 }} md={{ span: 23, order: 1 }} sm={{ span: 23, order: 1 }} xs={{ span: 23, order: 2 }} style={{marginRight:12}}>
                        <Button style={{ width: '100%', height: 47 }} type="primary" htmlType="submit" disabled={!check}>Đăng ký tư vấn</Button>
                    </Col>

                    <Col lg={{ span: 8, order: 2 }} md={{ span: 23, order: 2 }} sm={{ span: 23, order: 2 }} xs={{ span: 23, order: 1 }}>
                        <Link to={"https://storage.scf-sit.tradeflat.com/portal-bucket/chinh_sach/chinh_sach.pdf"} target="_blank">&gt;  Chính sách Bảo mật dữ liệu cá nhân</Link>
                    </Col>
                </Row>
            </Form>


            <Modal
                open={openModalReviewFile}

                footer={(_, {}) => (
                    <>
                        <Button onClick={handleModalCancel}>Đóng</Button>
                    </>
                )}
                width={'50vw'}
                centered={true}
            >
                    <iframe
                        src={"https://storage.scf-sit.tradeflat.com/portal-bucket/chinh_sach/chinh_sach.pdf"}
                        width="100%"
                        height="700px"
                        title="PDF Viewer"
                        frameBorder="0"
                    />
            </Modal>
        </div>
    );
}

export default Register;