
export const JMIX_REST_URL = process.env.REACT_APP_JMIX_REST_URL;
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const  KEYCLOAK_REDIRECT_URI = process.env.REACT_APP_KEYCLOAK_REDIRECT_URI;

export const DEFAULT_COUNT = 10;

export const SCF_LINK = process.env.REACT_APP_SCF_LINK;
export const LC_LINK = process.env.REACT_APP_LC_LINK;
export const GUARANTEE_LINK = process.env.REACT_APP_GUARANTEE_LINK;

export const NEW_CODE = process.env.REACT_APP_NEW_CODE;

export const ZALO_VAR = process.env.ZALO_VAR;


