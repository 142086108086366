import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from '@/shared/error/error-boundary-routes';

import RegistrationPage from '.';
import RegistrationConfirmation from './confirmation';
import RegistrationSuccess from './success';
import RegistrationFail from './fail';
import ConfirmationSuccess from './confirmation/confirmation_success';
import Login from './requiredLogin';
import ConfirmEmail from './confirmEmail';

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="/" element={<RegistrationPage />} />
        <Route path="/confirmation/:regcode" element={<RegistrationConfirmation />} />
        {/* <Route path="/confirmation" element={<RegistrationConfirmation />} /> */}
        <Route path="/confirmation_success" element={<ConfirmationSuccess />} />
        <Route path="/success" element={<RegistrationSuccess />} />
        <Route path="/fail" element={<RegistrationFail />} />
        <Route path="/require-login" element={<Login />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};
