import React, { Suspense } from "react";
import HomeCommon from "./common";
import { translations } from 'i18n/i18n';
import { useTranslation } from 'react-i18next';

// const HomeCommon = React.lazy(() => import('./common'));

export default function HomePage() {
const { t,i18n } = useTranslation();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <HomeCommon />
        </Suspense>
    )
}