import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  Button,
  Image,
  Row,
  Col,
  Card,
  Flex,
  Typography,
  Switch,
  Checkbox,
  Form,
  type FormProps,
  message,
  Mentions,
} from 'antd';
import type { SelectProps } from 'antd';
import { useTransition, animated, config } from '@react-spring/web';
import './index.css';
import type { CheckboxProps } from 'antd';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import FormItem from 'antd/es/form/FormItem';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { translations } from '@/i18n/i18n';
import { useAddEmailSupportMutation } from '@/services/api/email-support.api';

const { Text, Title, Paragraph } = Typography;

function Register({ display, isMobile }: any) {
  const { t } = useTranslation();
  const [
    addEmailSupport,
    { isError: isErrorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAdd },
  ] = useAddEmailSupportMutation();

  let schema = yup.object().shape({
    name: yup.string().required('Please enter your name!'),
    email: yup
      .string()
      .required(t(translations.register.errors.emailError))
      .email(t(translations.register.errors.emailInvalid)),
    phone: yup
      .number()
      .required('Please enter your phone number!')
      .typeError('Only accept numbers!')
      .test('isPhoneNumber', 'Phone number must contain 10 digits!', (value: any) => {
        return /^\d{9}$/.test(value);
      }),
    company: yup.string().required('Please enter your company name!'),
    message: yup.string().required('Please enter your message!'),
  });
  const yupSync: any = {
    async validator({ field }: any, value: any) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };

  const [check, setCheck] = useState(false);

  const titleTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 1000,
  });

  const contentTransition = useTransition(display, {
    from: { x: 50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 1000,
  });

  const onChange: CheckboxProps['onChange'] = e => {
    console.log('checked = ', e.target.checked);
    setCheck(e.target.checked);
  };

  const onFinish: FormProps['onFinish'] = values => {
    addEmailSupport(values);
    console.log('Success:', values);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (isSuccessAdd) {
      message.success('Message sent successfully');
      setCheck(true);
    }
    if (isErrorAdd) {
      message.info('Failed to send message');
      setCheck(true);
    }
    if (isLoadingAdd) {
      message.success('Sending message...');
      setCheck(false);
    }
  }, [isSuccessAdd, isErrorAdd, isLoadingAdd]);

  return (
    <div className="SECTION" style={{ margin: !isMobile ? '5% 13% 5% 13%' : '5% 5% 5% 5%' }}>
      <Form colon={false}  initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row justify={!isMobile ? 'space-between' : 'center'}>
          <Col lg={{ span: 8 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            {titleTransition((style, item) => (
              <animated.div style={style}>
                <Image
                  preview={false}
                  style={{ width: '100%', height: 'auto', maxWidth: 247 }}
                  src="/images/home/logo-tran-w-20230921063156-bzd33.png"
                />
                <Title level={3} style={{ width: '100%', textAlign: 'justify', marginTop: 0 }}>
                Tectonic companion 1st digital financial platform in Vietnam
                </Title>
              </animated.div>
            ))}
          </Col>
          <Col lg={{ span: 14 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Row justify={'space-between'}>
              <Col
                lg={{ span: 11 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ marginTop: '1%' }}
              >
                {contentTransition((style, item) => (
                  <animated.div style={style}>
                    <Row>
                      <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                        <Form.Item name={'name'} rules={[yupSync]} hasFeedback>
                          <Input style={{ width: '130%', height: 47 }} placeholder="First and last name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                        <Form.Item name={'email'} rules={[yupSync]} hasFeedback>
                          <Input style={{ width: '130%', height: 47 }} placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                        <Form.Item name={'phone'} rules={[yupSync]} hasFeedback>
                          <Input
                            style={{ width: '130%', height: 47 }}
                            placeholder="Phone"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ span: 20 }} md={{ span: 18 }} sm={{ span: 18 }} xs={{ span: 18 }}>
                        <Form.Item name={'company'} rules={[yupSync]} hasFeedback>
                          <Input style={{ width: '130%', height: 47 }} placeholder="Company" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </animated.div>
                ))}
              </Col>
              <Col
                lg={{ span: 11 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ marginTop: '1%' }}
              >
                {contentTransition((style, item) => (
                  <animated.div style={style}>
                    <Row>
                      <Col lg={{ span: 24 }} md={{ span: 23 }} sm={{ span: 23 }} xs={{ span: 23 }}>
                        <Form.Item name={'message'} rules={[yupSync]} hasFeedback>
                          {/* <Input style={{ width: '100%', height: 250 }} placeholder="Để lại lời nhắn cho chúng tôi" /> */}
                          <Mentions rows={10} placeholder="Send a message" />
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Row>
                                    <Col lg={{ span: 24 }} md={{ span: 23 }} sm={{ span: 23}} xs={{ span: 23}}>
                                        <Button style={{ width: '100%', height: 47 }} type="primary">Đăng ký tư vấn</Button>
                                    </Col>
                                </Row> */}
                  </animated.div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={'start'} align={'middle'} style={{ marginBottom: 20 }}>
          <Col
            lg={{ offset: 10, span: 14 }}
            md={{ span: 23 }}
            sm={{ span: 23 }}
            xs={{ span: 23 }}
            style={{ padding: 10, borderRadius: 5, border: 'solid 1px rgb(217, 217, 217)' }}
          >
            <Checkbox onChange={onChange} checked={check}>
            I agree to the Personal Data Privacy policy
            </Checkbox>
          </Col>
        </Row>
        <Row justify={'start'} align={'middle'} gutter={[0, 24]}>
          <Col
            lg={{ offset: 10, span: 5, order: 1 }}
            md={{ span: 23, order: 1 }}
            sm={{ span: 23, order: 1 }}
            xs={{ span: 23, order: 2 }}
            style={{marginRight:12}}
          >
            <Button
              style={{ width: '100%', height: 47 }}
              type="primary"
              htmlType="submit"
              disabled={!check}
            >
              Sign up for consultation
            </Button>
          </Col>

          <Col
            lg={{ span: 8, order: 2 }}
            md={{ span: 23, order: 2 }}
            sm={{ span: 23, order: 2 }}
            xs={{ span: 23, order: 1 }}
          >

            <Link
              to={
                "https://storage.scf-sit.tradeflat.com/portal-bucket/chinh_sach/chinh_sach.pdf" 
              }
              target="_blank"
              style={{ textDecoration: 'none', margin: 20 }}
            >
              &gt;  Personal Data Privacy policy
            </Link>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Register;
