
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from '@/shared/model/user.model';
import { IQueryParams } from '@/shared/reducers/reducer.utils';
import { axiosSecurity } from '@/config/axios-interceptor';

const initialState = {
  errorMessage: null,
  users: [] as ReadonlyArray<User>,
};

const apiUrl = 'api/users';

// Async Actions

export const getUsers = createAsyncThunk('userManagement/fetch_users', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axiosSecurity.get<User[]>(requestUrl);
});

export type UserManagementState = Readonly<typeof initialState>;

export const UserManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState as UserManagementState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, state => state)
      .addCase(getUsers.rejected, (state, action) => {
        // state.errorMessage = action.error.message; //HANT comment
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      });
  },
});

export const { reset } = UserManagementSlice.actions;

// Reducer
export default UserManagementSlice.reducer;
