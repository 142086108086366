interface CertInfo {
    CommonName?: string | null;
    SerialNumber?: string | null;
    Issuer?: string | null;
    NotBefore?: string | null;
    NotAfter?: string | null;
    SubjectDN?: string | null;
    Base64Encode?: string | null;
    Thumbprint?: string | null;
}

export interface DigitalSignature {
    ResponseCode?: number | null;
    ResponseMessage?: string | null;
    certInfo?: CertInfo | null;
    Signature?: string | null;
}

export const defaultValue: Readonly<DigitalSignature> = {};