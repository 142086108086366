import { Company } from "./company.model";
import { Department } from "./departmen.model";
import dayjs from 'dayjs';

export interface UserCompany {
  id?: string | null;
  company?: Company | null;
  version?: number | null;
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  enabled?: boolean | null;
  phoneNumber?: string | null;
  avatar?: string | null;
  status?: string | null;
  fullName?: string | null;
  position?: string | null;
  department?: Department | null;
  docType?: string | null;
  identificationNum?: string | null;
  dateRange?: string | null | dayjs.Dayjs;
  roleAssignmentText?: string | null;
  issuedBy?: string | null;
  createdDate?: string | null | dayjs.Dayjs;
  createdBy?: string | null;
}
export const defaultValue: Readonly<UserCompany> = {id:""};