import { Header } from "antd/es/layout/layout";
import { Button, Image, Tooltip, Typography } from 'antd'
import React from "react";
import { Link } from "react-router-dom";
import SettingMenu from "../../menu/setting";
import LocaleMenu from "../../menu/locale";
import { MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@/config/store";
import { setSiderCollapsed } from "../../layout.reducer";
import './index.css'
import { ConfigProvider } from 'antd';
import theme from '../../../theme/themeConfig'
import { useGetCurrentUserCompanyQuery } from "@/services/api/user-info.api";

const { Text, Paragraph } = Typography

export default function HeaderApp() {
    const { data: useInfo, isLoading: isLoadGet, isError: isErrorGet, isSuccess: isSuccessGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()

    const siderCollapsed = useAppSelector(store => store.layoutapp.siderCollapsed)
    const dispatch = useAppDispatch()

    const changeSiderCollapsed = () => {
        dispatch(setSiderCollapsed(!siderCollapsed));
    }
    return (<>
        <ConfigProvider theme={theme} >
            <Header style={{ background: 'rgb(47, 87, 250)', padding: 0, paddingRight: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Button
                            type="text"
                            icon={siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={changeSiderCollapsed}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                                color: 'white'
                            }}
                        />

                        <Link key={'gohome'} to={'/home/common'} style={{ display: "flex", justifyContent: 'center', alignContent: 'center', width: 62 }}>
                            <Button
                                type="text"
                                icon={<HomeOutlined />}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                    color: 'white'
                                }} />
                        </Link>
                    </div>

                    <div className='customMenu' style={{ display: 'flex' }}>
                        <div className='settingMenu'>
                            <SettingMenu />
                        </div>
                        <div style={{ margin: "auto" }}>
                            <Text style={{ margin: 0, color: 'rgb(253, 199, 87)', position: 'absolute', marginTop: '1px', fontSize: 12 }}>Xin chào,</Text>
                            <Tooltip placement="bottom" title={useInfo?.fullName ? useInfo?.fullName : useInfo?.email}>
                                <Paragraph style={{ margin: 0, color: '#FDC757', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '140px', marginTop: 14 }}>{useInfo?.fullName ? useInfo?.fullName : useInfo?.email}</Paragraph>
                            </Tooltip>
                        </div>

                        {/* <div className='localeMenu'>
                            <LocaleMenu />
                        </div> */}
                    </div>

                </div>
            </Header>
        </ConfigProvider>

    </>)
}