import { useAppSelector } from "@/config/store";
import { Company } from "@/shared/model/company.model";
import { Col, Divider, Form, Input, Radio, Row, Space, Typography, Image, Card } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { fileApi } from "@/services/api/file.api";
import { CorporatePositionEnum } from "@/shared/model/enums/enums";

const { Title, Text, Link } = Typography;
const getDefaultImage = () => {
    return '/images/default_image.png';
};

const formatDate = (dateString: string): string => {
    if (!dateString) {
        return dateString;
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
        return dateString;
    }

    const [year, month, day] = dateString.split('-');
    console.log(year, month, day);
    return `${day}/${month}/${year}`;
};

export default function CompanyChiefAccountant() {
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const { chiefAccountant = null } = companyEntity || {}
    const [form] = Form.useForm();
    const [downloadChiefAccountantFrontImage, { data: frontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [downloadChiefAccountantBackImage, { data: backImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [downloadChiefAccountantFaceImage, { data: faceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery();

    let schema = yup.object().shape({
        chiefAccountant: yup.object().shape({
            name: yup.string(),
            cardIssueDate: yup.date(),
            cardFrontImage: yup.string(),
            cardBackImage: yup.string(),
            faceImage: yup.string(),
        }),
    });
    const yupSync = yupValidator(schema, form.getFieldsValue);

    interface FieldData {
        name: string | number | (string | number)[];
        value?: any;
        touched?: boolean;
        validating?: boolean;
        errors?: string[];
    }
    useEffect(() => {
        console.log("HANT  useEffect downloadChiefAccountantFrontImage: ", chiefAccountant)
        if (chiefAccountant?.cardFrontImage)
            downloadChiefAccountantFrontImage({ fileId: chiefAccountant?.cardFrontImage, objectType: 'employee' })
    }, [chiefAccountant?.cardFrontImage])
    useEffect(() => {
        if (chiefAccountant?.cardBackImage)
            downloadChiefAccountantBackImage({ fileId: chiefAccountant?.cardBackImage, objectType: 'employee' })
    }, [chiefAccountant?.cardBackImage])
    useEffect(() => {
        if (chiefAccountant?.faceImage)
            downloadChiefAccountantFaceImage({ fileId: chiefAccountant?.faceImage, objectType: 'employee' })
    }, [chiefAccountant?.faceImage])

    useEffect(() => {
        let entity;
        entity = {
            ...companyEntity,
            chiefAccountant: {
                ...chiefAccountant,
                cardIssueDate: formatDate(chiefAccountant?.cardIssueDate ?? ""),
                birthday: formatDate(chiefAccountant?.birthday ?? "")
            }
        }

        form.setFieldsValue(entity)
        form.setFieldValue(['chiefAccountant', 'position'], companyEntity.chiefAccountant?.position === CorporatePositionEnum.ACCOUNTANT ? "Kế toán trường" : "")
    }, [])

    return (
        <Content>
            <Card type='inner' title='Thông tin kế toán trưởng' style={{ margin: 20 }}>
                <Row>
                    <Space >
                        <Text>Loại giấy tờ</Text>
                        <Divider orientation="right" />
                        <Radio.Group value={chiefAccountant?.docType}>
                            <Radio value={"CMND"}>CMND/CCCD/CCCD gắn chíp</Radio>
                            <Radio value={"HC"}>Hộ chiếu</Radio>
                        </Radio.Group>
                    </Space>
                </Row>
                <Form colon={false}
                    form={form}
                >
                    <Row style={{ margin: '24px 0px' }} gutter={[16, 16]}>
                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <div
                                style={{
                                    padding: '12px',
                                    border: '1px solid #f0f0f0',
                                    borderRadius: '8px 8px 8px 8px',
                                }}
                            >
                                <Form.Item
                                    name={['chiefAccountant', 'cardFrontImage']}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}

                                >
                                    <div style={{ width: '23vw', minWidth: 200, minHeight: 90, height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Image
                                            style={{ width: '100%', maxHeight: '18vw' }}
                                            preview={false}
                                            src={
                                                frontImageFile || getDefaultImage()
                                            }
                                            fallback={getDefaultImage()}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                        </Col>

                        {
                            (chiefAccountant?.docType?.toString() !== "HC") && (
                                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                                    <div style={{
                                        padding: '12px',
                                        border: '1px solid #f0f0f0',
                                        borderRadius: '8px 8px 8px 8px',
                                    }}>
                                        <Form.Item
                                            name={['chiefAccountant', 'cardBackImage']}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}

                                        >
                                            <div style={{ width: '23vw', height: '18vw', minWidth: 200, minHeight: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Image
                                                    style={{ width: '100%', maxHeight: '18vw' }}
                                                    preview={false}
                                                    src={
                                                        backImageFile || getDefaultImage()
                                                    }
                                                    fallback={getDefaultImage()}
                                                />
                                            </div>
                                        </Form.Item>
                                    </div>
                                </Col>
                            )
                        }

                        <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                            <div style={{
                                padding: '12px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '8px 8px 8px 8px',
                            }}>
                                <Form.Item
                                    name={['chiefAccountant', 'faceImage']}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}

                                >
                                    <div style={{ width: '23vw', height: '18vw', minWidth: 200, minHeight: 90, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Image
                                            style={{ width: '100%', maxHeight: '18vw' }}
                                            preview={false}
                                            src={
                                                faceImageFile || getDefaultImage()
                                            }
                                            fallback={getDefaultImage()}
                                        />
                                    </div>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Card type='inner' title='Xác thực thông tin kế toán trưởng' style={{ margin: 20 }}>
                <Row>
                    <Col
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        <Form colon={false}
                            form={form}
                            labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                            wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                            layout="horizontal"
                            disabled={true}

                        >
                            <Form.Item
                                name={['chiefAccountant', 'name']}
                                // name={'name'}
                                label="Họ và tên"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                            <Form.Item
                                name={['chiefAccountant', 'cardID']}
                                // name={'name'}
                                label="Số giấy tờ định danh"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                name={['chiefAccountant', 'cardIssueDate']}
                                label="Ngày cấp"
                                labelAlign="left"

                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                name={['chiefAccountant', 'cardIssueLocation']}
                                // name={'name'}
                                label="Nơi cấp"
                                labelAlign="left"

                                rules={[yupSync]}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col
                        xl={{ span: 12 }}
                        lg={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 24 }}
                    >
                        <Form colon={false}
                            form={form}
                            labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                            wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                            layout="horizontal"
                            disabled={true}
                        >
                            <Form.Item
                                name={['chiefAccountant', 'birthday']}
                                label="Ngày sinh"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>

                            <Form.Item
                                label="Chức vụ"
                                labelAlign="left"
                                style={{ width: '100%' }}
                                name={['chiefAccountant', 'position']}
                            >
                                <Input disabled style={{ height: 40 }} defaultValue={"Kế toán trưởng"} />
                            </Form.Item>

                            <Form.Item
                                name={['chiefAccountant', 'phoneNumber']}
                                label="Số điện thoại"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} maxLength={10} />
                            </Form.Item>

                            <Form.Item
                                name={['chiefAccountant', 'email']}
                                label="Email"
                                labelAlign="left"
                                rules={[yupSync]}
                                style={{ width: '100%' }}
                            >
                                <Input style={{ height: 40 }} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Content>
    )
}