
import { Storage } from '@/shared/utils/storage-util';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppThunk } from '@/config/store';
import { setLocale } from '@/shared/reducers/locale';
import { serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { RoleAssignment } from '../model/role-assignment.model';
import { axiosSecurity } from '@/config/axios-interceptor';
import Cookies from 'js-cookie';
import { UserCompany } from '../model/user-company.model';
import { notification } from 'antd';
export const initialState = {
  loading: false,
  isAuthenticated: false,
  account: {} as any,
  permissions:{} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
  access_token: null as unknown as string,
  userName: null as unknown as string,
};

export type AuthenticationState = Readonly<typeof initialState>;

// Actions
//Jmix authentication
export const getUserPermissions = createAsyncThunk(
  'onboarding/get_user_roles',
  async (thunkAPI) => {
    try{
      const roles = await axiosSecurity.get<RoleAssignment[]>(`/rest/portal/userInfo/permissions`);
      return roles;
    }
    catch (error: any) {
      if (error.response && error.response.status === 500) {
        // notification.error({ message: 'Lỗi', description: 'Người dùng không có quyền truy cập' });
      } else {
        // notification.error({ message: 'Error', description: error.message });
      }
      throw error;
    }
  },
  { serializeError: serializeAxiosError },
);
export const getCurrentUser = createAsyncThunk(
  'onboarding/get_current_user',
  async (thunkAPI) => {
    try{
      const userDetail = await axiosSecurity.get<UserCompany>(`/rest/portal/company-user/get-current-user`);
      return userDetail;
    }
    catch (error: any) {
      if (error.response && error.response.status === 500) {
        // notification.error({ message: 'Lỗi không thể lấy dữ liệu người dùng', description: 'Xin hãy liên hệ với nhà quản trị' });
      } else {
        // notification.error({ message: 'Error', description: error.message });
      }
      throw error;
    }
  },
  { serializeError: serializeAxiosError },
);
//**************************/
export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());

  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};

export const getAccount = createAsyncThunk('authentication/get_account', async () => axiosSecurity.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

export const logoutServer = createAsyncThunk('authentication/logout', async () => axiosSecurity.post<any>('api/logout', {}), {
  serializeError: serializeAxiosError,
});

export const logout: () => AppThunk = () => async dispatch => {
  await dispatch(logoutServer());
  // fetch new csrf token
  dispatch(getSession());
};

export const clearAuthentication = (messageKey:any) => (dispatch:any) => {
  // sessionStorage.clear();
  // localStorage.clear();
  // Cookies.remove('JSESSIONID');
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    loginSuccess(state,action:any){
      return{
        ...state,
        isAuthenticated:true,
        access_token: action.payload.access_token,
        userName: action.payload.profile?.preferred_username
      }
    },
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        // ...state,
        // loading: false,
        // isAuthenticated: false,
        ...initialState
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccount.rejected, (state, action:any) => {
        return{
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message,
      };
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: {...action.payload.data},
        };
      })
      .addCase(getUserPermissions.fulfilled,(state,action)=>{
          return{
            ...state,
            permissions: action.payload.data,
          }
      })
      .addCase(getCurrentUser.fulfilled,(state,action)=>{
        return{
          ...state,
          account: action.payload.data,
        }
    })
      .addCase(getUserPermissions.rejected,(state,action)=>{
        return{
          ...state,
          permissions: {},
        }
    })
      .addCase(logoutServer.fulfilled, (state, action) => ({
        ...initialState,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { loginSuccess,authError, clearAuth } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
