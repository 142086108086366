import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import ErrorBoundary from '@/shared/error/error-boundary';
import HomeLayout from '.';
import { ConfigProvider } from 'antd';
import theme from '../../theme/themeConfig'

export default function HomeLayoutRoutes({ children, ...rest }: PathRouteProps) {
    return (
        <ErrorBoundary>
            <ConfigProvider theme={theme} >
                <HomeLayout>
                    {children}
                </HomeLayout>
            </ConfigProvider>
        </ErrorBoundary>
    );
};
