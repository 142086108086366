import { useAppSelector } from '@/config/store';
import { translations } from '@/i18n/i18n';
import { Company } from '@/shared/model/company.model';
import { Button, Form, Input, Typography, Card, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { extractName } from '@/shared/utils/jmix-rest/file';
import { fileApi } from '@/services/api/file.api';

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  console.log(year, month, day);
  return `${day}/${month}/${year}`;
};


const { Text, Title } = Typography;
export default function CompanyCommon() {
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [openModalReviewFile, setOpenModalReviewFile] = useState(false)
  const [downloadfile, { data: pdfFlie}] = fileApi.endpoints.downloadFile.useLazyQuery();

  const formatCurrency = (amount: any) => {
    return amount.toLocaleString('vi-VN');
  };

  useEffect(() => {
    console.log(companyEntity)
  }, [])

  useEffect(() => {
    if (companyEntity) {
      const numericValue = parseFloat(companyEntity.charterCapital || "0");
      const formattedValue = formatCurrency(numericValue);
      form.setFieldsValue({
        ...companyEntity,
        charterCapital: formattedValue,
        dateRegistration: formatDate(companyEntity.dateRegistration || ""),
        legalRepresentative: {
          ...companyEntity.legalRepresentative,
          cardIssueDate: formatDate(companyEntity?.legalRepresentative?.cardIssueDate || ""),
        }
      });
      console.log('HANT : companyEntity is change', companyEntity);

    }
  }, [companyEntity]);

  return (
    <Card type="inner" title="Thông tin chung" style={{ margin: 20 }}
    // style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Form
        form={form}
        labelCol={{ lg: { span: 6 }, md: { span: 9 }, sm: { span: 24 }, xs: { span: 24 } }}
        wrapperCol={{ lg: { span: 10 }, md: { span: 15 }, sm: { span: 20 }, xs: { span: 24 } }}
        layout="horizontal"
        disabled={true}
        initialValues={companyEntity}
        colon={false}
        requiredMark={false}
      >
        <Form.Item label={t(translations.company.typeOfDocument)} labelAlign="left">
          <Input defaultValue={'Giấy chứng nhận đăng ký doanh nghiệp'} disabled style={{ padding: '10px', width: "100%" }} />
        </Form.Item>
        <Form.Item label={"Giấy tờ đã tải lên"} labelAlign="left">
          <Button disabled={false} type='link' style={{ padding: 0 }} onClick={async () => {
            await downloadfile({fileId: companyEntity.businessRegistration?.file || '', objectType: 'businessRegistration'})
            setOpenModalReviewFile(true)
          }}>
            {extractName(companyEntity?.businessRegistration?.file || "")}
          </Button>
        </Form.Item>
        <Form.Item name="code" label={t(translations.company.code)} labelAlign="left">
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="name"
          label={
            <span>
              {t(translations.company.name)}
            </span>
          }
          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm tên doanh nghiệp!' }]}
        >
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 10 }} />
        </Form.Item>

        <Form.Item
          name={'registrationType'}
          label={
            <span>
              {t(translations.company.registrationType)}
            </span>
          }
          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm loại hình doanh nghiệp!' }]}
        >
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name={['mainAddress', 'address']}
          label=
          {
            <span>
              {t(translations.company.mainAddress)}
            </span>
          }

          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm địa chỉ doanh nghiệp!' }]}
        >
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 10 }} />
        </Form.Item>

        <Form.Item
          name={'phoneNumber'}
          label=
          {
            <span>
              {t(translations.company.phoneNumber)}
            </span>
          }
          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm số điện thoại!' }]}
        >
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>



        {/* <Form.Item
          label={t(translations.company.uploadDocument)}
          name="upload"
          valuePropName="fileList"
          // getValueFromEvent={normFile}
          labelAlign="left"
        >
          <Input style={{ border: 'none', background: '#fff', padding:10 }}/>
        </Form.Item> */}


        <Form.Item name={'fax'} label={t(translations.company.fax)} labelAlign="left">
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>

        <Form.Item name={'email'} label={t(translations.company.email)} labelAlign="left">
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>

        <Form.Item name={'website'} label={t(translations.company.website)} labelAlign="left">
          <Input.TextArea style={{ padding: '10px', width: "100%" }} autoSize={{ minRows: 1, maxRows: 6 }} />
        </Form.Item>

        <Form.Item
          name={'charterCapital'}
          label=
          {
            <span>
              {t(translations.company.charterCapital)}
            </span>
          }
          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm vốn điều lệ!' }]}
        >
          <Input style={{ padding: '10px', width: "100%" }} suffix="VND" />
        </Form.Item>

        <Form.Item
          name={'numberOfChangesRegistration'}
          label={
            <span>
              {t(translations.company.numberOfChangesRegistration)}
            </span>
          }
          labelAlign="left"
          rules={[{ required: true }]}
        >
          <Input defaultValue={1} style={{ padding: '10px', width: '50%' }} />
        </Form.Item>

        <Form.Item
          name={'dateRegistration'}
          label={
            <span>
              {t(translations.company.dateChangeRegistration)}
            </span>

          }
          labelAlign="left"
          rules={[{ required: true, message: 'Vui lòng thêm ngày tháng!' }]}
          style={{ width: '100%' }}
        >
          <Input style={{ padding: '10px', width: '50%' }} />
        </Form.Item>

        <Form.Item name={["legalRepresentative", "name"]} label={"Người đại diện"} labelAlign="left">
          <Input style={{ height: 40 }} />
        </Form.Item>

        <Form.Item name={["legalRepresentative", "cardID"]} label={"Số giấy tờ định danh"} labelAlign="left">
          <Input style={{ height: 40 }} />
        </Form.Item>

        <Form.Item name={["legalRepresentative", "cardIssueLocation"]} label={"Nơi cấp"} labelAlign="left">
          <Input style={{ height: 40 }} />
        </Form.Item>

        <Form.Item name={["legalRepresentative", "cardIssueDate"]} label={"Ngày cấp"} labelAlign="left"
        >
          <Input style={{ height: 40 }} />
        </Form.Item>
      </Form>

      <Modal
        open={openModalReviewFile}
        onOk={() => setOpenModalReviewFile(false)}
        onCancel={() => setOpenModalReviewFile(false)}
        title={"Xem chi tiết"}
        footer={(_, { OkBtn }) => (
            <Button onClick={() => setOpenModalReviewFile(false)}>Đóng</Button>
        )}
        width={'50vw'}
        centered={true}
      >
        {pdfFlie && (
          <iframe
            src={pdfFlie}
            width="100%"
            height="700px"
            title="PDF Viewer"
          />
        )}
      </Modal>
    </Card>
  );
}
