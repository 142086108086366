import React, { useState, useEffect } from 'react';
import { getEntities } from '../bank.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { EntitiesLoadOptions } from '@/shared/reducers/reducer.utils';
import { Bank } from '@/shared/model/bank.model';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';


const columns: ColumnsType<Bank> = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    width: '20%',
  },
  {
    title: 'SwiftCode',
    dataIndex: 'swiftCode',
    width: '20%',
  },
  {
    title: 'id',
    dataIndex: 'id',
  },
];


export default function BankList() {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const banktList = useAppSelector(state => state.bank.entities);
  const loading = useAppSelector(state => state.bank.loading);
  const totalItems = useAppSelector(state => state.bank.totalItems);

  // {
  //     page: paginationState.activePage - 1,
  //     size: paginationState.itemsPerPage,
  //     sort: `${paginationState.sort},${paginationState.order}`,
  //   }
  const loadOptions: EntitiesLoadOptions = {
    offset: 0,
    limit: 20,
  }
  const getAllEntities = () => {
    dispatch(
      getEntities(loadOptions),
    );
  };
  useEffect(() => {
    getAllEntities();
  }, [])

  return (<>
    <Table
      columns={columns}
      rowKey={(record) => record.id as string}
      dataSource={banktList}
      //   pagination={tableParams.pagination}
      loading={loading}
      scroll={{ x: 'calc(300px + 50%)'}}
    // onChange={handleTableChange}
    />
  </>)
}