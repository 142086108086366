import { UserCompany } from '@/shared/model/user-company.model';
import { baseAuthAPI } from './base-auth.api'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { CompanyBankAccount } from '@/shared/model/company-bank-account.model';
import { Company } from '@/shared/model/company.model';


export const companyBankAccountApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getCompany: build.query<{company: Company[]}, Object>({
            query : (params: any) => {
                const loadOptions: SearchEntitiesRequestModel = {
                    filter: {
                        conditions: []
                    },
                    offset: params?.pagination?.current > 0 ? (params?.pagination?.current - 1) * params?.pagination?.pageSize : 0,
                    limit: params?.pagination?.pageSize,
                    returnCount: true,
                }
                return ({
                    url: '/rest/portal/company-bank-account/search',
                    method: 'POST',
                    body: loadOptions,
                })
            }
        }),

        getCompanyBankAccount: build.query<CompanyBankAccount, Object>({
            query(id) {
                return{
                    url: `/rest/portal/company-bank-account/${id}?fetchPlan=companyBankAccount-fetch-plan`,
                    method: 'GET',
                }
            }
            
        }),

        getCompanyBankAccounts: build.query<CompanyBankAccount[], any>({
            query: (params:SearchEntitiesRequestModel) => {
                return({
                    url: '/rest/portal/company-bank-account/search',
                    method: 'POST',
                    body: params
                })
            }
        }),


        addCompanyBankAccounts: build.mutation<CompanyBankAccount, Partial<CompanyBankAccount>>({
            query: (body) => ({
                url: `/rest/portal/company-bank-account`,
                method: 'POST',
                body,
            }),
        }),
        updateCompanyBankAccount: build.mutation<CompanyBankAccount, Partial<CompanyBankAccount>>({
            query(data) {
              const { id, ...body } = data
              return {
                url: `/rest/portal/company-bank-account/${id}`,
                method: 'PUT',
                body,
              }
            },
          }),

        deleteCompanyBankAccount: build.mutation<{ id: string }, string>({
            query(id) {
                return{
                    url: `/rest/portal/company-bank-account/${id}`,
                    method: 'DELETE',
                }
            }
        })

    })
})

export const {
    useGetCompanyBankAccountQuery,
    useGetCompanyBankAccountsQuery,
    useAddCompanyBankAccountsMutation,
    useUpdateCompanyBankAccountMutation,
    useDeleteCompanyBankAccountMutation,
  } = companyBankAccountApi

export const {
endpoints: { getCompanyBankAccount, getCompanyBankAccounts, addCompanyBankAccounts, updateCompanyBankAccount, deleteCompanyBankAccount },
} = companyBankAccountApi