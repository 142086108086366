import React from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import './index.css';
import { useTransition, animated, config } from '@react-spring/web';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';

const { Text, Link, Title, Paragraph } = Typography;

function FintechDigitalization({ display }: any) {
  const isMobile = useIsMobile();
  const imageTransition = useTransition(display, {
    from: { x: 50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  const textTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  return (
    <div
      className="SECTION"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: !isMobile ? '3% 13% 3% 13%' : '3% 5% 3% 5%',
      }}
    >
      <Row gutter={[48, 48]} align={'bottom'}>
        <Col lg={12}>
          <Row>
            <Col lg={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
              {textTransition((style, item) => (
                <animated.div style={style}>
                  <Title level={3} style={{ marginTop: '10%' }}>
                    Digitize end-to-end L/C process for Corporates
                  </Title>
                </animated.div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
              {imageTransition((style, item) => (
                <animated.div style={style}>
                  <Image
                    preview={false}
                    style={{ width: '100%', height: 'auto', borderRadius: '0 100px 0 0' }}
                    src="/images/home/LC/mo-hinh-etradevn-15-20230704090338-h_kn-.png"
                  />
                </animated.div>
              ))}
            </Col>
          </Row>
        </Col>

        <Col lg={{ span: 6 }}>
          <Row>
            {imageTransition((style, item) => (
              <animated.div style={style}>
                <Col
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 18, offset: 2 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Image
                    preview={false}
                    style={{ width: 44, height: 44 }}
                    src="/images/home/LC/lc-10-20221018162253-em9l8.png"
                  />
                </Col>

                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Title level={4} style={{ marginBottom: '1%' }}>
                    Rapidity
                  </Title>
                </Col>

                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Paragraph
                    // ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
                    style={{
                      lineHeight: 1.6,
                      textAlign: 'justify',
                      display: 'block',
                      fontWeight: 400,
                    }}
                  >
                    Down 90% transaction prcess compared with traditional workflow. Up-to-date and instant transactions among stakeholders
                  </Paragraph>
                </Col>
              </animated.div>
            ))}
          </Row>

          <Row>
            {imageTransition((style, item) => (
              <animated.div style={style}>
                <Col
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 18, offset: 2 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Image
                    preview={false}
                    style={{ width: 44, height: 44, marginTop: '65%' }}
                    src="/images/home/LC/lc-09-20221018162253-z4ekj.png"
                  />
                </Col>
                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Title level={4} style={{ marginBottom: '1%' }}>
                  Safety, Security
                  </Title>
                </Col>
                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Paragraph
                    ellipsis={{ rows: 4, expandable: true, symbol: 'more' }}
                    style={{
                      lineHeight: 1.6,
                      textAlign: 'justify',
                      display: 'block',
                      fontWeight: 400,
                    }}
                  >
TradeFlat was developed on the basis of applying advanced technology solutions, while ensuring the safety and security of the system: Blockchain, FPT CA, AI, OCR...
                  </Paragraph>
                </Col>
              </animated.div>
            ))}
          </Row>
        </Col>

        <Col lg={{ span: 6, offset: 0 }} md={{ span: 24, offset: 0 }}>
          <Row>
            <Col lg={{ span: 24 }} md={{ span: 0 }} sm={{ span: 0 }} xs={{ span: 0 }}>
              <h1 style={{ visibility: 'hidden' }}>a</h1>
            </Col>
          </Row>
          <Row>
            {imageTransition((style, item) => (
              <animated.div style={style}>
                <Col
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 18, offset: 2 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Image
                    preview={false}
                    style={{ width: 44, height: 44 }}
                    src="/images/home/LC/lc-11-20221018162253-qxyc9.png"
                  />
                </Col>
                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Title level={4} style={{ marginBottom: '1%' }}>
                  Saving
                  </Title>
                </Col>

                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Paragraph
                    // ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
                    style={{
                      lineHeight: 1.6,
                      textAlign: 'justify',
                      display: 'block',
                      fontWeight: 400,
                    }}
                  >
                    Cost saving of courier, hard copy stationary and personnel related to mannual operations
                  </Paragraph>
                </Col>
              </animated.div>
            ))}
          </Row>
          <Row>
            {imageTransition((style, item) => (
              <animated.div style={style}>
                <Col
                  lg={{ span: 24, offset: 0 }}
                  md={{ span: 18, offset: 2 }}
                  sm={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <Image
                    preview={false}
                    style={{ width: 44, height: 44, marginTop: '65%' }}
                    src="/images/home/LC/lc-12-20221018162253-hjr4l.png"
                  />
                </Col>
                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Title level={4} style={{ marginBottom: '1%' }}>
                  Centralization
                  </Title>
                </Col>
                <Col lg={{ span: 24, offset: 0 }} md={{ span: 18, offset: 2 }}>
                  <Paragraph
                    // ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}
                    style={{
                      lineHeight: 1.6,
                      textAlign: 'justify',
                      display: 'block',
                      fontWeight: 400,
                    }}
                  >
                    Connect sellers and buyers, corporates and banks. Transparent update information and documents among stakeholders
                  </Paragraph>
                </Col>
              </animated.div>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default FintechDigitalization;
