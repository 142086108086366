import { useAppSelector } from '@/config/store';
import { Col, Result, Row, Space } from 'antd';
import './../index.css';
import { InfoCircleFilled } from '@ant-design/icons';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';

export default function RegistrationSuccess() {
  const registrationEntity = useAppSelector(state => state.registration.entity);
  const { t } = useTranslation();
  console.log('registrationEntity:: --', registrationEntity);

  const hideEmail = (email: string | undefined) => {
    console.log('email:::', email);
    // Lấy phần đầu và cuối của email
    if (email) {
      const emailSplit = email.split('@');
      const prefix = emailSplit[0];
      const domain = emailSplit[1];
      let hiddenPrefix = '***';
      // Ẩn một số ký tự trong phần đầu của email
      if (prefix.length >= 2) {
        hiddenPrefix = prefix.substring(0, 2) + '*'.repeat(prefix.length - 1);
      }
      // Kết hợp phần ẩn và phần domain của email
      const hiddenEmail = `${hiddenPrefix}@${domain}`;

      return hiddenEmail;
    } else {
      return '***';
    }
  };

  return (
    <Row className="success">
      <Col
        className="SECTION-LEFT"
        lg={{ span: 7, order: 1 }}
        md={{ span: 24, order: 2 }}
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
      >
        {/* <Space direction="vertical" align="center" style={{ width: '28vw', fontSize: '18px' }}>
          <img src="../images/TradeFlat_logo_01.png" style={{ marginBottom: '36px' }} />
          <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg>          <span>
          Cảm ơn Quý khách đã đăng ký tài khoản đăng nhập Hệ sinh thái tài chính số TradeFlat.</span>
          <span>
          Hệ thống sẽ gửi thông báo xác nhận kích hoạt tài khoản tới địa chỉ email Quý khách đã đăng ký với hệ thống.          
          </span>
        </Space> */}
        <img src="../images/TradeFlat_logo_01.png" style={{}} />

        <Result
          status="success"
          title="Cảm ơn Quý khách đã đăng ký tài khoản đăng nhập Hệ sinh thái tài chính số TradeFlat!"
          subTitle="Hệ thống sẽ gửi thông báo xác nhận kích hoạt tài khoản tới địa chỉ email Quý khách đã đăng ký với hệ thống."
        >
        </Result>

      </Col>
      <Col
        lg={{ span: 12, order: 2 }}
        md={{ span: 24, order: 1 }}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
      >
        <img
          className="SECTION-RIGHT"
          src={'../images/registration.jpg'}
          alt="Registration Image"
        />
      </Col>
    </Row>
  );
}
