import { BankTypeEnum, StatusEnum } from "./enums/enums";

export interface Bank {
    id?: string;
    instanceName?: string | null;
    emailReceiveFinanceAlert?: string | null;
    representatives?: string | null;
    type?: BankTypeEnum | null;
    businessRegistration?: string | null;
    isUsingCreditAssessment?: boolean | null;
    status?: StatusEnum | null;
    name?: string | null;
    nameEn?: string | null;
    address?: string | null;
    shortName?: string | null;
    code?: string | null;
    swiftCode?: string | null;
    phoneNumber?: string | null;
    scfContract?: string | null;
    scfFacilityContract?: string | null;
    fax?: string | null;
    email?: string | null;
    state?: boolean | null;
    describe?: string | null;
    isUsingAPI?: boolean | null;
    version?: number | null;
    createdBy?: string | null;
    createdDate?: string | null;
    lastModifiedBy?: string | null;
    lastModifiedDate?: string | null;
    deletedBy?: string | null;
    deletedDate?: string | null;
  }

  export const defaultValue: Readonly<Bank> = {};
