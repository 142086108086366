import { Avatar, Menu } from 'antd';
import { MenuOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { clearAuthentication } from '@/shared/reducers/authentication.reducer';
import LocaleMenu from './locale';
import { hasAnyAuthority } from '@/shared/utils/jmix-rest/security';
import { AUTHORITIES } from '@/config/constants';
import { useTranslation } from 'react-i18next';
import { translations } from '@/i18n/i18n';
import './setting.css';

export default function SettingMenu() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const permissions = useAppSelector(state => state.authentication.permissions);
  const userName = useAppSelector(state => state.authentication.userName);

  return (
    <Menu
      mode="horizontal"
      style={{
        height: '4rem',
        position: 'sticky',
        top: '0px',
        zIndex: 999,
        width: '100%',
        background: 'rgb(47, 87, 250)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Menu.SubMenu
        title={
          <Avatar size={'small'} style={{ backgroundColor: '#fdc757', color: '#2f57fa' }} icon={<UserOutlined />} />
        }
      >
        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                    <Menu.Item key={'Onboarding'} className='MenuItem' >
                        <Link
                            key={'admin/onboarding'}
                            to={'/admin/onboarding'} >
                            <span >{t(translations.header.settingMenu.onboarding)}</span>
                        </Link>
                    </Menu.Item>
                )} */}
        {/* {!!isAuthenticated && (
                    <Menu.Item key="userinfo">
                        <Link
                            key={'admin/userinfo'}
                            to={'/user/userinfo'} >
                            <span >{t(translations.header.settingMenu.userInfo)} {userName}</span>
                        </Link>
                    </Menu.Item>
                )} */}
        {!isAuthenticated && (
          <Menu.Item key="signin" style={{ display: 'flex', justifyContent: 'start' }} onClick={() => auth.signinRedirect()}>
            <span style={{ fontSize: '16px' }}>
              {t(translations.header.settingMenu.login)}
            </span>
          </Menu.Item>
        )}
        {!!isAuthenticated &&
          hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
            <Menu.Item key="admin/*" style={{ display: 'flex', justifyContent: 'start' }}>
              <Link key={'/admin'} to={'/admin'}>
                <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.administration)}</span>
              </Link>
            </Menu.Item>
          )}
        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                    <Menu.Item key="entities/bank">
                        <Link
                            key={'entities/bank'}
                            to={'/entities/bank'} >
                            <span style={{ fontSize: '16px' }}>Ngân hàng</span>
                        </Link>
                    </Menu.Item>
                )} */}
        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                    <Menu.Item key="admin/usercompany">
                        <Link
                            key={'admin/usercompany'}
                            to={'/admin/usercompany'} >
                            <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.userCompanyManagement)}</span>
                        </Link>
                    </Menu.Item>
                )} */}
        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                    <Menu.Item key="admin/usermanage">
                        <Link
                            key={'admin/usermanage'}
                            to={'/admin/usermanage'} >
                            <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.userManagement)}</span>
                        </Link>
                    </Menu.Item>
                )} */}
        {/* {!!isAuthenticated && hasAnyAuthority(permissions.authorities, [AUTHORITIES.COMPANY_ADMIN]) && (
                    <Menu.Item key="admin/bankaccount">
                        <Link
                            key={'admin/bankaccount'}
                            to={'/admin/bankaccount'} >
                            <span style={{ fontSize: '16px' }}>{t(translations.header.settingMenu.bankAccount)}</span>
                        </Link>
                    </Menu.Item>
                )} */}

        {!isAuthenticated && (
          <Menu.Item key="signup">
            <Link
              key={'registration'}
              to={'/registration'}
              style={{ display: 'flex', justifyContent: 'start' }}
            >
              <span style={{ fontSize: '16px' }}>
                {t(translations.header.settingMenu.registration)}
              </span>
            </Link>
          </Menu.Item>
        )}

        {isAuthenticated && (
          <Menu.Item
            key="signin"
            onClick={() => {
              dispatch(clearAuthentication('Log out TradeFlat'));
              auth.signoutSilent();
            }}
            style={{ display: 'flex', justifyContent: 'start' }}
          >
            <span style={{ fontSize: '16px' }}>
              {t(translations.header.settingMenu.logout)}
            </span>
          </Menu.Item>
        )}
      </Menu.SubMenu>
    </Menu>
  );
}
