import React, { useEffect, useState } from "react";
import { Steps, Spin } from 'antd';
import OnboardingCommon from './component/common';
import OnboardingRegistration from './component/digital-sign-registration';
import OnboardingRepresentative from './component/representative';
import OnboardingChiefAccountant from './component/chief-accountant';
import { useAppDispatch, useAppSelector } from "@/config/store";
import UploadRegistration from "./component/upload-registration";
import { Company } from "@/shared/model/company.model";
import { Content } from "antd/es/layout/layout";
import i18next from "i18next";
import './index.css'
import { updateBreadcrumbItems } from "@/shared/layout/layout.reducer";
import { useLazyGetCompanyQuery, } from "@/services/api/onboarding.api";
import { saveDraftCompanyEntity } from "./onboarding.reducer";
import CompanyDetail from "../company";
import OnboardingEmpowerRepresentative from "./component/empower-representative";
import OnboardingEmpowerChiefAccount from "./component/empower-chief-accountant";
import OnboardingUserCompany from "./component/user-company";
import OnboardingBankAccount from "./component/bank-account";

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width;
};

export default function OnboardingPage() {

    const dispatch = useAppDispatch();

    const width = useWindowWidth();

    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const [getCompany, { data: companyEntityLoad, isLoading: isLoadingCompany, isSuccess: isSuccessCompany }] = useLazyGetCompanyQuery()
    const loading = useAppSelector(state => state.onboarding.loading);
    const currentStep = useAppSelector(state => state.onboarding.currentStep)
    const businessRegistration = companyEntity?.businessRegistration;

    const formStep = (step: number) => {

        if (companyEntity.isOnboardingSuccess) {
            return <CompanyDetail />
        }
        else {
            switch (step) {
                case 1:
                    return <OnboardingCommon />
                case 2:
                    return <OnboardingRegistration />
                case 3:
                    return <OnboardingRepresentative />
                case 4:
                    return <OnboardingEmpowerRepresentative />
                case 5:
                    return <OnboardingChiefAccountant />
                case 6:
                    return <OnboardingEmpowerChiefAccount />
                case 7:
                    return <OnboardingUserCompany />
                case 8:
                    return <OnboardingBankAccount />
                default:
                    break;
            }
        }
    }
    const steps = [
        {
            title: i18next.t('onboarding.steps.commonStep'),
            content: i18next.t('onboarding.steps.commonStep'),
        },
        {
            title: i18next.t('onboarding.steps.digitalSignStep'),
            content: i18next.t('onboarding.steps.digitalSignStep'),
        },
        {
            title: i18next.t('onboarding.steps.legalRepresentativeStep'),
            content: i18next.t('onboarding.steps.legalRepresentativeStep'),
        },
        {
            title: i18next.t('onboarding.steps.empowerLegalRepresentativeStep'),
            content: i18next.t('onboarding.steps.empowerLegalRepresentativeStep'),
        },
        {
            title: i18next.t('onboarding.steps.chiefAccountantStep'),
            content: i18next.t('onboarding.steps.chiefAccountantStep'),
        },
        {
            title: i18next.t('onboarding.steps.empowerChiefAccountantStep'),
            content: i18next.t('onboarding.steps.empowerChiefAccountantStep'),
        },
        {
            title: i18next.t('onboarding.steps.userCompany'),
            content: i18next.t('onboarding.steps.userCompany'),
        },
        {
            title: i18next.t('onboarding.steps.bankAccount'),
            content: i18next.t('onboarding.steps.bankAccount'),
        },
    ];
    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    useEffect(() => {
        getCompany();

        // dispatch(updateBreadcrumbItems(breadcrumbItems));
        return () => {
            dispatch(updateBreadcrumbItems([]));
        };
    }, []);

    useEffect(() => {
        if (companyEntityLoad && isSuccessCompany) {
            dispatch(saveDraftCompanyEntity(companyEntityLoad))
            console.log("companyEntityLoad", companyEntityLoad)
        }
    }, [companyEntityLoad, isLoadingCompany, isSuccessCompany]);

    if (companyEntity?.isOnboardingSuccess) {
        return (<CompanyDetail />)
    }

    if (isLoadingCompany) {
        return (<Spin>Đang tải thông tin</Spin>)
    }

    if ((!businessRegistration?.hasOcr) && !loading) {
        return (<UploadRegistration />)
        // return (<OnboardingUserCompany />)
    }


    return (
        <Content className="stepOnboarding">
            {currentStep >= 1 && (
                <Steps
                    current={currentStep - 1}
                    items={items}
                    labelPlacement='vertical'
                    percent={75}
                    size="default"
                    direction={width > 1250 ? "horizontal" : "vertical"}
                    style={{ padding: '30px 0px 0px 0px', background: '#fff', width: "80%" }} >
                </Steps>
            )}
            {formStep(currentStep)}
        </Content>
    )

    // style={{display:'flex', alignItems:'start'}}
}
