import { useAppSelector } from "@/config/store";
import { translations } from "@/i18n/i18n";
import { Company } from "@/shared/model/company.model";
import { Card, Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CompanySignRegistration() {
    const { t, } = useTranslation();
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);

    const { digitalSign = null } = companyEntity || {};
    if (!digitalSign) return <>Chua co du lieu</>
    return (
        <div className="digital-sign">
            <Card type='inner' title={'Thông tin chữ ký số'} style={{ margin: 20 }}>
                <Form colon={false}
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                // initialValues={digitalSign}
                >
                    <Form.Item
                        name={'serial'}
                        label={t(translations.companydigitalSign.serial)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >

                        <div>{digitalSign?.serial}</div>
                        {/* 23kl5jfrr6e78bsmkuiy63495h */}
                    </Form.Item>
                    <Form.Item
                        name={'signature'}
                        label={t(translations.companydigitalSign.signature)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.signature}</div>
                    </Form.Item>
                    <Form.Item
                        name={'subject'}
                        label={t(translations.companydigitalSign.subject)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.subject}</div>
                    </Form.Item>
                    <Form.Item
                        name={'signtime'}
                        label={t(translations.companydigitalSign.signtime)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.signtime}</div>
                    </Form.Item>
                    <Form.Item
                        name={'certificate'}
                        label={t(translations.companydigitalSign.certificate)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.certificate}</div>
                    </Form.Item>
                    <Form.Item
                        name={'fromDate'}
                        label={t(translations.companydigitalSign.fromDate)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.fromDate}</div>
                    </Form.Item>

                    <Form.Item
                        name={'toDate'}
                        label={t(translations.companydigitalSign.toDate)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.toDate}</div>
                    </Form.Item>
                    <Form.Item
                        name={'validity'}
                        label={t(translations.companydigitalSign.validity)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.validity}</div>
                    </Form.Item>
                    <Form.Item
                        name={'issuer'}
                        label={t(translations.companydigitalSign.issuer)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.issuer}</div>
                    </Form.Item>
                    <Form.Item
                        name={'taxCode'}
                        label={t(translations.companydigitalSign.taxCode)}
                        labelAlign="left"
                        style={{
                            borderBottom: '1px solid #F0F0F0',
                            padding: '10px',
                            margin: 0
                        }}
                    >
                        <div>{digitalSign?.taxCode}</div>
                    </Form.Item>


                </Form>
            </Card>

        </div>
    )
}