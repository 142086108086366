import axios from "axios";
import {
  Dictionary,
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
} from "@reduxjs/toolkit";
import { cleanEntity } from "@/shared/utils/entity-utils";
import {
  createEntitySlice,
  EntityState,
  serializeAxiosError,
  EntitiesLoadOptions,
} from "@/shared/reducers/reducer.utils";
import { encodeGetParams } from "@/shared/utils/jmix-rest/util";
const initialState: any = {
  loading: false,
  errorMessage: null,
  entities: [],
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

// const registrationApiUrl = 'rest/portal/registration';

// Actions

const userList = {
  data: 
  [
    {
        id: "1",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "Vũ",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
      {
        id: "2",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "2",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
        },
      {
        id: "3",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "3",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "4",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "4",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "5",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "5",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
          },
      {
        id: "6",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "6",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
      {
        id: "7",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "7",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "8",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "8",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "9",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "9",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
      {
        id: "11",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "Vũ",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
      {
        id: "12",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "2",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
        },
      {
        id: "13",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "3",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "14",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "4",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "15",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "5",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
          },
      {
        id: "16",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "6",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
      {
        id: "17",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "7",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "18",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "8",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage: ""
      },
      {
        id: "19",
        email: "vu@gmail",
        
        firstName: "Anh",
        lastName: "9",
        status: true,
        position: 'Sales Assistant',
        assignedTo: 'John Heart',
        company: 'ElectrixMax',
        address: '645 Prospect Crescent',
        city: 'Pasadena',
        state: 'CA', 
        zipCode: '91103',
        phone: '0902313213',
        age: "10",
        srcImage:""
      },
  ]
 
};

export const getAllUserInfo = createAsyncThunk(
  "listUserInfo/get_entity",
  async () => {
    const result = userList; //await axios.post<Registration>(registrationApiUrl + '/create', cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const UpdateListUserInfo = createAsyncThunk(
  "listUserInfo/update_entity",
  async (enitty: any) => {
    return enitty;
  },
  { serializeError: serializeAxiosError }
);

// export const createdRegistration = createAsyncThunk(
//   'registration/create_entity',
//   async (entity: UserInfo, thunkAPI) => {
//     const result = localStorage //await axios.post<Registration>(registrationApiUrl + '/create', cleanEntity(entity));
//     return result;
//   },
//   { serializeError: serializeAxiosError },
// );

// export const confirmation = createAsyncThunk(
//   'registration/registration_confirmation',
//   async (entity: Registration, thunkAPI) => {
//     const result = await axios.post<Registration>(registrationApiUrl + '/confirmation', cleanEntity(entity));
//     return result;
//   },
//   { serializeError: serializeAxiosError },
// );

// slice

export const userManagerSlice = createSlice({
  name: "userManager",
  initialState,
  reducers: {
    /**
     * Reset the entity state to initial state
     */
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getAllUserInfo), (state, action) => {
        // state.updating = false;
        // state.loading = false;
        // state.updateSuccess = true;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(UpdateListUserInfo), (state, action) => {
        state.entities = action.payload;
      });
  },
});

export const { reset } = userManagerSlice.actions;

// Reducer
export default userManagerSlice.reducer;
