import React, { useState } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography, Switch } from 'antd';
import { useTransition, animated, config } from '@react-spring/web';
import './index.css'
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { ConfigProvider } from 'antd';
import theme from '../../../theme/themeConfig'

const { Text, Link, Title, Paragraph } = Typography;
function Footer() {
    const isMobile = useIsMobile()
    return (
        <ConfigProvider theme={theme} >
            <div className='SECTION' style={{ marginTop: '2vh', backgroundColor: 'rgb(47, 84, 235)', color: 'white' }}>
                <Row align='middle' justify='center'>
                    <Col lg={{ span: 5 }}>
                        <Image preview={false} style={{ width: '20vw', height: 'auto', minWidth: 200, lineHeight: 0 }} src='/images/home/tradeflat-set-logo-12-20230919085646-jh7bj.png' />
                    </Col>
                </Row>
                <Row gutter={[0, 16]} align='middle' justify={'center'}>
                    <Col lg={{ span: 10 }} md={{ span: 14 }} sm={{ span: 16 }} xs={{ span: 20 }}>
                        <Title level={5} style={{ color: 'white', textAlign: 'center', marginTop: 0, fontWeight: 400 }}>Hệ sinh thái tài chính số đầu tiên tại Việt Nam</Title>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col lg={{ span: 16 }} md={{ span: 20 }} sm={{ span: 20 }} xs={{ span: 20 }} style={{ borderBottom: '1px solid rgb(255, 255, 255, 0.2)', }}>
                    </Col>
                </Row>
                <Row justify={isMobile ? 'start' : 'space-evenly'} style={{ marginTop: '2%' }} gutter={[0, 20]}>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 12, offset: 2 }} sm={{ span: 14, offset: 2 }} xs={{ span: 16, offset: 2 }}>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Trụ sở chính</Text>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Số 10 phố Phạm Văn Bạch, Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam</Text>
                    </Col>
                    <Col lg={{ span: 4, offset: 0 }} md={{ span: 13, offset: 2 }} sm={{ span: 14, offset: 2 }} xs={{ span: 16, offset: 2 }}>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Liên hệ</Text>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Email: FIS.Bank.TradeFlat@fpt.com</Text>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Hotline: 0966.181.681</Text>
                    </Col>
                    <Col lg={{ span: 6, offset: 0 }} md={{ span: 13, offset: 2 }} sm={{ span: 12, offset: 2 }} xs={{ span: 11, offset: 2 }}>
                        <Text style={{ display: 'block', color: 'rgb(255, 255, 255)', fontWeight: 400 }}>Được phát triển bởi</Text>
                        <Image preview={false} style={{ width: 155, height: 'auto' }} src='/images/logoFPTIS.png' />
                    </Col>
                </Row>
            </div>
        </ConfigProvider>

    );
}

export default Footer;