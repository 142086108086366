import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { Company, defaultValue } from '@/shared/model/company.model';
import { axiosSecurity } from '@/config/axios-interceptor';
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';

const initialState: EntityState<Company> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

const companyApiUrl = 'rest/portal/company';


export const getEntities = createAsyncThunk('company/fetch_entity_list', async (params: SearchEntitiesRequestModel) => {
    const requestUrl = companyApiUrl + "/search";
    return axiosSecurity.post<Company[]>(requestUrl, cleanEntity(params));
});

export const getEntity = createAsyncThunk(
    'company/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${companyApiUrl}/${id}`;
        return axiosSecurity.get<Company>(requestUrl);
    },
    { serializeError: serializeAxiosError },
);

export const CompanySlice = createEntitySlice({
    name: 'company',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data, headers } = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(headers['x-total-count'], 10),
                };
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
    },
});

export const { reset } = CompanySlice.actions;

// Reducer
export default CompanySlice.reducer;
