import { baseAuthAPI } from './base-auth.api'

const fileUploadUrl = '/rest/portal/fptfiles'

export const fileApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        downloadFile: build.query<string, { fileId:string, objectType:string | null }>({
            query({ fileId, objectType }) {
                return {
                    url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}&objectType=${objectType}`,
                    method: 'GET',
                    responseHandler: async (response) => URL.createObjectURL(await response.blob()),
                    cache: "no-cache",
                }
            }
        }),
    })
})

export const {
    useDownloadFileQuery
} = fileApi

export const {
    endpoints: { downloadFile },
} = fileApi