import application from './vi/application.json';
import header from './vi/header.json';
import onboarding from './vi/onboarding.json'
import action from './vi/action.json'
import bankBranch from './vi/model/bank-branch.json'
import bank from './vi/model/bank.json'
import companyBankAccount from './vi/model/company-bank-account.json'
import companyBusinessRegistration from './vi/model/company-business-registration.json'
import companyCheckEkyc from './vi/model/company-check-ekyc.json'
import companydigitalSign from './vi/model/company-digital-sign.json'
import companyemployee from './vi/model/company-employee.json'
import companyFeeservice from './vi/model/company-fee-service.json'
import company from './vi/model/company.json'
import corporateAddress from './vi/model/corporate-address.json'
import district from './vi/model/district.json'
import fileInfoResponse from './vi/model/file-info-response.json'
import province from './vi/model/province.json'
import registrationModel from './vi/model/registration.json'
import roleassignment from './vi/model/role-assignment.json'
import usercompany from './vi/model/user-company.json'
import user from './vi/model/user.json'
import ward from './vi/model/ward.json'
import register from './vi/register.json'
import companyDetail from './vi/company-detail.json'
import bpm from './vi/bpm.json'
import taskData from './vi/model/task-data.json'
export default {
    application,
    header,
    onboarding,
    action,
    bankBranch,
    bank,
    companyBankAccount,
    companyBusinessRegistration,
    companyCheckEkyc,
    companydigitalSign,
    companyemployee,
    companyFeeservice,
    company,
    corporateAddress,
    district,
    fileInfoResponse,
    province,
    registrationModel,
    roleassignment,
    usercompany,
    user,
    ward,
    register,
    companyDetail,
    bpm,
    taskData


}