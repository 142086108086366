import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { Company, defaultValue } from '@/shared/model/company.model';
import { defaultValue as initDigitalSign } from '@/shared/model/digital-signature.model';
import { FileInfoResponse } from '@/shared/model/file-info-response.model';
import { v4 as uuidv4 } from 'uuid';
import { CompanyEmployee, defaultValue as initCompanyEmployee } from '@/shared/model/company-employee.model';
import { CompanyBusinessRegistration, defaultValue as defaultValueRegistration } from '@/shared/model/company-business-registration.model';
import { axiosSecurity } from '@/config/axios-interceptor';
import { UserCompany, defaultValue as defaultValueUserCompany } from '@/shared/model/user-company.model'

const initialState: any = {
  // loading: false,
  // loadingOCRRegistration: false,
  // errorMessage: null,
  // entities: [],
  entity: defaultValue,
  // updating: false,
  // totalItems: 0,
  // updateSuccess: false,
  // fileRegistration: null,
  // fileLoading: false,
  // onboardingSuccess: false,
  //Steps
  currentStep: 1,
};

const apiUrl = '/rest/portal/onboarding';

// Actions

// export const getEntity = createAsyncThunk(
//   'onboarding/fetch_entity',
//   async () => {
//     const requestUrl = `${apiUrl}/get-onboarding`;
//     return axiosSecurity.get<Company>(requestUrl);
//   },
//   { serializeError: serializeAxiosError },
// );

// export const updateEntity = createAsyncThunk(
//   'onboarding/update_entity',
//   async (entity: Company, thunkAPI) => {
//     const result = await axiosSecurity.put<Company>(`${apiUrl}/save-onboarding/${entity.id}`, cleanEntity(entity));
//     // thunkAPI.dispatch(getEntity());
//     return result;
//   },
//   { serializeError: serializeAxiosError },
// );

// export const completeOnboarding = createAsyncThunk(
//   'onboarding/complete_onboarding',
//   async (entity: Company, thunkAPI) => {
//     const result = await axiosSecurity.put<Company>(`${apiUrl}/complete-onboarding/${entity.id}`, cleanEntity(entity));
//     // thunkAPI.dispatch(getEntity());
//     return result;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadFileRegistration = createAsyncThunk(
//   'onboarding/upload-registration',
//   async (file: any, thunkAPI) => {
//     return await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//   },
//   { serializeError: serializeAxiosError },
// );

// export const getOcrRegistration = createAsyncThunk(
//   'onboarding/get-ocr-registration',
//   async (fileRef: any, thunkAPI) => {
//     const requestUrl = `${apiUrl}/get-ocr-registration?fileRef=${encodeURIComponent(fileRef)}`;

//     return axiosSecurity.post<Company>(requestUrl);
//   },
//   { serializeError: serializeAxiosError },
// );

//Process file upload/download for Legal Representative
// export const uploadFrontImageLegalRepresentative = createAsyncThunk(
//   'onboarding/upload_image_representative',
//   async ({ docType, frontImage, backImage }: any, thunkAPI) => {
//     const frontImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, frontImage, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });

//     const backImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, backImage, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     const representativeInfo = await axiosSecurity.post<CompanyEmployee>(`${apiUrl}/onboarding/ocr-person-ekyc?docType=${docType}?frontImage=${frontImageRef.data.fileRef}?backImage=${backImageRef.data.fileRef}`, frontImage);
//     return representativeInfo;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadRepresentativeFrontImage = createAsyncThunk(
//   'onboarding/upload_front_image_representative',
//   async (file: any, thunkAPI) => {
//     const frontImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return frontImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadRepresentativeFrontImage = createAsyncThunk(
//   'onboarding/download_front_image_representative',
//   async (fileId: any, thunkAPI) => {
//     const frontImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return frontImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadRepresentativeBackImage = createAsyncThunk(
//   'onboarding/upload_back_image_representative',
//   async (file: any, thunkAPI) => {
//     console.log('HANT : ', file)
//     const backImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadRepresentativeBackImage = createAsyncThunk(
//   'onboarding/download_back_image_representative',
//   async (fileId: any, thunkAPI) => {
//     const backImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadRepresentativeFaceImage = createAsyncThunk(
//   'onboarding/upload_face_image_representative',
//   async (file: any, thunkAPI) => {
//     const faceImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return faceImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadRepresentativeFaceImage = createAsyncThunk(
//   'onboarding/download_face_image_representative',
//   async (fileId: any, thunkAPI) => {
//     const backImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );

//Process file upload/download for Chief Accountant
// export const uploadChiefAccountantFrontImage = createAsyncThunk(
//   'onboarding/upload_front_image_chief_accountant',
//   async (file: any, thunkAPI) => {
//     const frontImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return frontImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadChiefAccountantFrontImage = createAsyncThunk(
//   'onboarding/download_front_image_chief_accountant',
//   async (fileId: any, thunkAPI) => {
//     const frontImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return frontImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadChiefAccountantBackImage = createAsyncThunk(
//   'onboarding/upload_back_image_chief_accountant',
//   async (file: any, thunkAPI) => {
//     const backImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadChiefAccountantBackImage = createAsyncThunk(
//   'onboarding/download_back_image_chief_accountant',
//   async (fileId: any, thunkAPI) => {
//     const backImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const uploadChiefAccountantFaceImage = createAsyncThunk(
//   'onboarding/upload_face_image_chief_accountant',
//   async (file: any, thunkAPI) => {
//     const faceImageRef = await axiosSecurity.post<FileInfoResponse>(`/rest/portal/fptfiles`, file, {
//       headers: {
//         'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
//       }
//     });
//     return faceImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const downloadChiefAccountantFaceImage = createAsyncThunk(
//   'onboarding/download_face_image_chief_accountant',
//   async (fileId: any, thunkAPI) => {
//     const backImageRef = await axiosSecurity.get<Blob>(`/rest/portal/fptfiles?fileRef=${encodeURIComponent(fileId)}`, { responseType: "blob" });

//     return backImageRef;
//   },
//   { serializeError: serializeAxiosError },
// );
// export const executeOcrPersonEKycRepresentative = createAsyncThunk(
//   'onboarding/get_ocr_person_ekyc_representative',
//   async ({ docType, frontImage, backImage }: any, thunkAPI) => {
//     return await axiosSecurity.post<CompanyEmployee>(`${apiUrl}/ocr-person-ekyc?docType=${docType}&frontImage=${encodeURIComponent(frontImage)}&backImage=${encodeURIComponent(backImage)}`);

//   },
//   { serializeError: serializeAxiosError },
// )
// export const executeOcrPersonEKycChiefAccountant = createAsyncThunk(
//   'onboarding/get_ocr_person_ekyc_chief_accountant',
//   async ({ docType, frontImage, backImage }: any, thunkAPI) => {
//     return await axiosSecurity.post<CompanyEmployee>(`${apiUrl}/ocr-person-ekyc?docType=${docType}&frontImage=${encodeURIComponent(frontImage)}&backImage=${encodeURIComponent(backImage)}`);

//   },
//   { serializeError: serializeAxiosError },
// )

// export const partialUpdateEntity = createAsyncThunk(
//   'onboarding/partial_update_entity',
//   async (entity: Company, thunkAPI) => {
//     const result = await axiosSecurity.patch<Company>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
//     thunkAPI.dispatch(getEntity());
//     return result;
//   },
//   { serializeError: serializeAxiosError },
// );



// slice
export const OnboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    //Steps actions
    goNext(state, action) {
      console.log("HANT state.currentStep: ", state.currentStep)
      if (state.currentStep < 8) {
        state.currentStep = state.currentStep + 1;
      }
      state.entity = action.payload;
    },
    goPrev(state, action) {
      if (state.currentStep > 1) {
        state.currentStep = state.currentStep - 1;
      }
      state.entity = action.payload;
    },
    goToStep(state, action) {
      return {
        ...state,
        currentStep: action.payload
      }
    },
    saveDraft(state, action: any) {
      return {
        ...state,
        entity: action.payload
      }
    },
    clearRegistrationFile(state, action: any) {
      return {
        ...state,
        entity: action.payload
      }
    },
    saveDraftCompanyEntity(state, action) {
      state.entity = action.payload
    },

    setDocEmpowerChiefAccount(state, action) {
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }

      empowerChiefAccountant.empowerDoc = action.payload?.fileRef
      state.entity = {
        ...state.entity, empowerChiefAccountant: {
          ...state.entity.empowerChiefAccountant, ...empowerChiefAccountant
        }
      }
    },
    deleteDocEmpowerChiefAccount(state) {
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.empowerDoc = null;

      state.entity = {
        ...state.entity, empowerChiefAccountant: {
          ...state.entity.empowerChiefAccountant, ...empowerChiefAccountant
        }
      }
    },

    setDocEmpowerRepresentative(state, action) {
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }

      empowerRepresentative.empowerDoc = action.payload?.fileRef
      state.entity = {
        ...state.entity, empowerRepresentative: {
          ...state.entity.empowerRepresentative, ...empowerRepresentative
        }
      }
    },
    deleteDocEmpowerRepresentative(state) {
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.empowerDoc = null;

      state.entity = {
        ...state.entity, empowerRepresentative: {
          ...state.entity.empowerRepresentative, ...empowerRepresentative
        }
      }
    },

    setRegistrationFile(state, action) {
      let businessRegistration = state.entity.businessRegistration;
      if (!businessRegistration) {
        businessRegistration = defaultValueRegistration;
        businessRegistration.id = uuidv4();
      }
      businessRegistration.file = action.payload?.fileRef;
      businessRegistration.hasOcr = false;

      state.entity = { ...state.entity, ...{ businessRegistration } }
    },
    deleteRegistrationFile(state) {
      let businessRegistration = state.entity.businessRegistration;
      if (!businessRegistration) {
        businessRegistration = defaultValueRegistration;
        businessRegistration.id = uuidv4();
      }
      businessRegistration.file = null;
      businessRegistration.hasOcr = false;

      state.entity = { ...state.entity, ...{ businessRegistration } }
    },
    updateRegistrationOCR(state, action) {
      state.entity = action.payload
    },
    updateDigitalSign(state, action) {
      let digitalSign = state.entity.digitalSign;
      if (!digitalSign) {
        digitalSign = initDigitalSign;
        digitalSign.id = uuidv4();
      }

      digitalSign = {
        ...digitalSign,
        ...action.payload
      }

      state.entity.digitalSign = digitalSign
      console.log("update digital sign", state.entity.digitalSign)
    },
    updateLegalRepresentativeFrontImage(state, action) {
      console.log("UPDATE");
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.cardFrontImage = action.payload?.fileRef;
      state.entity.legalRepresentative = legalRepresentative;
      console.log("PAV", JSON.stringify(state.entity.legalRepresentative));
    },
    deleteLegalRepresentativeFrontImage(state, action){
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.cardFrontImage = action.payload;
      console.log("legalRepresentative delete", legalRepresentative)
      state.entity = {
        ...state.entity,
        legalRepresentative: {
          ...state.entity.legalRepresentative,
          ...legalRepresentative
        }
      };
    },
    updateLegalRepresentativeBackImage(state, action) {
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.cardBackImage = action.payload?.fileRef;
      state.entity.legalRepresentative = legalRepresentative;
    },
    deleteLegalRepresentativeBackImage(state, action){
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.cardBackImage = null;
      state.entity.legalRepresentative = legalRepresentative;
    },
    updateLegalRepresentativeFaceImage(state, action) {
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.faceImage = action.payload?.fileRef;
      state.entity.legalRepresentative = legalRepresentative;
    },
    deleteLegalRepresentativeFaceImage(state, action){
      let legalRepresentative = state.entity.legalRepresentative;
      if (!legalRepresentative) {
        legalRepresentative = initCompanyEmployee;
        legalRepresentative.id = uuidv4();
      }
      legalRepresentative.faceImage = null;
      state.entity.legalRepresentative = legalRepresentative;
    },
    
    updateEmpowerLegalRepresentativeFrontImage(state, action) {
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.cardFrontImage = action.payload?.fileRef;
      state.entity.empowerRepresentative = empowerRepresentative;
      console.log("PAV", state.entity.empowerRepresentative);
    },
    deleteEmpowerLegalRepresentativeFrontImage(state, action){
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.cardFrontImage = null;
      state.entity.empowerRepresentative = empowerRepresentative;
    },
    updateEmpowerLegalRepresentativeBackImage(state, action) {
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.cardBackImage = action.payload?.fileRef;
      state.entity.empowerRepresentative = empowerRepresentative;
    },
    deleteEmpowerLegalRepresentativeBackImage(state, action){
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.cardBackImage = null;
      state.entity.empowerRepresentative = empowerRepresentative;
    },
    updateEmpowerLegalRepresentativeFaceImage(state, action) {
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.faceImage = action.payload?.fileRef;
      state.entity.empowerRepresentative = empowerRepresentative;
    },
    deleteEmpowerLegalRepresentativeFaceImage(state, action){
      let empowerRepresentative = state.entity.empowerRepresentative;
      if (!empowerRepresentative) {
        empowerRepresentative = initCompanyEmployee;
        empowerRepresentative.id = uuidv4();
      }
      empowerRepresentative.faceImage = null;
      state.entity.empowerRepresentative = empowerRepresentative;
    },
    
    updateChiefAccountantFrontImage(state, action) {
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.cardFrontImage = action.payload?.fileRef;
      state.entity.chiefAccountant = chiefAccountant;
    },
    deleteChiefAccountantFrontImage(state, action){
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.cardFrontImage = null;
      state.entity.chiefAccountant = chiefAccountant;
    },
    updateChiefAccountantBackImage(state, action) {
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.cardBackImage = action.payload?.fileRef;
      state.entity.chiefAccountant = chiefAccountant;
    },
    deleteChiefAccountantBackImage(state, action){
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.cardBackImage = null;
      state.entity.chiefAccountant = chiefAccountant;
    },
    updateChiefAccountantFaceImage(state, action) {
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.faceImage = action.payload?.fileRef;
      state.entity.chiefAccountant = chiefAccountant;
    },
    deleteChiefAccountantFaceImage(state, action){
      let chiefAccountant = state.entity.chiefAccountant;
      if (!chiefAccountant) {
        chiefAccountant = initCompanyEmployee;
        chiefAccountant.id = uuidv4();
      }
      chiefAccountant.faceImage = null;
      state.entity.chiefAccountant = chiefAccountant;
    },
    
    updateEmpowerChiefAccountantFrontImage(state, action) {
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.cardFrontImage = action.payload?.fileRef;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    deleteEmpowerChiefAccountantFrontImage(state, action){
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.cardFrontImage = null;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    updateEmpowerChiefAccountantBackImage(state, action) {
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.cardBackImage = action.payload?.fileRef;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    deleteEmpowerChiefAccountantBackImage(state, action){
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.cardBackImage = null;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    updateEmpowerChiefAccountantFaceImage(state, action) {
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.faceImage = action.payload?.fileRef;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    deleteEmpowerChiefAccountantFaceImage(state, action){
      let empowerChiefAccountant = state.entity.empowerChiefAccountant;
      if (!empowerChiefAccountant) {
        empowerChiefAccountant = initCompanyEmployee;
        empowerChiefAccountant.id = uuidv4();
      }
      empowerChiefAccountant.faceImage = null;
      state.entity.empowerChiefAccountant = empowerChiefAccountant;
    },
    saveCompanyBankAccount(state, action) {
      let companyBankAccount = state.entity.bankAccount

      companyBankAccount = {
        ...companyBankAccount,
        ...action.payload
      }
      console.log("companyBankAccount update", companyBankAccount)
      state.entity.bankAccount = companyBankAccount
    },

    updateSuperAdmin(state, action) {
      // let supperAdmin = state.entity.supperAdmin;
      // if (!supperAdmin) {
      //   console.log("defaultValueUserCompany", uuidv4())
      //   supperAdmin = defaultValueUserCompany;
      //   supperAdmin.firstName = uuidv4();    
      // }

      // console.log("supperAdmin", supperAdmin)

      // supperAdmin = {
      //   ...supperAdmin,
      //   ...action.payload
      // }
      // state.entity.supperAdmin = supperAdmin
    }
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(getEntity.fulfilled, (state, action) => {

  //       state.loading = false;
  //       state.entity = action.payload.data;
  //     })
  //     .addCase(uploadFileRegistration.fulfilled, (state, action) => {
  //       let businessRegistration = state.entity.businessRegistration;
  //       if (!businessRegistration) {
  //         businessRegistration = defaultValueRegistration;
  //         businessRegistration.id = uuidv4();
  //       }
  //       businessRegistration.file = action.payload.data?.fileRef;
  //       businessRegistration.hasOcr = false;
  //       return {
  //         ...state,
  //         fileLoading: false,
  //         updating: false,
  //         loading: false,
  //         updateSuccess: true,
  //         entity: { ...state.entity, ...{ businessRegistration } }
  //       }

  //     })
  //     .addCase(uploadFileRegistration.rejected, (state, action) => {
  //       state.loadingOCRRegistration = false;
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.businessRegistration) {
  //         state.entity.businessRegistration.file = null;
  //         state.entity.businessRegistration.hasOcr = false;
  //       }
  //     })
  //     .addCase(uploadFileRegistration.pending, (state, action) => {
  //       state.loadingOCRRegistration = true;
  //       state.errorMessage = null;
  //       state.updateSuccess = false;
  //       state.fileLoading = true;
  //       if (!!state.entity.businessRegistration) {
  //         state.entity.businessRegistration.hasOcr = false;
  //       }
  //     })
  //     .addCase(getOcrRegistration.pending, (state, action) => {
  //       state.loadingOCRRegistration = true;
  //       state.updating = true;
  //       state.updateSuccess = false;
  //     })
  //     .addCase(getOcrRegistration.fulfilled, (state, action) => {
  //       state.loadingOCRRegistration = false;
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       state.entity = action.payload.data;
  //     })
  //     .addCase(getOcrRegistration.rejected, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = false;
  //     })

  //     //Legal Representative
  //     .addCase(executeOcrPersonEKycRepresentative.fulfilled, (state, action) => {
  //       let legalRepresentative = { ...state.entity.legalRepresentative, ...action.payload.data }
  //       return {
  //         ...state,
  //         fileLoading: false,
  //         updating: false,
  //         loading: false,
  //         updateSuccess: true,
  //         entity: { ...state.entity, ...{ legalRepresentative } }
  //       }
  //     })
  //     .addCase(uploadRepresentativeFrontImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let legalRepresentative = state.entity.legalRepresentative;
  //       if (!legalRepresentative) {
  //         legalRepresentative = initCompanyEmployee;
  //         legalRepresentative.id = uuidv4();
  //       }
  //       legalRepresentative.cardFrontImage = action.payload.data?.fileRef;
  //       state.entity.legalRepresentative = legalRepresentative;

  //     })
  //     .addCase(downloadRepresentativeFrontImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.legalRepresentative)
  //         state.entity.legalRepresentative.cardFrontImageFile = URL.createObjectURL(action.payload?.data);

  //     })
  //     .addCase(uploadRepresentativeBackImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let legalRepresentative = state.entity.legalRepresentative;
  //       if (!legalRepresentative) {
  //         legalRepresentative = initCompanyEmployee;
  //         legalRepresentative.id = uuidv4();
  //       }
  //       legalRepresentative.cardBackImage = action.payload.data?.fileRef;
  //       state.entity.legalRepresentative = legalRepresentative;
  //     })
  //     .addCase(downloadRepresentativeBackImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.legalRepresentative)
  //         state.entity.legalRepresentative.cardBackImageFile = URL.createObjectURL(action.payload?.data);

  //     })
  //     .addCase(uploadRepresentativeFaceImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let legalRepresentative = state.entity.legalRepresentative;
  //       if (!legalRepresentative) {
  //         legalRepresentative = initCompanyEmployee;
  //         legalRepresentative.id = uuidv4();
  //       }
  //       legalRepresentative.faceImage = action.payload.data?.fileRef;
  //       state.entity.legalRepresentative = legalRepresentative;

  //     })
  //     .addCase(downloadRepresentativeFaceImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.legalRepresentative)
  //         state.entity.legalRepresentative.faceImageFile = URL.createObjectURL(action.payload?.data);

  //     })
  //     //End Legal Representative
  //     //Chief Accountant
  //     .addCase(executeOcrPersonEKycChiefAccountant.fulfilled, (state, action) => {
  //       let chiefAccountant = { ...state.entity.chiefAccountant, ...action.payload.data }
  //       return {
  //         ...state,
  //         fileLoading: false,
  //         updating: false,
  //         loading: false,
  //         updateSuccess: true,
  //         entity: { ...state.entity, ...{ chiefAccountant } }
  //       }
  //     })
  //     .addCase(uploadChiefAccountantFrontImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let chiefAccountant = state.entity.chiefAccountant;
  //       if (!chiefAccountant) {
  //         chiefAccountant = initCompanyEmployee;
  //         chiefAccountant.id = uuidv4();
  //       }
  //       chiefAccountant.cardFrontImage = action.payload.data?.fileRef;
  //       state.entity.chiefAccountant = chiefAccountant;
  //       console.log("HANT uploadChiefAccountantFrontImage: ", chiefAccountant)
  //     })
  //     .addCase(downloadChiefAccountantFrontImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.chiefAccountant)
  //         state.entity.chiefAccountant.cardFrontImageFile = URL.createObjectURL(action.payload?.data);
  //       console.log("HANT downloadChiefAccountantFrontImage: ", URL.createObjectURL(action.payload?.data))
  //     })
  //     .addCase(uploadChiefAccountantBackImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let chiefAccountant = state.entity.chiefAccountant;
  //       if (!chiefAccountant) {
  //         chiefAccountant = initCompanyEmployee;
  //         chiefAccountant.id = uuidv4();
  //       }
  //       chiefAccountant.cardBackImage = action.payload.data?.fileRef;
  //       state.entity.chiefAccountant = chiefAccountant;
  //     })
  //     .addCase(downloadChiefAccountantBackImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.chiefAccountant)
  //         state.entity.chiefAccountant.cardBackImageFile = URL.createObjectURL(action.payload?.data);

  //     })
  //     .addCase(uploadChiefAccountantFaceImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;

  //       let chiefAccountant = state.entity.chiefAccountant;
  //       if (!chiefAccountant) {
  //         chiefAccountant = initCompanyEmployee;
  //         chiefAccountant.id = uuidv4();
  //       }
  //       chiefAccountant.faceImage = action.payload.data?.fileRef;
  //       state.entity.chiefAccountant = chiefAccountant;

  //     })
  //     .addCase(downloadChiefAccountantFaceImage.fulfilled, (state, action) => {
  //       state.fileLoading = false;
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       if (!!state.entity.chiefAccountant)
  //         state.entity.chiefAccountant.faceImageFile = URL.createObjectURL(action.payload?.data);
  //     })
  //     //End Chief Accountant
  //     .addMatcher(isFulfilled(updateEntity, completeOnboarding, partialUpdateEntity), (state, action) => {
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = true;
  //       state.entity = action.payload.data;
  //     })
  //     .addMatcher(isPending(updateEntity, completeOnboarding, partialUpdateEntity), state => {
  //       state.loading = true;
  //       state.errorMessage = null;
  //       state.updateSuccess = false;
  //       state.updating = true;

  //     })
  //     .addMatcher(isRejected(getEntity,updateEntity, completeOnboarding, partialUpdateEntity), (state, action) => {
  //       state.updating = false;
  //       state.loading = false;
  //       state.updateSuccess = false;
  //       state.errorMessage = action.error.message;

  //     })
  //     .addMatcher(isPending(getEntity), state => {
  //       state.errorMessage = null;
  //       state.updateSuccess = false;
  //       state.loading = true;
  //     })


  //     .addMatcher(isPending(getOcrRegistration, uploadRepresentativeFrontImage, uploadRepresentativeBackImage, uploadRepresentativeFaceImage), state => {
  //       state.errorMessage = null;
  //       state.updateSuccess = false;
  //       state.fileLoading = true;
  //     })
  //     .addMatcher(isRejected(getOcrRegistration,
  //       uploadRepresentativeFrontImage,
  //       uploadRepresentativeBackImage,
  //       uploadRepresentativeFaceImage,
  //       downloadRepresentativeFrontImage,
  //       downloadRepresentativeBackImage,
  //       downloadRepresentativeFaceImage,
  //       uploadChiefAccountantFrontImage,
  //       uploadChiefAccountantBackImage,
  //       uploadChiefAccountantFaceImage,
  //       downloadChiefAccountantFrontImage,
  //       downloadChiefAccountantBackImage,
  //       downloadChiefAccountantFaceImage), (state, action) => {
  //         state.updating = false;
  //         state.loading = false;
  //         state.updateSuccess = false;
  //         state.errorMessage = action.error.message;
  //       })

  //     ;
  // },
});

export const {
  saveDraftCompanyEntity,
  goNext,
  goPrev,
  goToStep,
  saveDraft,
  clearRegistrationFile,
  setRegistrationFile,
  setDocEmpowerChiefAccount,
  setDocEmpowerRepresentative,
  deleteDocEmpowerRepresentative,
  deleteDocEmpowerChiefAccount,
  deleteRegistrationFile,
  updateDigitalSign,
  updateRegistrationOCR,
  updateLegalRepresentativeFrontImage,
  updateLegalRepresentativeBackImage,
  updateLegalRepresentativeFaceImage,
  updateChiefAccountantFrontImage,
  updateChiefAccountantBackImage,
  updateChiefAccountantFaceImage,
  updateEmpowerLegalRepresentativeBackImage,
  updateEmpowerLegalRepresentativeFaceImage,
  updateEmpowerLegalRepresentativeFrontImage,
  updateEmpowerChiefAccountantFrontImage,
  updateEmpowerChiefAccountantBackImage,
  updateEmpowerChiefAccountantFaceImage,
  saveCompanyBankAccount,
  updateSuperAdmin,
  deleteChiefAccountantBackImage,
  deleteChiefAccountantFaceImage,
  deleteChiefAccountantFrontImage,
  deleteLegalRepresentativeBackImage,
  deleteLegalRepresentativeFaceImage,
  deleteLegalRepresentativeFrontImage,
  deleteEmpowerChiefAccountantBackImage,
  deleteEmpowerChiefAccountantFaceImage,
  deleteEmpowerChiefAccountantFrontImage,
  deleteEmpowerLegalRepresentativeBackImage,
  deleteEmpowerLegalRepresentativeFaceImage,
} = OnboardingSlice.actions;

// Reducer
export default OnboardingSlice.reducer;
