import React from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import './index.css';
import { useTransition, animated, config } from '@react-spring/web';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';

const { Text, Link, Title, Paragraph } = Typography;

function FintechDigitalization({ display, isMobile }: any) {
    const imageTransition = useTransition(display, {
        from: { x: 50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500,
    });

    const textTransition = useTransition(display, {
        from: { x: -50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500,
    });

    return (
        <div
            className="SECTION"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: !isMobile ? '3% 13% 0 13%' : '3% 5% 0 5%',
            }}
        >
            <Row align={'bottom'} justify={'space-between'}>
                <Col lg={{ span: 11 }}>
                    <Row>
                        <Col>
                            {textTransition((style, item) => (
                                <animated.div style={style}>
                                    <Title level={3} style={{ marginBottom: '10%' }}>
                                        E2E digital finance platform for businesses & banks in the supply chain
                                    </Title>
                                </animated.div>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {imageTransition((style, item) => (
                                <animated.div style={style}>
                                    <Image
                                        preview={false}
                                        style={{ width: '100%', height: '100%', borderRadius: '0 100px 0 0' }}
                                        src="/images/home/Overview/lc-26-20221018174814-seuvq.png"
                                    />
                                </animated.div>
                            ))}
                        </Col>
                    </Row>
                </Col>

                <Col lg={{ span: 11 }}>
                    <Row gutter={[0, 32]} align={'bottom'} justify={'space-between'}>
                        <Col lg={{ span: 11 }}>
                            <Row>
                                {imageTransition((style, item) => (
                                    <animated.div style={style}>
                                        <Col>
                                            <Image
                                                preview={false}
                                                style={{ width: 44, height: 44 }}
                                                src="/images/home/Overview/lc-10-20221018162253-em9l8.png"
                                            />
                                        </Col>

                                        <Col>
                                            <Title level={5} style={{ marginBottom: '1%' }}>
                                                Rapidity
                                            </Title>
                                        </Col>

                                        <Col>
                                            <Paragraph
                                                ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}
                                                style={{ lineHeight: 1.6, textAlign: 'justify', fontWeight: 400 }}
                                            >
                                                Down 90% transaction processing compared with traditional workflow. Real-time process and update transactions among stakeholders
                                            </Paragraph>
                                        </Col>
                                    </animated.div>
                                ))}
                            </Row>

                            <Row>
                                {imageTransition((style, item) => (
                                    <animated.div style={style}>
                                        <Col>
                                            <Image
                                                preview={false}
                                                style={{ width: 44, height: 44, marginTop: '65%' }}
                                                src="/images/home/Overview/lc-09-20221018162253-z4ekj.png"
                                            />
                                        </Col>
                                        <Col>
                                            <Title level={5} style={{ marginBottom: '1%' }}>
                                                Safety, Security
                                            </Title>
                                        </Col>
                                        <Col>
                                            <Paragraph
                                                ellipsis={{ rows: 4, expandable: true, symbol: 'more' }}
                                                style={{
                                                    lineHeight: 1.6,
                                                    textAlign: 'justify',
                                                    display: 'block',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                TradeFlat was developed on the basis of applying advanced technology solutions, while ensuring the safety and security of the system: Blockchain, FPT CA, AI, OCR...
                                            </Paragraph>
                                        </Col>
                                    </animated.div>
                                ))}
                            </Row>
                        </Col>

                        <Col lg={{ span: 11 }} md={{ span: 24 }}>
                            <Row>
                                {imageTransition((style, item) => (
                                    <animated.div style={style}>
                                        <Col>
                                            <Image
                                                preview={false}
                                                style={{ width: 44, height: 44 }}
                                                src="/images/home/Overview/lc-11-20221018162253-qxyc9.png"
                                            />
                                        </Col>
                                        <Col>
                                            <Title level={5} style={{ marginBottom: '1%' }}>
                                                Saving
                                            </Title>
                                        </Col>

                                        <Col>
                                            <Paragraph
                                                // ellipsis={{ rows: 4, expandable: true, symbol: 'more' }}
                                                style={{
                                                    lineHeight: 1.6,
                                                    textAlign: 'justify',
                                                    display: 'block',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Cost saving of courier, hard copy stationary and personnel related to mannual operations
                                            </Paragraph>
                                        </Col>
                                    </animated.div>
                                ))}
                            </Row>
                            <Row>
                                {imageTransition((style, item) => (
                                    <animated.div style={style}>
                                        <Col>
                                            <Image
                                                preview={false}
                                                style={{ width: 44, height: 44, marginTop: '65%' }}
                                                src="/images/home/Overview/lc-12-20221018162253-hjr4l.png"
                                            />
                                        </Col>
                                        <Col>
                                            <Title level={5} style={{ marginBottom: '1%' }}>
                                                Centralization
                                            </Title>
                                        </Col>
                                        <Col>
                                            <Paragraph
                                                // ellipsis={{ rows: 4, expandable: true, symbol: 'more' }}
                                                style={{
                                                    lineHeight: 1.6,
                                                    textAlign: 'justify',
                                                    display: 'block',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Connect sellers and buyers, corporates and banks. Transparent update information and documents among stakeholders
                                            </Paragraph>
                                        </Col>
                                    </animated.div>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default FintechDigitalization;
