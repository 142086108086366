import React, { Suspense } from "react";
import HomeCommon from "./common";
import { translations } from 'i18n/i18n';
import { useTranslation } from 'react-i18next';

// const HomeCommon = React.lazy(() => import('./common'));

export default function CopyrightFooter() {
    return (
        <div style={{display:'flex', justifyContent:'center',textAlign:'center' , backgroundColor:'#203794', color:'#B1B9DA', fontSize:'14px', padding:16}}>Copyright © 2023 FPT IS Company Limited. All rights reserved</div>
    )
}