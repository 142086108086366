import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontFamily: "Lexend", //1em
    colorPrimary: 'rgb(28, 0, 194)',
    colorPrimaryHover: 'rgb(253, 199, 87)',
    fontSize: 16
  },
};  

export default theme;