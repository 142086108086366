import { CompanyBankAccount } from "./company-bank-account.model";
import { CompanyBusinessRegistration } from "./company-business-registration.model";
import { CompanyDigitalSign } from "./company-digital-sign.model";
import { CompanyEmployee } from "./company-employee.model";
import { CompanyFeeService } from "./company-fee-service.model";
import { CompanyAddress } from "./corporate-address.model";
import { User } from "./user.model";
import {UserCompany} from "./user-company.model"
import {StatusEnum,ESiteEnum,FeeServicePackageEnum,EKYCStatusEnum,CompanyOnboardingRegisterEnum} from './enums/enums'
import dayjs from 'dayjs';

export interface Company {
     id?: string;
	 _instanceName?: string | null;
	 code?: string | null;
	 name?: string | null;
	 nameEn?: string | null;
	 shortName?: string | null;
	 registrationType?: string | null;
	 officeAddress?: CompanyAddress | null;
	 mainAddress?: CompanyAddress | null;
	 dateRegistration?: string | null;
	 dateChangeRegistration?: string | null;
	 numberOfChangesRegistration?: number | null;
	 phoneNumber?: string | null;
	 email?: string | null;
	 fax?: string | null;
	 website?: string | null;
	 charterCapital?: string | null;
	 bankAccount?: CompanyBankAccount[] | null;
	 legalRepresentative?: CompanyEmployee | null;
	 ownerName?: string | null;
	 ownerTaxCode?: string | null;
	 ownerIssueDate?: string | null;
	 ownerIssueLocation?: string | null;
	 ownerAddress?: string | null;
	 empowerRepresentative?: CompanyEmployee | null;
	 chiefAccountant?: CompanyEmployee | null;
	 feeServices?: CompanyFeeService | null;
	 users?: User[] | null;
	 empowerChiefAccountant?:  CompanyEmployee | null;
	 businessRegistration?: CompanyBusinessRegistration | null;
	 digitalSign?: CompanyDigitalSign | null;
	 hasRegisterLC?: boolean | null;
	 hasRegisterSCF?: boolean | null;
	 hasRegisterGuarantee?: boolean | null;
	 hasRegisterFactoring?: boolean | null;
	 isActived?: boolean | null;
	 status?: StatusEnum | null;
	 initiatedBy?: ESiteEnum | null;
	 scfFeePackage?: FeeServicePackageEnum | null;
	 lcFeePackage?: FeeServicePackageEnum | null;
	 guaranteeFeePackage?: FeeServicePackageEnum | null;
	 checkInfoEKYC?: EKYCStatusEnum | null;
	 isOnboardingSuccess?: boolean | null;
	 onboardingStatus?: CompanyOnboardingRegisterEnum | null;
	 eKYCStatus?: EKYCStatusEnum | null;
	 version?: number | null;
	 createdBy?: string | null;
	 createdDate?: string | null;
	 lastModifiedBy?: string | null;
	 lastModifiedDate?: string | null;
	 deletedBy?: string | null;
	 deletedDate?: string | null;
	 supperAdmin?: UserCompany | null;
	}

export const defaultValue: Readonly<Company> = {};

