import { useAppDispatch, useAppSelector } from "@/config/store";
import { translations } from "@/i18n/i18n";
import { yupValidator } from "@/shared/utils/yup-utils";
import { Button, Form, Input, Modal, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { deepMerge } from "@/shared/utils/object-utils";
import { v4 as uuidv4 } from 'uuid';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Company } from "@/shared/model/company.model";
import { UserCompany, defaultValue as defaultUserCompany } from "@/shared/model/user-company.model";
import { useGetCompanyQuery } from "@/services/api/company.api";
import { useAddUserCompanyMutation, useUpdateUserCompanyMutation, userCompanyApi } from "@/services/api/user-company.api";

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}
interface CompanyUserEditProps {
  openMode?: 'popup' | null;
  id?: string | null;
  closePopup?: any;

}
export default function CompanyUserEdit(props:CompanyUserEditProps) {
  const { t } = useTranslation()
  const {openMode,id : inputId,closePopup } = props
  const navigate = useNavigate();
  const { id : idParam } = useParams<'id'>();
  let id:any=undefined;
  if(openMode == 'popup'){
    id = inputId
  }else{
    id = idParam;
  }

  const isNew = id === undefined;
  const [editedEntity, setEditedEntity] = useState<UserCompany>(defaultUserCompany);

  const { data: currentCompany, isLoading: isLoadingCompany, isError: isErrorCompany, isSuccess: isSuccessCompany } = useGetCompanyQuery('9dc7e876-b56a-b37a-ccc4-6a93a32a77e7');
  const [getUserCompany, { data: existedEntity, isLoading: isLoadingGet, isError: isErrorGet, isSuccess: isSuccessGet }] = userCompanyApi.endpoints.getUserCompany.useLazyQuery();
  const [addUserCompany, { isError: isErrorAdd, isSuccess: isSuccessAdd, isLoading: isLoadingAdd }] = useAddUserCompanyMutation();
  const [updateUserCompany, { isError: isErrorUpdate, isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate }] = useUpdateUserCompanyMutation();

  const [form] = Form.useForm<UserCompany>();

  let schema = yup.object().shape({
    company: yup.object().shape({
      name: yup.string().required("name cannot empty"),
      taxCode: yup.string().required("name cannot empty"),
    }),
    username: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string().required(),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  const handleClose = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <div>Bạn thực sự muốn đóng?</div>,
      centered: true,
      onOk() {
        navigate('/admin/usercompany');
      },
    });
  };

  const onFormFieldsChange = (allFields: FieldData[]) => {
    let copyEditedEntity = Object.assign(editedEntity);
    allFields.forEach(field => {
      copyEditedEntity[field.name as keyof UserCompany] = field.value
    })
    setEditedEntity(copyEditedEntity);
    console.log("HANT editedEntity ", copyEditedEntity)

  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
    if (isNew) {
      addUserCompany(editedEntity);
    } else {
      updateUserCompany(editedEntity);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (!isNew) {
      getUserCompany(id);
    }
  }, [])
  useEffect(() => {
    if (!isNew && !!existedEntity) {
      console.log("HANT existedEntity ", existedEntity)
      let entity = deepMerge(existedEntity)
      setEditedEntity(entity)
    }
  }, [existedEntity])

  useEffect(() => {
    if (isNew)
      setEditedEntity({ ...editedEntity, company: currentCompany });
  }, [currentCompany])

  useEffect(() => {
    console.log("HANT editedEntity ", editedEntity)
    let entity = deepMerge(editedEntity)
    form.setFieldsValue(entity)
  }, [editedEntity])

  useEffect(() => {
    if (isSuccessUpdate || isSuccessAdd) {
      handleClose();
    }
  }, [isSuccessUpdate, isSuccessAdd])
  return (<>
    {/* <Modal open={true}
    onCancel={handleClose}
      footer={null}
    > */}
    <div>CompanyUserEdit</div>
    <Content>
      <Form colon={false} 
        form={form}
        onFieldsChange={(_, allFields) => {
          onFormFieldsChange(allFields);
        }}
        onFinish={onFinish}
      >
        <Form.Item
          rules={[yupSync]}
          name={['company', 'name']}
          label={t(translations.company.name)}
          >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          rules={[yupSync]}
          name={['company', 'taxCode']}
          label={t(translations.company.code)}>
          <Input disabled/>
        </Form.Item>
        <Form.Item name={"username"}
          label={t(translations.usercompany.username)}
          rules={[yupSync]}
        >
          <Input />
          {/* <Input onChange={(e) => handleChange("username", e)} /> */}
        </Form.Item>
        <Form.Item name={"email"}
          label={t(translations.usercompany.email)}
          rules={[yupSync]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"phoneNumber"}
          label={t(translations.usercompany.phoneNumber)}>
          <Input />
        </Form.Item>
        <Form.Item name={"firstName"}
          label={t(translations.usercompany.firstName)}
          rules={[yupSync]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"lastName"}
          label={t(translations.usercompany.lastName)}
          rules={[yupSync]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"avatar"}
          label={t(translations.usercompany.avatar)}>
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button onClick={handleClose}>Đóng</Button>
        </Form.Item>
      </Form>


      

    </Content>

    {/* </Modal> */}

  </>)
}

