import { useAppDispatch, useAppSelector } from '@/config/store';
import { Col, Row, Space, Input, Button, notification, Spin } from 'antd';
import './../index.css';
import { InfoCircleFilled } from '@ant-design/icons';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { registrationApi } from '@/services/api/registration.api';
import { createdRegistration, reset } from '../registration.reducer';
import { useNavigate } from 'react-router-dom';
import { InputOTP } from "antd-input-otp";


interface ConfirmEmailProps {
  loadingSendEmail: boolean;
  emailToCheck: string;
  entity: any;
}

export default function ConfirmEmail({ loadingSendEmail, emailToCheck, entity }: ConfirmEmailProps) {
  const registrationEntity = useAppSelector(state => state.registration.entity);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [requestCheckValid, setRequestCheckValid] = useState(0)
  const [inputNumber, setInputNumber] = useState(['']);
  const [funRequestCheckValidEmail, { data: checkCodeEmailValid, isLoading: loadingReceiveEmail, isError: errorReceiveEmail, isSuccess: succedReceiveEmail }] = registrationApi.endpoints.sendRequestCheckValidEmail.useLazyQuery();
  const updateSuccess = useAppSelector(state => state.registration.updateSuccess);
  const [funCheckValidEmail, { data: createOtp, isLoading: loadingCreateOtp, isError: errorCreateOtp, isSuccess: successCreateOtp }] = registrationApi.endpoints.checkValidEmail.useLazyQuery();

  const [clickSendEmailAgain, setClickSendEmailAgain] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [timeOTP, setTimeOTP] = useState(300);

  const [newTimeOtp, setNewTimeOtp] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setClickSendEmailAgain(false)
      setTimeLeft(180)
      return
    };

    const intervalId = setInterval(() => {
      if (clickSendEmailAgain) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, clickSendEmailAgain]);

  useEffect(() => {
    console.log(newTimeOtp)
    if (newTimeOtp && timeOTP > 0) {
      const intervalId = setInterval(() => {
        if (newTimeOtp) {
          setTimeOTP(prevTime => prevTime - 1);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    } else if (timeOTP <= 0) {
      setTimeOTP(300);
      setNewTimeOtp(false)
    }
  }, [newTimeOtp, timeOTP, errorCreateOtp]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };


  useEffect(() => {
    if (successCreateOtp || !loadingSendEmail) {
      setNewTimeOtp(true);
    }
  }, [loadingCreateOtp, successCreateOtp, createOtp, loadingSendEmail]);


  useEffect(() => {
    console.log(updateSuccess)
    if (updateSuccess) {
      navigate('/registration/success');
      dispatch(reset())
    }
  }, [updateSuccess]);

  // const onCheckValidEmail = () => {
  //   setWantCheckEmail(true)
  //   funCheckValidEmail(emailToCheck);
  // }

  // const handleInputNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setInputNumber(e.target.value);

  // }

  const sendEmailAgain = () => {
    setClickSendEmailAgain(true)
    funCheckValidEmail(entity)
  }

  const onRequestCheckValidEmail = async () => {
    await funRequestCheckValidEmail(JSON.stringify({ email: emailToCheck, otp: inputNumber.join('').toString() }));
    setRequestCheckValid(requestCheckValid + 1)
  }

  useEffect(() => {
    if (requestCheckValid != 0 && checkCodeEmailValid) {
      notification.success({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Xác thực email thành công
            </div>
          )
      });
      dispatch(createdRegistration(entity));
    } else if (requestCheckValid != 0 && !checkCodeEmailValid) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Xác thực email không thành công
            </div>
          )
      });
    }
  }, [requestCheckValid, clickSendEmailAgain]);

  useEffect(() => {
    if (successCreateOtp && clickSendEmailAgain) {
      console.log(checkCodeEmailValid)
      notification.success({
        message: "Đã gửi email",
        description: (
          <>
            Hệ thống đã gửi email cho bạn.
            <br />
            Vui lòng kiểm tra email để xác nhận.
          </>
        )
      })
    }

    if (errorCreateOtp && clickSendEmailAgain) {
      notification.error({
        message: 
        (
          <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
            Gửi email không thành công
          </div>
        )
      })
    }
  }, [createOtp, loadingCreateOtp, successCreateOtp, errorCreateOtp, clickSendEmailAgain])

  useEffect(() => {
    if (successCreateOtp) {
      setNewTimeOtp(false);
      setTimeOTP(300);
      setNewTimeOtp(true);
    }

  }, [errorCreateOtp, clickSendEmailAgain, successCreateOtp, createOtp])

  return (
    <Spin spinning={loadingSendEmail || loadingReceiveEmail || loadingCreateOtp}>
      <Row className="success">
        <Col
          className="SECTION-LEFT"
          lg={{ span: 12, order: 1 }}
          md={{ span: 24, order: 2 }}
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          style={{ padding: 50 }}
        >
          <Row justify={'center'}>
            {/* <div>Xác nhận email!</div>
          </Row>
          <Row justify={'space-around'} style={{margin: "20px 0"}}>
            <Col lg={{span:14}}>
              <Input value={inputNumber} onChange={handleInputNumberChange} />
            </Col>
            <Col lg={{span:10}} style={{display:'flex', justifyContent:'end'}}>
              <Button onClick={onRequestCheckValidEmail}>Xác thực</Button>
            </Col> */}
            <section className="card">
              <h2>{t(translations.register.confirmEmail)}</h2>
              <div style={{ marginBottom: 20 }}>OTP hết hạn {formatTime(timeOTP)}</div>
              <InputOTP
                inputType="custom"
                inputRegex="[0-9]"
                onChange={setInputNumber}
                value={inputNumber}

              />
              <Button block type="primary" onClick={onRequestCheckValidEmail} style={{ margin: 20, maxWidth: '352px', height: 40 }}>
                {t(translations.register.confirmButton)}
              </Button>
              <div>Bạn không nhận được mã OTP?{' '}
                {clickSendEmailAgain && (formatTime(timeLeft))}
                {!clickSendEmailAgain && (
                  <Button type='link' style={{ padding: 0 }} onClick={sendEmailAgain}>Gửi lại ngay</Button>
                )}
              </div>
            </section>
          </Row>
        </Col>
        <Col
          lg={{ span: 12, order: 2 }}
          md={{ span: 24, order: 1 }}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
        >
          <img
            className="SECTION-RIGHT"
            src={'../images/registration.jpg'}
            alt="Registration Image"
          />
        </Col>
      </Row>
    </Spin>

  );
}
