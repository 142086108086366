import React, { useState } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from 'antd';
import { animated, config, useTransition } from '@react-spring/web';

import './index.css';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
const { Text, Link, Title, Paragraph } = Typography;

const BlockchainFunding = ({ display, isMobile }: any) => {
    const [ellipsis, setEllipsis] = useState(true);

    const textTransition = useTransition(display, {
        from: { y: -50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        config: config.slow,
        delay: 500,
    });

    const imageTransition = useTransition(display, {
        from: { x: -50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500,
    });

    return (
        <div
            className="BlockchainFundingHome"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: !isMobile ? '1% 10% 0% 10%' : '1% 5% 0% 5%',
            }}
        >
            <Col>
                <Row justify={'center'}>
                    <Col lg={{ span: 11 }}>
                        {textTransition((style, item) => (
                            <animated.div style={style}>
                                <Title level={3} style={{ textAlign: 'center', marginBottom: '7%' }}>
                                    Remove bottlenecks, create digital connections in financing using blockchain technology
                                </Title>
                            </animated.div>
                        ))}
                    </Col>
                </Row>
                {imageTransition((style, item) => (
                    <animated.div style={style}>
                        <Row justify={'space-evenly'} gutter={[0, 16]}>
                            <Col lg={{ span: 5 }}>
                                <Card
                                    style={{ width: '100%', height: '100%', backgroundColor: 'rgb(239, 248, 255)', padding: '0 20px 0 20px' }}
                                    bodyStyle={{ padding: 0, overflow: 'hidden' }}
                                >
                                    <Image
                                        preview={false}
                                        className="ImageStyle"
                                        src="/images/home/Overview/lc-13-20221018164134-afxl_.png"
                                    />
                                    <Title level={4} >
                                        Digitalize the full process of financing activities
                                    </Title>
                                    <Text style={{
                                        lineHeight: 1.6,
                                        textAlign: "justify",
                                        display: "block",
                                    }}>
                                        Digitize the full chain of trade finance and supply chain finance activities for Vietnamese firms and banks on a single blockchain platform
                                    </Text>

                                </Card>
                            </Col>
                            <Col lg={{ span: 5 }}>
                                <Card
                                    style={{ width: '100%', height: '100%', backgroundColor: 'rgb(255, 250, 236)', padding: '0 20px 0 20px' }}
                                    bodyStyle={{ padding: 0, overflow: 'hidden' }}
                                >
                                    <Image
                                        preview={false}
                                        className="ImageStyle"
                                        src="/images/home/Overview/lc-14-20221018175515-me_bv.png"
                                    />
                                    <Title level={4}>
                                        Connecting financial supply & demand

                                    </Title>

                                    <Text style={{
                                        lineHeight: 1.6,
                                        textAlign: "justify",
                                        display: "block",
                                    }}>
                                        Enable dependable and long-term business collaboration by connecting financial supply and demand between enterprises and banks.

                                    </Text>
                                </Card>
                            </Col>
                            <Col lg={{ span: 5 }}>
                                <Card
                                    style={{ width: '100%', height: '100%', backgroundColor: 'rgb(235, 253, 247)', padding: '0 20px 50px 20px' }}
                                    bodyStyle={{ padding: 0, overflow: 'hidden' }}
                                >
                                    <Image
                                        preview={false}
                                        className="ImageStyle"
                                        src="/images/home/Overview/lc-15-20221018175515-sz-5d.png"
                                    />
                                    <Title level={4} style={{}}>
                                        Financial health monitoring & supervising
                                    </Title>
                                    <Text style={{
                                        lineHeight: 1.6,
                                        textAlign: "justify",
                                        display: "block",
                                    }}>
                                        Using big data to evaluate businesses, evaluate operations, and design efficiency financial programs and financial health monitoring after funding.
                                    </Text>

                                </Card>
                            </Col>
                            <Col lg={{ span: 5 }} md={{ span: 24 }}>
                                <Card
                                    style={{ width: '100%', height: '100%', backgroundColor: 'rgb(249, 245, 255)', padding: '0 20px 0 20px' }}
                                    bodyStyle={{ padding: 0, overflow: 'hidden' }}
                                >
                                    <Image
                                        preview={false}
                                        className="ImageStyle"
                                        src="/images/home/Overview/mask-group-1-20231221103252-u1jgi.png"
                                    />
                                    <Title level={4} >
                                        Optimal security - fraud prevention
                                    </Title>
                                    <Text style={{
                                        lineHeight: 1.6,
                                        textAlign: "justify",
                                        display: "block",
                                    }}>
                                        Speed up transactions, save time and optimize costs compared with traditional workflow
                                    </Text>
                                </Card>
                            </Col>
                        </Row>
                    </animated.div>
                ))}
            </Col>
        </div>
    );
};

export default BlockchainFunding;
