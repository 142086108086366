import React from 'react';
import { useLocation, Navigate, PathRouteProps } from 'react-router-dom';

import { Translate } from '@/shared/utils/translate';

import { useAppSelector } from '@/config/store';
import ErrorBoundary from '@/shared/error/error-boundary';
import { hasAnyAuthority } from '../utils/jmix-rest/security';

interface IOwnProps extends PathRouteProps {
  hasAnyAuthorities?: string[];
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, hasAnyAuthorities = [], ...rest }: IOwnProps) => {

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched =true;// useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const permissions = useAppSelector(state => state.authentication.permissions);
  const isAuthorized = hasAnyAuthority(permissions.authorities, hasAnyAuthorities);
  const pageLocation = useLocation();
  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  if (!sessionHasBeenFetched) {
    return <div style={{padding:'65px 65px'}}>sessionHasBeenFetched</div>;
  }

  if (isAuthenticated) {
    if (isAuthorized) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div style={{padding:'65px 65px'}}>
     You are not authorized to access this page
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/login',
        search: pageLocation.search,
      }}
      replace
      state={{ from: pageLocation }}
    />
  );
};



/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
