import { ReducersMapObject, combineReducers } from '@reduxjs/toolkit';
// import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import registration from '@/modules/registration/registration.reducer'
import onboarding from '@/modules/onboarding/onboarding.reducer'
import userInfo from '@/modules/user-info/user-info.reducer';
import userManger from '@/modules/user-manage/user-manage.reducer'
// import administration from 'app/modules/administration/administration.reducer'; //HANT comment
import entitiesReducers from '@/modules/entities/entities.reducers';
import locale from './locale';

import applicationProfile from './application-profile';

import userManagement from './user-management';
import authentication from './authentication.reducer';
import companyBankAccount from '@/modules/bank-account/bank-account.reducer'
import userCompany from '@/modules/company-user-manage/company-user.reducer'
import layoutapp from '@/shared/layout/layout.reducer'
import { baseAuthAPI } from '@/services/api/base-auth.api';
// const rootReducer: ReducersMapObject = {
const rootReducer = combineReducers({
  [baseAuthAPI.reducerPath]: baseAuthAPI.reducer,
  authentication, //HANT comment
  registration,
  onboarding,
  locale,
  applicationProfile,
  // administration,
  userManagement,
  userInfo,
  companyBankAccount,
  userManger,
  userCompany,
  layoutapp,
  // loadingBar,
  /* needle-add-reducer-combine - will add reducer here */
  ...entitiesReducers,
});

export default rootReducer;
