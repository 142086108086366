import axios from 'axios';
import { User } from 'oidc-client-ts';
import { JMIX_REST_URL, KEYCLOAK_URL, KEYCLOAK_CLIENT_ID } from './config';
const TIMEOUT = 1 * 60 * 1000;

const axiosNotAuth = axios.create();
axiosNotAuth.defaults.timeout = TIMEOUT;
axiosNotAuth.defaults.baseURL = JMIX_REST_URL;

const axiosSecurity = axios.create();
axiosSecurity.defaults.timeout = TIMEOUT;
axiosSecurity.defaults.baseURL = JMIX_REST_URL;

function getUser() {
  // console.log('HANT localStorage: ',localStorage)
  const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`);
 
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}
const setupAxiosInterceptors = (onUnauthenticated: any) => {
  const onRequestSuccess = (config: any) => {
    const user = getUser();
    const token = user?.access_token;
    console.log('HANT config : ',config)
    console.log('HANT user : ',user)
    console.log('HANT token : ',token)
    config.headers['Access-Control-Allow-Origin'] = '*';
    if(!config.headers['Content-Type']){
      config.headers['Content-Type'] = 'application/json';
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    console.log('HANT  config.headers.Authorization  : ', config.headers.Authorization )
    return config;
  };
  const onResponseSuccess = (response: any) => response;
  const onResponseError = (err: any) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(new Error(err));
  };
  axiosSecurity.interceptors.request.use(onRequestSuccess);
  axiosSecurity.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

export { axiosSecurity, axiosNotAuth };
