import { Company } from '@/shared/model/company.model';
import { baseAuthAPI } from './base-auth.api'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';
import { FileInfoResponse } from '@/shared/model/file-info-response.model';
import { cleanEntity } from '@/shared/utils/entity-utils';
import { CompanyEmployee } from '@/shared/model/company-employee.model';
import { url } from 'inspector';
import { OcrData } from '@/shared/model/ocr-data.modal';
import { TaxInfo } from '@/shared/model/tax-info.model';
import { CompanyBankCif } from '@/shared/model/company-bank-cif.model';

const onboardingApiUrl = '/rest/portal/onboarding';
const fileUploadUrl = '/rest/portal/fptfiles'
export const onboardingApi = baseAuthAPI.injectEndpoints({
  endpoints: (build) => ({
    getCompany: build.query<Company, void>({
      query() {
        return {
          url: `${onboardingApiUrl}/get-onboarding`,
          cache: 'no-cache'
        }
      },

    }),

    //updateCompany
    updateCompany: build.mutation<Company, Partial<Company>>({
      query(entity: Company) {
        return {
          url: `${onboardingApiUrl}/save-onboarding/${entity.id}`,
          method: 'PUT',
          body: cleanEntity(entity),
        }
      },
    }),
    //completeOnboarding
    completeOnboarding: build.mutation<Company, Partial<Company>>({
      query(entity: Company) {
        return {
          url: `${onboardingApiUrl}/complete-onboarding/${entity.id}`,
          method: 'PUT',
          body: cleanEntity(entity),
        }
      },
    }),
    uploadFileRegistration: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          body: file,
          formData: true,
        }
      },
    }),
    //Process file upload/download for Legal Representative
    uploadRepresentativeFrontImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),
    downloadRepresentativeFrontImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),
    uploadRepresentativeBackImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),
    downloadRepresentativeBackImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),
    uploadRepresentativeFaceImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),

    downloadFile: build.query<string, any>({
      query({fileId, objectType}){
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}&objectType=${objectType}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      }
    }),

    downloadRepresentativeFaceImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),
    //Process file upload/download for ChiefAccountant
    uploadChiefAccountantFrontImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),
    downloadChiefAccountantFrontImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),
    uploadChiefAccountantBackImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),
    downloadChiefAccountantBackImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),
    uploadChiefAccountantFaceImage: build.mutation<FileInfoResponse, FormData>({
      query(file) {
        return {
          url: `${fileUploadUrl}`,
          method: 'POST',
          formData: true,
          body: file,
        }
      },
    }),
    downloadChiefAccountantFaceImage: build.query<string, string>({
      query(fileId) {
        return {
          url: `${fileUploadUrl}?fileRef=${encodeURIComponent(fileId)}`,
          method: 'GET',
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
          cache: "no-cache",
        }
      },
    }),

    //OCR process    
    executeOcrRegistration: build.mutation<Company, string>({
      query: (fileId) => ({
        url: `${onboardingApiUrl}/get-ocr-registration?fileRef=${encodeURIComponent(fileId)}`,
        method: 'POST',
      }),
    }),
    //executeOcrPersonEKycRepresentative
    executeOcrPersonEKycRepresentative: build.mutation<OcrData, CompanyEmployee>({
      query: (employee: CompanyEmployee) => {
        const { docType, cardFrontImage, cardBackImage } = employee;
        return ({
          // url: `${onboardingApiUrl}/ocr-person-ekyc?docType=${docType}&frontImage=${encodeURIComponent(cardFrontImage||"")}&backImage=${encodeURIComponent(cardBackImage||"")}`,
          url: `${onboardingApiUrl}/ocr-person-ekyc`,
          method: 'POST',
          body: cleanEntity(employee)
        })
      }
      ,
    }),
    //executeOcrPersonEKycChiefAccountant
    executeOcrPersonEKycChiefAccountant: build.mutation<OcrData, object>({
      query: ({ docType, frontImage, backImage }: any) => ({
        url: `${onboardingApiUrl}/ocr-person-ekyc?docType=${docType}&frontImage=${encodeURIComponent(frontImage)}&backImage=${encodeURIComponent(backImage)}`,
        method: 'POST',
      }),
    }),

    vertifyIdentify: build.mutation<Boolean, { frontImage: any, faceImage: any }>({
      query: ({ frontImage, faceImage }) => ({
        url: `${onboardingApiUrl}/verfify-identify?frontImage=${encodeURIComponent(frontImage)}&faceImage=${encodeURIComponent(faceImage)}`,
        method: 'POST',
      }),
    }),

    checkDigitalSign: build.mutation<boolean, string>({
      query: (fileId) => ({
        url: `${onboardingApiUrl}/check-digital-sign?fileRef=${encodeURIComponent(fileId)}`,
        method: 'POST',
      }),
    }),

    checkSerialDigitalSign: build.mutation<boolean, string>({
      query: (fileId) => ({
        url: `${onboardingApiUrl}/check-serial-digital-sign?fileRef=${encodeURIComponent(fileId)}`,
        method: 'POST',
      }),
    }),

    checkCompanyTaxCode: build.mutation<TaxInfo, {taxcode: String, id_number: String}>({
      query: ({taxcode, id_number}) => ({
        url: `/rest/portal/taxAuth`,
        method: 'POST',
        body: {
          "taxcode": taxcode,
          "id_number": id_number
        }
      }),
    }),

    getCompanyBankCif: build.mutation<CompanyBankCif[], void>({
      query: () => ({
        url: `/rest/portal/companyBankCif/search?fetchPlan=companyBankCif-fetch-plan`,
        method: 'POST',
        body: {filter: {}}
      }),
    }),

    postCompanyBankCif: build.mutation<CompanyBankCif, any>({
      query: (body) => ({
        url: `/rest/portal/companyBankCif`,
        method: 'POST',
        body: body
      }),
    }),

    updateCompanyBankCif: build.mutation<any, {id: String, body: CompanyBankCif}>({
      query: ({id,body}) => ({
        url: `/rest/portal/companyBankCif/${id}`,
        method: 'PUT',
        body: body
      }),
    }),

    checkNumberCif: build.mutation<boolean, Partial<CompanyBankCif>>({
      query: (body) => ({
        url: `/rest/portal/companyBankCif/check-bank-cif`,
        method: 'POST',
        body: body
      }),
    })
  }),
})

export const {
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useUpdateCompanyMutation,
  useCompleteOnboardingMutation,
  useUploadFileRegistrationMutation,
  useUploadRepresentativeFrontImageMutation,
  useDownloadRepresentativeFrontImageQuery,
  useUploadRepresentativeBackImageMutation,
  useDownloadRepresentativeBackImageQuery,
  useUploadRepresentativeFaceImageMutation,
  useDownloadRepresentativeFaceImageQuery,
  useUploadChiefAccountantFrontImageMutation,
  useDownloadChiefAccountantFrontImageQuery,
  useUploadChiefAccountantBackImageMutation,
  useDownloadChiefAccountantBackImageQuery,
  useUploadChiefAccountantFaceImageMutation,
  useDownloadChiefAccountantFaceImageQuery,
  useExecuteOcrRegistrationMutation,
  useExecuteOcrPersonEKycRepresentativeMutation,
  useExecuteOcrPersonEKycChiefAccountantMutation,
  useVertifyIdentifyMutation,
  useCheckDigitalSignMutation,
  useCheckCompanyTaxCodeMutation,
  useCheckSerialDigitalSignMutation,
  useGetCompanyBankCifMutation,
  usePostCompanyBankCifMutation,
  useUpdateCompanyBankCifMutation,
  useCheckNumberCifMutation
} = onboardingApi

export const {
  endpoints: {
    getCompany,
    updateCompany,
    completeOnboarding,
    uploadFileRegistration,
    uploadRepresentativeFrontImage,
    downloadRepresentativeFrontImage,
    uploadRepresentativeBackImage,
    downloadRepresentativeBackImage,
    uploadRepresentativeFaceImage,
    downloadRepresentativeFaceImage,
    uploadChiefAccountantFrontImage,
    downloadChiefAccountantFrontImage,
    uploadChiefAccountantBackImage,
    downloadChiefAccountantBackImage,
    uploadChiefAccountantFaceImage,
    downloadChiefAccountantFaceImage,
    executeOcrRegistration,
    executeOcrPersonEKycRepresentative,
    executeOcrPersonEKycChiefAccountant,
    vertifyIdentify,
    checkDigitalSign,
    checkCompanyTaxCode,
    checkSerialDigitalSign,
    getCompanyBankCif,
    postCompanyBankCif,
    updateCompanyBankCif,
    checkNumberCif
  },
} = onboardingApi

