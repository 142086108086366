import React, { useEffect, useState } from 'react';
import { REDIRECT_URL } from '@/shared/utils/url-utils';
import { useLocation } from 'react-router';
import { Button, Col, ConfigProvider, Row, ThemeConfig, Typography } from 'antd';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Content } from 'antd/es/layout/layout';

const { Text, Title } = Typography;

export const LoginRedirect = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const pageLocation = useLocation();

  useEffect(() => {
    if (!!pageLocation && pageLocation.state && pageLocation.state.from)
      localStorage.setItem(REDIRECT_URL, pageLocation.state.from.pathname);
  });

  const themeLogin: ThemeConfig = {
    token: {
      fontFamily: "Lexend",
      fontSize: 16, //1em
      colorPrimary: 'rgb(28, 0, 194)',
      colorPrimaryHover: 'rgb(253, 199, 87)',
      // fontSize: 16
    },
  };


  return (<>
    {/* <Content style={{padding:'65px 0px'}}>
      You are not authenticated. Please Login?
      <Button onClick={() => auth.signinRedirect()}>
        Login
      </Button>
    </Content> */}
    <ConfigProvider theme={themeLogin}>
      <Row style={{ height: '100%' }} justify={'end'}>
        <Col xxl={{ span: 12, order: 1 }} xl={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} style={{ marginTop: '10%', marginBottom: '5%' }}>
          <Row justify={'center'} style={{marginBottom:'3%'}}>
            <img src='/images/TradeFlat_logo_01.png' />
          </Row>
          <Row justify={'center'}>
            <Col xxl={{ span: 14 }}>
              <Title level={4} style={{ textAlign: 'center' }}>
                Phiên đăng nhập đã hết hạn! Quý khách vui lòng đăng nhập lại.
              </Title>
              <Text style={{ textAlign: 'center', display: 'block', marginBottom: 20 }}>Hãy nhập tài khoản mật khẩu để tiếp tục sử dụng dịch vụ!</Text>
              <Button type='primary' style={{ width: '60%', display: 'block', margin: 'auto', height: 40, border: "none" }} onClick={() => auth.signinRedirect()}>
                Đăng nhập
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xxl={{ span: 12 }} xl={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
          <img src='/images/registration.jpg' style={{ width: '100%' }} />
        </Col>
      </Row>
    </ConfigProvider>

  </>);
};

export default LoginRedirect;
