import { useAppSelector } from "@/config/store";
import { Breadcrumb } from "antd";
import React from "react";

export default function layoutapp() {
    const breadcrumbItems = useAppSelector(state => state.layoutapp.breadcrumbItems);
    return (
        <Breadcrumb
            items={breadcrumbItems}
            style={{ margin: '16px 0' }}>
        </Breadcrumb>

    )
}