import { WebStorageStateStore } from "oidc-client-ts";
import {  KEYCLOAK_CLIENT_ID, KEYCLOAK_REDIRECT_URI, KEYCLOAK_URL } from "./config";


export function getOidcConfig(onAuthenticatedCallback:any) {
    return {
        authority: KEYCLOAK_URL || '' ,
        client_id: KEYCLOAK_CLIENT_ID || '',
        redirect_uri: KEYCLOAK_REDIRECT_URI || '',
        onSigninCallback: onAuthenticatedCallback,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew:true
        // ...
      };
}
