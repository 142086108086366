import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Typography, Button, Image, Upload, message, Input, Spin, notification } from 'antd';
import { EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useAppDispatch, useAppSelector } from '@/config/store';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { translations } from '@/i18n/i18n';
import { useGetCurrentUserCompanyQuery, useGetUserInfoQuery, userInfoApi, useUpdateUserInfoMutation, useUploadAvatarMutation } from '@/services/api/user-info.api';
import { Navigate, useNavigate } from 'react-router-dom';
import { dispatchUploadAvatar } from '../user-info.reducer'
import { reduceEachTrailingCommentRange } from 'typescript';
import { Footer } from 'antd/es/layout/layout';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';
import { fileApi } from '@/services/api/file.api';
const { Title, Text } = Typography;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const schema = yup.object().shape({
    phoneNumber: yup
        .number()
        .required()
        .typeError('Number only.')
        .test('isPhoneNumber', 'Phone number must have 10 digits', (value: any) => {
            return /^\d{9}$/.test(value);
        }),
    email: yup
        .string()
        .required()
        .test('', 'Email is not valid', (values: any) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(values);
        }),
    age: yup
        .number()
        .required()
        .typeError('Number only.')
        .positive()
        .integer()
        .round()
        .min(6)
        .max(12),

    firstName: yup.string().required(),
    lastName: yup.string().required(),
    fullName: yup.string().required(),
    employeeCode: yup.string().required(),
    roleAssignmentText: yup.string().required(),
    position: yup.string().required(),
    department: yup.string().required(),
});

export const yupSync: any = {
    async validator({ field }: { field: string }, value: any) {
        await schema.validateSyncAt(field, { [field]: value });
    },
    type: 'custom',
};

function hasVietnameseCharacters(path: any): boolean {
    const vietnameseRegex = /[áàảãạâấầẩẫậăắằẳẵặéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵđ]/i;
    return vietnameseRegex.test(path);
}


function ChangeInfo() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data: useInfo, isLoading: isLoadGet, isError: isErrorGet, isSuccess: isSuccessGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()
    const [updateUserInfo, { isError: isErrorUpdate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] = useUpdateUserInfoMutation()
    const [downloadAvatar, { data: avatar, isLoading: isLoadingGetAvatar, isSuccess: isSuccessGetAvatar, isError: isErrorGetAvatar }] = fileApi.endpoints.downloadFile.useLazyQuery();
    const [uploadAvatarFile, { data: upLoadAvatar, isError: uploadAvatarError, isLoading: uploadAvatarLoading, isSuccess: uploadAvatarSuccess }] = useUploadAvatarMutation()
    const [edit, setEdit] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>(`${useInfo?.avatar}`);
    const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const [avatarFile, setAvatarFile] = useState<string>()
    const [render, setRender] = useState(false)
    const navigate = useNavigate();
    let isMobile = useIsMobile();

    const uploadAvatar: UploadProps = {
        // onRemove: file => { },
        beforeUpload: file => {
            const isImage = validImageTypes.includes(file.type);
            const maxSizeMB = 5;

            if (!isImage) {
                notification.error({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                {`Tên file không hợp lệ!`}
                            </div>
                        )
                })
                return Upload.LIST_IGNORE;
            }

            if (file.size / 1024 / 1024 > maxSizeMB) {
                notification.error({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                {`file chỉ chứa tối đa ${maxSizeMB}MB`}
                            </div>
                        )
                })
                return Upload.LIST_IGNORE;
            }
            const formData = new FormData();
            formData.append('file', file);

            console.log(formData);

            uploadAvatarFile(formData)
            return false;
        },
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        // if (info.file.status === 'uploading') {
        //     setLoading(true);
        //     return;
        // }
        // if (info.file.status === 'done') {
        //     // Get this url from response in real world.
        //     getBase64(info.file.originFileObj as RcFile, url => {
        //         setLoading(false);
        //         setImageUrl(url);
        //     });
        // }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleFinish = (values: any) => {
        // const updatedValues = {
        //     ...values,
        //     id: useInfo?.attributes?.id,
        //     srcImage: imageUrl
        // };
        // console.log(`PAV: ${values}`)
        // updateUserInfo(updatedValues)
        // setEdit(false)
    };

    const onCancel = () => {
        navigate('/admin/userinfo');
    };

    const onSave = async () => {
        try {
            const values = await form.validateFields();

            const updatedValues = {
                ...values,
                id: useInfo?.id,
                avatar: avatarFile
            };

            await updateUserInfo(updatedValues);
            refetch()

        } catch (error) {
            notification.error({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Lưu dữ liệu không thành công
                        </div>
                    )
            })
            console.error('Error:', error);
        }
    };

    // useEffect(()=> {
    //     refetch()
    // }, [])

    useEffect(() => {
        console.log("anh change", useInfo)
    }, [useInfo])


    useEffect(() => {
        if (!!useInfo?.avatar) {
            downloadAvatar({ fileId: useInfo?.avatar, objectType: null });
            setAvatarFile(useInfo?.avatar)
        }
    }, [useInfo]);

    useEffect(() => {
        if (!!avatarFile) {
            console.log("PAV AVATAR", avatarFile)
            downloadAvatar({ fileId: !!avatarFile ? avatarFile : "", objectType: null })
        }
    }, [avatarFile])

    useEffect(() => {
        if (isSuccessUpdate) {
            navigate('/admin/userinfo')
            setEdit(false)
        }
        if (isLoadingUpdate) {
            setEdit(true)
        }
        if (isErrorUpdate) {
            notification.error({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Lưu dữ liệu không thành công
                        </div>
                    )
            })
            setEdit(false)
        }
    }, [isSuccessUpdate, isErrorUpdate, isLoadingUpdate])

    useEffect(() => {
        if (uploadAvatarSuccess) {
            if (hasVietnameseCharacters(upLoadAvatar?.name)) {
                notification.info({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Tên File không hợp lệ
                            </div>
                        )
                })
                // message.info("Tên File ảnh không hợp lệ")
            }
            else {
                notification.success({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Tải avatar thành công!
                            </div>
                        )
                })
                setAvatarFile(!!upLoadAvatar?.fileRef ? upLoadAvatar?.fileRef : `${useInfo?.avatar}`)
                setEdit(false)
            }
        }
        else if (uploadAvatarLoading) {
            setEdit(true)
        }
        else if (uploadAvatarError) {

            notification.error({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Tải avatar không thành công!
                        </div>
                    )
            })
            setEdit(false)
        }

        if (isLoadingGetAvatar) setEdit(true)
    }, [uploadAvatarSuccess, uploadAvatarLoading, uploadAvatarError, isLoadingGetAvatar])

    return (
        <Form colon={false}
            form={form}
            initialValues={useInfo}
            onFinish={values => handleFinish(values)}
            layout="vertical"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Col
                lg={{ span: 24 }}
                style={{
                    backgroundColor: 'white',
                    padding: '1% 3% 1% 3%',
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
                    // minHeight: !isMobile ? window.innerHeight - 20 * window.innerHeight / 100 : 0,
                }}
            >
                <Row>
                    <Title level={3}>{useInfo?.fullName}</Title>
                </Row>

                {/* <Row>
                    <Col lg={{ span: 6 }} xs={{ span: 14 }}>
                        <Row gutter={[16, 0]}>
                            <Col lg={{ span: 12 }}>
                                <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={onSave} disabled={edit}>
                                    {t(translations.user.save)}
                                </Button>
                            </Col>
                            <Col lg={{ span: 12 }}>
                                <Button style={{ width: '100%' }} onClick={onCancel}>Back</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                <Row gutter={[24, 0]} style={{ marginBottom: '1%', marginTop: "5%" }} align={'middle'} justify={'space-around'}>
                    <Col
                        lg={{ span: 8 }}
                        xs={{ span: 24 }}
                        style={{ padding: 0 }}
                    >
                        <Row justify={'center'}>
                            <Col lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '5%' }}>
                                {
                                    isLoadingGetAvatar ? <Spin indicator={<LoadingOutlined style={{ fontSize: 124 }} spin />} />
                                        : (
                                            <div
                                                style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Image
                                                    src={avatar}
                                                    preview={false}
                                                    style={{ width: '100%', maxHeight: '20vw' }}
                                                />

                                            </div>
                                        )
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Upload
                                    name={'avatar'}
                                    {...uploadAvatar}
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                    onChange={handleChange}
                                >
                                    <Button style={{ width: '100%', padding: '0 8vw' }}>
                                        Tải ảnh lên
                                    </Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={{ span: 10 }} xs={{ span: 24 }}>
                        {/* <Form.Item
                            label={t(translations.user.firstName)}
                            name={'firstName'}
                            hasFeedback
                            rules={[yupSync]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t(translations.user.lastName)}
                            name={'lastName'}
                            hasFeedback
                            rules={[yupSync]}
                        >
                            <Input></Input>
                        </Form.Item> */}
                        <Form.Item
                            label={t(translations.user.fullName)}
                            name={'fullName'}
                            hasFeedback
                            rules={[yupSync]}
                        >
                            <Input></Input>
                        </Form.Item>

                        <Form.Item
                            label={t(translations.user.phone)}
                            name={'phoneNumber'}
                            rules={[yupSync]}
                            hasFeedback
                        >
                            <Input></Input>
                        </Form.Item>

                        <Form.Item
                            label={t(translations.user.email)}
                            name={'email'}
                            rules={[yupSync]}
                            hasFeedback
                        >
                            <Input></Input>
                        </Form.Item>
                        {/* <Form.Item
                            label={t(translations.user.position)}
                            name={'position'}
                            hasFeedback
                            rules={[yupSync]}
                        >
                            <Input></Input>
                        </Form.Item>
                        <Form.Item
                            label={t(translations.user.department)}
                            name={'department'}
                            hasFeedback
                            rules={[yupSync]}
                        >
                            <Input></Input>
                        </Form.Item> */}
                    </Col>
                </Row>
                <Row style={{ float: 'right', position: 'absolute', bottom: 0, right: 0 }} align={'bottom'}>
                    <Footer style={{ display: 'flex', backgroundColor: 'white' }}>
                        <Button style={{ width: '100%', marginRight: 10, padding: '0 20px' }} type="primary" htmlType="submit" onClick={onSave} disabled={edit}>
                            {t(translations.user.save)}
                        </Button>
                        <Button style={{ width: '100%', padding: '0 20px' }} onClick={onCancel}>Hủy</Button>
                    </Footer>
                </Row>
            </Col>
        </Form>

    );
}

export default ChangeInfo;
