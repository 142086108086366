import React, { useState } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography, Switch } from 'antd';
import './index.css';
import { useTransition, animated, config } from '@react-spring/web';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';

const { Text, Link, Title, Paragraph } = Typography;

function Ecosystem({ display }: any) {
  const [ellipsis, setEllipsis] = useState(true);
  const isMobile = useIsMobile()

  const sectionTransition = useTransition(display, {
    from: { x: -50, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: config.slow,
    delay: 500,
  });

  return (
    <div
      className="SECTION"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: !isMobile ? '3% 10% 0 10%' : '3% 5% 0 5%',
      }}
    >
      {sectionTransition((style, item) => (
        <animated.div style={style}>
          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Col
              lg={{ span: 14 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ marginBottom: '17vh' }}
            >
              <Title level={3} style={{ textAlign: 'center' }}>
              E2E digital finance platform for businesses and banks in the supply chain
              </Title>
            </Col>
          </Row>
          <Row justify="space-evenly" align="middle" gutter={[0, 128]}>
            <Col
              xxl={{ span: 7 }}
              lg={{ span: 16, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <Card
                style={{
                  borderColor: 'rgba(10, 103, 233, 0.3)',
                  minHeight: '30vw',
                  paddingBottom: 0,
                  position: 'relative',
                }}
                bodyStyle={{ padding: 0 }}
              >
                <img
                  style={{
                    position: 'absolute',
                    borderRadius: 10,
                    width: !isMobile ? 380 : 320,
                    height: !isMobile ? 240 : 180,
                    margin: 'auto',
                    display: 'block',
                    left: '50%',
                    transform: 'translate(-50%, -60%)',
                  }}
                  src="/images/home/SCF/lc-24-20221018174156-p3nss.png"
                />
                <div className="body" style={{
                  marginTop: '12vh'
                }}>
                  <Title
                    level={5}
                    style={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      color: ' rgb(10, 103, 233)',
                      textAlign: 'center',
                    }}
                  >
                    Banks
                  </Title>
                  <ul style={{ marginRight: 20, fontSize: 17, textAlign: 'justify', paddingBottom: 50 }}>
                    <li>
                    Expand connection and funding opportunities for diverse businesses, suppliers/distributors, creating sustainable, long-term cooperative relationships
                    </li>
                    <li>
                    Support to monitor operations and monitor the business situation and financial health of the enterprise
                    </li>
                    <li>
                    In-depth data analysis, providing early warnings to prevent risks of capital loss
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>

            <Col
              xxl={{ span: 7 }}
              lg={{ span: 16, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <Card
                style={{
                  borderColor: 'rgba(10, 103, 233, 0.3)',
                  minHeight: '30vw',
                  position: 'relative',
                  paddingBottom: 0,
                }}
                bodyStyle={{ padding: 0 }}
              >
                <img
                  style={{
                    position: 'absolute',
                    borderRadius: 10,
                    width: !isMobile ? 380 : 320,
                    height: !isMobile ? 240 : 180,
                    margin: 'auto',
                    display: 'block',
                    left: '50%',
                    transform: 'translate(-50%, -60%)',
                  }}
                  src="/images/home/SCF/image-13-2-1-20231001084412-byd3t.jpg"
                />
                <div className="body" style={{ marginTop: '12vh' }}>
                  <Title
                    level={5}
                    style={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      color: ' rgb(10, 103, 233)',
                      textAlign: 'center',
                    }}
                  >
                    Financed business
                  </Title>
                  <ul style={{ marginRight: 20, fontSize: 17, textAlign: 'justify' }}>
                    <li>
                    Enhance business reputation with the ability to pay early, shorten payment time, compete on costs, and expand customers
                    </li>
                    <li>
                    Increase liquidity and payment efficiency, easily control the cash flow of the business
                    </li>
                    <li>
                    Strengthen relationships with strategic suppliers/distributors, creating a long-term sustainable supply chain
                    </li>
                    <li>
                    Save loan costs with low interest rates 
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>

            <Col
              xxl={{ span: 7 }}
              lg={{ span: 16, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <Card
                style={{
                  borderColor: 'rgba(10, 103, 233, 0.3)',
                  minHeight: '30vw',
                  paddingBottom: 0,
                  position: 'relative',
                }}
                bodyStyle={{ padding: 0 }}
              >
                <img
                  style={{
                    position: 'absolute',
                    borderRadius: 10,
                    width: !isMobile ? 380 : 320,
                    height: !isMobile ? 240 : 180,
                    margin: 'auto',
                    display: 'block',
                    left: '50%',
                    transform: 'translate(-50%, -60%)',
                  }}
                  src="/images/home/SCF/mo-hinh-etradevn-15-20230704090338-h_kn-.png"
                />
                <div className="body" style={{ marginTop: '12vh' }}>
                  <Title
                    level={5}
                    style={{
                      fontSize: 22,
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      color: ' rgb(10, 103, 233)',
                      textAlign: 'center',
                    }}
                  >
                    Distributors
                  </Title>
                  <ul style={{ marginRight: 20, fontSize: 17, textAlign: 'justify' }}>
                    <li>
                    Easily increase working capital without affecting the business's credit limit
                    </li>
                    <li>
                    Receive early payment from businesses, quick disbursement from banks
                    </li>
                    <li>
                    Minimize payment risks with the Buyer
                    </li>
                    <li>
                    Predict demand – adjust production and supply based on big data 
                    </li>
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        </animated.div>
      ))}
    </div>
  );
}

export default Ecosystem;
