import { TaskData } from '@/shared/model/task/task-data.model';
import { baseAuthAPI } from './base-auth.api'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';

export const taskDataApi = baseAuthAPI.injectEndpoints({
  endpoints: (build) => ({
    // <{ apiResponse: TaskData[], totalCount: number}, Object>
    getTasks: build.query<{ tasks: TaskData[], totalCount: number }, Object>({
      query: (params: any) => {
        //Convert Ant Table Params to Jmix Params
        const loadOptions: SearchEntitiesRequestModel = {
          filter: {
            conditions: []
          },
          offset: params?.pagination?.current > 0 ? (params?.pagination?.current - 1) * params?.pagination?.pageSize : 0,
          limit: params?.pagination?.pageSize,
          returnCount: true,
          sort: 'createdDate'
        }
        return ({
          url: '/rest/portal/company-user/search',
          method: 'POST',
          body: loadOptions,
        })
      },
      transformResponse: async (response: any, meta: any) => {
        const totalCount = Number(meta?.response?.headers.get('X-Total-Count'));
        const tasks = await response;
        return { tasks: tasks, totalCount: totalCount }
      }
    }),
    getTask: build.query<TaskData, string>({
      query(id) {
        return {
          url: `/rest/portal/company-user/${id}?fetchPlan=taskData-fetch-plan`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetTasksQuery,
  useGetTaskQuery
} = taskDataApi

export const {
  endpoints: { getTasks, getTask},
} = taskDataApi