
import BankDetail from '@/modules/entities/bank/bank-detail'
import { baseAuthAPI } from './base-auth.api'
import {Bank} from '../../shared/model/bank.model'
import { News } from '@/shared/model/news.model'

const newsUrl = '/rest/portal/news'

export const newsApi = baseAuthAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllNews: build.query<News[], void>({
            query: () => ({
                url: `${newsUrl}`,
                method: 'GET'
            })
        }),

        addNews: build.mutation<News, Partial<News>>({
            query: (body) => ({
                url: `${newsUrl}`,
                method: 'POST',
                body,
            }),
        }),
    })
})

export const {
   useGetAllNewsQuery,
   useAddNewsMutation
  } = newsApi

export const {
endpoints: { getAllNews, addNews },
} = newsApi