import React from 'react';
import { Route} from 'react-router-dom';
import LoginRedirect from './modules/login/login-redirect';
import HomePage from './modules/home/';
import HomePageEn from './modules/home-en/';
import HomeRoutersEn from '@/modules/home-en/routes';
import HomeRouters from './modules/home/routes'
import EntitiesRoutes from '@/modules/entities/routes';
import RegistrationRoutes from '@/modules/registration/routes'
import PrivateRoute from '@/shared/auth/private-route';
import ErrorBoundaryRoutes from '@/shared/error/error-boundary-routes';
import PageNotFound from '@/shared/error/page-not-found';
import { AUTHORITIES } from '@/config/constants';
import OnboardingPage from '@/modules/onboarding';
import UserManage from './modules/user-manage';
import UserCompany from './modules/company-user-manage'
import CompanyBankAccount from './modules/bank-account';

import UserInfoRouter from './modules/user-info/routes'
import CompanyDetail from './modules/company';
import { useAppSelector } from './config/store';
import BankList from './modules/entities/bank/bank-list';
import AppLayoutRoutes from './shared/layout/app-layout/app-layout-routes';
import HomeLayoutRoutes from './shared/layout/home-layout/home-layout-routes';
import Dashboard from './modules/dashboard';
import { ConfigProvider } from 'antd';
import theme from '../src/shared/theme/themeConfig'
import PendingTask from './modules/bpm/bpm-dashboard/pending-task';
import themeApp from './shared/theme/themeAppLayoutConfig';
import { Company } from './shared/model/company.model';
import CompanyCommon from './modules/company/component/common';

const loading = <div>loading ...</div>;

const AppRoutes = () => {
  const currentLocale = useAppSelector((state) => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);

  return (
    <div >
      {
        !isAuthenticated ? (
          <ErrorBoundaryRoutes>
            <Route
              path="/"
              element={
                <HomeLayoutRoutes>
                  <ConfigProvider theme={theme} >
                    <ErrorBoundaryRoutes>z
                      <Route index element={currentLocale == "vi" ? <HomePage /> : <HomePageEn />} />
                      <Route path='*' element={currentLocale == "vi" ? <HomeRouters /> : <HomeRoutersEn />} />
                    </ErrorBoundaryRoutes>
                  </ConfigProvider>
                </HomeLayoutRoutes>
              }
            />

            <Route
              path="/home/*"
              element={
                <HomeLayoutRoutes>
                  <ConfigProvider theme={theme} >
                    <ErrorBoundaryRoutes>z
                      <Route index element={currentLocale == "vi" ? <HomePage /> : <HomePageEn />} />
                      <Route path='*' element={currentLocale == "vi" ? <HomeRouters /> : <HomeRoutersEn />} />
                    </ErrorBoundaryRoutes>
                  </ConfigProvider>
                </HomeLayoutRoutes>
              }
            />
            <Route
              path="/registration/*"
              element={
                <HomeLayoutRoutes>
                  <ErrorBoundaryRoutes>
                    <Route path='*' element={<RegistrationRoutes />} />
                  </ErrorBoundaryRoutes>
                </HomeLayoutRoutes>

              }
            />


            <Route path='pending-task/' element={<PendingTask />} />
            <Route path="login" element={<LoginRedirect />} />
            <Route
              path="admin/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY_ADMIN]}>
                  <ConfigProvider theme={themeApp} >
                    <AppLayoutRoutes>
                      <ErrorBoundaryRoutes>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='userinfo/*' element={<UserInfoRouter />} />
                        <Route path='company-detail/' element={<CompanyDetail />} />
                        <Route path='onboarding/*' element={!companyEntity.isOnboardingSuccess ? <OnboardingPage /> : <CompanyCommon/>} />
                        <Route path='usermanage/*' element={<UserManage />} />
                        <Route path='usercompany/*' element={<UserCompany />} />
                        <Route path='bankaccount/*' element={<CompanyBankAccount />} />
                        <Route path='bank/' element={<BankList />} />
                        <Route
                          path="entities/*"
                          element={
                            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY_ADMIN]}>
                              <EntitiesRoutes />
                            </PrivateRoute>
                          }
                        />
                      </ErrorBoundaryRoutes>
                    </AppLayoutRoutes>
                  </ConfigProvider>
                </PrivateRoute>
              }
            />


            <Route path="*" element={<PageNotFound />} />
          </ErrorBoundaryRoutes>
        ) : (
          <ErrorBoundaryRoutes>
              <Route
                path="/"
                element={
                  // <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY_ADMIN]}>
                    <ConfigProvider theme={themeApp} >
                      <AppLayoutRoutes>
                        <ErrorBoundaryRoutes>
                          <Route index element={<Dashboard />} />
                        </ErrorBoundaryRoutes>
                      </AppLayoutRoutes>
                    </ConfigProvider>
                  // </PrivateRoute>
                }
              />

            <Route
              path="/home/*"
              element={
                <HomeLayoutRoutes>
                  <ConfigProvider theme={theme} >
                    <ErrorBoundaryRoutes>
                      <Route path='*' element={currentLocale == "vi" ? <HomeRouters /> : <HomeRoutersEn />} />
                    </ErrorBoundaryRoutes>
                  </ConfigProvider>
                </HomeLayoutRoutes>
              }
            />
            <Route
              path="/registration/*"
              element={
                <HomeLayoutRoutes>
                  <ErrorBoundaryRoutes>
                    <Route path='*' element={<RegistrationRoutes />} />
                  </ErrorBoundaryRoutes>
                </HomeLayoutRoutes>

              }
            />



            <Route path='pending-task/' element={<PendingTask />} />
            <Route path="login" element={<LoginRedirect />} />
            <Route
              path="admin/*"
              element={
                <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY_ADMIN]}>
                  <ConfigProvider theme={themeApp} >
                    <AppLayoutRoutes>
                      <ErrorBoundaryRoutes>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='userinfo/*' element={<UserInfoRouter />} />
                        <Route path='company-detail/' element={<CompanyDetail />} />
                        <Route path='onboarding/*' element={companyEntity.isOnboardingSuccess ? <CompanyDetail/> : <OnboardingPage />} />
                        <Route path='usermanage/*' element={<UserManage />} />
                        <Route path='usercompany/*' element={<UserCompany />} />
                        <Route path='bankaccount/*' element={<CompanyBankAccount />} />
                        <Route path='bank/' element={<BankList />} />
                        <Route
                          path="entities/*"
                          element={
                            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.COMPANY_ADMIN]}>
                              <EntitiesRoutes />
                            </PrivateRoute>
                          }
                        />
                      </ErrorBoundaryRoutes>
                    </AppLayoutRoutes>
                  </ConfigProvider>
                </PrivateRoute>
              }
            />


            <Route path="*" element={<PageNotFound />} />
          </ErrorBoundaryRoutes>
        )
      }
    </div>
  );
};

export default AppRoutes;
