import { useAppDispatch, useAppSelector } from '@/config/store';
import { translations } from '@/i18n/i18n';
import { yupValidator } from '@/shared/utils/yup-utils';
import { Button, Form, Input, Row, Spin, Col, message, notification } from 'antd';
import i18next from 'i18next';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import './index.css';
import { createdRegistration } from './registration.reducer';
import { useAuth } from 'react-oidc-context';
import { registrationApi, useCheckCompanyCodeQuery, useCheckEmailQuery } from '@/services/api/registration.api';
import ConfirmEmail from './confirmEmail';
import { DeleteOutlined } from '@ant-design/icons';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_URL } from '@/config/config';

export default function RegistrationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [token, setToken] = useState<string | null>(null);
  const [emailToCheck, setEmailToCheck] = useState('');
  const [companyCodeToCheck, setCompanyCodeToCheck] = useState('');
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidated, setIsvalidated] = useState(false);
  const [requestCheckValid, setRequestCheckValid] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const registrationEntity = useAppSelector(state => state.registration.entity);
  const loading = useAppSelector(state => state.registration.loading);
  const updating = useAppSelector(state => state.registration.updating);
  const updateSuccess = useAppSelector(state => state.registration.updateSuccess);
  const [fucCheckEmail, { data: checkEmail, isLoading: loadingCheckEmail, isError: errorCheckEmail, isSuccess: successCheckEmail }] = registrationApi.endpoints.checkEmail.useLazyQuery();
  const [fucCheckCompanyCode, { data: checkCompanyCode, isLoading: loadingCheckCompanyCode, isError: errorCheckCompanyCode, isSuccess: successCheckCompanyCode }] = registrationApi.endpoints.checkCompanyCode.useLazyQuery();
  const [funCheckValidEmail, { data: createOtp, isLoading: loadingCreateOtp, isError: errorCreateOtp, isSuccess: successCreateOtp }] = registrationApi.endpoints.checkValidEmail.useLazyQuery();
  const [funRequestCheckValidEmail, { data: checkCodeEmailValid, isLoading: loadingReceiveEmail, isError: errorReceiveEmail, isSuccess: succedReceiveEmail }] = registrationApi.endpoints.sendRequestCheckValidEmail.useLazyQuery();

  const [wantCheckEmail, setWantCheckEmail] = useState(false);
  const [submit, setSubmit] = useState(false)
  const [inputNumber, setInputNumber] = useState('');
  const [randomNumber, setRandomNumber] = useState(0);

  const [allValid, setAllValid] = useState(false);
  const [formEntity, setFormEntity] = useState('')
  const emailRegex = /^(?=.{6,50}$)(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9._%+\-!#$%^&*()=]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/


  const auth = useAuth();
  const [hasError, setHasError] = useState(false);

  const handleValidation = async () => {
    try {
      await yupSync.validator({ userEmail: emailToCheck });
      setHasError(false);
    } catch (errors) {
      setHasError(true);
    }
  };

  const handleClearInput = (fieldName: any) => {
    form.setFieldsValue({ [fieldName]: '' });
  };

  useEffect(() => {
    if (updateSuccess) {
      navigate('/registration/success');
    }
  }, [updateSuccess]);

  const onVerify = useCallback(
    (token: string | null) => {
      setIsButtonEnabled(!!token);
    },
    [token],
  );

  useEffect(() => {
    reloadReCapcha();
  }, [i18next.language]);

  const reloadReCapcha = () => {
    setIsButtonEnabled(false);
    setRecaptchaKey(new Date().getTime().toString());
  };



  let schema = yup.object().shape({
    companyName: yup.string().required(t(translations.register.errors.companyNameError)),
    companyCode: yup.string().required(t(translations.register.errors.companyCodeError)),
    userEmail: yup
      .string()
      .required(t(translations.register.errors.emailError))
      .test('valid email', t(translations.register.errors.emailInvalid), (value: any) => {
        return emailRegex.test(value)
      })
      ,
    password: yup
      .string()
      .required(t(translations.register.passwordRequiredMessage))
      .min(12, t(translations.register.passwordMinLength))
      .matches(/[a-z]/, t(translations.register.passwordMinLowercase))
      .matches(/[A-Z]/, t(translations.register.passwordMinUppercase))
      .matches(/[0-9]/, t(translations.register.passwordMinNumbers))
      .matches(/[^\w]/, t(translations.register.passwordMinSymbols)),
    repassword: yup
      .string()
      .required('Confirmation is required field')
      .test(
        'Valid confirm password',
        t(translations.register.repasswordRequiredMessage),
        function (repassword, context) {
          const { password } = context.parent;
          return password == repassword;
        },
      ),

    // repassword: yup
    //   .string()
    //   .required(t(translations.register.repasswordRequiredMessage))
    //   // .oneOf([form.getFieldValue('password')], t(translations.register.errors.passwordVerifyError))
    //   .when([], (x: any, a) => {
    //     return a.oneOf([form.getFieldValue('password')], t(translations.register.repasswordRequiredMessage));
    //   }),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  const onFinishRegistration = async (values: any) => {
    await fucCheckEmail(emailToCheck)
    await fucCheckCompanyCode(companyCodeToCheck)
    await setSubmit(!submit)
  };

  // const onCheckValidEmail = () => {
  //   setWantCheckEmail(true)
  //   funCheckValidEmail(form);
  // }


  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`);

  //     if (!oidcStorage) {
  //       const urlRefreshToken = "https://sso-sit.tradeflat.com/realms/scf-sit/protocol/openid-connect/token";

  //       const data = new URLSearchParams({
  //         username: 'nguyentienha0379@gmail.com',
  //         password: 'Abcd@123456789',
  //         client_id: "portal-frontend",
  //         grant_type: "password",
  //       });

  //       try {
  //         const response = await axios.post(urlRefreshToken, data, {
  //           headers: {
  //             'Content-Type': 'application/x-www-form-urlencoded',
  //           },
  //         });

  //         // Assuming the response contains access token and refresh token
  //         const { access_token, refresh_token } = response.data;

  //         // Create the new oidcStorage object
  //         const oidcStorageNew = {
  //           ...JSON.parse(oidcStorage || '{}'),
  //           access_token,
  //           refresh_token,
  //         };

  //         localStorage.setItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`, JSON.stringify(response.data));

  //         console.log(JSON.stringify(response.data));
  //       } catch (error) {
  //         console.error('Error fetching token:', error);
  //       }
  //     }
  //   };

  //   fetchToken();
  // }, []);

  const onRequestCheckValidEmail = async () => {
    await funRequestCheckValidEmail(JSON.stringify({ email: emailToCheck, otp: inputNumber }));
    setRequestCheckValid(!requestCheckValid)
  }

  // useEffect(() => {
  //   if (checkCodeEmailValid) {
  //     setIsValidEmail(true)
  //     notification.success({
  //       message: "Xác thực email thành công"
  //     })

  //   } else {
  //     setIsValidEmail(false)
  //     notification.error({
  //       message: "Mã xác nhận không đúng"
  //     })
  //   }
  // }, [requestCheckValid])

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    setEmailToCheck(event.target.value);
  }

  function handleCompanyCodeChange(event: ChangeEvent<HTMLInputElement>) {
    setCompanyCodeToCheck(event.target.value);
  }

  const handleInputNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputNumber(e.target.value);
  }

  useEffect(() => {
    console.log(JSON.stringify(form.getFieldsValue()))
    if (errorCheckEmail || errorCheckCompanyCode) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Lỗi kiểm tra
            </div>
          )
      })
    }

    if (successCheckCompanyCode && checkCompanyCode) {
      notification.info({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Mã số thuế doanh nghiệp đã được sử dụng
            </div>
          )
      })
      reloadReCapcha();
    }

    else if (successCheckEmail && checkEmail) {
      notification.info({
        message: "Email này đã được sử dụng!",
        description: "Hãy sử dụng email khác để đăng ký"
      })
      reloadReCapcha();
    }

    else if (successCheckCompanyCode && successCheckEmail && !checkEmail && !checkCompanyCode) {
      const entity = {
        ...registrationEntity,
        ...values,
      };
      funCheckValidEmail(JSON.stringify(form.getFieldsValue()));
      setAllValid(true)
      setFormEntity(entity);
      // dispatch(createdRegistration(entity));
      // reloadReCapcha();
    }
  }, [submit])

  const onFinishFailed = () => {
    reloadReCapcha();
  };

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(()=>{
    const oidcStorage = localStorage.getItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`)
    if (oidcStorage) {
      localStorage.removeItem(`oidc.user:${KEYCLOAK_URL}:${KEYCLOAK_CLIENT_ID}`)
    }
  }, [])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setIsvalidated(true);
      },
      () => {
        setIsvalidated(false);
      },
    );
  }, [values]);

  useEffect(() => {
    if (successCreateOtp) {
      // console.log(checkCodeEmailValid);
      notification.success({
        message: "Đã gửi email",
        description: (
          <>
            Hệ thống đã gửi email cho bạn.
            <br />
            Vui lòng kiểm tra email để xác nhận.
          </>
        )
      });
    }

    if (errorCreateOtp) {
      notification.info({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Gửi email không thành công
            </div>
          )
      })
    }
  }, [createOtp, loadingCreateOtp, successCreateOtp, errorCreateOtp])

  return (
    <Spin spinning={loading || loadingCheckEmail || loadingCheckCompanyCode}>
      {
        !allValid && <Row className="registration" style={{ paddingTop: 63 }}>
          <Col
            lg={{ span: 12, order: 1 }}
            md={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
          >
            <div className="SECTION-LEFT">
              <h1 style={{ color: 'black', fontSize: 30 }}>
                {t(translations.register.registerTitle)}
              </h1>
              <Row justify={'center'} style={{ marginTop: 40 }}>
                <Form colon={false}
                  className="FormSignUp"
                  name="vertical_login"
                  form={form}
                  layout="vertical"
                  onFinish={onFinishRegistration}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label={t(translations.register.companyNameLabel)}
                    name="companyName"
                    style={{ marginBottom: 16 }}
                    hasFeedback
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder={t(translations.register.companyPlaceHolder)}
                      style={{ height: '40px' }}
                      autoComplete='off'
                    // suffix={<DeleteOutlined onClick={() => handleClearInput('companyName')} />}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t(translations.register.companyCodeLabel)}
                    name={'companyCode'}
                    style={{ marginBottom: 16 }}
                    hasFeedback
                    rules={[yupSync]}
                  >
                    <Input
                      placeholder={t(translations.register.companyCodePlaceHolder)}
                      style={{ height: '40px' }}
                      value={companyCodeToCheck}
                      onChange={handleCompanyCodeChange}
                      autoComplete='off'
                    // suffix={<DeleteOutlined onClick={() => handleClearInput('companyCode')} />}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t(translations.register.emaiLabel)}
                    name={'userEmail'}
                    extra={<div style={{ display: 'flex', justifyContent: 'start' }}>{t(translations.register.emailNote)}</div>}
                    style={{ marginBottom: 16 }}
                    hasFeedback
                    rules={[yupSync]}
                  // suffix={<DeleteOutlined onClick={() => handleClearInput('userEmail')} />}
                  >
                    <Input
                      placeholder={t(translations.register.emailHolder)}
                      style={{ height: '40px' }}
                      value={emailToCheck}
                      onChange={handleEmailChange}
                      autoComplete='off'
                    // suffix={<DeleteOutlined onClick={() => handleClearInput('userEmail')} />}
                    />
                  </Form.Item>
                  {/* <Button onClick={onCheckValidEmail}>Kiểm tra email</Button>
                   {
                     wantCheckEmail && <Row justify={'space-between'} style={{margin: "20px 0"}}>
                       <Col lg={{span:14}}>
                         <Input value={inputNumber} onChange={handleInputNumberChange} />
                       </Col>
                       <Col lg={{span:10}} style={{display:'flex', justifyContent:'end'}}>
                         <Button onClick={onRequestCheckValidEmail}>Nhập số xác thực</Button>
                       </Col>
   
                     </Row>
                   } */}
                  <Form.Item
                    label={t(translations.register.passwordLabel)}
                    name={'password'}
                    extra={<div style={{ display: 'flex', justifyContent: 'start' }}>{t(translations.register.passwordExtra)}</div>}
                    style={{ marginBottom: 16 }}
                    hasFeedback
                    rules={[yupSync]}
                  >
                    <Input.Password
                      placeholder={t(translations.register.passwordHolder)}
                      style={{ height: '40px' }}
                      autoComplete="new-password"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t(translations.register.passwordRepeatLabel)}
                    name={'repassword'}
                    style={{ marginBottom: 16 }}
                    dependencies={['password']}
                    hasFeedback
                    rules={[yupSync]}
                  >
                    <Input.Password
                      placeholder={t(translations.register.passwordRepeatHolder)}
                      style={{ height: '40px' }}
                      autoComplete='off'
                    />
                  </Form.Item>

                  {isValidated && (
                    <Form.Item rules={[{ required: true }]}>
                      <ReCAPTCHA
                        key={recaptchaKey}
                        sitekey="6LcEexkpAAAAADuucB4mCq4iQKjKI1wbpfn8fkWv"
                        onChange={onVerify}
                        type="image"
                        hl={i18next.language}
                      />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%', height: '40px' }}
                      disabled={!isButtonEnabled || updating}
                    >
                      {t(translations.register.registButton)}
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <span>
                      {t(translations.register.registerQuestion)}
                      <a onClick={() => auth.signinRedirect()}>{t(translations.register.loginNow)}</a>
                    </span>
                  </Form.Item>
                </Form>
              </Row>

            </div>
          </Col>



          <Col
            lg={{ span: 12, order: 2 }}
            md={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
          >
            <img className="SECTION-RIGHT" src={'images/440973907_1509936859960278_2734526752135319129_n.png'} alt="Registration Image" />
          </Col>
        </Row>
      }

      {
        allValid && <ConfirmEmail loadingSendEmail={loadingCreateOtp} emailToCheck={emailToCheck} entity={formEntity} />
      }

    </Spin>
  );
}
