import { createAsyncThunk,  isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from '@/shared/utils/entity-utils';
import {  createEntitySlice, serializeAxiosError } from '@/shared/reducers/reducer.utils';
import { CompanyBankAccount, defaultValue as defaultValueBankAccount } from "@/shared/model/company-bank-account.model"
import { axiosSecurity } from '@/config/axios-interceptor';
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model';

const initialState: any = {
  loading: false,
  errorMessage: null,
  entities: [] as CompanyBankAccount[],
  entity: defaultValueBankAccount,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = '/rest/portal/company-bank-account';

// Actions

export const getEntities = createAsyncThunk('company_bank_account/fetch_entity_list', async (params: SearchEntitiesRequestModel) => {
  const requestUrl = apiUrl + "/search"
  console.log("api get" + requestUrl)
  return axiosSecurity.post<CompanyBankAccount[]>(requestUrl, cleanEntity(params));
});

export const getEntity = createAsyncThunk(
  'company_bank_account/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axiosSecurity.get<CompanyBankAccount>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'company_bank_account/create_entity',
  async (entity: CompanyBankAccount, thunkAPI) => {
    const result = await axiosSecurity.post<CompanyBankAccount>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({filter : {}}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'company_bank_account/update_entity',
  async (entity: CompanyBankAccount, thunkAPI) => {
    const result = await axiosSecurity.put<CompanyBankAccount>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({filter : {}}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'company_bank_account/partial_update_entity',
  async (entity: CompanyBankAccount, thunkAPI) => {
    const result = await axiosSecurity.patch<CompanyBankAccount>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({filter : {}}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'company_bank_account/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axiosSecurity.delete<CompanyBankAccount>(requestUrl);
    thunkAPI.dispatch(getEntities({filter : {}}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice
export const CompanyBankAccountSlice = createEntitySlice({
  name: 'companyBankAccount',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CompanyBankAccountSlice.actions;

// Reducer
export default CompanyBankAccountSlice.reducer;  