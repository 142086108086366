import React, { useState, useEffect } from 'react';
import { Input, Button, Image, Row, Col, Card, Flex, Typography, Switch } from 'antd';
import { useTransition, animated, config } from '@react-spring/web';
import useIsMobile from '@/shared/utils/use-is-mobile.hook';

const { Text, Link, Title, Paragraph } = Typography;


function Innovation({ display }: any) {
    const isMobile = useIsMobile();

    const sectionImgStyle: React.CSSProperties = {
        position: 'absolute',
        opacity: 0.8,
        backgroundImage: "url(/images/home/BgUUjAbG20230923102722.jpg)",
        backgroundSize: 'cover',
        backgroundPosition: '50% 0%',
        backgroundRepeat: 'repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        overflow: 'hidden',
        height: isMobile ? '1200px' : '500px', padding: 0
    };

    const sectionLazyStyle: React.CSSProperties = {
        position: 'absolute',
        backgroundColor: 'rgb(6, 29, 125)',
        opacity: 0.5,
        top: 0,
        left: 0,
        width: '100%',
        pointerEvents: 'none',
        height: isMobile ? '1200px' : '500px',
    };

    const sectionDivStyle: React.CSSProperties = {
        position: 'relative',
        mixBlendMode: 'screen',
        willChange: 'transform, opacity',
        backgroundColor: 'rgb(17, 10, 92)',
        borderColor: 'rgb(47, 84, 235)',
        width: '100%',
        height: '240px',
        // width: '100%',
        // height: 'auto',
        opacity: 1,
        borderRadius: '0 100px 0 0',
        alignContent: 'center',
        border: '1px solid #1244a0'
    };

    const divDescribeStyle: React.CSSProperties = {
        position: 'absolute',
        fontWeight: 700,
        fontSize: '20px',
        color: '#f6a400',
        margin: 0,
        top: '10%',
        left: '10%',
    };

    const divTextStyle: React.CSSProperties = {
        position: 'absolute',
        color: 'white',
        top: '60%',
        left: '10%',
        right: '7%',
    };


    return (
        <div
            style={{
                position: 'relative',
                height: isMobile ? '1200px' : '500px',
            }}
        >
            <div style={sectionImgStyle}></div>
            <div style={sectionLazyStyle}></div>
            <div style={{
                margin: !isMobile ? '0 13% 0 13%' : '0 5% 0 5%'
            }}>
                <Row justify={'center'}>
                    <Col lg={{ span: 10 }} md={{ span: 20 }} sm={{ span: 20 }} xs={{ span: 24 }} style={{ marginTop: '2%', textAlign: 'center' }}>
                        <Title level={3} style={{ color: 'white', textAlign: 'center' }}>E2E SCF transaction digitalization on Blockchain platform</Title>
                    </Col>
                </Row>

                <Row gutter={[0, 64]} justify= {!isMobile ? "space-between" : 'center'} align={'middle'}>
                    <Col xxl={{span:7}} xl={{span:7}} lg={{ span: 8 }} md={{ span: 16 }} sm={{ span: 18 }} xs={{ span: 24 }} style={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%' }}>
                        <div style={sectionDivStyle}>
                            <Text style={divDescribeStyle}> DISBURSEMENT AFTER</Text>
                            <Text style={{ position: 'absolute', fontWeight: 700, fontSize: 75, color: '#f6a400', margin: 0, top: '17%', left: '8%' }}>2DAYS</Text>
                            <Text style={divTextStyle}>Shorten disbursement time between banks and businesses: from 10-15 days to 2-3 days</Text>
                        </div>
                    </Col>
                    <Col xxl={{span:7}} xl={{span:7}} lg={{ span: 8 }} md={{ span: 16 }} sm={{ span: 18 }} xs={{ span: 24 }} style={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div  style={sectionDivStyle}>
                            <Text style={divDescribeStyle}>PAYMENT CYCLE</Text>
                            <Text style={{ position: 'absolute', fontWeight: 700, fontSize: 75, color: '#f6a400', margin: 0, top: '17%', left: '10%' }}>10DAYS</Text>
                            <Text style={divTextStyle}>Shorten the payment cycle of businesses with suppliers: from 60-180 days to 10-30 days</Text>
                        </div>
                    </Col>
                    <Col xxl={{span:7}} xl={{span:7}} lg={{ span: 8 }} md={{ span: 16 }} sm={{ span: 18 }} xs={{ span: 24 }} style={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div  style={sectionDivStyle}>
                            <Text style={divDescribeStyle}>REDUCE</Text>
                            <Text style={{ position: 'absolute', fontWeight: 700, fontSize: 75, color: '#f6a400', margin: 0, top: '17%', left: '8%' }}>90%</Text>
                            <Text style={divTextStyle}>Reduce payment risks up to 90%</Text>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>

    );
}

export default Innovation;