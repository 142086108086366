import React from 'react';
import {  PathRouteProps} from 'react-router-dom';
import ErrorBoundary from '@/shared/error/error-boundary';
import AppLayout from '.';
import { ConfigProvider } from 'antd';
import theme from '../../theme/themeConfig'
export default function AppLayoutRoutes({ children, ...rest }: PathRouteProps) {
    return (
        <ErrorBoundary>
            
            <AppLayout>
                {children}
            </AppLayout>
        </ErrorBoundary>
    );
};
