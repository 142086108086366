import React from "react";
import { Input, Button, Image, Row, Col, Card, Flex, Typography } from "antd";
import { useTransition, animated, config } from "@react-spring/web";
import useIsMobile from "@/shared/utils/use-is-mobile.hook";

const { Text, Link, Title } = Typography;

function Platform({ display }:any) {
    const isMobile = useIsMobile()
    const titleTransition = useTransition(display, {
        from: { y: -50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        config: config.slow,
        delay: 500
    })

    const imageTransition = useTransition(display, {
        from: { x: 50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500
    })

    const textTransition = useTransition(display, {
        from: { x: -50, opacity: 0 },
        enter: { x: 0, opacity: 1 },
        config: config.slow,
        delay: 500
    })

    return (
        <div style={{ margin: !isMobile ? "2% 13% 0 13%" : '2% 5% 0 5%' }}>
            <Col>
                <Row justify={"center"} style={{ textAlign: "center", marginBottom: '3%' }}>
                    {
                        titleTransition((style, item) => (
                            <animated.div style={style}>
                                <Title level={3}>
                                Digital finance, data analytics platform for businesses
                                </Title>
                            </animated.div>
                        ))
                    }
                </Row>
                <Row align={'middle'}>
                    <Col lg={{ span: 12, order: 1 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }} style={{ marginTop: '3%' }}>
                        {
                            textTransition((style, item) => (
                                <animated.div style={style}>
                                    <Row>
                                        <Col>
                                            <Title level={4}>Digital connection - Digital report</Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 20 }}>
                                            <Text
                                                style={{
                                                    lineHeight: 1.6,
                                                    textAlign: "justify",
                                                    display: "block",
                                                    
                                                    fontWeight: 400,
                                                    color: 'rgb(41, 41, 41)',
                                                }}
                                            >
                                                Businesses can now utilize a digital finance and business analysis platform based on big data to gauge their "business health". The platform offers accurate overviews to guide business strategies, while effectively monitoring business operations forboth businesses and banks.
                                            </Text>
                                        </Col>
                                    </Row>
                                </animated.div>
                            ))
                        }
                    </Col>
                    <Col lg={{ span: 12, order: 2 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
                        {
                            imageTransition((style, item) => (
                                <animated.div style={style}>
                                    <Image
                                        preview={false}
                                        src="/images/home/Business-health/mo-hinh-etradevn-21-20230704091315-zboui.png"
                                        style={{ borderRadius: "100px 0 0 0" }}
                                    ></Image>
                                </animated.div>
                            ))
                        }
                    </Col>
                </Row>
            </Col>
        </div>
    );
}

export default Platform;
