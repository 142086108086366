import React from 'react';
import { Translate } from '@/shared/utils/translate';
// import { Alert } from 'reactstrap';
import {Alert} from 'antd'
const PageNotFound = () => {
  return (
    <div>
      <h1>Khong ton tai trang</h1>
      <Alert message="Error Text" type="error" />
    </div>
  );
};

export default PageNotFound;
