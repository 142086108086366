import React from "react";
import { useTranslation } from "react-i18next";
import { translations } from "@/i18n/i18n";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import MyTaskComponent from "./my-task";
import GroupTaskComponent from "./group-task";
import ExpiredTaskComponent from "./expired-task";
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';

const breadcrumbItems: ItemType[] = [
    {
        title: 'Trang chủ',
    },
    {
        title: 'Quản lý người dùng doanh nghiệp',
        href: '',
    },
    {
        title: 'Danh sách người dùng',
    }
]

export default function PendingTask() {
    const { t } = useTranslation();
    const items: TabsProps['items'] = [
        {
          key: 'myTaskTab',
          label: t(translations.bpm.bpmDashboard.pendingTask.myTask.myTask),
          children: <MyTaskComponent/>,
        },
        {
          key: 'groupTaskTab',
          label: t(translations.bpm.bpmDashboard.pendingTask.groupTask.groupTask),
          children: <GroupTaskComponent/>,
        },
        {
          key: 'expiredTaskTab',
          label:t(translations.bpm.bpmDashboard.pendingTask.expiredTask.expiredTask),
          children: <ExpiredTaskComponent/>,
        },
      ];
      const onChange = (key: string) => {
        console.log(key);
      };

    return(<>
     <Tabs defaultActiveKey="myTaskTab" items={items} onChange={onChange} />
    </>)
}