import { useAppSelector } from '@/config/store';
import { translations } from '@/i18n/i18n';
import { InfoCircleFilled } from '@ant-design/icons';
import { Button, ConfigProvider, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import './../index.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

export default function RegistrationFail() {
  const registrationEntity = useAppSelector(state => state.registration.entity);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  const returnToLogin = () => {
    // navigate('/registration/login');
    auth.signinRedirect();
  };

  const forgotPassword = () => {
    navigate('/registration/forgotPassword');
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1877F2',
        },
      }}
    >
      <div className="fail">
        <div className="SECTION-LEFT">
          <Space direction="vertical" align="center" size={'middle'} style={{ width: '50%' }}>
            <img src="../images/TradeFlat_logo_01.png" />
            <InfoCircleFilled className="infor-icon" style={{ marginTop: '24px' }} />
            <span style={{ fontSize: '30px' }}>{t(translations.register.failure.title1)}</span>
            <span>{t(translations.register.failure.subTitle1)}</span>
            <Row style={{ marginTop: '24px' }}>
              <Button
                type="primary"
                style={{ padding: '0 5vw', height: '40px' }}
                onClick={returnToLogin}
              >
                {t(translations.register.failure.returnToLoginBtn)}
              </Button>
            </Row>
            <Row>
              <span>
                <a href="#" onClick={forgotPassword}>
                  {t(translations.register.failure.forgotPassword)}
                </a>
              </span>
            </Row>
          </Space>
        </div>
        <img
          className="SECTION-RIGHT"
          src={'../images/registration.jpg'}
          alt="Registration Image"
        />
      </div>
    </ConfigProvider>
  );
}
