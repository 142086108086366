export  interface Registration {
    id?: string;
    instanceName?: string | null;
    companyCode?: string | null;
    companyName?: string | null;
    userEmail?: string | null;
    password?: string | null;   
    userTelephone?: string | null;
    userComment?: string | null;    
    regDate?:  string | null;
    regExpiredDate?:  string | null;
  }

  export const defaultValue: Readonly<Registration> = {};