import { EntityFilterModel, defaultEntityFilter } from "./filter";


export interface SearchEntitiesRequestModel {
    filter: EntityFilterModel ;
    view?: string | null;
    fetchPlan?: string | null;
    limit?: number | null;
    offset?: number | null;
    sort?: string | null;
    returnNulls?: boolean | null;
    returnCount?: boolean | null;
    dynamicAttributes?: boolean | null;
    modelVersion?: string | null;
}
export const defaultValue: Readonly<SearchEntitiesRequestModel> = {
    filter: defaultEntityFilter
};