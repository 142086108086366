import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row, Table, Form, Input, Space, Modal, message, Select, Typography } from 'antd'
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    SearchOutlined,
} from '@ant-design/icons'
import { Content } from 'antd/es/layout/layout'

import './index.css'
import { translations } from '@/i18n/i18n'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/config/store'
import type { GetProp, InputRef, TableColumnsType, TableColumnType, TableProps } from 'antd';
import { updateEntity } from './bank-account.reducer'
import { CompanyBankAccount } from '@/shared/model/company-bank-account.model'
import { useNavigate } from 'react-router-dom'
import { SearchEntitiesRequestModel } from '@/shared/model/search-api-model/search-entities-request.model'
import { useGetCompanyBankAccountsQuery, useAddCompanyBankAccountsMutation, useDeleteCompanyBankAccountMutation } from '@/services/api/company-bank-account.api'
import { useGetAllBankQuery } from '@/services/api/bank.api'
import { CompanyBankAccountTypeEnum } from '@/shared/model/enums/enums'
import { FilterDropdownProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words';


const { Text } = Typography
type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;
interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}
type TablePagination<T extends object> = NonNullable<Exclude<TableProps<T>['pagination'], boolean>>;
type TablePaginationPosition = NonNullable<TablePagination<any>['position']>[number];


type DataIndex = keyof CompanyBankAccount;

export default function BankAccountList(props: any) {
    // const { t } = useTranslation()
    // const navigate = useNavigate()
    // const dispatch = useAppDispatch()
    // const companyList = useAppSelector(state => state.company.entities)
    // const [isModalOpen, setIsModalOpen] = useState(false)
    // const [handleOkCase] = useState(true)
    // const [confirmDelete, setConfirmDelete] = useState(false)
    // const [form] = Form.useForm()
    // const [bankAccountType] = useState(['TAI_KHOAN_THANH_TOAN'])
    // const { data: bankList = [], refetch: refetchBankList } = useGetAllBankQuery()
    // const loadOptions: SearchEntitiesRequestModel = {
    //     filter: {
    //         conditions: []
    //     },
    //     fetchPlan: 'companyBankAccount-fetch-plan'
    // }
    // const { data, refetch } = useGetCompanyBankAccountsQuery(loadOptions)
    // const [addCompanyBankAccount, { isError: isErrorAdd, isSuccess: isSuccessAdd }] = useAddCompanyBankAccountsMutation()
    // const [deleteCompanyBankAccount, {  isSuccess: isSuccessDelete }] = useDeleteCompanyBankAccountMutation()
    // const [top] = useState<TablePaginationPosition>('topRight');
    // const [allDataCompanyBankAccount, setAllDataCompanyBankAccount] = useState<CompanyBankAccount[] | undefined>([]);

    // const [searchText, setSearchText] = useState('');
    // const [searchedColumn, setSearchedColumn] = useState('');
    // const searchInput = useRef<InputRef>(null);

    // const generateKey = (record: any) => {
    //     return record.id
    // }

    // const pagination = {
    //     pageSize: 10,
    //     position: [top],
    // }
    // const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    // const handleSearch = (
    //     selectedKeys: string[],
    //     confirm: FilterDropdownProps['confirm'],
    //     dataIndex: DataIndex,
    // ) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };

    // const handleReset = (clearFilters: () => void) => {
    //     clearFilters();
    //     setSearchText('');
    // };

    // const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<CompanyBankAccount> => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
    //                 style={{ marginBottom: 8, display: 'block' }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
    //                     // icon={<SearchOutlined />}
    //                     size="small"
    //                 // style={{ width: 90 }}
    //                 >
    //                     Search
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters)}
    //                     size="small"
    //                     style={{ width: 90 }}
    //                 >
    //                     Reset
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         confirm({ closeDropdown: false });
    //                         setSearchText((selectedKeys as string[])[0]);
    //                         setSearchedColumn(dataIndex);
    //                     }}
    //                 >
    //                     Filter
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         close();
    //                     }}
    //                 >
    //                     close
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered: boolean) => (
    //         <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    //     ),
    //     onFilter: (value, record) => {
    //         const recordValue = record[dataIndex];
    //         if ( !recordValue ) {
    //             return false;
    //         }
    //         return recordValue.toString().toLowerCase().includes((value as string).toLowerCase());
    //     },
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) =>
    //         searchedColumn === dataIndex ? (
    //             <Highlighter
    //                 highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={text ? text.toString() : ''}
    //             />
    //         ) : (
    //             text
    //         ),
    // });

    // const columns: TableColumnsType<CompanyBankAccount> = [
    //     {
    //         title: t(translations.companyBankAccount.bankAccountType),
    //         dataIndex: 'bankAccountType',
    //         key: 'bankAccountType',

    //     },
    //     {
    //         title: t(translations.companyBankAccount.accountNumber),
    //         dataIndex: 'accountNumber',
    //         key: 'accountNumber',
    //         ...getColumnSearchProps('accountNumber'),

    //     },
    //     {
    //         title: t(translations.companyBankAccount.accountOwner),
    //         dataIndex: 'accountOwner',
    //         key: 'accountOwner',
    //         ...getColumnSearchProps('accountOwner'),
    //     },
    //     {
    //         title: t(translations.companyBankAccount.bank),
    //         dataIndex: 'bank',
    //         key: 'bank',
    //         render: (text: any, record: any) => {
    //             return text !== undefined ? text.name : ""
    //         },
    //         sorter: (a, b) => ((a.bank?.name ?? '')).localeCompare(b.bank?.name ?? ''),
    //     },
    //     {
    //         title: "Ngày tạo",
    //         dataIndex: 'createdDate',
    //         key: 'createdDate',
    //         sorter: (a, b) => new Date(a.createdDate ?? "").getTime() - new Date(b.createdDate ?? "").getTime(),

    //     },
    //     {
    //         title: "Người sửa",
    //         dataIndex: 'createdBy',
    //         key: 'createdBy',
    //         sorter: (a, b) => (a.createdBy ?? '').localeCompare(b.createdBy ?? ''),
    //     },
    //     {
    //         title: "Ngày sửa",
    //         dataIndex: 'lastModifiedDate',
    //         key: 'lastModifiedDate',
    //         sorter: (a, b) => new Date(a.createdDate ?? "").getTime() - new Date(b.createdDate ?? "").getTime(),
    //     },
    // ]

    // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    //     setSelectedRowKeys(newSelectedRowKeys)
    // }
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // }

    // const onCreateNew = () => {
    //     navigate('/admin/bankaccount/new')
    // }
    // const onEdit = () => {
    //     navigate(`/admin/bankaccount/${selectedRowKeys}/edit`)
    // }
    // const onDelete = () => {
    //     setConfirmDelete(true)
    // }

    // const handelDeleted = () => {
    //     for (const key of selectedRowKeys as string[]) {
    //         deleteCompanyBankAccount(key)
    //     }
    //     setConfirmDelete(false)
    //     message.info(t(translations.companyBankAccount.delete.messageSucces))
    // }

    // const handleCreate = async () => {
    //     const values = await form.getFieldsValue()
    //     values.bank = bankList.filter(bank => bank.id === values.bank)[0]
    //     values.owner = companyList.filter(company => company.id === values.owner)[0]
    //     console.log('Submit successful:', values)
    //     addCompanyBankAccount(values)

    //     if (isSuccessAdd) {
    //         message.success(t(translations.companyBankAccount.create.messageSucces))
    //     }
    //     if (isErrorAdd) {
    //         message.error(t(translations.companyBankAccount.create.messageError))

    //     }
    //     setIsModalOpen(false)
    // }

    // const handleUpdate = async () => {
    //     try {
    //         const values = await form.getFieldsValue()
    //         values.bank = bankList.filter(bank => bank.name === values.bank || bank.id === values.bank)[0]
    //         values.owner = companyList.filter(company => company.name === values.owner || company.id === values.owner)[0]
    //         values.id = selectedRowKeys[0]
    //         console.log('Submit successful:', values)
    //         dispatch(updateEntity(values))
    //         message.success(t(translations.companyBankAccount.edit.messageSucces))
    //     }
    //     catch {
    //         console.log('Submit fails:')
    //         message.error(t(translations.companyBankAccount.edit.messageError))
    //     }
    //     setIsModalOpen(false)
    // }

    // const handleCancel = () => {
    //     setIsModalOpen(false)
    //     setConfirmDelete(false)
    // }

    // useEffect(() => {
    //     refetch()
    // }, [])

    // useEffect(() => {
    //     if (isSuccessDelete) {
    //         refetch();
    //         refetchBankList();
    //     }
    // }, [isSuccessDelete])

    // useEffect(() => {
    //     const transformedData = data?.map(companyBankAccount => {
    //         if (companyBankAccount?.bankAccountType?.toString() === "TAI_KHOAN_THANH_TOAN") {
    //             return { ...companyBankAccount, bankAccountType: CompanyBankAccountTypeEnum.paymentAccount };
    //         }
    //         return companyBankAccount;
    //     });
    //     setAllDataCompanyBankAccount(transformedData);
    //     console.log("allDataCompanyBankAccount: ", data)
    // }, [data])

    return (
        <>
            {/* <div className='companyBankAccount'>
            <Content className="ContentBankAccount" style={{ margin: 20 }}>
                <Row gutter={[12, 12]}>
                    <Col>
                        <Button type="primary" onClick={onCreateNew} style={{ backgroundColor: '#2F57FA' }}>
                            <PlusOutlined />
                            {t(translations.companyBankAccount.create.button)}
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={onEdit} disabled={selectedRowKeys.length != 1} style={{}}>
                            <EditOutlined />
                            {t(translations.companyBankAccount.edit.button)}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={onDelete}
                            disabled={!selectedRowKeys.length || selectedRowKeys.length < 0}
                        >
                            <DeleteOutlined />
                            {t(translations.companyBankAccount.delete.button)}
                        </Button>
                    </Col>
                </Row>
                <Table
                    rowSelection={rowSelection}
                    dataSource={allDataCompanyBankAccount}
                    columns={columns}
                    pagination={pagination}
                    rowKey={generateKey}
                    scroll={{ x: 'calc(300px + 50%)' }}
                />
            </Content>
            <Modal
                title={t(translations.companyBankAccount.title)}
                open={isModalOpen}
                onOk={handleOkCase ? handleCreate : handleUpdate}
                onCancel={handleCancel}
            >

                <Form colon={false}  form={form} labelCol={{ lg: { span: 8 } }} style={{ marginTop: '3vw' }}>
                    <Form.Item label={t(translations.companyBankAccount.owner)} name="owner">
                        <Select style={{ width: 315 }}>
                            {companyList.map(company => (
                                <Select.Option key={company.id} value={company.id}>
                                    {company.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t(translations.companyBankAccount.accountOwner)} name="accountOwner">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t(translations.companyBankAccount.accountNumber)} name="accountNumber">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t(translations.companyBankAccount.bank)} name="bank">
                        <Select style={{ width: 315 }}>
                            {bankList.map(bank => (
                                <Select.Option key={bank.id} value={bank.id}>
                                    {bank.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label={t(translations.companyBankAccount.bankAccountType)} name="bankAccountType">
                        <Select style={{ width: 315 }}>
                            {bankAccountType.map(type => (
                                <Select.Option key={type} value={type}>
                                    {type === "TAI_KHOAN_THANH_TOAN" ? "Tài khoản thanh toán" : ""}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={t(translations.companyBankAccount.delete.confirm)}
                open={confirmDelete}
                onOk={handelDeleted}
                onCancel={handleCancel}
            >
                <Text>{t(translations.companyBankAccount.delete.textInfoConfirm)}</Text>
            </Modal>
        </div> */}
        </>
    )
}
