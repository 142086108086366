import './index.css';
import React, { useEffect, useState } from 'react';
import { Steps, Button, Layout, Col, Row, Form, Typography, Input, Card, Modal, Result, Spin, message, notification, DatePicker } from 'antd';
import { UploadOutlined, BellOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Content, Footer, Header } from 'antd/es/layout/layout';

import { BrowserRouter as Router, Link, Route, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { deepMerge } from '@/shared/utils/object-utils';
import { goNext, goPrev, goToStep, updateDigitalSign } from '../../onboarding.reducer';
import { useUpdateCompanyMutation } from '@/services/api/onboarding.api';
import Title from 'antd/es/typography/Title';
import { useDigitalSignatureMutation, useGetTokenMutation } from '@/services/api/digital-signature.api';
import dayjs from 'dayjs';
import { NEW_CODE } from '@/config/config';

const { Text } = Typography;
const { Step } = Steps;

const handleSubjectDN = (SubjectDN: string) => {
    const mstMatch = SubjectDN.match(/MST:(\d+),/)
    return mstMatch ? mstMatch[1] : 'N/A'
}

const parseExpiryDate = (dateStr: string): Date => {
    if (dateStr.includes(' ')) {
        const [datePart, timePart] = dateStr.split(' ');
        const [day, month, year] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
    }
    else {
        const [day, month, year] = dateStr.split('-').map(Number);
        return new Date(year, month - 1, day);

    }

};

export default function OnboardingDigitalSignRegistration(props: any) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const { onNext, onPrev, onSaveDraft } = props;
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    const { digitalSign } = companyEntity;
    const [updateCompany] = useUpdateCompanyMutation()
    const [getToken, { data: token, isLoading: loadingGetToken, isSuccess: successGetToken, isError: errorGetToken }] = useGetTokenMutation()
    const [funcDigitalSign, { data: dataDigitalSign, isLoading: loadingDigitalSign, isError: errorDigitalSign, isSuccess: successDigitalSign }] = useDigitalSignatureMutation()
    const [openModal, setOpenModal] = useState(false)
    const [clickGetToken, setClickGetToken] = useState(false)

    const [buttonToTest, setButtonToTest] = useState(true)

    const onSaveEntity = () => {
        form.validateFields().then((values: any) => {
            const entity = deepMerge(companyEntity, values);
            //Save data to Backend
            updateCompany(entity)
        });
    };

    const onGoNext = () => {
        form
            .validateFields()
            .then(values => {
                let entity = deepMerge(companyEntity, {
                    digitalSign: {
                        ...companyEntity.digitalSign,
                        ...(buttonToTest ? { toDate: companyEntity?.digitalSign?.toDate } : { toDate: values.toDate })
                    },
                });


                if ((companyEntity?.digitalSign?.digitalSignStatus && companyEntity?.digitalSign?.digitalSignStatus.toString() === "PENDING") || !companyEntity?.digitalSign?.digitalSignStatus) {
                    setOpenModal(true)
                }
                else {
                    if (buttonToTest) {
                        if (companyEntity?.digitalSign?.taxCode === companyEntity.code) {
                            let fromDate = companyEntity.digitalSign.fromDate?.includes("/") ? dayjs(companyEntity?.digitalSign?.fromDate, "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm") : companyEntity?.digitalSign?.fromDate
                            if (companyEntity.digitalSign.toDate?.includes("-")) {
                                if (companyEntity?.digitalSign?.toDate && dayjs(companyEntity?.digitalSign?.toDate, "DD-MM-YYYY HH:mm").isAfter(dayjs())) {
                                    dispatch(goNext(entity));
                                }
                                else {
                                    notification.error({
                                        message:
                                            (
                                                <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                                    Chứng thư số đã hết hạn!
                                                </div>
                                            )
                                    })
                                }
                            }
                            else if (companyEntity.digitalSign.toDate?.includes("/")) {
                                if (companyEntity?.digitalSign?.toDate && dayjs(companyEntity?.digitalSign?.toDate, "DD/MM/YYYY HH:mm").isAfter(dayjs())) {
                                    entity = {
                                        ...entity,
                                        digitalSign: {
                                            ...entity.digitalSign,
                                            fromDate: fromDate,
                                            toDate: dayjs(companyEntity?.digitalSign?.toDate, "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm")
                                        }
                                    }
                                    dispatch(goNext(entity));
                                }
                                else {
                                    notification.error({
                                        message:
                                            (
                                                <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                                    Chứng thư số đã hết hạn!
                                                </div>
                                            )
                                    })
                                }
                            }

                        }
                        else {
                            notification.error({
                                message:
                                    (
                                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                            Thông tin chứng thư số không phù hợp với Thông tin doanh nghiệp đăng ký!
                                        </div>
                                    )
                            })
                        }
                    }
                    else {
                        if (companyEntity?.code === form.getFieldValue('taxCode')) {
                            let fromDate = companyEntity.digitalSign.fromDate?.includes("/") ? dayjs(companyEntity?.digitalSign?.fromDate, "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm") : companyEntity?.digitalSign?.fromDate

                            if (dayjs(form.getFieldValue('toDate'), "DD/MM/YYYY HH:mm").isAfter(dayjs())) {
                                entity = {
                                    ...entity,

                                    digitalSign: {
                                        ...entity.digitalSign,
                                        taxCode: form.getFieldValue('taxCode'),
                                        subject: form.getFieldValue('subject'),
                                        fromDate: fromDate,
                                        toDate: dayjs(form.getFieldValue('toDate'), "DD/MM/YYYY HH:mm").format("DD-MM-YYYY HH:mm")
                                    }
                                }
                                dispatch(goNext(entity));
                            }
                            else {
                                notification.error({
                                    message:
                                        (
                                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                                Chứng thư số đã hết hạn!
                                            </div>
                                        )
                                })
                            }
                        }
                        else {
                            notification.error({
                                message:
                                    (
                                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                            Thông tin chứng thư số không phù hợp với Thông tin doanh nghiệp đăng ký!
                                        </div>
                                    )
                            })
                        }
                    }
                }
            })
            .catch(errorInfo => {console.log(errorInfo)});
    };
    const onGoPrev = () => {
        form
            .validateFields()
            .then(values => {
                const entity = deepMerge(companyEntity, values);
                dispatch(goPrev(entity));
            })
            .catch(errorInfo => {console.log(errorInfo)});
    };
    const onClose = () => {
        //Check if editingMode = true
        dispatch(goToStep(1));
        //Navigate to Home
        navigate('/');
    };
    useEffect(() => {
        if (errorGetToken) notification.error({
            message:
                (
                    <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                        errorGetToken
                    </div>
                )
        })
        if (successGetToken && token) {
            funcDigitalSign(token)
        }
    }, [getToken, successGetToken, loadingGetToken, errorGetToken]);

    useEffect(() => {
        const submitDigitalSign = async () => {
            if (successDigitalSign) {
                if (dataDigitalSign?.ResponseCode === 1) {
                    notification.error({
                        message:
                            (
                                <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                    Chứng thư số không được chọn!
                                </div>
                            )
                    })
                    return
                }
                else {
                    // notification.success({ message: "Digital Sign successfully" })

                    if (dataDigitalSign?.certInfo?.SubjectDN?.includes("CCCD")) {
                        notification.error({
                            message:
                                (
                                    <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                        Chữ ký số không hợp lệ
                                    </div>
                                )
                        })
                    }

                    else {
                        let dataUpdateDigitalSign = {
                            issuer: dataDigitalSign?.certInfo?.Issuer,
                            serial: dataDigitalSign?.certInfo?.SerialNumber,
                            fromDate: dataDigitalSign?.certInfo?.NotBefore,
                            toDate: dataDigitalSign?.certInfo?.NotAfter ? dayjs(dataDigitalSign?.certInfo?.NotAfter, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm") : "",
                            subject: dataDigitalSign?.certInfo?.CommonName,
                            taxCode: handleSubjectDN(dataDigitalSign?.certInfo?.SubjectDN || ""),
                            digitalSignStatus: "SUCCESS"
                        }
                        await dispatch(updateDigitalSign(dataUpdateDigitalSign))
                        form.setFieldsValue(dataUpdateDigitalSign)

                        setOpenModal(false)
                    }

                }
            }
            if (errorDigitalSign) {
                notification.error({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                errorDigitalSign
                            </div>
                        )
                })
            }
        }

        submitDigitalSign()

    }, [funcDigitalSign, successDigitalSign, errorDigitalSign, loadingDigitalSign]);

    // if (!digitalSign) return <>Chua co du lieu</>
    return (
        <Spin spinning={loadingGetToken}>
            {/* {NEW_CODE && <Button onClick={() => setButtonToTest(!buttonToTest)}>{buttonToTest.toString()}</Button>} */}
            <div className="digital-sign">
                <Card type='inner' title={'Thông tin chữ ký số'} style={{ margin: 20 }}>
                    <Form
                        labelCol={{ span: 5 }}
                        layout="horizontal"
                        form={form}
                        colon={false}
                    // initialValues={digitalSign}
                    >
                        <Form.Item
                            name={'serial'}
                            label={t(translations.companydigitalSign.serial)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >

                            <div>{digitalSign?.serial}</div>
                            {/* 23kl5jfrr6e78bsmkuiy63495h */}
                        </Form.Item>
                        <Form.Item
                            name={'signature'}
                            label={t(translations.companydigitalSign.signature)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            <div>{digitalSign?.signature}</div>
                        </Form.Item>
                        <Form.Item
                            name={'subject'}
                            label={t(translations.companydigitalSign.subject)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            {buttonToTest ? <div>{digitalSign?.subject}</div> : <Input />}
                        </Form.Item>
                        <Form.Item
                            name={'signtime'}
                            label={t(translations.companydigitalSign.signtime)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            <div>{digitalSign?.signtime}</div>
                        </Form.Item>
                        <Form.Item
                            name={'certificate'}
                            label={t(translations.companydigitalSign.certificate)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            <div>{digitalSign?.certificate}</div>
                        </Form.Item>
                        <Form.Item
                            name={'fromDate'}
                            label={t(translations.companydigitalSign.fromDate)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            <div>{digitalSign?.fromDate}</div>
                        </Form.Item>

                        <Form.Item
                            name={'toDate'}
                            label={t(translations.companydigitalSign.toDate)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        // rules={[{
                        //     validator: (_, value) => {
                        //         if (value && value.toDate() < new Date()) {
                        //             return Promise.reject('Chứng thư số hết hiệu lực!');
                        //         }
                        //         return Promise.resolve();
                        //     },
                        // }]}
                        >

                            {buttonToTest ? <div>{digitalSign?.toDate}</div> : <Input />}
                            {/* <div>{digitalSign?.toDate}</div> */}
                        </Form.Item>
                        <Form.Item
                            name={'validity'}
                            label={t(translations.companydigitalSign.validity)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}

                        >
                            <div>{digitalSign?.validity}</div>
                        </Form.Item>
                        <Form.Item
                            name={'issuer'}
                            label={t(translations.companydigitalSign.issuer)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            <div>{digitalSign?.issuer}</div>
                        </Form.Item>
                        <Form.Item
                            name={'taxCode'}
                            label={t(translations.companydigitalSign.taxCode)}
                            labelAlign="left"
                            style={{
                                borderBottom: '1px solid #F0F0F0',
                                padding: '10px',
                                margin: 0
                            }}
                        >
                            {buttonToTest ? <div>{digitalSign?.taxCode}</div> : <Input />}
                        </Form.Item>
                    </Form>
                </Card>

            </div>
            <Footer
                style={{
                    background: '#fff',
                    // position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'inline-block' }}>
                    {/* <Button style={{ margin: '0 8px', height:40 }} onClick={onSaveEntity}>
                        {t(translations.action.save_draft)}
                    </Button> */}
                    <Button style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
                        {t(translations.action.previous)}
                    </Button>
                </div>
                <div style={{ display: 'inline-block' }}>

                    <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext}>
                        {t(translations.action.next)}
                    </Button>
                </div>
            </Footer>

            <Modal
                open={openModal}
                onCancel={() => setOpenModal(false)}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                    </>
                )}
            >
                {/* <Result
                    title="Quý khách vui lòng đăng ký chữ ký số của doanh nghiêp"
                    subTitle="Trước khi đăng ký chữ ký số, vui lòng cắm USB chữ ký số vào máy tính"
                    extra={
                        <Button type="primary" key="console" style={{ height: 40 }}
                            onClick={() => { getToken() }}
                        >
                            Đăng ký chữ ký số
                        </Button>
                    }
                    style={{ padding: '48px 0' }}
                /> */}

                <div style={{ textAlign: 'center', padding: '0px 20px' }}>
                    <div>
                        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="72" height="72" rx="36" fill="#E8F1FE" />
                            <path d="M36 17.25C32.2916 17.25 28.6665 18.3497 25.5831 20.4099C22.4996 22.4702 20.0964 25.3986 18.6773 28.8247C17.2581 32.2508 16.8868 36.0208 17.6103 39.6579C18.3338 43.2951 20.1195 46.636 22.7418 49.2582C25.364 51.8805 28.7049 53.6662 32.3421 54.3897C35.9792 55.1132 39.7492 54.7419 43.1753 53.3227C46.6014 51.9036 49.5298 49.5004 51.5901 46.4169C53.6503 43.3335 54.75 39.7084 54.75 36C54.7446 31.0288 52.7675 26.2628 49.2523 22.7477C45.7372 19.2325 40.9712 17.2554 36 17.25V17.25ZM37.6063 46.5062H34.3797V32.875H37.6063V46.5062ZM37.3391 28.5922C37.161 28.7553 36.952 28.8809 36.7243 28.9615C36.4966 29.042 36.2551 29.0758 36.0141 29.0609C35.7686 29.0773 35.5224 29.0442 35.29 28.9637C35.0576 28.8832 34.8437 28.7568 34.6609 28.5922C34.4995 28.4184 34.3748 28.2138 34.2943 27.9907C34.2137 27.7676 34.179 27.5306 34.1922 27.2937C34.1758 27.0514 34.209 26.8083 34.2896 26.5792C34.3701 26.35 34.4965 26.1397 34.6609 25.9609C34.8442 25.797 35.0581 25.6712 35.2904 25.5907C35.5227 25.5102 35.7687 25.4767 36.0141 25.4922C36.255 25.4782 36.4963 25.5124 36.7239 25.5929C36.9514 25.6734 37.1605 25.7985 37.3391 25.9609C37.5035 26.1397 37.6299 26.35 37.7105 26.5792C37.791 26.8083 37.8242 27.0514 37.8078 27.2937C37.821 27.5306 37.7863 27.7676 37.7057 27.9907C37.6252 28.2138 37.5005 28.4184 37.3391 28.5922Z" fill="#1877F2" />
                        </svg>
                    </div>
                    <Title level={3}>Quý khách vui lòng đăng ký chữ ký số của doanh nghiệp</Title>
                    <div>Trước khi đăng ký chữ ký số, vui lòng cắm USB chữ ký số vào máy tính</div>
                    <Button type="primary" key="console" style={{ height: 40, width: '100%', backgroundColor: "rgb(24, 119, 242)", marginTop: 20 }}
                        onClick={() => { getToken() }}
                    >
                        Đăng ký chữ ký số
                    </Button>
                </div>

            </Modal>
        </Spin>
    );
}
