import { Company } from "./company.model";
import { CorporatePositionEnum, EDoctype, GenderEnum } from "./enums/enums";

export interface CompanyEmployee {
    id?: string;
    instanceName?: string | null;
   company?: Company | null;
   name?: string | null;
   position?: CorporatePositionEnum | null;
   gender?: GenderEnum | null;
   birthday?: string | null;
   docType?: EDoctype | null;
   cardID?: string | null;
   cardNumber?: string | null;
   cardIssueDate?: string | null;
   cardIssueLocation?: string | null;
   cardPermanentAddress?: string | null;
   cardTemporaryAddress?: string | null;
   cardEthnicity?: string | null;
   cardFrontImage?: string | null;
   cardFrontImageFile?: string | null;
   cardBackImage?: string | null;
   cardBackImageFile?: string | null;
   faceImage?: string | null;
   faceImageFile?: string | null;
   hasEKYC?: boolean | null;
   hasDigitalSign?: boolean | null;
   nationality?: string | null;
   empowerDoc?: string | null;
   empowerDateFrom?: string | null;
   empowerDateTo?: string | null;
   empowerPosition?: string | null;
   phoneNumber?: string | null;
   email?: string | null;
   jsonData?: string | null;
   version?: number | null;
   createdBy?: string | null;
   createdDate?: string | null;
   lastModifiedBy?: string | null;
   lastModifiedDate?: string | null;
   deletedBy?: string | null;
   deletedDate?: string | null;
  }

  export const defaultValue: Readonly<CompanyEmployee> = {};