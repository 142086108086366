import './index.css';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Form,
  Input,
  Upload,
  Button,
  DatePicker,
  Radio,
  Col,
  Row,
  Typography,
  RadioChangeEvent,
  Space,
  Card,
  Divider,
  Avatar,
  UploadProps,
  Flex,
  message,
  Spin,
  Checkbox,
  notification,
  UploadFile,
  Modal,
  Result
} from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import {
  deleteDocEmpowerRepresentative,
  goNext,
  goPrev,
  goToStep,
  saveDraftCompanyEntity,
  setDocEmpowerRepresentative,
  updateEmpowerLegalRepresentativeBackImage,
  updateEmpowerLegalRepresentativeFaceImage,
  updateEmpowerLegalRepresentativeFrontImage,
  updateLegalRepresentativeBackImage,
  updateLegalRepresentativeFaceImage,
  updateLegalRepresentativeFrontImage,

} from '../../onboarding.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { deepMerge } from '@/shared/utils/object-utils';
import { useNavigate } from 'react-router-dom';
import { displayPartsToString } from 'typescript';
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { executeOcrPersonEKycRepresentative, onboardingApi, useExecuteOcrPersonEKycRepresentativeMutation, useGetCompanyQuery, useUpdateCompanyMutation, useUploadFileRegistrationMutation, useUploadRepresentativeBackImageMutation, useUploadRepresentativeFaceImageMutation, useUploadRepresentativeFrontImageMutation, useVertifyIdentifyMutation } from '@/services/api/onboarding.api';
import { CompanyEmployee } from '@/shared/model/company-employee.model';
import { convertDateTimeToServer } from '@/shared/utils/date-utils';
import dayjs from "dayjs";
import { CloudDownloadOutlined, EyeTwoTone, UploadOutlined } from '@ant-design/icons';
import { extractName } from "@/shared/utils/jmix-rest/file";
import { userInfoApi } from '@/services/api/user-info.api';
import { CorporatePositionEnum, EDoctype } from '@/shared/model/enums/enums';
import { FileInfoResponse } from '@/shared/model/file-info-response.model';
import { fileApi } from '@/services/api/file.api';

const { Title, Text, Link } = Typography;

const getDefaultImage = () => {
  return '/images/default_image.png';
};

function isValidDate(dateString: any): boolean {
  const now = new Date();
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateString) {
    return true
  }

  if (!dateRegex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  if (date > now) {
    return false;
  }

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};
const reFormatDate = (dateString: string): string => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

export default function OnboardingEmpowerRepresentative(props: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { onNext, onPrev, onSaveDraft } = props;

  const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const [canNext, setCanNext] = useState(false)
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
  const { empowerRepresentative } = companyEntity;
  const [form] = Form.useForm();

  const [downloadFileFrontImage, { data: frontImageFile, isLoading: isLoadingDownload, isError: isErrorDownload, isSuccess: succesDownloadFrontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()

  const [uploadFileRegistration, { data, isLoading, isSuccess, isError }] = useUploadFileRegistrationMutation()
  const [downloadfile, { data: pdfFlie, isLoading: isLoadingGetFile, isSuccess: isSuccessGetFlie, isError: isErrorGetFlie }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [uploadRepresentativeFrontImage, { reset: resetFrontImage, data: frontImage, isLoading: isLoadingFrontImage, isSuccess: isSuccessFrontImage, isError: isErrorFrontImage }] = useUploadRepresentativeFrontImageMutation()
  // const [downloadRepresentativeFrontImage, { data: frontImageFile, isSuccess: succesDownloadFrontImageFile }] = onboardingApi.endpoints.downloadRepresentativeFrontImage.useLazyQuery();
  const [uploadRepresentativeBackImage, { reset: resetBackImage, data: backImage, isLoading: isLoadingBackImage, isSuccess: isSuccessBackImage, isError: isErrorBackImage }] = useUploadRepresentativeBackImageMutation()
  // const [downloadRepresentativeBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = onboardingApi.endpoints.downloadRepresentativeBackImage.useLazyQuery();
  const [uploadRepresentativeFaceImage, { reset: resetFaceImage, data: faceImage, isLoading: isLoadingFaceImage, isSuccess: isSuccessFaceImage, isError: isErrorFaceImage }] = useUploadRepresentativeFaceImageMutation()
  // const [downloadRepresentativeFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = onboardingApi.endpoints.downloadRepresentativeFaceImage.useLazyQuery();
  const [executeOcrPersonEKycRepresentative, { data: dataOCR, isLoading: isLoadingOCR, isSuccess: isSuccessOCR, isError: isErrorOCR }] = useExecuteOcrPersonEKycRepresentativeMutation();
  const [vertifyIdentify, { data: vertifyIdentifyUser, isLoading: isLoadingVertify, isSuccess: isSuccessVertify, isError: isErrorVertify }] = useVertifyIdentifyMutation();
  const [extract, setExtract] = useState(false)
  const [count, setCount] = useState(true);
  const [docType, setDocType] = useState(companyEntity?.empowerRepresentative?.docType || "CMND");
  const [changeDocType, setChangeDocType] = useState(false)
  const [checkRespre, setCheckRespre] = useState<CompanyEmployee | null | undefined>(empowerRepresentative)
  const [firstSetRespre, setFirstSetRespre] = useState(true);
  const [localFrontImage, setLocalFrontImage] = useState<any>(null)
  const [localBackImage, setLocalBackImage] = useState<any>(null)
  const [localFaceImage, setLocalFaceImage] = useState<any>(null)
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [typeFileDelete, setTypeFileDelete] = useState('');

  const [openModalReviewFile, setOpenModalReviewFile] = useState(false)
  const emailRegex = /^(?=.{6,50}$)(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9._%+\-!#$%^&*()=]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/

  let schema = yup.object().shape({
    empowerRepresentative: yup.object().shape({
      // name: yup.string(),
      // cardIssueDate: yup.string()
      //   .test('valid-date', 'Ngày cấp không hợp lệ.', value => {
      //     if (dayjs(value).isAfter(dayjs())) return false
      //     return true
      //   }).nullable()
      // , cardFrontImage: yup.string(),
      // cardBackImage: yup.string(),
      // faceImage: yup.string(),
      // phoneNumber: yup.string().nullable(),
      // cardID: yup.string().nullable(),
      // cardIssueLocation: yup.string().nullable(),
      // email: yup.string().email(t(translations.register.errors.emailInvalid)).nullable(),
      // birthday: yup.string()
      //   .test('valid-date', 'Ngày sinh không hợp lệ.', value => {
      //     if (dayjs(value).isAfter(dayjs())) return false
      //     return true
      //   }).nullable(),
      name: yup.string().nullable().test('valid', 'Họ và tên không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueDate: yup.string().nullable()
        .test('valid-date', 'Ngày cấp không hợp lệ.', value => {
          if (!value) return true;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      birthday: yup.string().nullable()
        .test('valid-date', 'Ngày sinh không hợp lệ.', value => {
          if (!value) return true;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      phoneNumber: yup.string().nullable().test('valid', 'Số điện thoại không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardID: yup.string().nullable().test('valid', 'Số giấy tờ định danh không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueLocation: yup.string().nullable().test('valid', 'Nơi cấp không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      email: yup.string().email(t(translations.register.errors.emailInvalid)).nullable()
        .test('valid email', t(translations.register.errors.emailInvalid), (value: any) => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          return emailRegex.test(value)
        })
        ,
      cardFrontImage: yup.string().nullable(),
      cardBackImage: yup.string().nullable(),
      faceImage: yup.string().nullable(),
      empowerDateFrom: yup.string().nullable()
        .test('valid-date', 'Ngày kết thúc ủy quyền không được nhỏ hơn ngày hiện tại!', value => {
          return true;
        }),
      empowerDateTo: yup.string().nullable()
        .test('valid-date', 'Ngày kết thúc ủy quyền không được nhỏ hơn ngày hiện tại!', (value, context) => {
          const empowerDateTo = context.parent.empowerDateTo;
          const currentDate = dayjs();
          const empowerDateFromValue = dayjs(value);
          if (!value) return true;
          if (empowerDateFromValue.isSame(currentDate, 'day')) return true
          if (dayjs(value).isBefore(dayjs())) return false;
          return true;
        })
        .test('valid-date', 'Ngày kết thúc ủy quyền không được nhỏ hơn ngày bắt đầu ủy quyền!', (value, context) => {
          const empowerDateTo = context.parent.empowerDateTo;
          if (!value) return true;
          if ((empowerDateTo && (dayjs(value).isBefore(empowerDateTo)))) return false;
          return true;
        }),
    }),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
  }

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      //   setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                Bạn chỉ có thể tải định dạng PDF!
              </div>
            )
        });
        return
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                File phải nhỏ hơn 20MB!
              </div>
            )
        });
        return
      }
      const formData = new FormData();
      formData.append("file", file);
      // dispatch(uploadFileRegistration(formData))
      uploadFileRegistration(formData);


      return
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
      // removeIcon: <DeleteOutlined onClick={onDeleteFile} />,
    },
    fileList,
  };

  const uploadFrontImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();

      formData.append('file', file);
      // dispatch(uploadRepresentativeFrontImage(formData));
      uploadRepresentativeFrontImage(formData)
      return false;
    },
  };
  const uploadBackImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeBackImage(formData)
      return false;
    },
  };
  const uploadFaceImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeFaceImage(formData)
      return false;
    },
  };

  const onPreviewFile = async () => {
    if (companyEntity?.empowerRepresentative?.empowerDoc) {
      setOpenModalReviewFile(true)
      downloadfile({ fileId: companyEntity.empowerRepresentative?.empowerDoc, objectType: null })
    }

    else {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Tải File không thành công!
            </div>
          )
      })
    }
  }

  const onDeleteFile = () => {
    dispatch(deleteDocEmpowerRepresentative())
  }

  // useEffect(() => {
  //   form.setFields([{
  //     name: "fileEmpower",
  //     errors: ["Giấy tờ ủy quyền có kí số không được để trống!"]
  //   }])
  // }, [])

  const onGoNext = () => {
    let uploadFile = false

    if (companyEntity.empowerRepresentative?.cardFrontImage ||
      companyEntity.empowerRepresentative?.cardBackImage ||
      companyEntity.empowerRepresentative?.faceImage) {
      if (!companyEntity.empowerRepresentative?.cardFrontImage) {
        form.setFields([{
          name: ['empowerRepresentative', 'cardFrontImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.empowerRepresentative?.cardBackImage && companyEntity.empowerRepresentative.docType === "CMND") {
        form.setFields([{
          name: ['empowerRepresentative', 'cardBackImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.empowerRepresentative?.faceImage) {
        form.setFields([{
          name: ['empowerRepresentative', 'faceImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
    }
    if (!uploadFile) {
      if (!companyEntity.empowerRepresentative?.empowerDoc && extract) {
        form.setFields([{
          name: "fileEmpower",
          errors: ["Giấy tờ ủy quyền có kí số không được để trống!"]
        }])
      }
      form
        .validateFields()
        .then(async values => {
          if (companyEntity?.empowerRepresentative?.docType === EDoctype.CCCD && companyEntity?.empowerRepresentative?.cardFrontImage && companyEntity?.empowerRepresentative?.cardBackImage && companyEntity?.empowerRepresentative?.faceImage && !extract
            || companyEntity?.empowerRepresentative?.docType === EDoctype.HC && companyEntity?.empowerRepresentative?.cardFrontImage && companyEntity?.empowerRepresentative?.faceImage && !extract
          ) {
            notification.info({
              message:
                (
                  <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                    Hãy trích xuất hình ảnh để tiếp tục!
                  </div>
                )
            })
          }
          else {
            let date = form.getFieldValue(['empowerRepresentative', 'cardIssueDate'])
            let birthDay = form.getFieldValue(['empowerRepresentative', 'birthday'])
            let empowerDateTo = form.getFieldValue(['empowerRepresentative', 'empowerDateTo'])
            let empowerDateFrom = form.getFieldValue(['empowerRepresentative', 'empowerDateFrom'])

            let entityForm = form.getFieldsValue();

            entityForm = {
              ...entityForm,
              empowerRepresentative: {
                ...entityForm.empowerRepresentative,
                // ...dataOCR,
                position: CorporatePositionEnum.AUTHORIZATION_CHIEF_REPRESENTATIVE,
                cardIssueDate: date ? date.format("YYYY-MM-DD") : "",
                birthday: birthDay ? birthDay.format("YYYY-MM-DD") : "",
                empowerDateTo: empowerDateTo ? empowerDateTo.format("YYYY-MM-DD") : "",
                empowerDateFrom: empowerDateFrom ? empowerDateFrom.format("YYYY-MM-DD") : "",
              }
            }

            let empowerRepresentativeOriginal = deepMerge(companyEntity.empowerRepresentative, entityForm.empowerRepresentative)

            await dispatch(saveDraftCompanyEntity({ ...companyEntity, empowerRepresentative: empowerRepresentativeOriginal }));
            await dispatch(goNext({ ...companyEntity, empowerRepresentative: empowerRepresentativeOriginal }));
          }


          // const entity = deepMerge(companyEntity, { empowerRepresentative: values });
        })
        .catch(errorInfo => {console.log(errorInfo)});
    }
  };

  const onGoPrev = () => {
    let date = form.getFieldValue(['empowerRepresentative', 'cardIssueDate'])
    let birthDay = form.getFieldValue(['empowerRepresentative', 'birthday'])
    let empowerDateTo = form.getFieldValue(['empowerRepresentative', 'empowerDateTo'])
    let empowerDateFrom = form.getFieldValue(['empowerRepresentative', 'empowerDateFrom'])
    let entityForm = form.getFieldsValue();

    entityForm = {
      ...entityForm,
      empowerRepresentative: {
        ...entityForm.empowerRepresentative,
        cardIssueDate: date ? date.format("YYYY-MM-DD") : null,
        birthday: birthDay ? birthDay.format("YYYY-MM-DD") : null,
        empowerDateTo: empowerDateTo ? empowerDateTo.format("YYYY-MM-DD") : null,
        empowerDateFrom: empowerDateFrom ? empowerDateFrom.format("YYYY-MM-DD") : null,
      }
    }

    let empowerRepresentativeOriginal = deepMerge(companyEntity.empowerRepresentative, entityForm.empowerRepresentative)
    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      empowerRepresentative: {
        ...companyEntity.empowerRepresentative,
        ...empowerRepresentativeOriginal
      }
    }));
    dispatch(goPrev({
      ...companyEntity,
      empowerRepresentative: {
        ...companyEntity.empowerRepresentative,
        ...empowerRepresentativeOriginal
      }
    }));
  };
  const onClose = () => {
    //Check if editingMode = true
    dispatch(goToStep(1));

  };
  const onChangeDocType = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setDocType(newValue);
    form.setFieldsValue({
      empowerRepresentative: {
        ...form.getFieldValue('empowerRepresentative'),
        docType: newValue,
      },
    });
    setChangeDocType(true)
  };

  const onFormFieldsChange = (allFields: FieldData[]) => {
    // let entityForm = form.getFieldsValue();
    // let empowerRepresentativeOriginal = deepMerge(companyEntity.empowerRepresentative, entityForm.empowerRepresentative)
    // dispatch(saveDraftCompanyEntity({ ...companyEntity, ...{ empowerRepresentative: empowerRepresentativeOriginal } }));
  }
  const onGetOcrPersonEKyc = async () => {
    await vertifyIdentify({
      frontImage: companyEntity?.empowerRepresentative?.cardFrontImage,
      faceImage: companyEntity?.empowerRepresentative?.faceImage,
    });
  };

  const updateFile = (type: string, file: FileInfoResponse) => {
    let dataUpdateFile
    if (type === "front") {
      let updateFile = {
        ...companyEntity.empowerRepresentative,
        frontImage: file.fileRef
      }
      dataUpdateFile = deepMerge(companyEntity?.empowerRepresentative, updateFile)
    }
    if (type === "back") {
      let updateFile = {
        ...companyEntity.empowerRepresentative,
        backImage: file.fileRef
      }
      dataUpdateFile = deepMerge(companyEntity?.empowerRepresentative, updateFile)
    }

    if (type === "face") {
      let updateFile = {
        ...companyEntity.empowerRepresentative,
        faceImage: file.fileRef
      }
      dataUpdateFile = deepMerge(companyEntity?.empowerRepresentative, updateFile)
    }

    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      empowerRepresentative: {
        ...empowerRepresentative,
        ...dataUpdateFile,
      }
    }));
  }

  const deleteFile = (values: any) => {
    let dataDeleteFile
    if (values === "front") {
      setLocalFrontImage(null)
      resetFrontImage()

      let deleteFile = {
        ...companyEntity.empowerRepresentative,
        cardFrontImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerRepresentative, deleteFile)

      form.setFieldValue(['empowerRepresentative', 'cardFrontImage'], null)

    }

    else if (values === "back") {
      setLocalBackImage(null)
      resetBackImage()

      let deleteFile = {
        ...companyEntity.empowerRepresentative,
        cardBackImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerRepresentative, deleteFile)

      form.setFieldValue(['empowerRepresentative', 'cardBackImage'], null)

    }

    else if (values === "face") {
      setLocalFaceImage(null)
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.empowerRepresentative,
        faceImage: null
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerRepresentative, deleteFile)

      form.setFieldValue(['empowerRepresentative', 'faceImage'], null)
    }

    setExtract(false)

    const fieldsToReset = [
      ['empowerRepresentative', 'email'], ['empowerRepresentative', 'phoneNumber'], ['empowerRepresentative', 'birthday'], ['empowerRepresentative', 'cardIssueLocation'], ['empowerRepresentative', 'cardIssueDate'], ['empowerRepresentative', 'cardID'], ['empowerRepresentative', 'name']
    ];

    fieldsToReset.forEach(field => form.setFieldValue(field, null));

    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      empowerRepresentative: {
        ...empowerRepresentative,
        ...dataDeleteFile,
      }
    }));
  }

  useEffect(() => {
    form.setFields([{
      name: "fileEmpower",
      errors: []
    }]);
  }, [companyEntity?.empowerRepresentative?.empowerDoc])

  useEffect(() => {
    if (empowerRepresentative?.cardFrontImage) {
      form.setFieldValue(['empowerRepresentative', 'cardFrontImage'], empowerRepresentative?.cardFrontImage)
    }
    if (empowerRepresentative?.cardBackImage) {
      form.setFieldValue(['empowerRepresentative', 'cardBackImage'], empowerRepresentative?.cardBackImage)
    }
    if (empowerRepresentative?.faceImage) {
      form.setFieldValue(['empowerRepresentative', 'faceImage'], empowerRepresentative?.faceImage)
    }
  }, [empowerRepresentative])

  useEffect(() => {
    if (companyEntity.empowerRepresentative?.cardFrontImage) {
      form.setFields([{
        name: ['empowerRepresentative', 'cardFrontImage'],
        errors: []
      }])
    }
    if (companyEntity.empowerRepresentative?.cardBackImage) {
      form.setFields([{
        name: ['empowerRepresentative', 'cardBackImage'],
        errors: []
      }])
    }
    if (companyEntity.empowerRepresentative?.faceImage) {
      form.setFields([{
        name: ['empowerRepresentative', 'faceImage'],
        errors: []
      }])
    }
  }, [companyEntity.empowerRepresentative])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDocEmpowerRepresentative(data))
    }
  }, [isSuccess, isLoading, isError, data])

  useEffect(() => {
    if (changeDocType) {
      setLocalFrontImage(null)
      setLocalBackImage(null)
      setLocalFaceImage(null)
      resetFrontImage()
      resetBackImage()
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.empowerRepresentative,
        cardFrontImage: null,
        cardBackImage: null,
        faceImage: null
      }
      let dataDeleteFile = deepMerge(companyEntity?.empowerRepresentative, deleteFile)

      form.setFieldsValue({
        empowerRepresentative: {
          docType: docType
        },
      });

      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        empowerRepresentative: {
          ...empowerRepresentative,
          ...dataDeleteFile,
          docType: docType
        }
      }));

      setExtract(false)

      form.resetFields();

      const fieldsToReset = [
        ['empowerRepresentative', 'email'], ['empowerRepresentative', 'phoneNumber'], ['empowerRepresentative', 'birthday'], ['empowerRepresentative', 'cardIssueLocation'], ['empowerRepresentative', 'cardIssueDate'], ['empowerRepresentative', 'cardID'], ['empowerRepresentative', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));

      form.setFieldsValue({
        empowerRepresentative: {
          docType: docType,
          position: "Ủy quyền người đại diện pháp luật"

        },
      });

      setChangeDocType(false)
    }
  }, [docType])

  useEffect(() => {
    if (frontImageFile && succesDownloadFrontImageFile && localFrontImage !== frontImageFile) {
      setLocalFrontImage(frontImageFile);
    }
  }, [frontImageFile, succesDownloadFrontImageFile])

  useEffect(() => {
    if (backImageFile && succesDownloadBackImageFile && localBackImage !== backImageFile) setLocalBackImage(backImageFile);

  }, [backImageFile, succesDownloadBackImageFile])

  useEffect(() => {
    if (faceImageFile && succesDownloadFaceImageFile && localFaceImage !== frontImageFile) setLocalFaceImage(faceImageFile);

  }, [faceImageFile, succesDownloadFaceImageFile]);

  useEffect(() => {
    setCount(false)


    if (isSuccessVertify && vertifyIdentifyUser && !count && companyEntity.empowerRepresentative) {
      executeOcrPersonEKycRepresentative(companyEntity.empowerRepresentative);
    }

    if (isSuccessVertify && !vertifyIdentifyUser && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              eKYC cá nhân không khớp với ảnh trên giấy tờ
            </div>
          )
      })
      setExtract(false)
    }

    if (isErrorVertify && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Không thể thực hiện nhận dạng!
            </div>
          )
      })
      setExtract(false)
    }

  }, [vertifyIdentifyUser, isLoadingVertify, isSuccessVertify, isErrorVertify])

  useEffect(() => {
    if (!!empowerRepresentative?.cardFrontImage) {
      downloadFileFrontImage({ fileId: empowerRepresentative.cardFrontImage, objectType: null })

    }
  }, [empowerRepresentative?.cardFrontImage]);
  useEffect(() => {
    if (!!empowerRepresentative?.cardBackImage)
      downloadFileBackImage({ fileId: empowerRepresentative.cardBackImage, objectType: null })
  }, [empowerRepresentative?.cardBackImage]);
  useEffect(() => {
    if (!!empowerRepresentative?.faceImage)
      downloadFileFaceImage({ fileId: empowerRepresentative.faceImage, objectType: null })
  }, [empowerRepresentative?.faceImage]);

  useEffect(() => {
    if (isSuccessFrontImage && !!frontImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerRepresentative', 'email'], ['empowerRepresentative', 'phoneNumber'], ['empowerRepresentative', 'birthday'], ['empowerRepresentative', 'cardIssueLocation'], ['empowerRepresentative', 'cardIssueDate'], ['empowerRepresentative', 'cardID'], ['empowerRepresentative', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FrontImage
      // updateFile("front", frontImage)
      dispatch(updateEmpowerLegalRepresentativeFrontImage(frontImage))
    }
  }, [isSuccessFrontImage])
  useEffect(() => {
    if (isSuccessBackImage && !!backImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerRepresentative', 'email'], ['empowerRepresentative', 'phoneNumber'], ['empowerRepresentative', 'birthday'], ['empowerRepresentative', 'cardIssueLocation'], ['empowerRepresentative', 'cardIssueDate'], ['empowerRepresentative', 'cardID'], ['empowerRepresentative', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update BackImage

      // updateFile("back", backImage)
      dispatch(updateEmpowerLegalRepresentativeBackImage(backImage))
    }
  }, [isSuccessBackImage])
  useEffect(() => {
    if (isSuccessFaceImage && !!faceImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerRepresentative', 'email'], ['empowerRepresentative', 'phoneNumber'], ['empowerRepresentative', 'birthday'], ['empowerRepresentative', 'cardIssueLocation'], ['empowerRepresentative', 'cardIssueDate'], ['empowerRepresentative', 'cardID'], ['empowerRepresentative', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FaceImage
      dispatch(updateEmpowerLegalRepresentativeFaceImage(faceImage))
    }
  }, [isSuccessFaceImage])

  // useEffect(() => {

  // }, [companyEntity])

  useEffect(() => {
    if (dataOCR && dataOCR.cardIssueDate) {
      let dataOcrNew

      if (dataOCR.docType === null) {
        let dataOcrNew = {
          ...dataOCR,
          docType: docType
        }
      }
      else {
        dataOcrNew = dataOCR
      }
      dispatch(saveDraftCompanyEntity({ ...companyEntity, empowerRepresentative: dataOcrNew }))
      form.setFieldsValue({
        empowerRepresentative: {
          ...dataOcrNew,
          position: "Ủy quyền người đại diện pháp luật",
          birthday: dataOcrNew?.birthday ? dayjs(dataOcrNew?.birthday, "YYYY-MM-DD") : "",
          cardIssueDate: dataOcrNew?.cardIssueDate ? dayjs(dataOcrNew?.cardIssueDate, "YYYY-MM-DD") : ""
        }
      });

      notification.success({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin thành công!
            </div>
          )
      });
      setExtract(true);
    }

    if (dataOCR === null || (dataOCR?.name === null && dataOCR?.cardIssueDate === null)) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin không thành công!
            </div>
          )
      });
      setExtract(false)
    }
  }, [dataOCR])

  //cập nhật form lần đầu
  useEffect(() => {
    let entity = deepMerge(companyEntity)
    entity = {
      ...entity,
      empowerRepresentative: {
        ...entity.empowerRepresentative,
        birthday: entity.empowerRepresentative.birthday ? dayjs(entity.empowerRepresentative.birthday, "YYYY-MM-DD") : "",
        empowerDateFrom: entity.empowerRepresentative.empowerDateFrom ? dayjs(entity.empowerRepresentative.empowerDateFrom) : "",
        empowerDateTo: entity.empowerRepresentative.empowerDateTo ? dayjs(entity.empowerRepresentative.empowerDateTo) : "",
        cardIssueDate: entity.empowerRepresentative.cardIssueDate ? dayjs(entity.empowerRepresentative.cardIssueDate, "YYYY-MM-DD") : ""
      }
    }
    form.setFieldsValue(entity)

    form.setFieldsValue({
      empowerRepresentative: {
        position: "Ủy quyền người đại diện pháp luật",
      },
    });

    if (!companyEntity.empowerRepresentative?.docType) {
      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        empowerRepresentative: {
          ...empowerRepresentative,
          docType: "CMND"
        }
      }));
      form.setFieldsValue({
        empowerRepresentative: {
          docType: "CMND",
        },
      });
    }

    if (!!empowerRepresentative?.cardFrontImage) downloadFileFrontImage({ fileId: empowerRepresentative.cardFrontImage, objectType: null })
    if (!!empowerRepresentative?.cardBackImage) downloadFileBackImage({ fileId: empowerRepresentative.cardBackImage, objectType: null })
    if (!!empowerRepresentative?.faceImage) downloadFileFaceImage({ fileId: empowerRepresentative.faceImage, objectType: null })
    // if (localFrontImage || localBackImage || localFaceImage) {
    //   form.setFieldsValue({
    //     empowerRepresentative: {
    //       cardFrontImage: localFrontImage,
    //       cardBackImage: localBackImage,
    //       faceImage: localFaceImage,
    //     },
    //   });
    // }

    if (
      (empowerRepresentative?.docType?.toString() === "CMND" && empowerRepresentative?.cardBackImage !== null && empowerRepresentative?.cardFrontImage != null && empowerRepresentative?.faceImage !== null && empowerRepresentative?.name !== null && empowerRepresentative?.cardIssueDate !== null) ||
      (empowerRepresentative?.docType?.toString() === "HC" && empowerRepresentative?.cardFrontImage != null && empowerRepresentative?.faceImage !== null && empowerRepresentative?.name !== null && empowerRepresentative?.cardIssueDate !== null)
    ) {
      setExtract(true)
    }

  }, [])

  return (
    <Spin spinning={isLoadingOCR || isLoadingVertify} >
      <Content className='empowerRepresentative'>
        <Card type='inner' title='Thông tin ủy quyền người đại diện' style={{ margin: 20 }} >
          <Row>
            <Form form={form} initialValues={{ empowerRepresentative: { docType } }}>
              <Form.Item label={"Loại giấy tờ"} name={['empowerRepresentative', 'docType']}>
                <Radio.Group onChange={onChangeDocType} value={companyEntity?.empowerRepresentative?.docType?.toString()} defaultValue={'CMND'}>
                  <Radio value={"CMND"} >CMND/CCCD/CCCD gắn chíp</Radio>
                  <Radio value={"HC"}>Hộ chiếu</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider orientation="right" />
            </Form>
          </Row>
          <Form id='orcForm'
            form={form}
          >
            {/* <Form.Item name={['empowerRepresentative', 'hasEKYC']} >

            <Input />
          </Form.Item> */}

          </Form>
          <Form id='imagesForm'
            form={form}
          >
            <Row style={{ margin: '24px 0px' }}>
              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200,
                    marginRight: 10
                  }}
                >
                  <Form.Item
                    name={['empowerRepresentative', 'cardFrontImage']}

                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          !!localFrontImage
                            ? localFrontImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>

                  <h5>Ảnh mặt trước giấy tờ</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFrontImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!empowerRepresentative?.cardFrontImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("front")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>

              {!(docType === "HC") && (
                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                  <div
                    style={{
                      padding: '12px',
                      border: '1px solid #f0f0f0',
                      borderRadius: '8px 8px 8px 8px',
                      minHeight: 200,
                      marginRight: 10
                    }}
                  >
                    <Form.Item
                      name={['empowerRepresentative', 'cardBackImage']}

                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}

                    >
                      <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                        <Image
                          style={{ width: '100%', maxHeight: '18vw' }}
                          preview={false}
                          src={
                            !!localBackImage
                              ? localBackImage
                              : getDefaultImage()
                          }
                          fallback={getDefaultImage()}
                        />
                      </div>
                    </Form.Item>

                    <h5>Ảnh mặt sau giấy tờ</h5>
                    <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                    <Row justify={'space-between'}>
                      <Col lg={{ span: 11 }}>
                        <Upload {...uploadBackImageProps} maxCount={1} showUploadList={false}>
                          <Button disabled={isLoadingOCR || isLoadingVertify}
                            style={{
                              width: '100%',
                              border: 'none',
                              backgroundColor: 'rgba(232, 241, 254, 1)',
                              color: 'rgba(24, 119, 242, 1)',
                              height: 40
                            }}
                          >
                            Tải ảnh lên
                          </Button>
                        </Upload>
                      </Col>
                      <Col lg={{ span: 11 }}>
                        <Button disabled={!empowerRepresentative?.cardBackImage} onClick={() => {
                          setConfirmDeleteFile(true)
                          setTypeFileDelete("back")
                        }} style={{ width: '100%', height: 40 }}>
                          Xóa
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200
                  }}
                >
                  <Form.Item
                    name={['empowerRepresentative', 'faceImage']}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}

                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          !!localFaceImage
                            ? localFaceImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>
                  <h5>eKYC cá nhân</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFaceImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!empowerRepresentative?.faceImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("face")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>

          {((docType === "CMND" && !!empowerRepresentative?.cardFrontImage &&
            !!empowerRepresentative?.cardBackImage &&
            !!empowerRepresentative?.faceImage) ||
            (docType === "HC" && !!empowerRepresentative?.cardFrontImage &&
              !!empowerRepresentative?.faceImage)) && (
              <div>
                <Row justify={'center'}>
                  <Col lg={{ span: 12 }}>
                    {/* <Row justify={'end'}>
                      {(isLoadingOCR || isLoadingVertify) && <Spin>Đang tải thông tin</Spin>}
                    </Row> */}
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Row style={{ width: '100%' }} justify={'end'}>
                      <Button disabled={isLoadingOCR || isLoadingVertify} onClick={onGetOcrPersonEKyc} style={{ backgroundColor: '#1c00c2', color: 'white', height: 40, border: "none", float: 'right' }}><CloudDownloadOutlined /> Trích xuất</Button>
                    </Row>
                  </Col>
                </Row>
              </div>

            )}
        </Card >


        {/* <Divider /> */}
        < Card type='inner' title='Xác thực thông tin người được ủy quyền người đại diện' style={{ margin: 20 }
        } >
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                colon={false}
                requiredMark={false}
              >
                <Form.Item
                  name={['empowerRepresentative', 'name']}
                  // name={'name'}
                  label={
                    <span>
                      Họ và tên
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Họ và tên không được để trống" }]}

                >
                  <Input placeholder="Nhập họ và tên" style={{ height: 40 }} maxLength={50} />
                </Form.Item>
                <Form.Item
                  name={['empowerRepresentative', 'cardID']}
                  // name={'name'}
                  label={
                    <span>
                      Số giấy tờ định danh
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Số giấy tờ định danh không được để trống" }]}

                >
                  <Input placeholder="Nhập số giấy tờ kinh doanh" style={{ height: 40 }} maxLength={12} />
                </Form.Item>

                <Form.Item
                  name={['empowerRepresentative', 'cardIssueDate']}
                  label={
                    <span>
                      Ngày cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Ngày cấp không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Nhập ngày cấp' style={{ height: 40, width: "100%" }} format={"DD/MM/YYYY"} />
                  {/* <Input placeholder="Nhập ngày cấp" style={{ height: 40 }} /> */}
                </Form.Item>

                <Form.Item
                  name={['empowerRepresentative', 'cardIssueLocation']}
                  // name={'name'}
                  label={
                    <span>
                      Nơi cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Nơi cấp không được để trống" }]}

                >
                  <Input placeholder="Nhập nơi cấp" style={{ height: 40 }} maxLength={300} />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                colon={false}
                requiredMark={false}
              >
                <Form.Item
                  name={['empowerRepresentative', 'birthday']}
                  label={
                    <span>
                      Ngày sinh
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Ngày sinh không được để trống" }]}

                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Nhập ngày sinh' style={{ height: 40, width: "100%" }} format={"DD/MM/YYYY"} />
                  {/* <Input placeholder='Nhập ngày sinh' style={{ height: 40 }} /> */}
                </Form.Item>

                <Form.Item
                  label="Chức vụ"
                  labelAlign="left"
                  style={{ width: '100%' }}
                  name={['empowerRepresentative', 'position']}
                >
                  <Input disabled style={{ height: 40 }} />
                </Form.Item>

                <Form.Item
                  name={['empowerRepresentative', 'phoneNumber']}
                  label={
                    <span>
                      Số điện thoại
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Số điện thoại không được để trống" }]}

                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập số điện thoại' style={{ height: 40 }} maxLength={10} />
                </Form.Item>

                <Form.Item
                  name={['empowerRepresentative', 'email']}
                  label={
                    <span>
                      Email
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Email không được để trống" }]}

                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập email' style={{ height: 40 }} maxLength={50} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </ Card>

        < Card type='inner' title='Xác thực thông tin ủy quyền' style={{ margin: 20 }
        } >
          <Col
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form colon={false}
              form={form}
              onFieldsChange={(_, allFields) => {
                onFormFieldsChange(allFields);
              }}
              labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
              wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
              layout="horizontal"
              disabled={!extract ? true : false}
              requiredMark={false}
            >
              <Form.Item
                // name={['empowerRepresentative', 'cardIssueLocation']}
                name={'fileEmpower'}
                label={
                  <span>
                    Giấy tờ ủy quyền có kí số
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"

              // rules={[yupSync]}
              >
                {
                  companyEntity.empowerRepresentative?.empowerDoc ? <div style={{ display: "flex" }}>
                    <Button onClick={onPreviewFile} type='default' style={{ marginRight: 5, backgroundColor: 'rgb(232, 241, 254)', color: "rgb(24, 119, 242)", border: 'none' }}>{extractName(companyEntity.empowerRepresentative?.empowerDoc)}<EyeTwoTone /></Button>
                    <Button onClick={onDeleteFile}>{t(translations.action.delete)}</Button>
                  </div>
                    :
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Tải lên giấy ủy quyền</Button>
                    </Upload>
                }
              </Form.Item>
              <Form.Item
                name={['empowerRepresentative', 'empowerDateFrom']}
                label={
                  <span>
                    Ngày bắt đầu ủy quyền
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"
                rules={[yupSync, { required: extract ? true : false, message: "Ngày bắt đầu ủy quyền không được để trống" }]}
                style={{ width: '100%' }}
              >
                <DatePicker placeholder='Nhập ngày bắt đầu ủy quyền' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
              </Form.Item>

              <Form.Item
                name={['empowerRepresentative', 'empowerDateTo']}
                label={
                  <span>
                    Ngày kết thúc ủy quyền
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"
                rules={[yupSync, { required: extract ? true : false, message: "Ngày kết thúc ủy quyền không được để trống" }]}
                style={{ width: '100%' }}
              >
                <DatePicker placeholder='Nhập ngày kết thúc ủy quyền' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </Form>
          </Col>

        </ Card>

      </Content >
      <Footer
        style={{
          background: '#fff',
          // position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'inline-block' }}>
          {/* <Button disabled={isLoadingOCR} style={{ margin: '0 8px', height: 40 }} onClick={onSaveEntity}>
            {t(translations.action.save_draft)}
          </Button> */}
          <Button disabled={isLoadingOCR || isLoadingVertify} style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
            {t(translations.action.previous)}
          </Button>
        </div>
        <div style={{ display: 'inline-block' }}>

          <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext} disabled={isLoadingOCR || isLoadingVertify}>
            {t(translations.action.next)}
          </Button>
        </div>
      </Footer>

      <Modal
        open={openModalReviewFile}
        onOk={() => setOpenModalReviewFile(false)}
        onCancel={() => setOpenModalReviewFile(false)}
        title={"Xem chi tiết"}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={() => setOpenModalReviewFile(false)}>Đóng</Button>
          </>
        )}
        width={'50vw'}
        centered={true}
      >
        {pdfFlie && (
          <iframe
            src={pdfFlie}
            width="100%"
            height="700px"
            title="PDF Viewer"
            frameBorder="0"
          />
        )}
      </Modal>
      <Modal
        open={confirmDeleteFile}
        onCancel={() => setConfirmDeleteFile(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40 }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40, backgroundColor: '#ED5E68' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xóa
                </Button>
              </Col>

            </Row>
          </>
        )}
      >
        {/* <Result
          title="Xác nhận xóa file"
          extra={
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xác nhận
                </Button>
              </Col>

            </Row>
          }
        /> */}
        <p style={{ margin: '24px 0', textAlign: 'center' }}>Bạn có chắc chắn muốn xóa ảnh</p>
      </Modal>
    </Spin>
  );
}
