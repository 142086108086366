import { Company } from "./company.model";

export interface CompanyBusinessRegistration {
    id?: string;
    instanceName?: string | null;
    docType?: string | null;
    company?: Company | null;
    taxCode?: string | null;
    issueLocation?: string | null;
    file?: string | null;
    hasOcr?: boolean |null;
    jsonData?: string | null;
    version?: number | null;
    createdBy?: string | null;
    createdDate?: string | null;
    lastModifiedBy?: string | null;
    lastModifiedDate?: string | null;
    deletedBy?: string | null;
    deletedDate?: string | null;
  }

  export const defaultValue: Readonly<CompanyBusinessRegistration> = {};