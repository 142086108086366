import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Storage } from '@/shared/utils/storage-util';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import theme from '@/shared/theme/themeConfig';
import getStore from '@/config/store';
import { registerLocale } from '@/config/translation';
import setupAxiosInterceptors from '@/config/axios-interceptor';
import ErrorBoundary from '@/shared/error/error-boundary';
import { AuthProvider } from "react-oidc-context";
import { getOidcConfig } from './config/keycloak.config';
import { getUserPermissions, loginSuccess, clearAuthentication, getCurrentUser } from './shared/reducers/authentication.reducer';
import {
  persistStore
} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';
import './index.css'

// import { i18n } from './i18n/i18n';
const store = getStore();
registerLocale(store);
let persistor = persistStore(store);
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

const onAuthenticatedCallback = (user: any) => {
  store.dispatch(loginSuccess(user));
  store.dispatch(getUserPermissions());
  store.dispatch(getCurrentUser());
}

const oidcConfig = getOidcConfig(onAuthenticatedCallback)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider {...oidcConfig} >
            {/* <ConfigProvider theme={theme} > */}
              <App />
              
            {/* </ConfigProvider> */}
          </AuthProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



